import queryString from 'query-string';
import { addCacheResistanceToParams } from '../lib/cache';

export default class QuotesClient {
  constructor(client) {
    this.client = client;
    this.resourceUrl = '/quotes';
    this.cancelUrl = '/scrap-car-quote';
    this.marketPlaceUrl = '/market-place';
    this.salvageOfferUrl = '/scrap-car-salvage-offer';
  }

  getSalvageOfferFormConfig = ({ req }) =>
    this.client.get(`${this.salvageOfferUrl}/form-config`, addCacheResistanceToParams(), req);

  getSalvageOfferPrice = data => this.client.post(`${this.salvageOfferUrl}/make-salvage-offer`, data);

  getHistory = ({ limit, offset, req }) =>
    this.client.get(
      `${this.resourceUrl}/history`,
      addCacheResistanceToParams(queryString.stringify({ limit, offset })),
      req
    );

  getActiveQuotes = () => this.client.get(`${this.resourceUrl}/active-quotes`, addCacheResistanceToParams());

  deleteQuote = id => this.client.delete(`${this.resourceUrl}/${id}`);

  cancelQuote = data => {
    const id = data.quoteId;
    return this.client.post(`${this.cancelUrl}/${id}/cancel`, { reason: data.reason });
  };

  amendQuoteCollection = data => {
    const id = data.quoteId;
    return this.client.post(`${this.cancelUrl}/${id}/amend-quote-collection`, { ...data });
  };

  refreshPrice = id => this.client.post(`${this.resourceUrl}/${id}/refresh-price`);

  cancelConditionOffer = data => {
    const id = data.quoteId;
    return this.client.post(`${this.marketPlaceUrl}/customer-cancel-condition-offer`, { quote_id: id });
  };

  cancelConditionOfferByToken = ({ token, req }) =>
    this.client.post(`${this.marketPlaceUrl}/customer-cancel-condition-offer-by-token`, { token }, req);

  captureDetail = data => this.client.postFormData(`${this.cancelUrl}/capture-detail`, data);

  updateCaptureDetail = data => this.client.put(`${this.cancelUrl}/capture-detail`, data);

  sendCaptureFormSMS = data => this.client.post(`${this.cancelUrl}/send-report`, data);

  fetchCaptureForm = (token, req) =>
    this.client.get(`${this.cancelUrl}/fetch-capture-form/${token}`, addCacheResistanceToParams(), req);

  uploadCaptureFormMedia = data => this.client.postFormData(`${this.cancelUrl}/media-upload`, data);

  customerRequestCallBack = data => this.client.post(`${this.cancelUrl}/customer-request-call-back`, data);
  
  uploadSalvageOfferMedia = data => this.client.postFormData(`${this.salvageOfferUrl}/upload-file`, data);

}
