import { combineActions, handleActions } from 'redux-actions';
import immutable, { extend } from 'immutability-helper';

import QuoteTypes from '../action-types/quote';
import { STATUS } from '../constants';

extend('$setIfUpdated', (value, original) => (value === undefined ? original : value));

export const quoteState = {
  registrationNumber: '',
  postcode: '',
  postcode_lat: 0,
  postcode_long: 0,
  addresses: [],
  bookingSlots: [],

  quoteId: '',
  entityId: '',
  quoteToken: '',
  quoteStatus: '',

  phoneNumber: '',
  hasAccount: '',

  firstName: '',
  lastName: '',

  carMake: '',
  carRange: '',
  carModel: '',
  carDerivative: '',
  carWeight: '',
  carPrice: '',
  carImage: '',
  carYear: '',
  carColour: '',

  salvagePrice: '',

  maxMotMileage: '',

  terms: '',
  isAddressManual: false,
  address: '',
  address1: '',
  address2: '',
  addressCityTown: '',
  county: '',
  preferredCollectionDateTime: { date: '', startTime: '', endTime: '' },

  paymentType: '',
  bankFullName: '',
  bankSortCode: '',
  bankAccountNumber: '',

  schedule: [],

  message: '',
  status: '',

  isManual: false,

  isFetchingApplication: false,

  hintText: '',
  offerConditions: [],
  sendSMSButtonDisable: false,
  captureFormVideoUploading: false,

  salvageOfferFormConfig: [],
  salvageOfferDetails: [],
  salvageOfferConditionsData: [],

  offerPendingType: '',
  offerType: '',

  isSalvageOfferQuote: false,

  auction:null
};

export default {
  quote: handleActions(
    {
      // CREATE QUOTE
      [QuoteTypes.CREATE_QUOTE.REQUEST]: (state, { payload: { registrationNumber, postcode } }) =>
        immutable(state, {
          registrationNumber: { $setIfUpdated: registrationNumber },
          postcode: { $setIfUpdated: postcode },
          quoteId: { $set: '' },
          quoteToken: { $set: '' },
          quoteStatus: { $set: '' },
          message: { $set: '' },
          status: { $set: STATUS.RUNNING },
          auction:{$set: null}
        }),
      [QuoteTypes.CREATE_QUOTE.SUCCESS]: (
        state,
        {
          payload: {
            quoteId,
            quoteToken,
            quoteStatus,
            entityId,
            isManual,
            hintText,
            image,
            salvagePrice,
            maxMotMileage,
            auction
          },
        },
      ) =>
        immutable(state, {
          quoteId: { $set: quoteId },
          entityId: { $set: entityId },
          quoteToken: { $set: quoteToken },
          quoteStatus: { $set: quoteStatus },
          status: { $set: STATUS.SUCCESS },
          isManual: { $set: isManual },
          carImage: { $set: image },
          hintText: { $set: hintText },
          salvagePrice: { $set: salvagePrice },
          maxMotMileage: { $set: maxMotMileage },
          auction:{$set: auction}
        }),
      [QuoteTypes.CREATE_QUOTE.ERROR]: state =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),
      [QuoteTypes.CREATE_QUOTE_WITHOUT_INSTANT_PRICE.actionName]:(
        state,
        {
          payload: {
            quoteId,
            quoteToken,
            quoteStatus,
            image
          },
        },
      ) =>
        immutable(state, {
          quoteId: { $set: quoteId },
          quoteToken: { $set: quoteToken },
          quoteStatus: { $set: quoteStatus },
          carImage: { $set: image },
        }),
      [QuoteTypes.HANDLE_QUOTE_UNAUTHORIZED_STATUS.actionName]: state =>
        immutable(state, {
          quoteStatus: { $set: '401' },
        }),
      [QuoteTypes.HANDLE_QUOTE_FORBIDDEN_STATUS.actionName]: state =>
        immutable(state, {
          quoteStatus: { $set: '403' },
        }),
      [QuoteTypes.HANDLE_QUOTE_BAD_STATUS.actionName]: state =>
        immutable(state, {
          quoteStatus: { $set: '400' },
        }),
      // Salvage offer
      [QuoteTypes.GET_SALVAGE_OFFER_PRICE.SUCCESS]: (
        state,
        {
          payload: {
            scrapQuoteId,
            price,
            mobile,
            salvagePrice = '',
            offerPendingType = '',
            salvageOfferDetails = [],
            status,
            entityId,
            isManual,
          },
        },
      ) =>
        immutable(state, {
          quoteId: { $set: scrapQuoteId },
          quoteStatus: { $setIfUpdated: status },
          carPrice: { $setIfUpdated: price },
          phoneNumber: { $set: mobile },
          salvagePrice: { $set: salvagePrice },
          entityId: { $set: entityId },
          isManual: { $set: isManual },
          status: { $set: STATUS.SUCCESS },
          offerPendingType: { $set: offerPendingType },
          salvageOfferDetails: { $set: salvageOfferDetails },
        }),

      // ENTER DETAILS
      [QuoteTypes.ENTER_DETAILS.REQUEST]: state =>
        immutable(state, {
          message: { $set: '' },
          status: { $set: STATUS.RUNNING },
        }),
      [QuoteTypes.ENTER_DETAILS.SUCCESS]: (
        state,
        {
          payload: {
            quoteId,
            quoteToken,
            carMake,
            carRange,
            carModel,
            carDerivative,
            registrationNumber,
            phoneNumber,
            carWeight,
            postcode,
            carPrice,
            quoteStatus,
            carImage,
            carYear,
            carColour,
            hasAccount,
          },
        },
      ) =>
        immutable(state, {
          registrationNumber: { $setIfUpdated: registrationNumber },
          phoneNumber: { $setIfUpdated: phoneNumber },
          postcode: { $setIfUpdated: postcode },
          quoteId: { $set: quoteId },
          quoteToken: { $set: quoteToken },
          quoteStatus: { $setIfUpdated: quoteStatus },
          carMake: { $setIfUpdated: carMake },
          carRange: { $setIfUpdated: carRange },
          carModel: { $setIfUpdated: carModel },
          carDerivative: { $setIfUpdated: carDerivative },
          carWeight: { $setIfUpdated: carWeight },
          carPrice: { $setIfUpdated: carPrice },
          carImage: { $setIfUpdated: carImage },
          carYear: { $setIfUpdated: carYear },
          carColour: { $setIfUpdated: carColour },
          hasAccount: { $setIfUpdated: hasAccount },
          message: { $set: '' },
          status: { $set: STATUS.SUCCESS },
        }),

      [QuoteTypes.ENTER_DETAILS.ERROR]: state =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),

      // SET ONLINE QUOTE
      [QuoteTypes.SET_ONLINE_QUOTE.REQUEST]: (state, { payload: { quoteStatus } }) =>
        immutable(state, {
          quoteStatus: { $setIfUpdated: quoteStatus },
          message: { $set: '' },
          status: { $set: STATUS.SUCCESS },
        }),
      [QuoteTypes.SET_ONLINE_QUOTE.SUCCESS]: (state, { payload: { quoteStatus } }) =>
        immutable(state, {
          quoteStatus: { $setIfUpdated: quoteStatus },
          message: { $set: '' },
          status: { $set: STATUS.SUCCESS },
        }),
      [QuoteTypes.SET_ONLINE_QUOTE.ERROR]: state =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),

      // CANCEL QUOTE / RE QUOTE
      [combineActions(QuoteTypes.CANCEL_QUOTE.actionName, QuoteTypes.RE_QUOTE)]: () => quoteState,

      // ARRANGE A COLLECTION
      [QuoteTypes.SELECT_SLOT]: (state, { payload }) =>
        immutable(state, {
          preferredCollectionDateTime: { $set: payload },
        }),
      [QuoteTypes.GET_BOOKING_SLOTS.REQUEST]: state =>
        immutable(state, {
          message: { $set: '' },
          ...(state.bookingSlots?.length > 0 ? { bookingSlots: { $set: [] } } : {}),
        }),
      [QuoteTypes.GET_BOOKING_SLOTS.SUCCESS]: (state, { payload: items }) =>
        immutable(state, {
          bookingSlots: { $set: items },
        }),

      [QuoteTypes.ARRANGE_COLLECTION.REQUEST]: (
        state,
        { payload: { phoneNumber, terms, address, address1, address2, town, county, preferredCollectionDateTime } },
      ) =>
        immutable(state, {
          phoneNumber: { $set: phoneNumber },
          terms: { $set: terms },
          address: { $set: address },
          address1: { $set: address1 },
          address2: { $set: address2 },
          addressCityTown: { $set: town },
          county: { $set: county },
          preferredCollectionDateTime: { $set: preferredCollectionDateTime },
          message: { $set: '' },
          status: { $set: STATUS.RUNNING },
        }),

      [QuoteTypes.ARRANGE_COLLECTION.SUCCESS]: (state, { payload: { quoteId, quoteToken, quoteStatus, salvageOfferConditionsData = [], offerType = '' } }) =>
        immutable(state, {
          quoteId: { $setIfUpdated: quoteId },
          quoteToken: { $setIfUpdated: quoteToken },
          quoteStatus: { $setIfUpdated: quoteStatus },
          offerType: { $set: offerType },
          salvageOfferConditionsData: { $set: salvageOfferConditionsData },
          status: { $set: STATUS.SUCCESS },
        }),

      [QuoteTypes.ARRANGE_COLLECTION.ERROR]: state =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),

      // GET ADDRESSES BY POSTCODE
      [QuoteTypes.GET_ADDRESSES_BY_POSTCODE.REQUEST]: state =>
        immutable(state, {
          message: { $set: '' },
          status: { $set: STATUS.RUNNING },
        }),

      // reset Addresses
      [QuoteTypes.RESET_ADDRESSES]: state =>
        immutable(state, {
          addresses: { $set: [] },
        }),

      [QuoteTypes.GET_ADDRESSES_BY_POSTCODE.SUCCESS]: (state, { payload: { latitude, longitude, addresses } }) =>
        immutable(state, {
          postcode_lat: { $set: latitude },
          postcode_long: { $set: longitude },
          addresses: { $set: addresses },
          status: { $set: STATUS.SUCCESS },
        }),

      [QuoteTypes.GET_ADDRESSES_BY_POSTCODE.ERROR]: state =>
        immutable(state, {
          status: { $set: !STATUS.ERROR ? 'No results' : STATUS.ERROR },
        }),

      // SET PAYMENT METHOD
      [QuoteTypes.SET_PAYMENT_METHOD.REQUEST]: (
        state,
        { payload: { paymentType, bankFullName, bankSortCode, bankAccountNumber,referralCode } },
      ) =>
        immutable(state, {
          paymentType: { $set: paymentType },
          bankFullName: { $set: bankFullName },
          bankSortCode: { $set: bankSortCode },
          bankAccountNumber: { $set: bankAccountNumber },
          referralCode: { $set: referralCode },
          message: { $set: '' },
          status: { $set: STATUS.RUNNING },
        }),

      [QuoteTypes.SET_PAYMENT_METHOD.SUCCESS]: (
        state,
        { payload: { quoteId, quoteStatus, carPrice, schedule, isSalvageOfferQuote } },
      ) =>
        immutable(state, {
          quoteId: { $setIfUpdated: quoteId },
          quoteStatus: { $setIfUpdated: quoteStatus },
          carPrice: { $setIfUpdated: carPrice },
          schedule: { $setIfUpdated: schedule },
          status: { $set: STATUS.SUCCESS },
          isSalvageOfferQuote: { $set: !!isSalvageOfferQuote },
        }),

      [QuoteTypes.SET_PAYMENT_METHOD.ERROR]: state =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),

      [QuoteTypes.SET_PAYMENT_METHOD_INPUT_CHANGE_SEGMENT.REQUEST]: (
        state,
        { payload: { paymentType, bankFullName, bankSortCode, bankAccountNumber, referralCode } },
      ) =>
        immutable(state, {
          paymentType: { $set: paymentType },
          bankFullName: { $set: bankFullName },
          bankSortCode: { $set: bankSortCode },
          bankAccountNumber: { $set: bankAccountNumber },
          referralCode: { $set: referralCode },
          message: { $set: '' },
          status: { $set: STATUS.RUNNING },
        }),

      // COMPLETE ARRANGE COLLECTION
      [QuoteTypes.COMPLETE_ARRANGE_COLLECTION.actionName]: state =>
        immutable(state, {
          message: { $set: '' },
          status: { $set: STATUS.SUCCESS },
        }),

      // DROP QUOTE
      [QuoteTypes.DROP_QUOTE.actionName]: () => quoteState,

      [combineActions(QuoteTypes.CONTINUE_WITH_QUOTE.REQUEST, QuoteTypes.CONTINUE_WITH_QUOTE_SHORT_LINK.REQUEST)]: (
        state,
        { payload: { token } },
      ) =>
        immutable(state, {
          message: { $set: '' },
          status: { $set: STATUS.RUNNING },
          quoteStatus: { $set: '' },
          quoteToken: { $setIfUpdated: token },
        }),

      [QuoteTypes.REFRESH_PRICE.REQUEST]: state =>
        immutable(state, {
          message: { $set: '' },
          status: { $set: STATUS.RUNNING },
          quoteStatus: { $set: '' },
        }),

      [combineActions(
        QuoteTypes.CONTINUE_WITH_QUOTE.SUCCESS,
        QuoteTypes.CONTINUE_WITH_QUOTE_SHORT_LINK.SUCCESS,
        QuoteTypes.REFRESH_PRICE.SUCCESS,
        QuoteTypes.CONTINUE_WITH_CAPTURE_SHORT_LINK.SUCCESS,
      )]: (
        state,
        {
          payload: {
            scrapQuoteId,
            registration,
            postcode,
            quoteToken,
            status,
            hasAccount,
            carMake,
            carRange,
            carModel,
            carDerivative,
            weight,
            price,
            salvagePrice,
            maxMotMileage,
            image,
            year,
            color,
            mobile,
            email,
            entityId,
            isManual = false,
            redirect = false,
            isRequestPhotos = false,
            isRequestPhoneNumber = false,
            medias = [],
            offerConditions = [],
            offerType = '',
            salvageOfferDetails = [],
            salvageOfferConditionsData = [],
            offerPendingType = '',
            auction,
          },
        },
      ) =>
          immutable(state, {
            registrationNumber: { $set: registration },
            postcode: { $set: postcode },
            quoteId: { $set: scrapQuoteId },
            quoteToken: { $set: quoteToken },
            quoteStatus: { $set: status },
            hasAccount: { $set: hasAccount },
            carMake: { $set: carMake },
            carRange: { $set: carRange },
            carModel: { $set: carModel },
            carDerivative: { $set: carDerivative ?? '' },
            carWeight: { $set: weight },
            carPrice: { $set: price },
            salvagePrice: { $set: salvagePrice },
            maxMotMileage: { $set: maxMotMileage },
            carImage: { $set: image },
            carYear: { $set: year },
            carColour: { $set: color },
            phoneNumber: { $set: mobile },
            status: { $set: STATUS.SUCCESS },
            email: { $set: email },
            entityId: { $set: entityId },
            isManual: { $set: isManual },
            redirect: { $set: redirect },
            offerConditions: { $set: offerConditions },
            offerPendingType: { $set: offerPendingType },
            isRequestPhotos: { $set: isRequestPhotos },
            isRequestPhoneNumber: { $set: isRequestPhoneNumber },
            medias: { $set: medias },
            offerType: { $set: offerType },
            salvageOfferDetails: { $set: salvageOfferDetails },
            salvageOfferConditionsData: { $set: salvageOfferConditionsData },
            auction: { $set: auction },
          }),

      [combineActions(
        QuoteTypes.CONTINUE_WITH_QUOTE.ERROR,
        QuoteTypes.CONTINUE_WITH_QUOTE_SHORT_LINK.ERROR,
        QuoteTypes.REFRESH_PRICE.ERROR,
      )]: (state, { payload: { quoteStatus } }) =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
          quoteStatus: { $setIfUpdated: quoteStatus },
        }),

      [QuoteTypes.SET_MANUAL_ADDRESS.actionName]: (state, { payload: { isAddressManual } }) =>
        immutable(state, {
          isAddressManual: { $setIfUpdated: isAddressManual },
          status: { $set: STATUS.SUCCESS },
        }),

      [QuoteTypes.FETCH_SCRAP_APPLICATION.REQUEST]: state =>
        immutable(state, {
          isFetchingApplication: { $set: true },
        }),

      [QuoteTypes.FETCH_SCRAP_APPLICATION.ERROR]: state =>
        immutable(state, {
          isFetchingApplication: { $set: false },
        }),

      [QuoteTypes.FETCH_SCRAP_APPLICATION.SUCCESS]: (state, { payload }) => ({
        ...state,
        ...convertDataFormat(state, payload),
        isFetchingApplication: false,
      }),

      [QuoteTypes.SET_SEND_SMS_BUTTON_STATUS.REQUEST]: (state, { payload }) => {
        return immutable(state, {
          sendSMSButtonDisable: { $set: payload },
        });
      },

      [QuoteTypes.UPLOAD_CAPTURE_FORM_VIDEO.REQUEST]: state => {
        return immutable(state, {
          captureFormVideoUploading: { $set: true },
        });
      },
      [QuoteTypes.UPLOAD_CAPTURE_FORM_VIDEO.SUCCESS]: state => {
        return immutable(state, {
          captureFormVideoUploading: { $set: false },
        });
      },
      [QuoteTypes.GET_SALVAGE_OFFER_FORM_CONFIG.SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          salvageOfferFormConfig: { $set: payload },
        });
      },
    },
    quoteState,
  ),
};

function convertDataFormat(
  state,
  {
    registration,
    scrapQuoteId,
    status,
    image,
    color,
    weight,
    price,
    salvagePrice,
    year,
    mobile,
    date,
    startTime,
    endTime,
    ...rest
  },
) {
  const preferredCollectionDateTime = date
    ? {
        date,
        startTime,
        endTime,
      }
    : state.preferredCollectionDateTime;
  return {
    registrationNumber: registration,
    quoteId: scrapQuoteId,
    quoteStatus: status,
    carImage: image,
    carColour: color,
    carWeight: weight,
    carPrice: price,
    ...(salvagePrice ? { salvagePrice } : {}),
    carYear: year,
    phoneNumber: mobile,
    preferredCollectionDateTime,
    ...rest,
  };
}
