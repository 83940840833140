const mobileNumberFormat = mobile => {
  if (!mobile) return null;
  let mobileNumber;
  if (mobile.substr(0, 2) === '07') {
    mobileNumber = `+44${mobile.substr(2)}`;
  }
  if (mobile.substr(0, 4) === '0044') {
    mobileNumber = `+44${mobile.substr(4)}`;
  }
  return mobileNumber;
};

export default mobileNumberFormat;
