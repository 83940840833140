import { call, put, select, takeEvery } from 'redux-saga/effects';
import { warranties } from 'api-client/apiClientInstance/warranties';
import { reportToBugsnag } from 'lib/bugsnag';
import { getRootModalState } from 'store/selectors/modal';
import { modalClose } from 'store/actions/modal';
import { showToast } from 'store/actions/toasts';
import WarrantiesQuotesTypes from '../action-types/warrantiesQuotes';

export function* getQuotesHistory({ payload }) {
  const { data, req, resetList } = payload;
  try {
    const response = yield call(
      warranties.getQuotesHistory,
      {
        ...data,
      },
      req
    );
    yield put({
      type: WarrantiesQuotesTypes.GET_WARRANTIES_QUOTES_HISTORY.SUCCESS,
      payload: { ...response, resetList },
    });
  } catch (err) {
    reportToBugsnag(err, 'getWarrantiesQuotesHistory');

    yield put({
      type: WarrantiesQuotesTypes.GET_WARRANTIES_QUOTES_HISTORY.ERROR,
      payload: err,
    });
  }
}

export function* deleteWarrantiesQuote({ payload }) {
  const { quoteId } = payload;
  try {
    yield call(warranties.deleteQuote, quoteId);
    yield put(
      showToast({
        message: 'Quote has been successfully deleted.',
        kind: 'success',
      })
    );
    yield put({
      type: WarrantiesQuotesTypes.DELETE_WARRANTIES_QUOTE.SUCCESS,
      payload: { quoteId },
    });
    const modalState = yield select(getRootModalState());
    if (modalState.isModalOpen) {
      yield put(modalClose());
    }
  } catch (err) {
    reportToBugsnag(err, 'deleteWarrantiesQuote');

    yield put({
      type: WarrantiesQuotesTypes.DELETE_WARRANTIES_QUOTE.ERROR,
      payload: err,
    });
  }
}

export default [
  takeEvery(WarrantiesQuotesTypes.GET_WARRANTIES_QUOTES_HISTORY.REQUEST, getQuotesHistory),
  takeEvery(WarrantiesQuotesTypes.DELETE_WARRANTIES_QUOTE.REQUEST, deleteWarrantiesQuote),
];
