import { createAsyncActionSet } from '../lib/action-helper';

export default {
  FETCH_CMS: createAsyncActionSet('FETCH_CMS'),
  MULTI_FETCH_CMS: createAsyncActionSet('MULTI_FETCH_CMS'),
  FETCH_TAXONOMY_CATEGORIES: createAsyncActionSet('FETCH_TAXONOMY_CATEGORIES'),
  FETCH_TAXONOMY_MANUFACTURERS: createAsyncActionSet('FETCH_TAXONOMY_MANUFACTURERS'),
  RESOLVE_CMS_ENTITY: createAsyncActionSet('RESOLVE_CMS_ENTITY'),
  FETCH_CMS_INDIVIDUAL: createAsyncActionSet('FETCH_CMS_INDIVIDUAL'),
};
