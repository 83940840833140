import React from 'react';
import PropTypes from 'prop-types';
import BaseConfirmationPopup from 'components/BaseConfirmationPopup';

const ConfirmPopup = ({ title = '', description = '', onConfirm }) => {
  return <BaseConfirmationPopup title={title} description={description} confirmAction={onConfirm} />;
};
ConfirmPopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default ConfirmPopup;
