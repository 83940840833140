export default class AwaitingCollection {
  constructor(client) {
    this.client = client;
  }

  // Mocks
  getAwaitingCollectionCarList = () => {};

  getCollectionDetails = () => {};
}
