import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getScrappedCarsStatus } from 'store/selectors/scrappedCars';
import { deleteQuoteRequest } from 'store/actions/scrappedCars';
import { STATUS } from 'store/constants';
import BaseConfirmationPopup from '../../../components/BaseConfirmationPopup';

const DeleteQuotePopup = () => {
  const dispatch = useDispatch();
  const deleteQuote = () => dispatch(deleteQuoteRequest());
  const status = useSelector(getScrappedCarsStatus);
  return (
    <BaseConfirmationPopup
      title={`Are you sure you <strong>want to delete</strong> this scrap car quote?`}
      description="By hitting yes, your scrap car quote will be permanently deleted and can not be recovered"
      confirmAction={deleteQuote}
      isConfirmButtonDisabled={status === STATUS.RUNNING}
    />
  );
};

export default DeleteQuotePopup;
