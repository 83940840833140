import { takeLatest } from 'redux-saga/effects';
import { Router } from 'server/pages';
import ActionTypes from 'store/action-types/numberPlate';
import { CHECK_OUT_URL } from 'containers/plateCheckout/constant';

export function* redirectToPersonDetail() {
  Router.pushRoute(CHECK_OUT_URL.PERSONAL_DETAIL);
}

export function* goHome() {
  Router.pushRoute(CHECK_OUT_URL.HOME);
}

export default [
  takeLatest([ActionTypes.CREATE_CART.SUCCESS], redirectToPersonDetail),
  takeLatest(ActionTypes.GO_HOME, goHome),
];
