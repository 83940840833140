export default class NewLocations {
  constructor(client) {
    this.client = client;
  }

  getLocations = (query, expandAddress) => {
    const fullQuery = `${query}?api-key=GORjJgurkkWzU8zCMKHhyg22615${expandAddress ? '&expand=true' : ''}`;
    return this.client.get(fullQuery);
  };
}
