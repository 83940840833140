export default class NumberPlateClient {
  constructor(client) {
    this.client = client;
    this.resourceUrl = '/number-plate-cart';
    this.numberPlateUrl = '/number-plate';
    this.searchUrl = '';
  }

  checkReg = data => this.client.post(`${this.resourceUrl}/check-registration-available`, data);

  checkUserExists = email => this.client.post(`${this.resourceUrl}/check-user-exists`, { email });

  getUserAccess = email => this.client.post(`${this.resourceUrl}/get-user-access`, { email });

  IsNewUser = email => this.client.post(`${this.resourceUrl}/is-new-user`, { email });

  numberPlateSearch = ({ keyword, start, sort = '', fq = '', rows = 60 }) =>
    this.client.numberPlateGet(
      `${this.searchUrl}?rows=${rows}&q=${keyword.toUpperCase()}&start=${start}&sort=${sort}&${fq}`,
    );

  numberPlateFilter = ({ keyword, page, filter, order, pagesize = 25 }) =>
    this.client.post(`${this.numberPlateUrl}/search`, {
      params: {
        order,
        filter,
        pagesize,
        page,
        search: keyword.toUpperCase(),
      },
    });

  numberPlateFirstSearch = ({ keyword, filter, order, pagesize = 25 }) =>
    this.client.post(`${this.numberPlateUrl}/search`, {
      params: {
        pagesize,
        filter,
        page: 1,
        order,
        search: keyword.toUpperCase(),
      },
    });

  sendEnquireRequest = data => this.client.post(`${this.numberPlateUrl}/enquire`, data);
}
