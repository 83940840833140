import { createAsyncActionSet } from '../lib/action-helper';

export default {
  GET_LIVE_AUCTION_CAR_LIST: createAsyncActionSet('GET_LIVE_AUCTION_CAR_LIST'),
  RESET_LIVE_AUCTION_CAR_LIST: 'RESET_LIVE_AUCTION_CAR_LIST',
  SET_LIVE_AUCTION_CAR_LIST_PAGING: 'SET_LIVE_AUCTION_CAR_LIST_PAGING',
  RESET_LIVE_AUCTION_CAR_LIST_PAGING: 'RESET_LIVE_AUCTION_CAR_LIST_PAGING',
  SET_LIVE_AUCTION_CAR_LIST_FILTER: 'SET_LIVE_AUCTION_CAR_LIST_FILTER',
  DELETE_LIVE_AUCTION_CAR_LIST: 'DELETE_LIVE_AUCTION_CAR_LIST',
};
