import { createSelector } from 'reselect';

export const getMyCar = state => state.myCar;

export const getMyCarsSelector = createSelector(getMyCar, myCar => myCar?.allCars);

export const getSelectedCarSelector = createSelector(getMyCar, myCar => myCar?.selectedCar);

export const getMyCarStatusSelector = createSelector(getMyCar, myCar => myCar?.status);

export const getMyCarDateTargetSelector = createSelector(getMyCar, myCar => myCar?.updateDateTarget);
