import { put, take, takeLatest, call, select, all } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { reportToBugsnag} from 'lib/bugsnag';
import { makeGetFAQCats } from 'store/selectors/faq';
import FaqTypes from '../action-types/faq';
import CmsTypes from '../action-types/cms';
import { wrapWith, extractStoreIds } from '../lib/mergeToStateHelper';
import uuid from '../lib/uuidv4-builder';
import { SOME_ERROR_OCCURRED } from '../constants';

const DATA_INCLUDES = ['type', 'revision_uid', 'uid', 'menu_link', 'faq_category'];
const DATA_FILTER_KEY = '[parent.section][value]';
const DATA_ITEM_FILTER_KEY = '[faq_category.parent.section][value]';
const CATEGORY_FILTER_KEY = '[faq_category.name][value]';
const CATEGORY_ID_FILTER_KEY = '[faq_category.id][value]';
const SECTION = 'faq';
const DATA_TYPE = 'items';
const SORT = '-date';
const SORT_CATEGORIES_BY_WEIGHT = 'weight';
import { subRequestCms } from './cms';

export function* getFAQData({ payload }) {
  const fingerprint = `${uuid()}faqData`;
  const chan = yield call(channel);
  try {
    const { includes: specificInc = [], faqType, category, filter: specificFilter = {}, ...rest } = payload;
    const includes = [...DATA_INCLUDES, ...specificInc];
    const filter = { [DATA_ITEM_FILTER_KEY]: faqType, ...specificFilter };
    if (category) {
      filter[CATEGORY_FILTER_KEY] = category.replace(/-/g, ' ');
    }
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { section: SECTION, type: DATA_TYPE, includes, filter, sort: SORT, ...rest },
      fingerprint,
      chan,
    });
    const {
      payload: { data, links },
      path,
    } = yield take(chan); // ({ type, fingerprint: fp }) => type === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp);
    yield put({
      type: FaqTypes.GET_FAQ.SUCCESS,
      payload: wrapWith(faqType, extractStoreIds(data, path, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getFAQData');


    yield put({
      type: FaqTypes.GET_FAQ.ERROR,
      payload: err,
    });
  }
}

export function* getFAQCategories({ payload }) {
  const STORE_AS = 'categories';
  const fingerprint = 'faqCategories';
  const chan = yield call(channel);
  try {
    const { includes: specificInc = [], faqType, ...rest } = payload;
    const includes = [...specificInc];
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: {
        section: 'faq',
        type: 'categories',
        filter: { [DATA_FILTER_KEY]: faqType },
        includes,
        sort: SORT_CATEGORIES_BY_WEIGHT,
        ...rest,
      },
      fingerprint,
      chan,
    });
    const {
      payload: { data, links },
      path,
    } = yield take(chan); // ({ type, fingerprint: fp }) => type === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp);
    yield put({
      type: FaqTypes.GET_FAQ_CATEGORIES.SUCCESS,
      payload: wrapWith(STORE_AS, wrapWith(faqType, extractStoreIds(data, path, links))),
    });
  } catch (err) {
    reportToBugsnag(err, 'getFAQCategories');


    yield put({
      type: FaqTypes.GET_FAQ_CATEGORIES.ERROR,
      payload: err,
    });
  }
}

export function* searchFaq({ payload }) {
  const fingerprint = 'faqSearch';
  const STORE_AS = 'search';
  const FILTER_GROUP_CONJUNCTION = 'OR';
  const FILTER_GROUP_TITLE = 'text-search';
  const FILTER_OPERATOR = 'CONTAINS';
  const FILTER_FIELDS = ['title', 'summary.value'];
  const chan = yield call(channel);
  try {
    const { includes: specificInc = [], faqType, filter: specificFilter = {}, search, ...rest } = payload;
    const searchFilter = FILTER_FIELDS.reduce(
      (accumulator, field) => ({
        ...accumulator,
        [`[${field}][condition][path]`]: field,
        [`[${field}][condition][operator]`]: FILTER_OPERATOR,
        [`[${field}][condition][value]`]: search,
        [`[${field}][condition][memberOf]`]: FILTER_GROUP_TITLE,
      }),
      { [`[${FILTER_GROUP_TITLE}][group][conjunction]`]: FILTER_GROUP_CONJUNCTION },
    );
    const includes = [...DATA_INCLUDES, ...specificInc];
    const filter = { [DATA_ITEM_FILTER_KEY]: faqType, ...searchFilter, ...specificFilter };
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { section: SECTION, type: DATA_TYPE, includes, filter, ...rest },
      fingerprint,
      chan,
    });
    const {
      payload: { data, links },
      path,
    } = yield take(chan); // ({ type, fingerprint: fp }) => type === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp);
    yield put({
      type: FaqTypes.SEARCH_FAQ.SUCCESS,
      payload: wrapWith(STORE_AS, wrapWith(faqType, extractStoreIds(data, path, links))),
    });
  } catch (err) {
    reportToBugsnag(err, 'searchFaq');


    yield put({
      type: FaqTypes.SEARCH_FAQ.ERROR,
      payload: err,
    });
  }
}

export function* faqLanding({ payload: { faqType } }) {
  const STORE_AS = 'landing';
  const chan = yield call(channel);
  try {
    yield call(getFAQCategories, { payload: { faqType } });
    const categories = yield select(makeGetFAQCats(faqType));
    const multiPayload = categories.map(category => {
      // const includes = [...DATA_INCLUDES, ...specificInc];
      const limit = { limit: 10 };
      const filter = { [DATA_ITEM_FILTER_KEY]: faqType };
      if (category) {
        filter[CATEGORY_ID_FILTER_KEY] = category.id;
      }
      return { key: category.id, section: SECTION, prefix: DATA_TYPE, includes: [], filter, sort: SORT, limit };
    });
    const { payload: data } = yield subRequestCms({
      type: CmsTypes.MULTI_FETCH_CMS.REQUEST,
      payload: multiPayload,
      chan,
    });
    const out = {};
    let allByType = [];
    const mapped = categories.map(category => {
      const items = data[category.id];
      if (items) {
        try {
          const { data: itemData, links } = items;
          const byIds = extractStoreIds(itemData, undefined, links);
          out[category.id] = byIds;
          allByType = [...allByType, ...byIds.byId];
          return put({
            type: CmsTypes.FETCH_CMS.SUCCESS,
            payload: items,
          });
        } catch (e) {
          reportToBugsnag(e, 'extractStoreIds');


        }
      }
      return items;
    });
    yield all(mapped);
    yield put({
      type: FaqTypes.GET_FAQ.SUCCESS,
      payload: wrapWith(faqType, { byId: allByType, links: {} }),
    });
    yield put({
      type: FaqTypes.FAQ_LANDING.SUCCESS,
      payload: wrapWith(STORE_AS, wrapWith(faqType, out)),
    });
  } catch (err) {
    reportToBugsnag(err, 'faqLanding');


    yield put({
      type: FaqTypes.FAQ_LANDING.ERROR,
      payload: err,
    });
  }
}

export function* getByPath({ payload }) {
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.RESOLVE_CMS_ENTITY.REQUEST,
      payload,
      chan,
    });
    const {
      payload: { data },
      path,
    } = yield take(chan);
    yield put({
      type: FaqTypes.GET_FAQ_BY_PATH.SUCCESS,
      payload: extractStoreIds(data, path),
    });
  } catch (err) {
    reportToBugsnag(err, 'faqLanding');


    yield put({
      type: FaqTypes.GET_FAQ_BY_PATH.ERROR,
      payload: err,
    });
  }
}

export default [
  takeLatest(FaqTypes.GET_FAQ.REQUEST, getFAQData),
  takeLatest(FaqTypes.SEARCH_FAQ.REQUEST, searchFaq),
  takeLatest(FaqTypes.FAQ_LANDING.REQUEST, faqLanding),
  takeLatest(FaqTypes.GET_FAQ_BY_PATH.REQUEST, getByPath),
  takeLatest(FaqTypes.GET_FAQ_CATEGORIES.REQUEST, getFAQCategories),
];
