import { put, take, takeLatest, call } from 'redux-saga/effects';
import { channel, END } from 'redux-saga';
import { reportToBugsnag } from 'lib/bugsnag';
import { homePage } from 'api-client/apiClientInstance/homePage';
import ScrapMyCarTypes from '../action-types/scrapMyCar';
import { snakeToCamelCase } from '../lib/snakeToCamelCase';
import CmsTypes from '../action-types/cms';
import { wrapWith, extractStoreIds } from '../lib/mergeToStateHelper';
import { SOME_ERROR_OCCURRED } from '../constants';

export function* getCMSData({ payload }) {
  const STORE_AS = 'scrapMyCarPage';
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { section: 'scrapMyCar', ...payload },
      chan,
    });
    const {
      payload: { data, links },
      path,
    } = yield take(chan);
    yield put({
      type: ScrapMyCarTypes.GET_SCRAP_MY_CAR_PAGES.SUCCESS,
      payload: wrapWith(STORE_AS, extractStoreIds(data, path, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getCMSData');

    yield put({
      type: ScrapMyCarTypes.GET_SCRAP_MY_CAR_PAGES.ERROR,
      payload: err,
    });
  }
}

export function* getManufacturers() {
  const STORE_AS = 'allManufacturers';
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.FETCH_TAXONOMY_MANUFACTURERS.REQUEST,
      chan,
    });
    const {
      payload: { data, links, type },
      path,
    } = yield take(chan);
    yield put({
      type: ScrapMyCarTypes.GET_SCRAP_MY_CAR_MANUFACTURERS.SUCCESS,
      payload: wrapWith(STORE_AS, wrapWith(type, extractStoreIds(data, path, links))),
    });
  } catch (err) {
    reportToBugsnag(err, 'getManufacturers');

    yield put({
      type: ScrapMyCarTypes.GET_SCRAP_MY_CAR_MANUFACTURERS.ERROR,
      payload: err,
    });
  }
}

export function* getSettings() {
  const fingerprint = 'scrapMyCarSettings';
  const chan = yield call(channel);
  try {
    const type = 'settings';
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { section: 'scrap-my-car', type, includes: ['homepage', 'top_banner'] },
      fingerprint,
      chan,
    });
    const fullData = yield take(chan);
    //   ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp,
    // );
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: ScrapMyCarTypes.GET_SCRAP_MY_CAR_SETTINGS.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getSettings');

    yield put({
      type: ScrapMyCarTypes.GET_SCRAP_MY_CAR_SETTINGS.ERROR,
      payload: err,
    });
  }
}

export function* getIndividualByPath({ payload: { specificIncludes, ...payload } }) {
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.RESOLVE_CMS_ENTITY.REQUEST,
      payload,
      chan,
    });
    const {
      payload: {
        data,
        path: resourcePath,
        jsonapi: { resourceName },
      },
      path,
    } = yield take(chan);
    let outData = data;
    if (resourceName !== 'scrap_car_page') {
      const key = snakeToCamelCase(resourceName);
      const includes = specificIncludes[key];
      yield put({
        type: CmsTypes.FETCH_CMS_INDIVIDUAL.REQUEST,
        payload: { path: resourcePath, includes },
        path,
      });
      const {
        payload: { data: includedData },
      } = yield take(CmsTypes.FETCH_CMS_INDIVIDUAL.SUCCESS);
      outData = includedData;
    }
    yield put({
      type: ScrapMyCarTypes.GET_SCRAP_MY_CAR_BY_PATH.SUCCESS,
      payload: extractStoreIds(outData, path),
    });

    // [Service side call]: we need end channel inside saga, we cannot put it on root page call.
    if (!path.includes('/faqs')) {
      yield put(END);
    }
  } catch (err) {
    reportToBugsnag(err, 'getIndividualByPath');

    yield put({
      type: ScrapMyCarTypes.GET_SCRAP_MY_CAR_BY_PATH.ERROR,
      payload: err,
    });
  }
}

export function* getNearestLocations({ payload: { lat, lng, range } }) {
  const fingerprint = 'nearestLocations';
  const storeAs = 'nearestLocation';
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { type: 'nearest-locations', section: 'scrap-my-car', id: `${lat},${lng}<=${range}`, includes: [] },
      storeAs: 'nearestLocation',
      fingerprint,
      chan,
    });
    const fullData = yield take(chan);
    //   ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp,
    // );
    const {
      payload: { data },
    } = fullData;
    yield put({
      type: ScrapMyCarTypes.GET_NEAREST_LOCATIONS.SUCCESS,
      payload: wrapWith(storeAs, extractStoreIds(Object.values(data, undefined))),
      storeAs,
      fingerprint,
    });
  } catch (err) {
    reportToBugsnag(err, 'getNearestLocations');

    yield put({
      type: ScrapMyCarTypes.GET_NEAREST_LOCATIONS.ERROR,
      payload: err,
      storeAs,
      fingerprint,
    });
  }
}

export function* fetchMedias({ chan }) {
  let out = {};
  try {
    const data = yield call(homePage.get, '/media?category=guides,blogs&limit=9&mediaType=scrapping-your-car');
    out = {
      type: ScrapMyCarTypes.GET_SCRAP_MY_CAR_MEDIAS.SUCCESS,
      payload: { medias: data },
    };
  } catch (err) {
    reportToBugsnag(err, 'fetchMedias');

    out = {
      type: ScrapMyCarTypes.GET_SCRAP_MY_CAR_MEDIAS.ERROR,
      payload: err,
    };
  }
  yield put(out);
  if (chan) {
    yield put(chan, out);
  }
}

export default [
  takeLatest(ScrapMyCarTypes.GET_SCRAP_MY_CAR_BY_TYPE.REQUEST, getCMSData),
  takeLatest(ScrapMyCarTypes.GET_SCRAP_MY_CAR_BY_PATH.REQUEST, getIndividualByPath),
  takeLatest(ScrapMyCarTypes.GET_SCRAP_MY_CAR_SETTINGS.REQUEST, getSettings),
  takeLatest(ScrapMyCarTypes.GET_SCRAP_MY_CAR_MANUFACTURERS.REQUEST, getManufacturers),
  takeLatest(ScrapMyCarTypes.GET_NEAREST_LOCATIONS.REQUEST, getNearestLocations),
  takeLatest(ScrapMyCarTypes.GET_SCRAP_MY_CAR_MEDIAS.REQUEST, fetchMedias),
];
