import { getCookie } from 'lib/cookie';
import { addCacheResistanceToParams } from '../lib/cache';

export default class MagentoClient {
  constructor(client) {
    this.client = client;
  }

  isMagentoLogin = (req = null) => {
    const magentoToken = getCookie('access_token', req);
    this.client.token = magentoToken;

    return !!this.client.accessToken;
  };

  getUrl = (authUrl, gUrl, req = null) => (this.isMagentoLogin(req) ? authUrl : gUrl);

  setPersonDetail = ({ cartId, data }) => {
    const gUrl = `/guest-carts/personal-detail/${cartId}`;
    const authUrl = '/carts/mine/personal-detail';
    return this.client.post(this.getUrl(authUrl, gUrl), data);
  };

  fetchCart = cartId => {
    const gUrl = `/guest-carts/info/${cartId}`;
    const authUrl = '/carts/mine/carts/info';
    return this.client.get(this.getUrl(authUrl, gUrl));
  };

  getCustomizeOptions = cartId => {
    const gUrl = `/guest-carts/customise-plate/${cartId}`;
    const authUrl = '/carts/mine/customise-plate';
    return this.client.get(this.getUrl(authUrl, gUrl));
  };

  setCustomPlate = ({ status, cartId, buyArcylic, customOptions }) => {
    const gUrl = `/guest-carts/customise-plate/${cartId}`;
    const authUrl = '/carts/mine/customise-plate';
    const data = {
      current_step: status,
      cusomise: {
        buy_physical_plate: buyArcylic,
        customise_options: customOptions,
      },
    };
    return this.client.post(this.getUrl(authUrl, gUrl), data);
  };

  createCart = registration => {
    const gUrl = '/guest-carts/number-plate';
    const authUrl = '/carts/mine/number-plate';
    return this.client.post(this.getUrl(authUrl, gUrl), {
      maskedId: '',
      numberPlate: registration,
      store: 'car',
    });
  };

  getTransferDelivery = cartId => {
    const gUrl = `/guest-carts/transfer-delivery/${cartId}`;
    const authUrl = '/carts/mine/transfer-delivery';
    return this.client.get(this.getUrl(authUrl, gUrl));
  };

  setTransferService = cartId => {
    const gUrl = `/guest-carts/number-plate/transfer-service/${cartId}`;
    const authUrl = '/carts/mine/number-plate/transfer-service';
    return this.client.post(this.getUrl(authUrl, gUrl), { service: 1 });
  };

  setTransferServiceSelf = cartId => {
    const gUrl = `/guest-carts/number-plate/transfer-service/${cartId}`;
    const authUrl = '/carts/mine/number-plate/transfer-service';
    return this.client.post(this.getUrl(authUrl, gUrl), { service: 0 });
  };

  setTransferDelivery = ({ cartId, data }) => {
    const gUrl = `/guest-carts/transfer-delivery/${cartId}`;
    const authUrl = '/carts/mine/transfer-delivery';
    return this.client.post(this.getUrl(authUrl, gUrl), data);
  };

  getDeliveryMethods = cartId => {
    const gUrl = `/guest-carts/${cartId}/shipping-methods`;
    const authUrl = '/carts/mine/shipping-methods';
    return this.client.get(this.getUrl(authUrl, gUrl));
  };

  createCustomer = data => {
    const gUrl = '/customers';
    return this.client.post(this.getUrl(gUrl, gUrl), data);
  };

  customerLogin = data => {
    const gUrl = '/integration/customer/token';
    return this.client.post(this.getUrl(gUrl, gUrl), data);
  };

  getCustomerInfo = (req = null) => {
    const gUrl = '/customers/me';
    return this.client.get(this.getUrl(gUrl, gUrl, req));
  };

  addCartToCustomer = ({ cartId, data }) => {
    const url = `/guest-carts/${cartId}`;
    // need set access cookies
    return this.client.put(this.getUrl(url, url), data);
  };

  setCheckoutStatus = ({ status, cartId }) => {
    const gUrl = `/guest-carts/flag-step/${cartId}`;
    const authUrl = '/carts/mine/carts/flag-step';
    return this.client.post(this.getUrl(authUrl, gUrl), { step: status });
  };

  getStripSetupIntent = () => {
    const url = '/carts/mine/stripe-client-secret';
    // need set access cookies
    return this.client.get(this.getUrl(url, url));
  };

  getCustomerCards = () => {
    const url = '/carts/mine/stripe-cards';
    // need set access cookies
    return this.client.get(this.getUrl(url, url));
  };

  placeOrder = data => {
    const url = '/carts/mine/place-order';
    // need set access cookies
    return this.client.put(this.getUrl(url, url), data);
  };

  getOrderList = data => {
    const url = '/carts/mine/orders';
    return this.client.post(this.getUrl(url), data);
  };

  resetCart = ({ cartId }) => {
    const gUrl = `/guest-carts/checkout/process/reset/${cartId}`;
    const authUrl = '/carts/mine/checkout/process/reset';
    return this.client.put(this.getUrl(authUrl, gUrl));
  };

  getFeatureNumberPlateList = (pageSize = 50, page = 0) => {
    const url = `/featuredPlates?searchCriteria[page_size]=${pageSize}&searchCriteria[current_page]=${page}`;
    return this.client.get(url);
  };
}
