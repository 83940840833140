import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import { immutablySetOrMergeDeeply } from '../lib/mergeToStateHelper';
import * as vehicleInspectionsActions from '../actions/vehicleInspections';

const initialState = {
  vehicleInspectionsIsLoading: false,
  vehicleInspectionsError: null,
  vehicleInspectionsPage: {},
  settings: {},
  byPath: {},
};

export default {
  vehicleInspections: handleActions(
    {
      [combineActions(
        vehicleInspectionsActions.getVehicleInspectionsSettingsRequest,
        vehicleInspectionsActions.getVehicleInspectionsByTypeRequest,
        vehicleInspectionsActions.getVehicleInspectionsByPathRequest,
      )]: state =>
        immutable(state, {
          vehicleInspectionsIsLoading: { $set: true },
          vehicleInspectionsError: { $set: null },
        }),

      [combineActions(
        vehicleInspectionsActions.getVehicleInspectionsSettingsSuccess,
        vehicleInspectionsActions.getVehicleInspectionsByTypeSuccess,
        vehicleInspectionsActions.getVehicleInspectionsByPathSuccess,
      )]: (state, { payload }) => {
        const update = immutablySetOrMergeDeeply(state, payload, {
          vehicleInspectionsIsLoading: { $set: false },
          vehicleInspectionsError: { $set: null },
        });
        return immutable(state, update);
      },

      [combineActions(
        vehicleInspectionsActions.getVehicleInspectionsSettingsError,
        vehicleInspectionsActions.getVehicleInspectionsByTypeError,
        vehicleInspectionsActions.getVehicleInspectionsByPathError,
      )]: (state, { payload }) =>
        immutable(state, {
          vehicleInspectionsIsLoading: { $set: false },
          vehicleInspectionsError: { $set: payload },
        }),
    },
    initialState,
  ),
};
