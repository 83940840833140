import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import { immutablySetOrMergeDeeply } from '../lib/mergeToStateHelper';
import * as immutabilityCommands from '../schemas/cmsImmutabilityCommands';

import * as faqActions from '../actions/faq';

const {
  DEFAULT_MISSING_COMMAND,
  DEFAULT_OBJECT_COMMAND,
  DEFAULT_ARRAY_COMMAND,
  ...defaultCommandMapping
} = immutabilityCommands;

const initialState = {
  faqIsLoading: false,
  faqError: null,
  insurance: {},
  finance: {},
  categories: {},
  search: {},
  byPath: {},
  searchPhrase: {},
  landing: {},
};

export default {
  faq: handleActions(
    {
      [combineActions(
        faqActions.getFaqRequest,
        faqActions.searchFaqRequest,
        faqActions.faqLandingRequest,
        faqActions.getFaqByPathRequest,
        faqActions.getFaqCategoriesRequest,
      )]: state =>
        immutable(state, {
          faqIsLoading: { $set: true },
          faqError: { $set: null },
        }),

      [combineActions(
        faqActions.faqLandingSuccess,
        faqActions.getFaqByPathSuccess,
        faqActions.getFaqCategoriesSuccess,
      )]: (state, { payload }) => {
        const update = immutablySetOrMergeDeeply(state, payload, {
          faqIsLoading: { $set: false },
          faqError: { $set: null },
        });
        return immutable(state, update);
      },

      [combineActions(faqActions.getFaqSuccess, faqActions.searchFaqSuccess)]: (state, { payload }) => {
        const mapping = { ...defaultCommandMapping, byId: '$set' };
        const update = immutablySetOrMergeDeeply(
          state,
          payload,
          {
            faqIsLoading: { $set: false },
            faqError: { $set: null },
          },
          1,
          mapping,
        );
        return immutable(state, update);
      },

      [combineActions(
        faqActions.getFaqError,
        faqActions.searchFaqError,
        faqActions.faqLandingError,
        faqActions.getFaqByPathError,
        faqActions.getFaqCategoriesError,
      )]: (state, { payload }) =>
        immutable(state, {
          faqIsLoading: { $set: false },
          faqError: { $set: payload },
        }),

      [faqActions.setSearchPhrase]: (state, { payload: { faqType, search } }) =>
        immutable(
          state,
          immutablySetOrMergeDeeply(
            state,
            {
              searchPhrase: {
                [faqType]: search,
              },
            },
            {
              faqIsLoading: { $set: false },
              faqError: { $set: null },
            },
          ),
        ),
    },

    initialState,
  ),
};
