import { createSelector } from 'reselect';
import { makeGetByPath, byId } from './cms/genericSelectors';
import { makeGetCmsByType } from './cms';
const getMedia = state => state.media;
const getMediaByType = type => (state, category) => {
  const mediaType = state.media[type];
  return category ? mediaType[category] || [] : mediaType;
};
const getCmsCategories = makeGetCmsByType('categories');
const getCmsNews = makeGetCmsByType('news');
const getCmsVideos = makeGetCmsByType('videos');
const getCmsGuides = makeGetCmsByType('guides');
const getCmsReviews = makeGetCmsByType('reviews');
const getCmsBlogs = makeGetCmsByType('blogs');
const getByPath = makeGetByPath('media');
const selectorMap = {
  news: getCmsNews,
  videos: getCmsVideos,
  guides: getCmsGuides,
  reviews: getCmsReviews,
  blogs: getCmsBlogs,
};
const returnByType = type => selectorMap[type];

export const makeGetLinks = type =>
  createSelector([getMediaByType(type)], media => {
    if (media) {
      return media.links;
    }
    return {};
  });
export const makeGetNewsList = type =>
  createSelector([getMediaByType(type), returnByType(type)], (media, cms) => byId(media, cms));

export const getMediaCentre = createSelector(
  [getMedia, getCmsNews, getCmsVideos, getCmsGuides, getCmsReviews, getCmsBlogs],
  (media, news, videos, guides, reviews, blogs) => {
    const types = { news, videos, guides, reviews, blogs };
    const { mediaCentre } = media;
    const out = { news: [], videos: [], guides: [], reviews: [], blogs: [], topSection: [] };
    if (mediaCentre) {
      const keys = Object.keys(mediaCentre);
      const data = keys.map(type =>
        type === 'topSection'
          ? mediaCentre[type].map(({ id, type: topType }) => (types[topType] && types[topType][id]) || {})
          : mediaCentre[type].byId.map(item => types[type][item]),
      );
      return { ...out, ...keys.reduce((accumulator, key, i) => ({ ...accumulator, [key]: data[i] }), {}) };
    }
    return out;
  },
);

export const makeGetIndividualByPath = type =>
  createSelector([getByPath, returnByType(type), getMedia], (id, cms, { related: { [type]: related } }) => {
    let item = cms[id];
    if (item) {
      const {
        category: { id: categoryId },
      } = item;
      if (categoryId) {
        let relatedIds = related || {};
        if (relatedIds[categoryId]?.byId && related?.byId) {
          const allIds = [...relatedIds[categoryId].byId, ...related.byId].filter(rId => rId !== id).slice(0, 6);
          relatedIds = { byId: allIds };
        }
        item = { ...item, relatedNews: byId(relatedIds, cms) };
      }
    }
    return item || {};
  });

export const getCategories = createSelector([getMedia, getCmsCategories], (media, categories) => {
  const { allCategories } = media;
  return byId(allCategories, categories).map(cat => ({
    ...cat,
    contentByType: media.categoriesContentByType[cat.id],
  }));
});
