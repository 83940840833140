import { createActions } from 'redux-actions';
import WarrantiesQuoteProcessTypes from '../action-types/warrantiesAgQuoteProcess';

export const {
  createAgWarrantiesQuoteRequest,
  createAgWarrantiesQuoteSuccess,
  createAgWarrantiesQuoteError,

  updateQuoteInfoRequest,
  updateQuoteInfoSuccess,
  updateQuoteInfoError,

  clearQuoteInfo,
  storeQuoteInfo,
  clearClientSecret,

  getAvailableProductsRequest,
  getAvailableProductsSuccess,
  getAvailableProductsError,

  selectProductRequest,
  selectProductSuccess,
  selectProductError,

  getClientSecretRequest,
  getClientSecretSuccess,
  getClientSecretError,

  submitPaymentRequest,
  submitPaymentSuccess,
  submitPaymentError,

  checkPaymentRequest,
  checkPaymentSuccess,
  checkPaymentError,

  requireCustomQuoteRequest,
  requireCustomQuoteSuccess,
  requireCustomQuoteError,

  getQuoteInfoByTokenRequest,
  getQuoteInfoByTokenSuccess,
  getQuoteInfoByTokenError,
} = createActions({
  [WarrantiesQuoteProcessTypes.CREATE_AG_WARRANTIES_QUOTE.REQUEST]: payload => payload,
  [WarrantiesQuoteProcessTypes.CREATE_AG_WARRANTIES_QUOTE.SUCCESS]: payload => payload,
  [WarrantiesQuoteProcessTypes.CREATE_AG_WARRANTIES_QUOTE.ERROR]: payload => payload,

  [WarrantiesQuoteProcessTypes.UPDATE_QUOTE_INFO.REQUEST]: payload => payload,
  [WarrantiesQuoteProcessTypes.UPDATE_QUOTE_INFO.SUCCESS]: payload => payload,
  [WarrantiesQuoteProcessTypes.UPDATE_QUOTE_INFO.ERROR]: payload => payload,

  [WarrantiesQuoteProcessTypes.CLEAR_QUOTE_INFO]: payload => payload,
  [WarrantiesQuoteProcessTypes.STORE_QUOTE_INFO]: payload => payload,
  [WarrantiesQuoteProcessTypes.CLEAR_CLIENT_SECRET]: payload => payload,

  [WarrantiesQuoteProcessTypes.GET_AVAILABLE_PRODUCTS.REQUEST]: payload => payload,
  [WarrantiesQuoteProcessTypes.GET_AVAILABLE_PRODUCTS.SUCCESS]: payload => payload,
  [WarrantiesQuoteProcessTypes.GET_AVAILABLE_PRODUCTS.ERROR]: payload => payload,

  [WarrantiesQuoteProcessTypes.SELECT_PRODUCT.REQUEST]: payload => payload,
  [WarrantiesQuoteProcessTypes.SELECT_PRODUCT.SUCCESS]: payload => payload,
  [WarrantiesQuoteProcessTypes.SELECT_PRODUCT.ERROR]: payload => payload,

  [WarrantiesQuoteProcessTypes.GET_CLIENT_SECRET.REQUEST]: payload => payload,
  [WarrantiesQuoteProcessTypes.GET_CLIENT_SECRET.SUCCESS]: payload => payload,
  [WarrantiesQuoteProcessTypes.GET_CLIENT_SECRET.ERROR]: payload => payload,

  [WarrantiesQuoteProcessTypes.SUBMIT_PAYMENT.REQUEST]: payload => payload,
  [WarrantiesQuoteProcessTypes.SUBMIT_PAYMENT.SUCCESS]: payload => payload,
  [WarrantiesQuoteProcessTypes.SUBMIT_PAYMENT.ERROR]: payload => payload,

  [WarrantiesQuoteProcessTypes.CHECK_PAYMENT.REQUEST]: payload => payload,
  [WarrantiesQuoteProcessTypes.CHECK_PAYMENT.SUCCESS]: payload => payload,
  [WarrantiesQuoteProcessTypes.CHECK_PAYMENT.ERROR]: payload => payload,

  [WarrantiesQuoteProcessTypes.REQUIRE_CUSTOM_QUOTE.REQUEST]: payload => payload,
  [WarrantiesQuoteProcessTypes.REQUIRE_CUSTOM_QUOTE.SUCCESS]: payload => payload,
  [WarrantiesQuoteProcessTypes.REQUIRE_CUSTOM_QUOTE.ERROR]: payload => payload,

  [WarrantiesQuoteProcessTypes.GET_QUOTE_INFO_BY_TOKEN.REQUEST]: payload => payload,
  [WarrantiesQuoteProcessTypes.GET_QUOTE_INFO_BY_TOKEN.SUCCESS]: payload => payload,
  [WarrantiesQuoteProcessTypes.GET_QUOTE_INFO_BY_TOKEN.ERROR]: payload => payload,
});
