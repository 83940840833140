import { createActions } from 'redux-actions';
import ScrapMyCarTypes from '../action-types/scrapMyCar';

export const {
  getScrapMyCarSettingsRequest,
  getScrapMyCarSettingsSuccess,
  getScrapMyCarSettingsError,

  getScrapMyCarByTypeRequest,
  getScrapMyCarByTypeSuccess,
  getScrapMyCarByTypeError,

  getScrapMyCarManufacturersRequest,
  getScrapMyCarManufacturersSuccess,
  getScrapMyCarManufacturersError,

  getScrapMyCarByPathRequest,
  getScrapMyCarByPathSuccess,
  getScrapMyCarByPathError,

  getNearestLocationsRequest,
  getNearestLocationsSuccess,
  getNearestLocationsError,

  getScrapMyCarMediasRequest,
  getScrapMyCarMediasSuccess,
  getScrapMyCarMediasError,
} = createActions({
  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_SETTINGS.REQUEST]: payload => payload,
  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_SETTINGS.SUCCESS]: payload => payload,
  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_SETTINGS.ERROR]: payload => payload,

  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_BY_TYPE.REQUEST]: payload => payload,
  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_BY_TYPE.SUCCESS]: payload => payload,
  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_BY_TYPE.ERROR]: payload => payload,

  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_MANUFACTURERS.REQUEST]: payload => payload,
  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_MANUFACTURERS.SUCCESS]: payload => payload,
  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_MANUFACTURERS.ERROR]: payload => payload,

  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_BY_PATH.REQUEST]: payload => payload,
  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_BY_PATH.SUCCESS]: payload => payload,
  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_BY_PATH.ERROR]: payload => payload,

  [ScrapMyCarTypes.GET_NEAREST_LOCATIONS.REQUEST]: payload => payload,
  [ScrapMyCarTypes.GET_NEAREST_LOCATIONS.SUCCESS]: payload => payload,
  [ScrapMyCarTypes.GET_NEAREST_LOCATIONS.ERROR]: payload => payload,

  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_MEDIAS.REQUEST]: payload => payload,
  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_MEDIAS.SUCCESS]: payload => payload,
  [ScrapMyCarTypes.GET_SCRAP_MY_CAR_MEDIAS.ERROR]: payload => payload,
});
