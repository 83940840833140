import React from 'react';
import 'lazysizes';
import moment from 'moment';

export const getParentRoutePath = (fullPath, currentRouteKey) =>
  fullPath.substring(0, fullPath.indexOf(currentRouteKey, 0));

export const formatDateToTime = date => {
  if (!(date instanceof Date)) {
    date = new Date(date?.replace(/-/g, '/'));
  }
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const daySide = hours >= 12 ? 'pm' : 'am';

  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes} ${daySide}`;
};

export const formatDateToTime24 = date => {
  if (!(date instanceof Date)) {
    date = new Date(date?.replace(/-/g, '/'));
  }
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
};

export const formatDateToWeek = date => {
  if (!(date instanceof Date)) {
    date = new Date(date?.replace(/-/g, '/'));
  }
  let week = moment(date).day();
  switch (week) {
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
    case 0:
      return 'Sunday'
  }
};

export const getDateReadableFormat = dateFormat => {
  const date = new Date(dateFormat);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const getImgSources = (img1, img2, media) => (
  <source key={media} srcSet={`${media.includes('1220') ? img2 : img1}, ${img2} 2x`} media={media} />
);

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * getBreadCrumbsItems
 *
 * making link by concatenating previous routes items with current one
 * formatting kebab-case to Capitalized Case
 * generate it to item (object)
 *
 * @param {array} routesItems - array of objects with routes
 */

export const getBreadCrumbsItems = breadcrumbsItems => (value, index, items) => {
  const link = `/${items.slice(0, index + 1).join('/')}`; // making link by concatenating previous routes items with current one
  const name = capitalize(value.split(/[-]/).join(' ')); // formatting kebab-case to Capitalized Case
  breadcrumbsItems.push({ link, name });
};

export const buildBreadCrumbItems = path => {
  const breadcrumbsItems = [];
  const routesItems = path.split('/').slice(1);

  routesItems.forEach(getBreadCrumbsItems(breadcrumbsItems));

  return breadcrumbsItems;
};

export const formatNumber = number =>
  number
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    .split('.')
    .shift();

export const formatCurrency = number => number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const numberWithCommas = number => {
  return (number * 1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberPlatePrice = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const startCase = str => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word[0].toUpperCase() + word.substr(1))
    .join(' ');
};

export const carBrandCase = str => {
  const exceptions = ['BMW'];
  if (exceptions.indexOf(str) > -1) {
    return str;
  }

  return startCase(str);
};

export const makeId = length => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const formatPostCode = postcode => {
  if (!postcode) {
    return '';
  }
  const newPostcode = postcode.replace(/\s+/g, '');
  const { length } = newPostcode;
  let result = '';
  switch (length) {
    case 5:
      result = `${newPostcode.slice(0, 2)} ${newPostcode.slice(2)}`;
      break;
    case 6:
      result = `${newPostcode.slice(0, 3)} ${newPostcode.slice(3)}`;
      break;
    case 7:
      result = `${newPostcode.slice(0, 4)} ${newPostcode.slice(4)}`;
      break;
    default:
      result = newPostcode;
      break;
  }
  return result;
};

export const formatPhoneNumber = number => {
  return number ? (typeof number === 'string' ? number.replace(/^07/, '+447').replace(/\s+/g, '') : number) : number;
};

export const postToOutcode = postcode => {
  let len = 4;
  switch (postcode.length) {
    case 7:
      len = 4;
      break;
    case 6:
      len = 3;
      break;
    case 5:
      len = 2;
      break;
    default:
      return postcode;
  }
  return postcode.substr(0, len);
};

export const formatPartHideBankAccount = bankAccount => {
  return bankAccount ? (typeof bankAccount === 'string' ? bankAccount.replace(/.(?=.{4})/g, '*') : bankAccount) : bankAccount;
};