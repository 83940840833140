const QUOTE_STATUS = {
  DRAFT: 'draft',
  ACCEPT: 'accept',
  CREATED: 'created',
  ARRANGE: 'arrange',
  PENDING_PAYMENT: 'pending_payment',
  CONFIRMED: 'confirmed',
  EXPIRED: 'expired',
  PAID: 'paid',
  COLLECTION: 'collection',
  DELETED: 'deleted',
  COMPLETED: 'completed',
  UNAUTHORIZED: '401',
  FORBIDDEN: '403',
  BAD: '400',
  CONTINUE_FORBIDDEN: 'CONTINUE_FORBIDDEN',
  NEW_OFFER: 'new_offer',
  OFFER_WITH_CONDITION: 'offer_with_condition',
  SALVAGE_OFFER: 'salvage_offer',
  SALVAGE_OFFER_NEED_CONFIRM: 'salvage_offer_need_confirm',
  SALVAGE_OFFER_FAULT: 'salvage_offer_fault',
};

const QUOTE_OFFER_TYPE = {
  SCRAP_OFFER: 'scrap_offer',
  CONDITION_OFFER: 'condition_offer',
  SALVAGE_OFFER: 'salvage_offer',
};

const HAS_QUOTES_OFFER_STATUSES = [
  QUOTE_STATUS.NEW_OFFER,
  QUOTE_STATUS.OFFER_WITH_CONDITION,
  QUOTE_STATUS.SALVAGE_OFFER,
  QUOTE_STATUS.SALVAGE_OFFER_FAULT,
  QUOTE_STATUS.SALVAGE_OFFER_NEED_CONFIRM,
];

const QUOTE_ROUTES = {
  PRICE_NEED_CONFIRM: '/scrap-my-car/price-need-confirm',
  ENTER_DETAILS: '/scrap-my-car/enter-details',
  SALVAGE_PRICE: '/scrap-my-car/salvage-offer',
  SALVAGE_DETAILS: '/scrap-my-car/salvage-offer-details',
  SALVAGE_OFFER_NEED_CONFIRM: '/scrap-my-car/salvage-offer-need-confirm',
  ONLINE_QUOTATION: '/scrap-my-car/online-quote',
  ARRANGE_COLLECTION: '/scrap-my-car/arrange-a-collection',
  PAYMENT_METHOD: '/scrap-my-car/payment-method',
  CONFIRMATION: '/scrap-my-car/confirmation',
  HOMEPAGE: '/scrap-my-car',
  CUSTOMER_PORTAL_QUOTE_HISTORY: '/my-account/scrap-my-car/quote-history',
  CUSTOMER_PORTAL_SCRAPPED_CARS: '/my-account/scrap-my-car/scrapped-cars',
  LOGIN: '/sign-in',
};

const QUOTE_FUNNEL_NAME = 'Scrap Car Quote';

const QUOTE_FUNNEL_STEP_NUMBER = {
  CREATE_QUOTE: '1',
  ENTER_DETAILS: '2',
  BETTER_THAN_SCRAP: '2',
  ACCEPT_QUOTE: '3',
  CANCEL_QUOTE: '3',
  ARRANGE_COLLECTION: '4',
  SET_PAYMENT_METHOD: '5',
  CONFIRM_QUOTE: '6',
};

const QUOTE_CURRENCY = {
  GBP: 'GBP',
  EUR: 'EUR',
  USD: 'USD',
};

const BOOKING_JOB_STATUS = {
  STATUS_CANCELLED: 'cancelled',
  STATUS_SCHEDULED: 'scheduled',
  STATUS_CONFIRMED: 'confirmed',
  STATUS_STARTED: 'started',
  STATUS_ARRIVED: 'arrived',
  STATUS_COLLECTED: 'collected',
  STATUS_FINISHED: 'finished',
  STATUS_ABORTED: 'aborted',
  STATUS_SUSPENDED: 'suspended',
};

const OFFER_PENDING_TYPE = {
  MILEAGE: 'mileage',
  EXIST_UNPAID_FINANCE: 'exist_unpaid_finance',
  ENGINE_CONDITION_ADDITIONAL: 'engine_condition_additional',
  INSURANCE_WRITE_OFF: 'insurance_write_off',
  FURTHER_INFORMATION: 'further_information',
  PRICE_RANGE_MISMATCH: 'price_range_mismatch',
  LAST_PRICE_TOO_LOW: 'last_price_too_low',
};

const SHORT_LINK_ACTION = {
  QUOTE: 'quote',
  SALVAGE: 'salvage',
};

// Use construction of module.exports is required
// because of the absence of server support for export keyword
module.exports = {
  QUOTE_STATUS,
  QUOTE_OFFER_TYPE,
  HAS_QUOTES_OFFER_STATUSES,
  QUOTE_ROUTES,
  QUOTE_FUNNEL_NAME,
  QUOTE_FUNNEL_STEP_NUMBER,
  QUOTE_CURRENCY,
  BOOKING_JOB_STATUS,
  OFFER_PENDING_TYPE,
  SHORT_LINK_ACTION,
};
