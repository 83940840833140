import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import { immutablySetOrMergeDeeply } from '../lib/mergeToStateHelper';
import * as carTaxActions from '../actions/carTax';

const initialState = {
  carTaxIsLoading: false,
  carTaxError: null,
  carTaxPage: {},
  settings: {},
  byPath: {},
};

export default {
  carTax: handleActions(
    {
      [combineActions(
        carTaxActions.getCarTaxSettingsRequest,
        carTaxActions.getCarTaxQuotesRequest,
        carTaxActions.getCarTaxByPathRequest,
      )]: state =>
        immutable(state, {
          carTaxIsLoading: { $set: true },
          carTaxError: { $set: null },
        }),
      [combineActions(
        carTaxActions.getCarTaxSettingsSuccess,
        carTaxActions.getCarTaxQuotesSuccess,
        carTaxActions.getCarTaxByPathSuccess,
      )]: (
        state,
        { payload },
      ) => {
        const update = immutablySetOrMergeDeeply(state, payload, {
          carTaxIsLoading: { $set: false },
          carTaxError: { $set: null },
        });
        return immutable(state, update);
      },
      [combineActions(
        carTaxActions.getCarTaxSettingsError,
        carTaxActions.getCarTaxQuotesError,
        carTaxActions.getCarTaxQuotesError,
      )]: (
        state,
        { payload },
      ) =>
        immutable(state, {
          carTaxIsLoading: { $set: false },
          carTaxError: { $set: payload },
        }),
    },
    initialState,
  ),
};
