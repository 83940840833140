import { createSelector } from 'reselect';
import { byId, makeGetByPath } from './cms/genericSelectors';
import { makeGetCmsByType } from './cms';
import quote from '../action-types/quote';
const getByPath = makeGetByPath('finance');
const getSettings = state => state.finance.settings;
const getFinance = state => state.finance;
export const getClaculatorData = state => state.finance.calculatorData;
export const getQuote = state => state.finance;
export const getRefId = state => state.financeQuoteProcess.internals;

const getCmsManufacturers = makeGetCmsByType('finance');
const getCmsCarFinancePage = makeGetCmsByType('nodeCarFinancePage');
const getCmsCarFinanceLoan = makeGetCmsByType('nodeCarFinanceLoan');
const getCmsFinanceCarManufacturer = makeGetCmsByType('nodeFinanceCarManufacturer');

export const getFinanceSettings = createSelector(
  [getSettings, makeGetCmsByType('configPagesCarFinance')],
  (iSettings, cms) => {
    const [settings] = byId(iSettings, cms);
    return settings;
  },
);

export const getIndividualByPathFinance = createSelector(
  [getByPath, getCmsCarFinancePage, getCmsCarFinanceLoan, getCmsFinanceCarManufacturer],
  (id, carFinancePage, carFinanceLoan, carManufacturer) => {
    return carFinancePage[id] || carFinanceLoan[id] || carManufacturer[id] || {};
  },
);

export const getFinanceManufacturers = createSelector([getFinance, getCmsManufacturers], (finance, manufacturers) => {
  const { allManufacturers } = finance;
  return byId(allManufacturers, manufacturers);
});

export const getFinancePages = createSelector([getFinanceSettings], settings => (settings && settings.pages) || []);

export const getLinkToken = createSelector(getQuote, quote => quote.quoteToken);

export const getQuoteStatus = createSelector(getQuote, quote => quote.quoteStatus);
