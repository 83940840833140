import { useDispatch } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import FormCheckbox from 'components/form/FormCheckbox';
import { Formik } from 'formik';
import InputFormGroup from 'components/form/InputFormGroup';
import PropsTypes from 'prop-types';
import React from 'react';
import { editProfileRequest } from 'store/actions';
import { editProfileSchema } from 'common/user';
import { getInitialEditProfileFormValues } from 'lib/forms';
import DateOfBirthForm from './DateOfBirthForm';

function EditProfileForm({ checkBoxTextEmail, checkBoxTextSms }) {
  const dispatch = useDispatch();

  const initialEditProfileFormValues = getInitialEditProfileFormValues();

  const saveChanges = (
    { firstName, lastName, email, phoneNumber, year, month, day, subscribedToSms, subscribedToEmail },
    { setErrors, setSubmitting },
  ) => {
    const dateOfBirth = year.value || month.value || day.value ? `${year?.value}-${month?.value}-${day?.value}` : '';

    const data = {
      firstName,
      lastName,
      email,
      dateOfBirth,
      ...(initialEditProfileFormValues.phoneNumber !== phoneNumber && { phoneNumber: phoneNumber.replace(/\s+/g, '') }),
      subscribedToEmail: subscribedToEmail ? 1 : 0,
      subscribedToSms: subscribedToSms ? 1 : 0,
    };

    dispatch(editProfileRequest(data, { setErrors, setSubmitting }));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialEditProfileFormValues}
      validationSchema={editProfileSchema}
      onSubmit={saveChanges}
      render={({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        errors,
        isValid,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="page-form">
          <Form.Row className="mt-3">
            <Form.Group as={Col}>
              <Form.Label className="page-form__field label">First name</Form.Label>
              <InputFormGroup
                id="firstName"
                rounded
                validationMarks
                value={values.firstName}
                name="firstName"
                placeholder="First name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                valid={touched.firstName && !errors.firstName}
                error={touched.firstName && errors.firstName}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className="mt-3">
            <Form.Group as={Col}>
              <Form.Label className="page-form__field label">Last name</Form.Label>
              <InputFormGroup
                id="lastName"
                rounded
                validationMarks
                value={values.lastName}
                name="lastName"
                placeholder="Last name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                valid={touched.lastName && !errors.lastName}
                error={touched.lastName && errors.lastName}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className="mt-3">
            <Form.Group as={Col}>
              <Form.Label className="page-form__field label">Email</Form.Label>
              <InputFormGroup
                id="email"
                rounded
                type="email"
                validationMarks
                value={values.email}
                name="email"
                placeholder="Email"
                handleChange={handleChange}
                handleBlur={handleBlur}
                valid={touched.email && !errors.email}
                error={touched.email && errors.email}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className="mt-3">
            <Form.Group as={Col}>
              <Form.Label className="page-form__field label">Mobile phone number</Form.Label>
              <InputFormGroup
                id="phoneNumber"
                rounded
                type="tel"
                validationMarks
                value={values.phoneNumber}
                name="phoneNumber"
                placeholder="Mobile phone number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                valid={touched.phoneNumber && !errors.phoneNumber}
                error={touched.phoneNumber && errors.phoneNumber}
              />
            </Form.Group>
          </Form.Row>
          <DateOfBirthForm
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            values={values}
            touched={touched}
            errors={errors}
          />
          <Form.Row className="mt-3">
            <Form.Group as={Col}>
              <FormCheckbox
                checked={values.subscribedToEmail}
                name="subscribedToEmail"
                handleChange={handleChange}
                id="subscribedToEmail"
                isSingleLine
                isAlignedToRight
              >
                {checkBoxTextEmail}
              </FormCheckbox>
            </Form.Group>
            <Form.Group as={Col}>
              <FormCheckbox
                checked={values.subscribedToSms}
                name="subscribedToSms"
                handleChange={handleChange}
                id="subscribedToSms"
                isSingleLine
              >
                {checkBoxTextSms}
              </FormCheckbox>
            </Form.Group>
          </Form.Row>
          <ButtonGroup className="flex-column text-center btn-block mt-3">
            <Button disabled={isSubmitting || !isValid} variant="primary" type="submit" className="pl-6 pr-6 btn-lg">
              <strong>Save changes</strong>
            </Button>
          </ButtonGroup>
        </Form>
      )}
    />
  );
}

EditProfileForm.propTypes = {
  checkBoxTextEmail: PropsTypes.string.isRequired,
  checkBoxTextSms: PropsTypes.string.isRequired,
};

export default React.memo(EditProfileForm);
