import { call, put, takeLatest, take, race, cancelled, select, takeEvery } from 'redux-saga/effects';
import { reportToBugsnag } from 'lib/bugsnag';
import { user } from 'api-client/apiClientInstance/user';
import { auth } from 'api-client/apiClientInstance/auth';
import { customer } from 'api-client/apiClientInstance/customer';
import { redirect as redirectPage } from 'lib/routes';
import { Router } from 'server/pages';
import { getErrorMessage } from 'lib/message';
import { setCookie, getCookie, removeCookie } from 'lib/cookie';
import { payments } from 'api-client/apiClientInstance/payments';
import { segmentTrack } from 'lib/segment';
import { getUserEmailSelector, getUserFirstNameSelector, getUserLastNameSelector } from 'store/selectors/user';
import UserTypes from '../action-types/user';
import AuctionModalTypes from '../action-types/modal';
import setFormikErrors from '../lib/setFormikErrors';
import { SOME_ERROR_OCCURRED, SUCCESSFULLY_LOGGED_IN, SUCCESSFULLY_SIGNED_UP } from '../constants';
import { getRootModalState } from '../selectors/modal';
import { modalClose } from '../actions/modal';
import {
  userLoginSuccess,
  userLoginError,
  refreshTokenSuccess,
  refreshTokenError,
  getUserDataSuccess,
  getUserDataError,
  userLogoutSuccess,
  userLogoutError,
  userSignupSuccess,
  userSignupError,
  changePasswordSuccess,
  changePasswordError,
  forgotPasswordSuccess,
  forgotPasswordError,
  showToast,
  personalDataSuccess,
  personalDataError,
  editProfileSuccess,
  editProfileError,
  deleteAccountSuccess,
  deleteAccountError,
  resetPasswordSuccess,
  resetPasswordError,
  refreshTokenRequest,
  userLogoutRequest,
  getUserDataRequest,
  dropUserData,
  createSupportTicketSuccess,
  createSupportTicketError,
  getPaymentMethodsSuccess,
  getPaymentMethodsError,
} from '../actions';
import { getFailType, getSuccessType, requestAction } from '../lib/helper';

export function* login({
  payload: {
    data,
    redirect,
    actions: { setErrors, setSubmitting },
    shouldShowMessage = true,
  },
}) {
  try {
    setSubmitting(true);
    const { userJWT: token } = yield call(auth.login, data);
    const accessToken = getCookie('access_token');
    if (!accessToken) {
      setCookie('access_token', token);
    }
    yield put(userLoginSuccess({ token, redirect }));
    if (shouldShowMessage) {
      yield put(
        showToast({
          message: SUCCESSFULLY_LOGGED_IN,
          kind: 'success',
        })
      );
    }
  } catch (err) {
    // reportToBugsnag(err, 'login');
    const errorMessages = err?.error?.messages;
    const commonErrorMessage = errorMessages?.common?.[0] || err?.message;

    if (commonErrorMessage) {
      yield put(
        showToast({
          message: `${commonErrorMessage}`,
          kind: 'error',
        })
      );
    }
    setFormikErrors(setErrors, errorMessages);
    yield put(userLoginError(err));
  } finally {
    setSubmitting(false);
  }
}

export function* refreshToken({ payload }) {
  try {
    const { token } = payload;
    const { userJWT } = yield call(auth.refreshToken, { token });

    yield put(refreshTokenSuccess({ token: userJWT }));
  } catch (err) {
    reportToBugsnag(err, 'refreshToken');
    yield put(refreshTokenError(err));
  }
}

export function* getUserData({ payload }) {
  try {
    const data = yield call(user.getUserProfile, { req: payload?.req });

    yield put(getUserDataSuccess(data));
  } catch (err) {
    // reportToBugsnag(err, 'getUserData');
    yield put(getUserDataError(err));
  }
}

export function* logout({ payload: { redirect } }) {
  try {
    yield call(auth.logout);

    yield put(userLogoutSuccess({ token: '', redirect }));
  } catch (err) {
    reportToBugsnag(err, 'logout');
    yield put(userLogoutError(err));
  }
}

export function* signUp({
  payload: {
    data,
    actions: { setErrors, setSubmitting },
    redirect,
    callback,
  },
}) {
  try {
    setSubmitting(true);
    const { userJWT: token } = yield call(auth.customerSignUp, data);

    yield put(userSignupSuccess({ token, redirect }));
    if (callback) {
      callback({ ...data, status: 'success' });
    }
    yield put(
      showToast({
        message: SUCCESSFULLY_SIGNED_UP,
        kind: 'success',
      })
    );
  } catch (err) {
    reportToBugsnag(err, 'signUp');
    const errorMessages = err?.error?.messages;
    const errMsg = getErrorMessage(err);
    if (callback) {
      callback({ ...data, status: 'failed' });
    }
    if (errMsg) {
      yield put(
        showToast({
          message: `${errMsg}`,
          kind: 'error',
        })
      );
    }
    if (errMsg.indexOf('already exist.') !== -1) {
      const {
        router: { asPath },
      } = Router;
      const path = asPath.split('/')[1];
      if (path !== 'register') {
        yield redirectPage({}, `/${path}/sign-in`);
      } else {
        yield redirectPage({}, `/sign-in`);
      }
    }

    setFormikErrors(setErrors, errorMessages);
    yield put(userSignupError(err));
    setSubmitting(false);
  }
}

function* watchUserChannel() {
  let socket;
  try {
    // TODO: use it for personal channel when it will be implementing
    // const userJWT = yield select((...args) => getUserTokenSelector(...args))
    // socket = yield call(createWebSocketConnection, `${process.env.SOCKET_URL}/messages?userJWT=${userJWT}`)
    // const socketChannel = yield call(createSocketChannel, socket)
    //
    // while (true) {
    //   const action = yield take(socketChannel);
    //   yield put(action);
    // }
  } catch (err) {
    reportToBugsnag(err, 'watchUserChannel');
  } finally {
    if (yield cancelled()) {
      socket.close();
    }
  }
}

function* startStopUserChannel() {
  while (true) {
    yield take(UserTypes.WATCH_USER_CHANNEL.PROGRESS);
    yield race({
      task: call(watchUserChannel),
      cancel: take(UserTypes.WATCH_USER_CHANNEL.FINISH),
    });
  }
}

export function* changePassword({
  payload: {
    data,
    actions: { setErrors, setSubmitting },
  },
}) {
  try {
    setSubmitting(true);
    const { userJWT: token } = yield call(auth.changePassword, data);
    yield put(
      showToast({
        message: 'You have successfully updated your password on your account',
        kind: 'success',
      })
    );

    yield put(changePasswordSuccess({ token }));
    setSubmitting(false);
  } catch (err) {
    reportToBugsnag(err, 'changePassword');
    if (err.status !== 401) {
      setFormikErrors(setErrors, err?.error?.messages);

      yield put(
        showToast({
          message: 'There were errors. Please fix them and try again',
          kind: 'error',
        })
      );
      setSubmitting(false);
    }
    yield put(changePasswordError(err));
  }
}

export function* forgotPassword({ payload: { data, actions = { resetForm: () => {} } } }) {
  const { modalType } = yield select(getRootModalState());
  const {
    router: { query },
  } = Router;
  if (query && query.source) {
    data.source = query.source;
  }
  try {
    const { resetForm } = actions;

    yield call(auth.forgotPassword, data);

    yield put(forgotPasswordSuccess());

    yield put(
      showToast({
        message:
          'We have sent you a password reset link to your email. Click the link in the email and follow the instructions to enter a new password',
        kind: 'success',
      })
    );
    resetForm();
    if (modalType === 'forgotPassword') {
      yield put({ type: AuctionModalTypes.CLOSE_MODAL });
    }
    yield put(userLogoutRequest({}, {}, '/'));
  } catch (err) {
    reportToBugsnag(err, 'forgotPassword');
    const errMsg = getErrorMessage(err);
    if (err.status !== 401) {
      const setErrors = actions?.setErrors;
      setFormikErrors(setErrors, errMsg);
      if (modalType === 'forgotPassword') {
        yield put({ type: AuctionModalTypes.CLOSE_MODAL });
      }
    }
    yield put(
      showToast({
        message: errMsg,
        kind: 'error',
      })
    );
    yield put(forgotPasswordError(err));
    const { resetForm } = actions;
    resetForm();
  }
}

export function* resetPassword({
  payload: {
    data,
    actions: { setErrors, setSubmitting },
  },
}) {
  try {
    setSubmitting(true);
    const { source } = data;
    yield call(auth.resetPassword, data);

    yield put(
      showToast({ message: 'You have successfully reset and updated your password on your account', kind: 'success' })
    );
    yield put(resetPasswordSuccess());
    localStorage.clear();
    if (source) {
      window.location.href = `https://${source}`;
    } else {
      yield redirectPage({}, '/sign-in');
    }

    setSubmitting(false);
  } catch (err) {
    reportToBugsnag(err, 'resetPassword');
    if (err.status !== 401) {
      setFormikErrors(setErrors, err?.error?.messages);

      yield put(
        showToast({
          message: err?.error?.messages?.resetToken || 'There were errors. Please fix them and try again',
          kind: 'error',
        })
      );
      setSubmitting(false);
    }
    yield put(resetPasswordError(err));
  }
}

export function* editProfile({
  payload: {
    data,
    actions: { setErrors, setSubmitting },
  },
}) {
  try {
    setSubmitting(true);
    yield call(customer.editProfile, data);

    yield put(showToast({ message: 'Your profile details has been updated', kind: 'success' }));

    yield put(editProfileSuccess());
    yield put(getUserDataRequest());
    setSubmitting(false);
  } catch (err) {
    reportToBugsnag(err, 'editProfile');
    if (err.status !== 401) {
      setFormikErrors(setErrors, err?.error?.messages);
      if (err?.error?.messages?.dateOfBirth) {
        const errorFields = data.dateOfBirth.split('-');
        const errorObj = {
          year: errorFields[0],
          month: errorFields[1],
          day: errorFields[2],
        };

        setErrors({
          month: err.error.messages.dateOfBirth[0],
          ...((errorObj.day === 'undefined' || errorObj.day === '') && { day: 'only-border' }),
          ...((errorObj.year === 'undefined' || errorObj.year === '') && { year: 'only-border' }),
        });
      }

      yield put(showToast({ message: 'There were errors. Please fix them and try again', kind: 'error' }));
      setSubmitting(false);
    }
    yield put(editProfileError(err));
  }
}

export function* deleteAccount({ payload: { redirect } }) {
  const { modalType } = yield select(getRootModalState());

  try {
    yield call(user.deleteAccount);

    yield put(
      showToast({ message: 'Your account has been permanently deleted and can not be recovered.', kind: 'success' })
    );

    yield put(deleteAccountSuccess({ redirect }));

    if (modalType === 'deleteAccountPopup') {
      yield put(modalClose());
    }
  } catch (err) {
    reportToBugsnag(err, 'deleteAccount');
    if (err.status !== 401) {
      yield put(showToast({ message: 'There were errors. Please fix them and try again', kind: 'error' }));

      if (modalType === 'deleteAccountPopup') {
        yield put(modalClose());
      }
    }
    yield put(deleteAccountError(err));
  }
}

export function* requestPersonalData({ payload: { redirect } }) {
  const { modalType } = yield select(getRootModalState());

  try {
    yield call(user.requestPersonalData);

    yield put(
      showToast({
        message: 'We have received your request for your personal data and will be in touch with you shortly.',
        kind: 'success',
      })
    );
    if (modalType === 'requestPersonalDataPopup') {
      yield put(modalClose());
    }

    yield put(personalDataSuccess({ redirect }));
  } catch (err) {
    reportToBugsnag(err, 'requestPersonalData');
    if (err.status !== 401) {
      yield put(
        showToast({
          message: err?.error?.messages?.common || 'There were errors. Please fix them and try again',
          kind: 'error',
        })
      );

      if (modalType === 'requestPersonalDataPopup') {
        yield put(modalClose());
      }
    }
    yield put(personalDataError(err));
  }
}

function* requestMiddleware(action) {
  const { fail } = yield race({
    success: take(getSuccessType(action)),
    fail: take(getFailType(action)),
  });

  if (fail && fail.payload && fail.payload.status === 401) {
    yield put(refreshTokenRequest());

    const { success } = yield race({
      success: take(UserTypes.REFRESH_TOKEN.SUCCESS),
      fail: take(UserTypes.REFRESH_TOKEN.ERROR),
    });

    if (success) {
      yield put(action);
    } else {
      yield put(userLogoutRequest({}, {}, '/sign-in'));
    }
  }
}

export function* handleLogoutSuccess() {
  yield put(dropUserData());
}

export function* createSupportTicket({
  payload: {
    values: { subject, description, criticalStatus, screen, mobileNumber },
    actions: { setErrors, setSubmitting },
  },
}) {
  try {
    setSubmitting(true);
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('description', description);
    formData.append('mobileNumber', mobileNumber);
    formData.append('criticalStatus', criticalStatus.value);
    if (screen?.src) {
      formData.append('screen', screen.src);
    }
    yield call(customer.createSupportTicket, formData);
    setSubmitting(false);
    const firstName = yield select(getUserFirstNameSelector);
    const lastName = yield select(getUserLastNameSelector);
    const email = yield select(getUserEmailSelector);
    segmentTrack('Support_Ticket', {
      first_name: firstName,
      last_name: lastName,
      email,
      phone: mobileNumber,
      subject,
      priority: criticalStatus.value,
    });
    yield put(createSupportTicketSuccess());
  } catch (err) {
    reportToBugsnag(err, 'handleLogoutSuccess');
    if (err.status !== 401) {
      setSubmitting(false);
      const errorMessages = err?.error?.messages;
      const commonErrorMessage = errorMessages?.common?.[0];

      if (commonErrorMessage) {
        yield put(
          showToast({
            message: commonErrorMessage || [SOME_ERROR_OCCURRED],
            kind: 'error',
          })
        );
      }
      setFormikErrors(setErrors, errorMessages);
    }

    yield put(createSupportTicketError(err));
  }
}

export function* loginWith3rdParty({ payload }) {
  try {
    const { data, redirect } = payload;
    const { userJWT: token } = yield call(auth.loginWith3rdParty, data);
    yield put(userLoginSuccess({ token, redirect }));
    yield put(
      showToast({
        message: SUCCESSFULLY_LOGGED_IN,
        kind: 'success',
      })
    );
  } catch (err) {
    reportToBugsnag(err, 'loginWith3rdParty');
    const errorMessages = err?.error?.messages;
    const commonErrorMessage = errorMessages?.common?.[0] || err?.message;

    if (commonErrorMessage) {
      yield put(
        showToast({
          message: `${commonErrorMessage}`,
          kind: 'error',
        })
      );
    }

    yield put(userLoginError(err));
  }
}

export function* getPaymentMethods() {
  try {
    const methods = yield call(payments.getPaymentMethods);
    yield put(getPaymentMethodsSuccess(methods));
  } catch (err) {
    reportToBugsnag(err, 'getPaymentMethods');
    const errorMessages = err?.error?.messages;
    const commonErrorMessage = errorMessages?.common?.[0] || err?.message;

    if (commonErrorMessage) {
      yield put(
        showToast({
          message: `${commonErrorMessage}`,
          kind: 'error',
        })
      );
    }
    yield put(getPaymentMethodsError());
  }
}

/**
 * User Sagas
 */
export default [
  takeLatest(UserTypes.USER_LOGIN.REQUEST, login),
  takeLatest(UserTypes.REFRESH_TOKEN.REQUEST, refreshToken),
  takeLatest(UserTypes.GET_USER_DATA.REQUEST, getUserData),
  takeLatest(UserTypes.CREATE_SUPPORT_TICKET.REQUEST, createSupportTicket),
  takeLatest(UserTypes.USER_LOGOUT.REQUEST, logout),
  takeLatest(UserTypes.USER_SIGNUP.REQUEST, signUp),
  takeLatest(UserTypes.CHANGE_PASSWORD.REQUEST, changePassword),
  takeLatest(UserTypes.FORGOT_PASSWORD.REQUEST, forgotPassword),
  takeLatest(UserTypes.RESET_PASSWORD.REQUEST, resetPassword),
  takeLatest(UserTypes.EDIT_PROFILE.REQUEST, editProfile),
  takeLatest(UserTypes.DELETE_ACCOUNT.REQUEST, deleteAccount),
  takeLatest(UserTypes.PERSONAL_DATA.REQUEST, requestPersonalData),
  takeEvery(requestAction, requestMiddleware),
  takeLatest(UserTypes.USER_LOGOUT.SUCCESS, handleLogoutSuccess),
  startStopUserChannel(),
  takeLatest(UserTypes.LOGIN_WITH_3RD_PARTY.REQUEST, loginWith3rdParty),
  takeLatest(UserTypes.GET_PAYMENT_METHODS.REQUEST, getPaymentMethods),
];
