import { createStore, applyMiddleware } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import reducer from 'store/reducers';

import indexSaga from 'store/sagas';
import webSpecificSagas from './sagas';

const isDev = process.env.ENV !== 'production';
const bindMiddleware = middleware => {
  if (isDev) {
    // eslint-disable-next-line global-require
    const { composeWithDevTools } = require('redux-devtools-extension');
    const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
    return composeEnhancers(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, bindMiddleware([sagaMiddleware]));

  store.sagaTask = sagaMiddleware.run(function* rootSaga() {
    yield all([...indexSaga, ...webSpecificSagas]);
  });
  return store;
};

export const wrapper = createWrapper(makeStore, { debug: isDev });
