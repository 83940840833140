import { createSelector } from 'reselect';
import { formatDateToTime24 } from 'lib/formatters';

const getScrappedCars = state => state.scrappedCars;

export const getActionQuoteId = createSelector(getScrappedCars, scrappedCars => scrappedCars.actionQuoteId);

export const getActionQuotePreferTime = createSelector(getScrappedCars, scrappedCars => {
  const quotes = scrappedCars.quoteHistory.items;
  const actionQuote = quotes.find(quote => quote.id === scrappedCars.actionQuoteId);
  return actionQuote
    ? {
        date: actionQuote.bookingDate,
        startTime: formatDateToTime24(actionQuote.bookingStartTime),
        endTime: formatDateToTime24(actionQuote.bookingEndTime),
      }
    : {};
});

export const getScrappedCarsStatus = createSelector(getScrappedCars, scrappedCars => scrappedCars.status);

export const getQuoteHistory = createSelector(getScrappedCars, scrappedCars => scrappedCars.quoteHistory);

export const getQuoteHistoryItems = createSelector(getQuoteHistory, quoteHistory => quoteHistory.items);

export const getQuoteHistoryTotal = createSelector(
  getQuoteHistory,
  quoteHistory => quoteHistory.total - quoteHistory.hideQty,
);

export const getReQuoteVrm = createSelector(getScrappedCars, scrappedCars => scrappedCars.reQuoteVrm);

export const getQuoteHistoryLimit = createSelector(getQuoteHistory, quoteHistory => quoteHistory.limit);

export const getQuoteHistoryOffset = createSelector(getQuoteHistory, quoteHistory => quoteHistory.offset);

export const getActiveQuotesSelector = createSelector(getScrappedCars, scrappedCars => scrappedCars.activeQuotes);

export const isQuoteHistoryInitialized = createSelector(getQuoteHistory, quoteHistory => quoteHistory.isInitialized);

export const isScrappedCarsInitialized = createSelector(scrappedCars => scrappedCars.isInitialized);
