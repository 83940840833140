import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { common } from 'api-client/apiClientInstance/common';
import { reportToBugsnag } from 'lib/bugsnag';
import CommonTypes from '../action-types/common';

export function* deleteFiles({ payload }) {
  try {
    const { paths, beforeUnload } = payload;
    if (beforeUnload) {
      const data = new FormData();
      data.append('paths', paths);
      yield call(common.deleteFilesByBeacon, data);
    } else {
      yield call(common.deleteFiles, paths);
    }
  } catch (err) {
    yield put({
      type: CommonTypes.DELETE_FILES.ERROR,
      payload: err,
    });
    reportToBugsnag(err, 'deleteFiles');
  }
}

export function* trackingUTM({ payload }) {
  try {
    yield call(common.trackingUTM, payload);
  } catch (err) {
    yield put({
      type: CommonTypes.TRACKING_UTM.ERROR,
      payload: err,
    });
    reportToBugsnag(err, 'trackingUTM');
  }
}

export default [
  takeEvery(CommonTypes.DELETE_FILES.REQUEST, deleteFiles),
  takeLatest(CommonTypes.TRACKING_UTM.REQUEST, trackingUTM),
];
