import { put, takeLatest, call } from 'redux-saga/effects';
import { homePageV2 } from 'api-client/apiClientInstance/homePageV2';
import { reportToBugsnag} from 'lib/bugsnag';
import HomePageTypes from '../action-types/homePageV2';
import { SOME_ERROR_OCCURRED } from '../constants';
// import { snakeToCamelCase } from '../lib/snakeToCamelCase';
// import { wrapWith, extractStoreIds } from '../lib/mergeToStateHelper';

export function* fetchMediasV2({ chan }) {
  let out = {};
  try {
    const data = yield call(homePageV2.get, '/media?v1');
    out = {
      type: HomePageTypes.GET_HOME_PAGE_MEDIAS_V2.SUCCESS,
      payload: { medias: data },
    };
  } catch (err) {
    reportToBugsnag(err, 'fetchMediasV2');

    out = {
      type: HomePageTypes.GET_HOME_PAGE_MEDIAS_V2.ERROR,
      payload: err,
    };
  }
  yield put(out);
  if (chan) {
    yield put(chan, out);
  }
}

export default [takeLatest(HomePageTypes.GET_HOME_PAGE_MEDIAS_V2.REQUEST, fetchMediasV2)];
