import { createActions } from 'redux-actions';
import NumberPlateActionTypes from '../action-types/numberPlates';

export const {
  getSettingRequest,
  getSettingSuccess,
  getSettingError,

  getIdeasPagesRequest,
  getIdeasPagesSuccess,
  getIdeasPagesError,

  getPrivateNumberPlatesByPathRequest,
  getPrivateNumberPlatesByPathSuccess,
  getPrivateNumberPlatesByPathError,

  getTypesOfRegistrationsByPathRequest,
  getTypesOfRegistrationsByPathSuccess,
  getTypesOfRegistrationsByPathError,

  getFaqsByPathRequest,
  getFaqsByPathSuccess,
  getFaqsByPathError,

  numberPlateSearchRequest,
  numberPlateSearchSuccess,
  numberPlateSearchError,

  randomFetchPlatesRequest,
  randomFetchPlatesSuccess,
  randomFetchPlatesError,

  setPlateKeywordForSearchRequest,
  setPlateKeywordForSearchSuccess,
  setPlateKeywordForSearchError,

  numberPlateLoadMoreRequest,
  numberPlateLoadMoreSuccess,
  numberPlateLoadMoreError,

  submitEnquireRequest,
  submitEnquireSuccess,
  submitEnquireError,

  addFilterRequest,
  addFilterSuccess,
  addFilterError,

  clearFilterRequest,
  clearFilterSuccess,
  clearFilterError,

  changeLoading,
} = createActions({
  [NumberPlateActionTypes.GET_SETTING.REQUEST]: payload => payload,
  [NumberPlateActionTypes.GET_SETTING.SUCCESS]: payload => payload,
  [NumberPlateActionTypes.GET_SETTING.ERROR]: payload => payload,

  [NumberPlateActionTypes.GET_IDEAS_PAGES.REQUEST]: payload => payload,
  [NumberPlateActionTypes.GET_IDEAS_PAGES.SUCCESS]: payload => payload,
  [NumberPlateActionTypes.GET_IDEAS_PAGES.ERROR]: payload => payload,

  [NumberPlateActionTypes.GET_PRIVATE_NUMBER_PLATES_BY_PATH.REQUEST]: payload => payload,
  [NumberPlateActionTypes.GET_PRIVATE_NUMBER_PLATES_BY_PATH.SUCCESS]: payload => payload,
  [NumberPlateActionTypes.GET_PRIVATE_NUMBER_PLATES_BY_PATH.ERROR]: payload => payload,

  [NumberPlateActionTypes.GET_TYPES_OF_REGISTRATIONS_BY_PATH.REQUEST]: payload => payload,
  [NumberPlateActionTypes.GET_TYPES_OF_REGISTRATIONS_BY_PATH.SUCCESS]: payload => payload,
  [NumberPlateActionTypes.GET_TYPES_OF_REGISTRATIONS_BY_PATH.ERROR]: payload => payload,

  [NumberPlateActionTypes.GET_FAQS_BY_PATH.REQUEST]: payload => payload,
  [NumberPlateActionTypes.GET_FAQS_BY_PATH.SUCCESS]: payload => payload,
  [NumberPlateActionTypes.GET_FAQS_BY_PATH.ERROR]: payload => payload,

  [NumberPlateActionTypes.NUMBER_PLATE_SEARCH.REQUEST]: payload => payload,
  [NumberPlateActionTypes.NUMBER_PLATE_SEARCH.SUCCESS]: payload => payload,
  [NumberPlateActionTypes.NUMBER_PLATE_SEARCH.ERROR]: payload => payload,

  [NumberPlateActionTypes.RANDOM_FETCH_PLATES.REQUEST]: payload => payload,
  [NumberPlateActionTypes.RANDOM_FETCH_PLATES.SUCCESS]: payload => payload,
  [NumberPlateActionTypes.RANDOM_FETCH_PLATES.ERROR]: payload => payload,

  [NumberPlateActionTypes.SET_PLATE_KEYWORD_FOR_SEARCH.REQUEST]: payload => payload,
  [NumberPlateActionTypes.SET_PLATE_KEYWORD_FOR_SEARCH.SUCCESS]: payload => payload,
  [NumberPlateActionTypes.SET_PLATE_KEYWORD_FOR_SEARCH.ERROR]: payload => payload,

  [NumberPlateActionTypes.NUMBER_PLATE_LOAD_MORE.REQUEST]: payload => payload,
  [NumberPlateActionTypes.NUMBER_PLATE_LOAD_MORE.SUCCESS]: payload => payload,
  [NumberPlateActionTypes.NUMBER_PLATE_LOAD_MORE.ERROR]: payload => payload,

  [NumberPlateActionTypes.SUBMIT_ENQUIRE.REQUEST]: payload => payload,
  [NumberPlateActionTypes.SUBMIT_ENQUIRE.SUCCESS]: payload => payload,
  [NumberPlateActionTypes.SUBMIT_ENQUIRE.ERROR]: payload => payload,

  [NumberPlateActionTypes.ADD_FILTER.REQUEST]: payload => payload,
  [NumberPlateActionTypes.ADD_FILTER.SUCCESS]: payload => payload,
  [NumberPlateActionTypes.ADD_FILTER.ERROR]: payload => payload,

  [NumberPlateActionTypes.CLEAR_FILTER.REQUEST]: payload => payload,
  [NumberPlateActionTypes.CLEAR_FILTER.SUCCESS]: payload => payload,
  [NumberPlateActionTypes.CLEAR_FILTER.ERROR]: payload => payload,

  [NumberPlateActionTypes.CHANGE_LOADING]: payload => payload,
});
