export const categories = entity => {
  const { name, path, links, id, type, dataByType } = entity;
  return { title: name, url: path || links.self.href, id, type, dataByType };
};

export const manufacturer = entity => {
  const { id, name, image } = entity;
  const defaultSrc = image?.sizes?.thumbnailLg;
  return { defaultSrc, alt: name, id, name, url: '#', image };
};

export const insuranceBroker = entity => {
  const { broker, ...rest } = entity;
  return { ...rest, manufacturer: manufacturer(broker) };
};

export const mediaImage = entity => {
  let out = entity;
  if (entity.file) {
    out = { ...entity, ...entity.file };
  }
  return out;
};

export const mediaVideo = entity => {
  let out = entity;
  out.thumbnail = out.thumbnail ? out.thumbnail : out.image;
  if (out.thumbnail) {
    out = { ...out, ...out.thumbnail };
  }
  if (out.file) {
    const { file, ...rest } = out;
    out = { ...rest, videoFile: file };
  }
  return out;
};

export const paragraphImage = entity => {
  // adding a schema to make the structure compliant to the components, by nesting a image property in itself.
  let out = entity;
  const { image: firstLevelImage } = out;
  if (firstLevelImage) {
    const image = { ...firstLevelImage, image: firstLevelImage };
    out = { ...entity, image };
  }
  return out;
};

// produces a compliant image structure for other paragraph types
export const paragraphLargeCtaBlock = paragraphImage;
export const paragraphIconBlock = paragraphImage;
export const paragraphIconBlockV2 = paragraphImage;
export const guides = paragraphImage;
export const reviews = paragraphImage;
export const blogs = paragraphImage;

export const fileFile = entity => {
  const { styles, ...data } = entity;
  let links = {};
  if (styles && styles.meta && styles.meta.imageDerivatives) {
    links = styles.meta.imageDerivatives.links;
  }

  const sizes = Object.keys(links).reduce((accumulator, key) => {
    let out = accumulator;
    if (key !== 'self') {
      out = { ...accumulator, [key]: links[key].href, [`${key}_webp`]: links[key]?.webp, [`${key}_meta`]: links[key]?.meta };
    }
    return out;
  }, {});
  const listCard = {
    srcset: [{ src: sizes.mediaSectionTeaser_webp, type: 'image/webp' }, { src: sizes.mediaSectionTeaser }],
    defaultSrc: sizes.mediaSectionTeaser,
    alt: sizes.originalSize_meta?.alt,
    ...data,
  };
  const listOverlay = {
    srcset: [
      { src: sizes.mediaSectionLargeTeaser_webp, type: 'image/webp' },
      { src: sizes.mediaSectionTeaser_webp, type: 'image/webp', media: '(max-width: 999px)' },
      { src: sizes.mediaSectionLargeTeaser },
      { src: sizes.mediaSectionTeaser, media: '(max-width: 999px)' },
    ],
    defaultSrc: sizes.mediaSectionLargeTeaser,
    alt: sizes.originalSize_meta?.alt,
    ...data,
  };
  const poster = {
    srcset: [
      { src: sizes.originalSize_webp, type: 'image/webp' },
      { src: sizes.mediaSectionLargeTeaserMobile_webp, type: 'image/webp', media: '(max-width: 999px)' },
      { src: sizes.originalSize },
      { src: sizes.mediaSectionLargeTeaserMobile, media: '(max-width: 999px)' },
    ],
    defaultSrc: sizes.originalSize,
    alt: sizes.originalSize_meta?.alt,
    ...data,
  };
  const author = {
    srcset: [
      { src: sizes.originalSize_webp, type: 'image/webp' },
      { src: sizes.thumbnail_webp, type: 'image/webp', media: '(max-width: 999px)' },
      { src: sizes.originalSize },
      { src: sizes.thumbnail, media: '(max-width: 999px)' },
    ],
    defaultSrc: sizes.originalSize,
    alt: sizes.originalSize_meta?.alt,
    ...data,
  };
  const customerQuote = {
    srcset: [
      { src: sizes.customerQuote_webp, type: 'image/webp' },
      { src: sizes.customerQuote_webp, type: 'image/webp', media: '(max-width: 999px)' },
      { src: data.customerQuote },
      { src: sizes.customerQuote, media: '(max-width: 999px)' },
    ],
    defaultSrc: data.uri,
    alt: sizes.originalSize_meta?.alt,
    ...data,
  };

  const thumbnailFull = {
    srcset: [{ src: sizes?.thumbnailLg_webp, type: 'image/webp' }, { src: sizes?.thumbnailLg }],
    defaultSrc: sizes?.thumbnailLg,
    href: sizes?.thumbnailLg,
    alt: sizes.originalSize_meta?.alt,
    ...data,
  };
  return { ...data, sizes, poster, listOverlay, listCard, author, customerQuote, thumbnailFull };
};

export const taxonomyTermFaqCategories = entity => {
  const { path, name, title } = entity;
  return {
    ...entity,
    url: path,
    title: name,
    originalTitle: title,
  };
};

export const keyMapping = {
  media: 'image',
};
