import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import { immutablySetOrMergeDeeply } from '../lib/mergeToStateHelper';
import * as insuranceActions from '../actions/insurance';

const initialState = {
  insuranceIsLoading: false,
  insuranceError: null,
  insurancePage: {},
  settings: {},
  manufacturers: {},
  allManufacturers: {},
  byPath: {},
};

export default {
  insurance: handleActions(
    {
      [combineActions(
        insuranceActions.getInsuranceByTypeRequest,
        insuranceActions.getInsuranceByPathRequest,
        insuranceActions.getInsuranceSettingsRequest,
        insuranceActions.getInsuranceManufacturersRequest,
      )]: state =>
        immutable(state, {
          insuranceIsLoading: { $set: true },
          insuranceError: { $set: null },
        }),

      [combineActions(
        insuranceActions.getInsuranceByTypeSuccess,
        insuranceActions.getInsuranceByPathSuccess,
        insuranceActions.getInsuranceSettingsSuccess,
        insuranceActions.getInsuranceManufacturersSuccess,
      )]: (state, { payload }) => {
        const update = immutablySetOrMergeDeeply(state, payload, {
          insuranceIsLoading: { $set: false },
          insuranceError: { $set: null },
        });
        return immutable(state, update);
      },

      [combineActions(
        insuranceActions.getInsuranceByTypeError,
        insuranceActions.getInsuranceByPathError,
        insuranceActions.getInsuranceSettingsError,
        insuranceActions.getInsuranceManufacturersError,
      )]: (state, { payload }) =>
        immutable(state, {
          insuranceIsLoading: { $set: false },
          insuranceError: { $set: payload },
        }),
    },
    initialState,
  ),
};
