import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import { immutablySetOrMergeDeeply } from '../lib/mergeToStateHelper';
import * as warrantiesActions from '../actions/warranties';

const initialState = {
  warrantyIsLoading: false,
  warrantyError: null,
  warrantyPage: {},
  settings: {},
  byPath: {},
  quote: {},
  fleetWarrantySettings: {},
  token: null,
  quoteToken: null,
  status: null,
};

export default {
  warranties: handleActions(
    {
      [combineActions(
        warrantiesActions.getWarrantiesByTypeRequest,
        warrantiesActions.getWarrantiesByPathRequest,
        warrantiesActions.getWarrantiesSettingsRequest,
        warrantiesActions.getFleetWarrantiesSettingsRequest,
        warrantiesActions.getWarrantiesQuoteRequest,
      )]: state =>
        immutable(state, {
          warrantyIsLoading: { $set: true },
          warrantyError: { $set: null },
        }),

      [combineActions(warrantiesActions.getWarrantiesShortLinkRequest)]: (state, { payload: { token } }) =>
        immutable(state, {
          token: { $setIfUpdated: token },
        }),

      [combineActions(
        warrantiesActions.getWarrantiesByTypeSuccess,
        warrantiesActions.getWarrantiesByPathSuccess,
        warrantiesActions.getWarrantiesSettingsSuccess,
        warrantiesActions.getFleetWarrantiesSettingsSuccess,
        warrantiesActions.getWarrantiesQuoteSuccess,
      )]: (state, { payload }) => {
        const update = immutablySetOrMergeDeeply(state, payload, {
          warrantyIsLoading: { $set: false },
          warrantyError: { $set: null },
        });
        return immutable(state, update);
      },

      [combineActions(warrantiesActions.getWarrantiesShortLinkSuccess)]: (state, { payload: { quoteToken, status } }) =>
        immutable(state, {
          status: { $setIfUpdated: status },
          quoteToken: { $setIfUpdated: quoteToken },
        }),

      [combineActions(
        warrantiesActions.getWarrantiesByTypeError,
        warrantiesActions.getWarrantiesByPathError,
        warrantiesActions.getWarrantiesSettingsError,
        warrantiesActions.getFleetWarrantiesSettingsError,
        warrantiesActions.getWarrantiesQuoteError,
        warrantiesActions.getWarrantiesShortLinkError,
      )]: (state, { payload }) =>
        immutable(state, {
          warrantyIsLoading: { $set: false },
          warrantyError: { $set: payload },
        }),
    },
    initialState,
  ),
};
