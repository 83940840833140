import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './IframePopup.scss';

const IframePopup = ({ src, onLoadFunc }) => {
  const [isLoading, setIsLoading] = useState(true);
  const iframeRef = useRef(null);
  const onLoad = () => {
    onLoadFunc(iframeRef.current);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };
  useEffect(() => {
    // change modal style
    const styleNode = document.createElement('style');
    styleNode.id = 'iframe-popup-custom-style';
    styleNode.innerHTML = `
        .modal-dialog{
          margin-top: 10vh !important;
        }
        `;
    document
      .getElementsByTagName('head')
      .item(0)
      .appendChild(styleNode);
    return () => {
      document.getElementsByTagName('head')[0].removeChild(styleNode);
    };
  });
  return (
    <div className="iframe-popup-wrapper" id="iframe-popup-wrapper">
      <iframe src={src} title="iframe" onLoad={onLoad} ref={iframeRef}></iframe>
      {isLoading && (
        <div className="d-flex justify-content-center loading-wrapper">
          <div
            className="spinner-border"
            role="status"
            style={{
              color: '#9FD34A',
              width: '1.5rem',
              height: '1.5rem',
              visibility: isLoading ? 'visible' : 'hidden',
            }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};
IframePopup.propTypes = {
  src: PropTypes.string,
  onLoadFunc: PropTypes.func, // return a func need to execute
};

IframePopup.defaultProps = {
  src: '',
  onLoadFunc: () => {},
};

export default IframePopup;
