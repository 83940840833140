import { createSelector } from 'reselect';
import { byId, makeGetByPath } from './cms/genericSelectors';
import { makeGetCmsByType } from './cms';

const getByPath = makeGetByPath('warranties');
const getSettings = state => state.warranties.settings;
export const getClaculatorData = state => state.finance.calculatorData;
const getCmsWarantyPage = makeGetCmsByType('nodeCarWarrantiesPage');
const getQuoteSettings = state => state.warranties.quote;
const getFleetWarrantySettings = state => state.warranties.fleetWarrantySettings;
const getWarranties = state => state.warranties;
const getCmsManufacturers = makeGetCmsByType('manufacturer');
const getCmsWarrantiesManufacturerQuote = makeGetCmsByType('nodeManufacturerWarrantyQuote');

export const getWarrantySettings = createSelector(
  [getSettings, makeGetCmsByType('configPagesCarWarrantiesSettings')],
  (iSettings, cms) => {
    const [settings] = byId(iSettings, cms);
    return settings;
  }
);

export const getIndividualByPathWarranty = createSelector(
  [getByPath, getCmsWarantyPage, getCmsWarrantiesManufacturerQuote],
  (id, carWarrantyPage, cmsManufacturerQuote) => carWarrantyPage[id] || cmsManufacturerQuote[id] || {}
);

export const getWarantyPages = createSelector([getWarrantySettings], settings => (settings && settings.pages) || []);

export const getWarrantiesQuoteSettings = createSelector(
  [getQuoteSettings, makeGetCmsByType('configPagesCarWarrantiesQuote')],
  (iSettings, cms) => {
    const [quoteSettings] = byId(iSettings, cms);
    return quoteSettings;
  }
);

export const getFleetWarrantiesSettings = createSelector(
  [getFleetWarrantySettings, makeGetCmsByType('configPagesCarWarrantiesFleet')],
  (iSettings, cms) => {
    const [fleetWarrantySettings] = byId(iSettings, cms);
    return fleetWarrantySettings;
  }
);

export const getManufacturers = createSelector([getWarranties, getCmsManufacturers], (warranties, manufacturers) => {
  const { allManufacturers } = warranties;
  return byId(allManufacturers, manufacturers);
});

export const getToken = createSelector(getWarranties, quote => quote.token);

export const getWarrantiesQuoteStatus = createSelector(getWarranties, quote => quote.status);

export const getRefIdQuote = createSelector(getWarranties, quote => quote.refId);

export const getWarrantyError = createSelector(getWarranties, quote => quote.warrantyError);
