import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';

import RedirectTypes from '../action-types/redirect';

export const redirectState = {
  isRedirectToStep: false,
};

export default {
  redirect: handleActions(
    {
      [RedirectTypes.REDIRECT_TO_STEP.REQUEST]: state =>
        immutable(state, {
          isRedirectToStep: { $set: true },
        }),

      [RedirectTypes.REDIRECT_TO_STEP.SUCCESS]: state =>
        immutable(state, {
          isRedirectToStep: { $set: false },
        }),

      [RedirectTypes.REDIRECT_TO_STEP.ERROR]: state =>
        immutable(state, {
          isRedirectToStep: { $set: false },
        }),
    },
    redirectState,
  ),
};
