import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Please enter your e-mail')
    .email('Please enter a valid e-mail'),
});

export default validationSchema;
