import { END } from 'redux-saga';
import { takeLatest, select, put, call } from 'redux-saga/effects';
import { setCookie, removeCookie, getCookie, preserveToastOnSSR } from 'lib/cookie';
import { redirect } from 'lib/routes';
import {
  continueWithQuoteShortLinkRequest,
  createQuoteRequest,
  dropQuote,
  setOnlineQuoteRequest,
} from 'store/actions/quote';
import QuoteTypes from 'store/action-types/quote';
import UserTypes from 'store/action-types/user';
import {
  getQuoteStatus,
  getQuoteToken,
  getQuote,
  getQuoteId,
  getHasAccount,
  getSalvageOfferPrice,
} from 'store/selectors/quote';
import { QUOTE_STATUS, QUOTE_ROUTES, SHORT_LINK_ACTION } from 'modules/ScrapMyCar/QuoteProcess/constants';
import { redirectToStepRequest, redirectToStepSuccess } from 'store/actions/redirect';
import { scrapCarQuote } from 'api-client/apiClientInstance/scrapCarQuote';
import { reportToBugsnag } from 'lib/bugsnag';

import { continueWithQuoteShortLinkError, continueWithQuoteShortLinkSuccess } from 'store/actions';
import { isUserAuthenticatedSelector } from 'store/selectors/userAuth';

const setQuoteDataToCookies = (quoteStatus = '') => {
  setCookie('quoteStatus', quoteStatus);
};

const removeQuoteDataFromCookies = () => {
  removeCookie('quoteStatus');
};

const removeQuoteDataFromStorage = () => {
  removeCookie('scrap-authorization');
};

export const getStepRoute = (quoteStatus = '', requestedQuoteStatus = '', salvageOfferPrice, action) => {
  const isRequestedStatusValid = requestedStatusCheck(quoteStatus, requestedQuoteStatus);
  const status = isRequestedStatusValid ? requestedQuoteStatus : quoteStatus;
  switch (status) {
    case QUOTE_STATUS.DRAFT:
      return QUOTE_ROUTES.ENTER_DETAILS;
    case QUOTE_STATUS.CREATED: {
      if (action) {
        switch (action) {
          case SHORT_LINK_ACTION.QUOTE:
            return QUOTE_ROUTES.ARRANGE_COLLECTION;
          case SHORT_LINK_ACTION.SALVAGE:
            if (salvageOfferPrice) {
              return QUOTE_ROUTES.SALVAGE_DETAILS;
            }
            break;
          default: {
            break;
          }
        }
      }
      if (salvageOfferPrice) {
        return QUOTE_ROUTES.SALVAGE_PRICE;
      }
      return QUOTE_ROUTES.ONLINE_QUOTATION;
    }
    case QUOTE_STATUS.NEW_OFFER:
      return QUOTE_ROUTES.ONLINE_QUOTATION;
    case QUOTE_STATUS.OFFER_WITH_CONDITION:
      return QUOTE_ROUTES.ONLINE_QUOTATION;
    case QUOTE_STATUS.SALVAGE_OFFER:
      return QUOTE_ROUTES.ONLINE_QUOTATION;
    case QUOTE_STATUS.SALVAGE_OFFER_NEED_CONFIRM:
      return QUOTE_ROUTES.SALVAGE_OFFER_NEED_CONFIRM;
    case QUOTE_STATUS.SALVAGE_OFFER_FAULT:
      return QUOTE_ROUTES.ONLINE_QUOTATION;
    case QUOTE_STATUS.ARRANGE:
      return QUOTE_ROUTES.ARRANGE_COLLECTION;
    case QUOTE_STATUS.PENDING_PAYMENT:
      return QUOTE_ROUTES.PAYMENT_METHOD;
    case QUOTE_STATUS.CONFIRMED:
      return QUOTE_ROUTES.CONFIRMATION;
    case QUOTE_STATUS.EXPIRED:
      return QUOTE_ROUTES.CUSTOMER_PORTAL_QUOTE_HISTORY;
    case QUOTE_STATUS.PAID:
      return QUOTE_ROUTES.CUSTOMER_PORTAL_SCRAPPED_CARS;
    case QUOTE_STATUS.COLLECTION:
      return QUOTE_ROUTES.CUSTOMER_PORTAL_SCRAPPED_CARS;
    case QUOTE_STATUS.DELETED:
      return QUOTE_ROUTES.CUSTOMER_PORTAL_SCRAPPED_CARS;
    case QUOTE_STATUS.COMPLETED:
      return QUOTE_ROUTES.CUSTOMER_PORTAL_SCRAPPED_CARS;
    case QUOTE_STATUS.UNAUTHORIZED:
      return QUOTE_ROUTES.LOGIN;
    case QUOTE_STATUS.FORBIDDEN:
      return QUOTE_ROUTES.HOMEPAGE;
    case QUOTE_STATUS.BAD:
      return QUOTE_ROUTES.HOMEPAGE;
    default:
      return QUOTE_ROUTES.HOMEPAGE;
  }
};

const getStatusIndex = status => {
  const quoteStatuses = [
    QUOTE_STATUS.DRAFT,
    QUOTE_STATUS.CREATED,
    QUOTE_STATUS.ARRANGE,
    QUOTE_STATUS.PENDING_PAYMENT,
    QUOTE_STATUS.CONFIRMED,
    QUOTE_STATUS.NEW_OFFER,
    QUOTE_STATUS.OFFER_WITH_CONDITION,
  ];
  return quoteStatuses.indexOf(status);
};

const isRequestedStatusLessThanCurrent = (quoteStatusIndex, requestedQuoteStatusIndex) => {
  const isQuoteStatusValid = quoteStatusIndex > -1;
  const isRequestedQuoteStatusValid = requestedQuoteStatusIndex > -1;
  return isQuoteStatusValid && isRequestedQuoteStatusValid && requestedQuoteStatusIndex < quoteStatusIndex;
};

const isRequestedStatusEqualToCurrent = (quoteStatusIndex, requestedQuoteStatusIndex) => {
  const isQuoteStatusValid = quoteStatusIndex > -1;
  const isRequestedQuoteStatusValid = requestedQuoteStatusIndex > -1;
  return isQuoteStatusValid && isRequestedQuoteStatusValid && requestedQuoteStatusIndex === quoteStatusIndex;
};

const isRequestedStatusLessOrEqualThanCurrent = (quoteStatusIndex, requestedQuoteStatusIndex) => {
  const isQuoteStatusValid = quoteStatusIndex > -1;
  const isRequestedQuoteStatusValid = requestedQuoteStatusIndex > -1;
  return isQuoteStatusValid && isRequestedQuoteStatusValid && requestedQuoteStatusIndex <= quoteStatusIndex;
};

export const requestedStatusCheck = (quoteStatus = '', requestedQuoteStatus = '') => {
  const quoteStatusIndex = getStatusIndex(quoteStatus);
  const requestedQuoteStatusIndex = getStatusIndex(requestedQuoteStatus);
  return isRequestedStatusEqualToCurrent(quoteStatusIndex, requestedQuoteStatusIndex);
};

export const requestedStatusCheckForData = (quoteStatus = '', requestedQuoteStatus = '') => {
  const quoteStatusIndex = getStatusIndex(quoteStatus);
  const requestedQuoteStatusIndex = getStatusIndex(requestedQuoteStatus);
  return isRequestedStatusLessThanCurrent(quoteStatusIndex, requestedQuoteStatusIndex);
};

export const checkQuoteStatus = (ctx, baseStepRoute = '/', requestedQuoteStatus = '', currentRoute) => {
  const quoteStatus = getCookie('quoteStatus', ctx.req);
  if (quoteStatus && requestedQuoteStatus) {
    const stepRoute = getStepRoute(quoteStatus, requestedQuoteStatus);
    if (currentRoute !== stepRoute) {
      redirect(ctx, stepRoute);
    }
  }
};

export function* redirectToStep(stepRouteToRedirect, ctx = null) {
  yield put(redirectToStepRequest());
  const quoteStatus = yield select(getQuoteStatus);
  yield setQuoteDataToCookies(quoteStatus);

  try {
    yield redirect(ctx, stepRouteToRedirect);
  } catch (err) {
    reportToBugsnag(err, 'redirectToStep');
  } finally {
    yield put(redirectToStepSuccess());
  }
}

export function* cancelQuote() {
  yield removeQuoteDataFromCookies();
  yield removeQuoteDataFromStorage();
  yield redirect({}, '/scrap-my-car');
}

export function* dropQuoteData() {
  yield removeQuoteDataFromCookies();
  yield removeQuoteDataFromStorage();
}

export function* continueWithQuoteSuccess({ payload: { ctx } }) {
  const quoteStatus = yield select(getQuoteStatus);
  const stepRouteToRedirect = getStepRoute(quoteStatus);
  yield redirectToStep(stepRouteToRedirect, ctx);
}

export function* continueWithQuoteStatus({ payload: { ctx, acceptOffer, action } }) {
  let quoteStatus = yield select(getQuoteStatus);
  const fullQuote = yield select(getQuote);
  if (quoteStatus === QUOTE_STATUS.PENDING_PAYMENT && !fullQuote.redirect) {
    quoteStatus = QUOTE_STATUS.ARRANGE;
  }
  if (quoteStatus === QUOTE_STATUS.OFFER_WITH_CONDITION && acceptOffer) {
    quoteStatus = QUOTE_STATUS.ARRANGE;
  }
  const salvageOfferPrice = yield select(getSalvageOfferPrice);
  const stepRouteToRedirect = getStepRoute(quoteStatus, '', salvageOfferPrice, action);
  yield redirectToStep(stepRouteToRedirect, ctx);
}

export function* redirectToStepByQuoteStatus() {
  const quoteStatus = yield select(getQuoteStatus);
  yield setQuoteDataToCookies(quoteStatus);
  const salvageOfferPrice = yield select(getSalvageOfferPrice);
  const stepRouteToRedirect = getStepRoute(quoteStatus, '', salvageOfferPrice);
  yield redirectToStep(stepRouteToRedirect);
}

export function* handleUnauthorizedQuote() {
  let quoteStatus = yield select(getQuoteStatus);

  // new customer register then associate quote with customer
  const quoteId = yield select(getQuoteId);
  const hasAccount = yield select(getHasAccount);
  // if (!hasAccount && quoteId && quoteStatus === QUOTE_STATUS.PENDING_PAYMENT) {
  //   // associate only when customer just register
  //   yield call(scrapCarQuote.associateQuoteWithCustomer, quoteId);
  // }

  if (!quoteStatus) {
    quoteStatus = getCookie('scrap_car_quote_status');
  }
  if (quoteStatus === QUOTE_STATUS.UNAUTHORIZED) {
    yield put(createQuoteRequest({ actions: { setSubmitting: () => {} } }));
  }

  if (quoteStatus === QUOTE_STATUS.CONTINUE_FORBIDDEN) {
    yield put(continueWithQuoteShortLinkRequest({}));
  }
  removeCookie('scrap_car_quote_status');
}

export function* handleLogout() {
  yield put(dropQuote());
}

export function* continueWithQuoteShortLink({ payload: { req, res, acceptOffer, companyToken, action } }) {
  const isAuthenticated = yield select(isUserAuthenticatedSelector);
  let token = yield select(getQuoteToken);
  if (!token) {
    token = getCookie('qoute_token');
    removeCookie('qoute_token');
  }
  let isAcceptOffer = acceptOffer;
  if (!acceptOffer) {
    isAcceptOffer = getCookie('scrap_car_quote_accept_offer') === token;
    removeCookie('scrap_car_quote_accept_offer');
  }
  try {
    if (token) {
      const data = yield call(scrapCarQuote.continueWithQuote, token, req, isAcceptOffer, companyToken, action);
      const { applicationToken, access_token } = data;
      // service side call, we need set access_token manual. because set cookies not work in service side.
      if (access_token && req && res) setCookie('access_token', access_token, req, res);
      setCookie('scrap-authorization', applicationToken, req, res);
      yield put(
        continueWithQuoteShortLinkSuccess({
          ctx: { res },
          acceptOffer: isAcceptOffer,
          companyToken,
          action,
          ...data,
        }),
      );
      if (isAcceptOffer && !req) {
        const quoteStatus = data?.status === QUOTE_STATUS.OFFER_WITH_CONDITION ? QUOTE_STATUS.ARRANGE : data?.status;
        yield put(setOnlineQuoteRequest({ quoteStatus }));
      }
      // only server side need to end saga.
      if (req) yield put(END);
    }
  } catch (err) {
    if (err.status === 401 && !isAuthenticated) {
      yield put(continueWithQuoteShortLinkError({ quoteStatus: QUOTE_STATUS.CONTINUE_FORBIDDEN }));
      setCookie('scrap_car_quote_status', QUOTE_STATUS.CONTINUE_FORBIDDEN, req, res);
      setCookie('qoute_token', token, req, res);
      if (isAcceptOffer) {
        setCookie('scrap_car_quote_accept_offer', token, req, res);
      }
      redirect({ res }, '/sign-in');
    } else if (err.status === 403) {
      const message = err?.error?.messages?.common?.[0];
      preserveToastOnSSR(message, 'error', req, res);
      redirect({ res }, '/scrap-my-car');
    } else if (err.status === 404) {
      redirect({ res }, '/not-found');
    } else {
      reportToBugsnag(err, 'continueWithQuoteShortLink');
      yield put(continueWithQuoteShortLinkError(err));
    }
  }
}

export function* setQuoteStatus() {
  const quoteStatus = yield select(getQuoteStatus);
  yield setQuoteDataToCookies(quoteStatus);
}

export function* redirectToPriceConfirm() {
  yield redirectToStep(QUOTE_ROUTES.PRICE_NEED_CONFIRM);
}

export default [
  takeLatest([QuoteTypes.CREATE_QUOTE.SUCCESS], redirectToStepByQuoteStatus),
  takeLatest([QuoteTypes.GET_SALVAGE_OFFER_PRICE.SUCCESS], redirectToStepByQuoteStatus),
  takeLatest([QuoteTypes.ENTER_DETAILS.SUCCESS], redirectToStepByQuoteStatus),

  takeLatest([QuoteTypes.SET_ONLINE_QUOTE.SUCCESS], redirectToStepByQuoteStatus),

  takeLatest([QuoteTypes.COMPLETE_ARRANGE_COLLECTION.actionName], redirectToStepByQuoteStatus),
  takeLatest([QuoteTypes.SET_PAYMENT_METHOD.SUCCESS], redirectToStepByQuoteStatus),
  takeLatest([QuoteTypes.REDIRECT_TO_STEP_BY_QUOTE_STATUS], redirectToStepByQuoteStatus),
  takeLatest([QuoteTypes.HANDLE_QUOTE_FORBIDDEN_STATUS.actionName], redirectToStepByQuoteStatus),
  takeLatest([QuoteTypes.HANDLE_QUOTE_UNAUTHORIZED_STATUS.actionName], redirectToStepByQuoteStatus),
  takeLatest([QuoteTypes.HANDLE_QUOTE_BAD_STATUS.actionName], redirectToStepByQuoteStatus),
  takeLatest([QuoteTypes.CANCEL_QUOTE.SUCCESS, QuoteTypes.RE_QUOTE], cancelQuote),
  takeLatest([QuoteTypes.DROP_QUOTE.actionName], dropQuoteData),
  takeLatest(
    [QuoteTypes.CONTINUE_WITH_QUOTE.SUCCESS, QuoteTypes.CONTINUE_WITH_QUOTE_SHORT_LINK.SUCCESS],
    continueWithQuoteStatus,
  ),
  takeLatest([QuoteTypes.REFRESH_PRICE.SUCCESS], continueWithQuoteSuccess),
  takeLatest([UserTypes.USER_LOGIN.SUCCESS], handleUnauthorizedQuote),
  takeLatest([UserTypes.USER_LOGOUT.SUCCESS], handleLogout),
  takeLatest(QuoteTypes.CONTINUE_WITH_QUOTE_SHORT_LINK.REQUEST, continueWithQuoteShortLink),
  takeLatest(QuoteTypes.ARRANGE_COLLECTION.SUCCESS, setQuoteStatus),

  takeLatest([QuoteTypes.CREATE_QUOTE_WITHOUT_INSTANT_PRICE.actionName], redirectToPriceConfirm),
];
