import fetch from 'cross-fetch';
import { reportToBugsnag } from 'lib/bugsnag';

export default class SolrSearchClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  getConfig(method, data) {
    const config = {
      method: method.toUpperCase(),
      // credentials: 'include',
      // redirect: 'follow',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if (this.accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${this.accessToken}`,
      };
    }

    if (data) {
      config.body = JSON.stringify(data);
    }

    return config;
  }

  async numberPlateRequest(endpoint, config) {
    const response = await fetch(endpoint, config);
    const { status } = response;
    if (status === 204) {
      return { status: response.status };
    }
    if (status >= 200 && status < 300) {
      return response
        .json()
        .then(({ response: response_2, ...rest }) => ({ ...response_2, ...rest }))
        .catch(error => {
          throw error;
        });
    }
    const err = await response.json();
    const error_1 = err;
    error_1.status = response.status;
    throw error_1;
  }

  numberPlateGet(endpoint, params) {
    const query = params ? `?${params}` : '';
    return this.numberPlateRequest(`${this.baseUrl}${endpoint}${query}`, this.getConfig('get', null));
  }

  numberPlatePost(endpoint, data) {
    return this.numberPlateRequest(`${this.baseUrl}${endpoint}`, this.getConfig('post', data));
  }

  set token(token) {
    this.accessToken = token;
  }
}
