import { createAsyncActionSet } from 'store/lib/action-helper';

export default {
  GET_SETTING: createAsyncActionSet('GET_SETTING'),
  GET_IDEAS_PAGES: createAsyncActionSet('GET_IDEAS_PAGES'),
  GET_PRIVATE_NUMBER_PLATES_BY_PATH: createAsyncActionSet('GET_PRIVATE_NUMBER_PLATES_BY_PATH'),
  GET_TYPES_OF_REGISTRATIONS_BY_PATH: createAsyncActionSet('GET_TYPES_OF_REGISTRATIONS_BY_PATH'),
  GET_FAQS_BY_PATH: createAsyncActionSet('GET_FAQS_BY_PATH'),
  NUMBER_PLATE_SEARCH: createAsyncActionSet('NUMBER_PLATE_SEARCH'),
  NUMBER_PLATE_LOAD_MORE: createAsyncActionSet('NUMBER_PLATE_LOAD_MORE'),
  SET_PLATE_KEYWORD_FOR_SEARCH: createAsyncActionSet('SET_PLATE_KEYWORD_FOR_SEARCH'),
  RANDOM_FETCH_PLATES: createAsyncActionSet('RANDOM_FETCH_PLATES'),
  SUBMIT_ENQUIRE: createAsyncActionSet('SUBMIT_ENQUIRE'),

  ADD_FILTER: createAsyncActionSet('ADD_FILTER'),
  CLEAR_FILTER: createAsyncActionSet('CLEAR_FILTER'),

  CHANGE_LOADING: 'CHANGE_LOADING',
};
