import { call, put, take, takeLatest, all } from 'redux-saga/effects';
import { channel, END } from 'redux-saga';
import { reportToBugsnag } from 'lib/bugsnag';
import ValuationsTypes from '../action-types/valuations';
import CmsTypes from '../action-types/cms';
import { wrapWith, extractStoreIds } from '../lib/mergeToStateHelper';

export function* getSettings() {
  const fingerprint = 'valuationSettings';
  try {
    const type = 'settings';
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: {
        section: 'car-valuation',
        type,
        includes: [
          'field_content_item',
          'field_top_banner',
          'field_top_banner.image.file',
          'field_confirmation_page',
          'field_options_section',
          'field_confirmation_page.items,field_login_section,field_signup_section',
          'field_confirmation_page.right_col',
          'field_confirmation_page.right_col.image',
          'field_confirmation_page.right_col.image.file',
          'field_confirmation_page.left_col',
          'field_confirmation_page.left_col.image',
          'field_confirmation_page.left_col.image.file',
        ],
      },
      fingerprint,
    });

    const fullData = yield take(
      ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp
    );
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: ValuationsTypes.GET_VALUATIONS_SETTINGS.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getSettings');

    yield put({
      type: ValuationsTypes.GET_VALUATIONS_SETTINGS.ERROR,
      payload: err,
    });
  }
}

export function* getQuote() {
  const fingerprint = 'valuationQuotes';
  try {
    const type = 'pages';
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: {
        section: 'car-valuation',
        type,
        includes: [
          'field_paragraphs',
          'field_paragraphs.items',
          'field_paragraphs.image',
          'field_paragraphs.image.file',
          'field_paragraphs.items.image',
          'field_paragraphs.items.media.file',
          'field_paragraphs.items.image.file',
          'field_paragraphs.items.paragraphs',
          'field_paragraphs.items.paragraphs.items',
          'field_paragraphs.items.paragraphs.image',
          'field_paragraphs.items.paragraphs.image.file',
          'field_paragraphs.items.paragraphs.items.image',
          'field_paragraphs.items.paragraphs.items.image.file',
          'field_paragraphs.items.paragraphs.items.paragraphs',
          'field_paragraphs.items.paragraphs.items.media.file',
          'field_paragraphs.items.paragraphs.items.category',
          'field_paragraphs.left_col',
          'field_paragraphs.left_col.image.file',
          'field_paragraphs.right_col',
          'field_paragraphs.right_col.image.file',
          'field_paragraphs.field_logo',
          'field_paragraphs.field_logo.file',
        ],
      },
      fingerprint,
    });
    const fullData = yield take(
      ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp
    );
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: ValuationsTypes.GET_VALUATIONS_QUOTES.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getQuote');

    yield put({
      type: ValuationsTypes.GET_VALUATIONS_QUOTES.ERROR,
      payload: err,
    });
  }
}

export function* getIndividualByPath({ payload: { specificIncludes, ...payload } }) {
  const chan = yield call(channel);
  const { includes } = payload;
  try {
    yield put({
      type: CmsTypes.RESOLVE_CMS_ENTITY.REQUEST,
      payload,
      chan,
    });
    const {
      payload: {
        data,
        path: resourcePath,
        jsonapi: { resourceName },
      },
      path,
    } = yield take(chan);
    let outData = data;
    if (resourceName !== 'node--car_valuation') {
      yield put({
        type: CmsTypes.FETCH_CMS_INDIVIDUAL.REQUEST,
        payload: { path: resourcePath, includes },
        path,
      });
      const {
        payload: { data: includedData },
      } = yield take(CmsTypes.FETCH_CMS_INDIVIDUAL.SUCCESS);
      outData = includedData;
    }
    yield put({
      type: ValuationsTypes.GET_VALUATIONS_BY_PATH.SUCCESS,
      payload: extractStoreIds(outData, path),
    });
  } catch (err) {
    reportToBugsnag(err, 'getIndividualByPath');

    yield put({
      type: ValuationsTypes.GET_VALUATIONS_BY_PATH.ERROR,
      payload: err,
    });
  }
}

export function* waitingValuationContent() {
  // Only used for server rendering
  try {
    yield all({
      settings: take(ValuationsTypes.GET_VALUATIONS_SETTINGS.SUCCESS),
      path: take(ValuationsTypes.GET_VALUATIONS_BY_PATH.SUCCESS),
    });
  } finally {
    yield put(END);
  }
}

export default [
  takeLatest(ValuationsTypes.GET_VALUATIONS_SETTINGS.REQUEST, getSettings),
  takeLatest(ValuationsTypes.GET_VALUATIONS_QUOTES.REQUEST, getQuote),
  takeLatest(ValuationsTypes.GET_VALUATIONS_BY_PATH.REQUEST, getIndividualByPath),
  takeLatest(ValuationsTypes.WAITING_VALUATION_CONTENT, waitingValuationContent),
];
