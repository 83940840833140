import { call, put, takeLatest } from 'redux-saga/effects';
import { carModels } from 'api-client/apiClientInstance/carModels';
import { reportToBugsnag} from 'lib/bugsnag';
import ActionType from '../action-types/carModels';
import { SOME_ERROR_OCCURRED } from '../constants';

function* loadCarModels({ payload }) {
  try {
    const response = yield call(carModels.getCarModelsData, payload);

    yield put({
      type: ActionType.GET_CAR_MODELS_LIST.SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionType.GET_CAR_MODELS_LIST.ERROR,
      payload: err,
    });
    reportToBugsnag(err, 'loadCarModels');

  }
}

export default [takeLatest(ActionType.GET_CAR_MODELS_LIST.REQUEST, loadCarModels)];
