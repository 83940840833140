import React from 'react';
import FormGroupHead from 'components/form/FormGroupHead';
import ErrorIndificator from 'components/errorIndificator';
import BookingJobSlotItem from './BookingJobSlotItem';

const BookingJobSlot = ({
  title,
  description,
  name,
  value,
  values,
  setFieldValue,
  error,
  originCollectionDate,
  className = 'pb-3 pt-5',
}) => (
  <div className={`form-box ${className}`}>
    <div className="form-block">
      <FormGroupHead title={title} description={description} />
      <BookingJobSlotItem
        name={name}
        value={value}
        values={values}
        setFieldValue={setFieldValue}
        originCollectionDate={originCollectionDate}
      />
    </div>
    <ErrorIndificator message={error} />
  </div>
);

export default BookingJobSlot;
