import { createActions } from 'redux-actions';
import InsuranceTypes from '../action-types/insurance';

export const {
  getInsuranceSettingsRequest,
  getInsuranceSettingsSuccess,
  getInsuranceSettingsError,

  getInsuranceByTypeRequest,
  getInsuranceByTypeSuccess,
  getInsuranceByTypeError,

  getInsuranceManufacturersRequest,
  getInsuranceManufacturersSuccess,
  getInsuranceManufacturersError,

  getInsuranceByPathRequest,
  getInsuranceByPathSuccess,
  getInsuranceByPathError,
} = createActions({
  [InsuranceTypes.GET_INSURANCE_SETTINGS.REQUEST]: payload => payload,
  [InsuranceTypes.GET_INSURANCE_SETTINGS.SUCCESS]: payload => payload,
  [InsuranceTypes.GET_INSURANCE_SETTINGS.ERROR]: payload => payload,

  [InsuranceTypes.GET_INSURANCE_BY_TYPE.REQUEST]: payload => payload,
  [InsuranceTypes.GET_INSURANCE_BY_TYPE.SUCCESS]: payload => payload,
  [InsuranceTypes.GET_INSURANCE_BY_TYPE.ERROR]: payload => payload,

  [InsuranceTypes.GET_INSURANCE_MANUFACTURERS.REQUEST]: payload => payload,
  [InsuranceTypes.GET_INSURANCE_MANUFACTURERS.SUCCESS]: payload => payload,
  [InsuranceTypes.GET_INSURANCE_MANUFACTURERS.ERROR]: payload => payload,

  [InsuranceTypes.GET_INSURANCE_BY_PATH.REQUEST]: payload => payload,
  [InsuranceTypes.GET_INSURANCE_BY_PATH.SUCCESS]: payload => payload,
  [InsuranceTypes.GET_INSURANCE_BY_PATH.ERROR]: payload => payload,
});
