import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SignInForm from 'modules/User/SignIn/SignInForm';
import { createStructuredSelector } from 'reselect';
import { isUserAuthenticatedSelector } from 'store/selectors/userAuth';
import { modalClose } from 'store/actions/modal';

const SignInPopup = ({ textContent, isUserAuthenticated, closeModal }) => {
  if (isUserAuthenticated) {
    closeModal();
  }

  return (
    <div className="tab" id="tab-signin">
      <div className="content-frame">
        <p>{textContent}</p>
        <form className="form-login form-bordered form-large b-validation">
          <SignInForm isForgotPasswordShown={false} isRedirect={false} isModalShouldBeClosed />
        </form>
      </div>
    </div>
  );
};

SignInPopup.propTypes = {
  textContent: PropTypes.string,
  isUserAuthenticated: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

SignInPopup.defaultProps = {
  textContent: '',
};

const mapStateToProps = createStructuredSelector({
  isUserAuthenticated: isUserAuthenticatedSelector,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(modalClose()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPopup);
