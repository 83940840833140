import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { signInInitialValues, signInSchema } from 'common/user';
import { useDispatch, useSelector } from 'react-redux';
import SocialLogin from 'modules/User/SignIn/SocialLogin';
import { Formik } from 'formik';
import InputFormGroup from 'components/form/InputFormGroup';
import { Link } from 'server/pages';
import PasswordInputGroup from 'components/form/PasswordInputGroup';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getUserEmailSelector } from 'store/selectors/user';
import { getUserLoginBusy } from 'store/selectors/userAuth';
import { userLoginRequest, setLoginBusy } from 'store/actions/user';
import ReactPiwik from 'react-piwik';

const SignInForm = ({ isForgotPasswordShown, redirect, forceEmail = null }) => {
  const dispatch = useDispatch();
  let email = forceEmail || useSelector(getUserEmailSelector) || '';
  let password;
  const login = (data, formikBag) => {
    ReactPiwik.push(['trackEvent', 'Form Submit', 'Sign-in-form']);
    dispatch(setLoginBusy(true));
    dispatch(userLoginRequest(data, formikBag, redirect));
  };

  const isBusy = useSelector(getUserLoginBusy);

  React.useEffect(() => {
    ReactPiwik.push(['FormAnalytics::trackForm', document.getElementById('sign-in-form')]);

    new Promise(resolve => {
      // setTimeout(() => {
      resolve();
      // }, 0);
    }).then(() => {
      const $email = document.querySelector('#email');
      const $password = document.querySelector('#password');
      email = $email.value || email;
      password = $password.value || '';
      setInitValues({
        ...initValues,
        email,
        password,
      });
    });
  }, []);

  const [initValues, setInitValues] = useState({
    ...signInInitialValues,
    email,
    password,
  });

  return (
    <>
      <SocialLogin redirect={redirect} forceEmail={forceEmail} />
      <p className="divider">or</p>

      <Formik
        initialValues={initValues}
        enableReinitialize
        isInitialValid={!!initValues.email}
        validationSchema={signInSchema}
        onSubmit={login}
        render={({ handleSubmit, handleChange, handleBlur, values, touched, isValid, isSubmitting, errors }) => (
          <Form
            noValidate
            onSubmit={handleSubmit}
            id="sign-in-form"
            name="sign-in-form"
            data-matomo-name="sign-in-form"
          >
            <Form.Row>
              <Form.Group as={Col}>
                <InputFormGroup
                  rounded
                  id="email"
                  type="email"
                  validationMarks
                  value={values.email}
                  name="email"
                  placeholder="Email"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  valid={touched.email && !errors.email}
                  error={touched.email && errors.email}
                  autoComplete="email"
                  disabled={!!email}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <PasswordInputGroup
                  rounded
                  id="password"
                  value={values.password}
                  name="password"
                  placeholder="Password"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={touched.password && errors.password}
                  autoComplete="current-password"
                />
              </Form.Group>
            </Form.Row>
            <ButtonGroup className="flex-column text-center px-0 col-12">
              <Button
                disabled={isBusy || !isValid || isSubmitting}
                variant="primary"
                size="lg"
                className="mt-3"
                type="sumbit"
              >
                Login
              </Button>
              {isForgotPasswordShown && (
                <div>
                  <br />
                  <Link route="/forgot-password">
                    <a className="register__link">Forgot your password?</a>
                  </Link>
                </div>
              )}
            </ButtonGroup>
          </Form>
        )}
      />
    </>
  );
};

SignInForm.propTypes = {
  isForgotPasswordShown: PropTypes.bool,
  redirect: PropTypes.string,
  forceEmail: PropTypes.string,
};

SignInForm.defaultProps = {
  isForgotPasswordShown: true,
  redirect: true,
  forceEmail: null,
};

export default SignInForm;
