import React from 'react';
import PropsTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import moment from 'moment';

import SelectFormGroup from 'components/form/SelectFormGroup';
import { getDataForBirth } from 'lib/forms';

const DateOfBirthForm = ({
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
  title,
  classNames,
  noBlankOption,
}) => {
  const { dayData, monthData, yearData } = getDataForBirth(values?.month?.value, values?.year?.value, noBlankOption);
  const handleDataOfBirthSelectChange = (name, { value }) => {
    let yearValue = values?.year?.value || '';
    let monthValue = values?.month?.value || '';
    if (name === 'year') {
      yearValue = value;
    } else if (name === 'month') {
      monthValue = value;
    }

    const date = moment().date(1);

    if (yearValue) {
      date.year(yearValue);
    }

    if (monthValue) {
      date.month(monthValue - 1);
    }

    if (values?.day?.value > date.daysInMonth()) {
      setFieldValue('day', '');
    }
  };
  return (
    <Form.Row className="mt-3">
      <Form.Group as={Col}>
        <Form.Label className={`page-form__field label ${classNames?.title}`}>{title}</Form.Label>
        <div className="page-form__select-group">
          <SelectFormGroup
            options={dayData}
            name="day"
            placeholder="Day"
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            stylesTheme="editProfileDateTheme"
            currentOption={values.day}
            error={touched.day && errors.day}
            classNames={classNames?.day}
          />
          <SelectFormGroup
            options={monthData}
            name="month"
            placeholder="Month"
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            stylesTheme="editProfileDateTheme"
            currentOption={values.month}
            onChange={handleDataOfBirthSelectChange}
            error={touched.month && errors.month}
            classNames={classNames?.month}
          />
          <SelectFormGroup
            options={yearData}
            name="year"
            placeholder="Year"
            setFieldValue={setFieldValue}
            onChange={handleDataOfBirthSelectChange}
            setFieldTouched={setFieldTouched}
            stylesTheme="editProfileDateTheme"
            currentOption={values.year}
            error={touched.year && errors.year}
            classNames={classNames?.year}
          />
        </div>
      </Form.Group>
    </Form.Row>
  );
};

DateOfBirthForm.propTypes = {
  setFieldValue: PropsTypes.func.isRequired,
  setFieldTouched: PropsTypes.func.isRequired,
  setFieldError: PropsTypes.func.isRequired,
  values: PropsTypes.object.isRequired,
  touched: PropsTypes.object.isRequired,
  errors: PropsTypes.object.isRequired,
  title: PropsTypes.string,
  classNames: PropsTypes.shape({
    title: PropsTypes.string,
    day: PropsTypes.string,
    month: PropsTypes.string,
    year: PropsTypes.string,
  }),
  noBlankOption: PropsTypes.bool,
};

DateOfBirthForm.defaultProps = {
  title: 'Date of birth',
  classNames: { day: '', month: '', year: '' },
  noBlankOption: false,
};

export default React.memo(DateOfBirthForm);
