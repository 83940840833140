import { call, put, select, takeLatest } from 'redux-saga/effects';
import { motQuote } from 'api-client/apiClientInstance/motQuote';
import { reportToBugsnag } from 'lib/bugsnag';
import { redirect } from 'lib/routes';
import { userLogoutRequest } from 'store/actions/user';
import { md5 } from 'lib/encrypt';
import moment from 'moment';
import { segmentTrack, segmentIdentify } from 'lib/segment';
import { getErrorMessage } from 'lib/message';
import { getRegistrationNumber } from '../selectors/motQuoteProcess';
import MotQuoteTypes from '../action-types/motQuoteProcess';
import { createMotQuoteSuccess } from '../actions/motQuoteProcess';
import { showToast } from '../actions/toasts';
import { SOME_ERROR_OCCURRED } from '../constants';
import mobileNumberFormat from '../lib/mobilePhoneHandler';

import {
  getUserEmailSelector,
  getUserFirstNameSelector,
  getUserLastNameSelector,
  getUserPhoneNumberSelector,
  getGdprConsent,
} from '../selectors/user';

export function* createMotQuote({
  payload: {
    actions: { setSubmitting },
  },
}) {
  try {
    setSubmitting(true);
    const registrationNumber = yield select(getRegistrationNumber);
    const firstName = yield select(getUserFirstNameSelector);
    const lastName = yield select(getUserLastNameSelector);
    const email = yield select(getUserEmailSelector);
    const phoneNumber = yield select(getUserPhoneNumberSelector);
    const gdprConsent = yield select(getGdprConsent);

    if (email) {
      segmentIdentify(md5(email), {
        email,
        firstname: firstName,
        lastname: lastName,
        GDPRConsent: gdprConsent,
        first_name: firstName,
        last_name: lastName,
        phone: mobileNumberFormat(phoneNumber),
        mobile: mobileNumberFormat(phoneNumber),
        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
      });
    }

    const { BillingAccount, Request, Response, motTaxID, vehicleImage } = yield call(motQuote.getMot, {
      carRegistration: registrationNumber,
    });

    if (email) {
      segmentIdentify(md5(email), {
        email,
        firstname: firstName,
        lastname: lastName,
        first_name: firstName,
        last_name: lastName,
        phone: mobileNumberFormat(phoneNumber),
        GDPRConsent: gdprConsent,
        mobile: mobileNumberFormat(phoneNumber),
        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
      });
    }

    if (Response && Response.StatusMessage && Response.StatusMessage === 'Success') {
      segmentTrack('Mot_Complete_Quote', {
        ...Response.DataItems,
      });
    }
    yield put(createMotQuoteSuccess({ BillingAccount, Request, Response, motTaxID, vehicleImage, registrationNumber }));
    yield redirect({}, '/check-mot-history/car-mot-details');

    setSubmitting(false);
  } catch (err) {
    reportToBugsnag(err, 'createMotQuote');
    if (err.status == 401) {
      yield redirect({}, '/check-mot-history/register');
    }
    const errMsg = getErrorMessage(err);
    if (err?.exception === 'ErrorException') {
      yield put(showToast({ message: 'Invalid Registration Number', kind: 'error' }));
      // yield redirect({}, '/check-mot-history/');
    } else if (errMsg.search('Token Expired') != -1) {
      yield put(showToast({ message: 'Login Expired', kind: 'error' }));
      yield put(userLogoutRequest({}, {}, '/check-mot-history/register'));
    } else if (errMsg.search('daily limit') != -1 || errMsg.search('No data results') != -1) {
      yield put(showToast({ message: errMsg, kind: 'error' }));
      yield redirect({}, '/check-mot-history');
    } else {
      yield put(
        createMotQuoteSuccess({
          BillingAccount: null,
          Request: null,
          Response: null,
          motTaxID: null,
          vehicleImage: null,
          status: '',
        }),
      );
    }
    setSubmitting(false);
  }
}

export default [takeLatest([MotQuoteTypes.CREATE_MOT_QUOTE.REQUEST], createMotQuote)];
