import { createAsyncActionSet } from '../lib/action-helper';

export default {
  SET_CART_ID: 'SET_CART_ID',
  SET_NAV_ROUTE: 'SET_NAV_ROUTE',
  RESET_CHECK_STATUS: 'RESET_CHECK_STATUS',
  MANUALLY_ENTER_ADDRESS: 'MANUALLY_ENTER_ADDRESS',
  START_ACTION: 'START_ACTION',
  STOP_ACTION: 'STOP_ACTION',
  SET_CUSTOM: 'SET_CUSTOM',
  SET_TRANSFER_CUSTOM: 'SET_TRANSFER_CUSTOM',
  DROP_CART: 'DROP_CART',
  GO_HOME: 'GO_HOME',

  RESTART: createAsyncActionSet('RESTART'),
  CHECK_REG: createAsyncActionSet('CHECK_REG'),
  SUBMIT_PERSONAL_DETAIL: createAsyncActionSet('SUBMIT_PERSONAL_DETAIL'),
  SUBMIT_CUSTOM_TEMPLATE: createAsyncActionSet('SUBMIT_CUSTOM_TEMPLATE'),
  SUBMIT_TRANSFER_DELIVERY: createAsyncActionSet('SUBMIT_TRANSFER_DELIVERY'),
  CONFIRM_CART: createAsyncActionSet('CONFIRM_CART'),
  PLACE_ORDER: createAsyncActionSet('PLACE_ORDER'),

  FETCH_CART: createAsyncActionSet('FETCH_CART'),
  GET_CUSTOMIZE_OPTION: createAsyncActionSet('GET_CUSTOMIZE_OPTION'),
  GET_TRANSFER_DELIVERY: createAsyncActionSet('GET_TRANSFER_DELIVERY'),
  UPDATE_TRANSFER_AND_GET_DELIVERY: createAsyncActionSet('UPDATE_TRANSFER_AND_GET_DELIVERY'),
  CREATE_CART: createAsyncActionSet('CREATE_CART'),
  GET_DELIVERY_METHOD: createAsyncActionSet('GET_DELIVERY_METHOD'),
  GET_CUSTOMER_CARDS: createAsyncActionSet('GET_CUSTOMER_CARDS'),
  GET_STRIPE_SETUP_INTENT: createAsyncActionSet('GET_STRIPE_SETUP_INTENT'),

  CHECK_USER_LOGIN: createAsyncActionSet('CHECK_USER_LOGIN'),
  CHECK_USER_ACCOUNT: createAsyncActionSet('CHECK_USER_ACCOUNT'),
};
