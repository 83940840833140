import { addCacheResistanceToParams } from '../lib/cache';

export default class FinanceCarQuoteClient {
  constructor(client) {
    this.client = client;
    this.resourceUrl = '/finance-flow';
  }

  continueWithQuote = token => this.client.get(`${this.resourceUrl}/continue/${token}/`, addCacheResistanceToParams());
}
