import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { showToast } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useState } from 'react';
import { getUserEmailSelector } from 'store/selectors/user';
import { getUserLoginBusy, isUserAuthenticatedSelector } from 'store/selectors/userAuth';
import { loginWith3rdPartyRequest, setLoginBusy } from 'store/actions/user';
import ErrorIndificator from 'components/errorIndificator';

const SocialLogin = ({ redirect, forceEmail = null, loginCallback, googleLabel, faceBookLabel }) => {
  const [isLimit3rdPartyCookie, setIsLimit3rdPartyCookie] = useState(false);

  const isAuthenticated = useSelector(isUserAuthenticatedSelector);

  const dispatch = useDispatch();
  const email = forceEmail || useSelector(getUserEmailSelector) || '';
  const [socialEmail, setSocialEmail] = useState(email);
  const [redirectUri, setRedirectUri] = useState('');

  const loginBy3rd = useRef(false);

  const isBusy = useSelector(getUserLoginBusy);

  React.useEffect(() => {
    // check sessionStorage isLimit3rdPartyCookie
    if (sessionStorage && sessionStorage.getItem('isLimit3rdPartyCookie')) {
      setIsLimit3rdPartyCookie(true);
    }
    setRedirectUri(window?.location?.href || '/');
  }, []);

  React.useEffect(() => {
    if (loginBy3rd.current && isAuthenticated && loginCallback && socialEmail) {
      loginCallback({ email: socialEmail });
    }
  }, [isAuthenticated]);

  const matchUser = loginEmail => {
    if (!!email && email !== loginEmail) {
      dispatch(
        showToast({
          message: "User doesn't match",
          kind: 'error',
        }),
      );
      return false;
    }

    return true;
  };

  const onSuccess_Google = res => {
    if (matchUser(res.profileObj.email)) {
      setSocialEmail(res.profileObj.email);
      const data = { type: 'google', dataToken: res.tokenObj.id_token };
      dispatch(setLoginBusy(true));
      dispatch(loginWith3rdPartyRequest({ data, redirect }));
      loginBy3rd.current = true;
    } else {
      dispatch(setLoginBusy(false));
    }
  };

  const onResponse_Facebook = res => {
    if (res.accessToken) {
      if (matchUser(res.email)) {
        setSocialEmail(res.email);
        const data = { type: 'facebook', dataToken: res.accessToken };
        dispatch(setLoginBusy(true));
        dispatch(loginWith3rdPartyRequest({ data, redirect }));
        loginBy3rd.current = true;
      } else {
        dispatch(setLoginBusy(false));
      }
    } else {
      dispatch(setLoginBusy(false));
    }
  };

  const onFailure_Google = () => {
    setIsLimit3rdPartyCookie(true);
    sessionStorage && sessionStorage.setItem('isLimit3rdPartyCookie', true);
    dispatch(setLoginBusy(false));
  };

  const on3rdLoginClicked = () => {
    dispatch(setLoginBusy(true));
  };

  return (
    <>
      {!isAuthenticated && (
        <div className={`${isBusy ? 'disableClick' : ''} row`}>
          <div className={`${isLimit3rdPartyCookie ? 'mb-0' : 'mb-3'} mb-sm-4 col-lg-6 col-12`}>
            <GoogleLogin
              clientId={process.env.GOOGLE_LOGIN_CLIENT_ID}
              theme="dark"
              buttonText={googleLabel || 'Sign in with Google'}
              className="my-google-login-button"
              icon="fa-facebook"
              onSuccess={onSuccess_Google}
              onFailure={onFailure_Google}
              isSignedIn={false}
              disabled={isLimit3rdPartyCookie}
            />
            {isLimit3rdPartyCookie && <ErrorIndificator message={isLimit3rdPartyCookie && ' Option not available'} />}
          </div>

          <div className="mb-3 mb-sm-4 col-lg-6 col-12">
            <FacebookLogin
              appId={process.env.FACEBOOK_LOGIN_APP_ID}
              autoLoad={false}
              fields="name,email,picture"
              callback={onResponse_Facebook}
              cssClass="my-facebook-button-class"
              redirectUri={redirectUri}
              isDisabled={!redirectUri}
              icon={
                <img
                  alt="facebook"
                  src="data:img/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
                AAALEwAACxMBAJqcGAAACLZJREFUeJzt3V1onmcdx/HfdT1PYq0tqxW3pQRENuvL5mSbzjapRERh
                WBGZTe2LsoOgtDrxFQ8N7YlHjqGzyzZkUJhxZegQBzvdQVq7HQ2m0B2O2a2r7dkwNsl1edBa1yWd
                bV783/f9+34OSw9+0Dzf3k/+IU8ScI0+t39qqJa0tdaFW2pKwyppS8oaqlUfSEk3qJRNRWlDznVQ
                Ul/SgJRT9O53MzN9oNH71lo/egCaaWT/kY/WkkdUdVeu5W4l3V6KNkpVSllJkvLFv5v+8xLK+dIf
                Wb+mWoUAQJK0Y9dvP1gGL+xMtd5blcdS0c1JuvhaTjl4HdYKATC2bdfjm/sD83sWSt1bNTeSaspS
                4v9vIwTA0I69j45V1Qekua9W5cHc7LfpWEMEwMXkZN7+yk27s/LPquqdF/+QR3t3BMDA6L6p+8or
                9XBWui16C5qFAHTYtn1Tn0xVv1LV5zPv7LEEAtBBnxg/Nripd+5Qrfpp5t8Y74Ivjo7ZvvvI7al3
                /smkdAf/5+N/4btAHbLjG0e+lXs6maQ7oregHXgC6ILJyTxyaujBmvSD6CloFwLQcmP3P7Huwql/
                PpmS7ovegvYhAC22bdcv3zt/4V9/zil/IXoL2onvAbTU2P1PrMv99X+SxIsfy0YA2mhyMs/Nzk6n
                lL4YPQXtxluAFho5NfRgyvpa9A60HwFomdF9j0yo8t1+rA7eArTI9j1H7iwL9eHoHegOngBa4u6v
                PLo+1fpUyloXvQXdQQBaYt3G8gspfSR6B7qFtwAtsGPfI6NS/X70DnQPAWi48fFjvYWaftP0366L
                diIADXe6d/5Alj4VvQPdRAAa7EvfPPo+Vf08ege6iwA02FsLb/1QWTdG70B3EYCGGht/eEMt6SfR
                O9BtnAEbar7fm8jS+6N3oNt4Amig8fFjPZX0o+gd6D4C0EB/7/1jp7I+FL0D3UcAGiil9J3oDfBA
                ABpmZM+vtxTVe6N3wAPfBGyYpIHdSepF74AHngAapqSyJ3oDfBCABrln/2PDWfmz0TvggwA0yEBZ
                2Bm9AV4IQKOkL0cvgBcC0BBjY5P9wq/4xv8ZAWiI+eGbPp2lDdE74IUANEXJY9ET4IcANEQpZTR6
                A/wQgIZIOX8megP8EIAGuGf/Y8NJujl6B/wQgAboL8zfFb0BnghAAySl26I3wBMBaAQCgBgEoAFq
                Kh+P3gBPBKABknRL9AZ44vcBBNu26/HN0sIN0TtilPMq+UVlvZRKebn08mtK5fRA6Z3T/Nys3jw7
                +/zzh+ajV3YZAQjWH5z7cK0+D2JF5dWkdDTl+uzMrWde0KFDJXqTMwIQrCgNO3zoX1E5mZQOn9h6
                5jle9M1BAKLVtCV6wloqpbyee/mBE7/77h+it2AxAhCt1iGlbj4D1FKfqQsDEzNPfft89BYszefN
                Z1PV1MnP/quqDx3/2Btf/8vTvPibjCeAaKlulrr1BFBVHzo+fZBPNmoBngCipbo5esJqqqU+c3z6
                wI+jd+DaEIBgteRN0RtWSynl9bLQn5BSjd6Ca0MAouWyMXrCaskpfY/3/O1CAILlovXRG1ZDUTk5
                8/uDf4zegetDAKLlbgQgKR2O3oDrRwCClaL3RG9YqaLy6vGtbzwXvQPXjwBEy7n1p9ikdJQf720n
                AhCv9Z8EnHJ9NnoDlocABMsqLQ9AOT9z65kXoldgeQhAuNzuHwMs+UUe/9uLAGBlsl6KnoDlIwBY
                kVTKy9EbsHwEACtSevm16A1YPgKAlUnldPQELB8BwIoMlN656A1YPgKAlZmfm42egOUjAFiZN88S
                gBYjAFgRfm9/uxEAwBgBAIwRAMAYAQCMEQDAGAEAjBEAwBgBAIwRAMAYAQCMEQDAGAEAjBEAwBgB
                AIwRAMAYAQCMEQDAGAEAjBEAwBgBAIwRAMAYAQCMEQDAWLs/m17S6N6pGr0B3VRUz56YPnhj9I61
                xBMAcFXpr9EL1hoBAK4iFf0tesNaIwDAVeRceQIAfGUCANia63f+LUA/egDQREX17ImnJ85G71hr
                PAEAS+r+BUAiAMCSHC4AEgEAluRwAZAIAHAV3b8ASAQAWJrBBUDiCgAs4nIBkHgCAJbgcQGQCACw
                iMsFQCIAwCIuFwCJAABL8LgASAQAWMzkAiBxBQCu4HQBkHgCAN7B5wIgEQDgCk4XAIkAAFdwugBI
                BAB4B58LgEQAgCsZXQAkrgDAZW4XAIknAOBtvC4AEgEALnO7AEgEALjM7QIgEQDgbbwuABIBAP7L
                7AIgcQUAJHleACSeAIBL/C4AEgEAJHleACQCAEjyvABIBAC4xO8CIBEA4CLDC4DEFQCwvQBIPAEA
                cr0ASAQAsL0ASAQAsL0ASAQAkOsFQCIAgO0FQOIKAHPOFwCJJwDY870ASAQA5pwvABIBgDnnC4BE
                AGDP9wIgEQC4M74ASFKKHuBudO9Ujd6wEjPTB/gaajGeAABjBAAwRgAAYwQAMEYAAGMEADBGAABj
                BAAwRgAAYwQAMEYAAGMEADBGAABjBAAwRgAAYwQAMEYAAGMEADBGAABjBAAwRgAAYwQAMEYAAGME
                ADBGAABjBAAwRgAAYwQAMEYAAGMEADBGAABjBAAwRgAAYwQAMEYAAGMEADBGAABjBAAwRgAAYwQA
                MEYAAGMEADBGAABjBAAwRgAAYwQAMEYAAGMEADBGAABjBAAwRgAAYwQAMEYAAGMEADBGAABjBAAw
                RgAAYwQAMEYAAGMEADBGAABjBAAwRgAAYwQAMEYAAGMEADBGAABjBAAwRgAAYwQAMEYAAGMEADBG
                AABjBAAwRgAAYwQAMEYAAGMEADBGAABjBAAwRgAAYwQAMEYAAGMEADBGAABjBAAwRgAAYwQAMEYA
                AGMEADBGAABjBAAwRgAAYwQAMEYAAGMEADBGAABjBAAwRgAAYwQAMEYAAGMEADBGAABjBAAwRgAA
                YwQAMEYAAGMEADBGAABjBAAwRgAAY/8GuaykGaseCxUAAAAASUVORK5CYII="
                ></img>
              }
              onClick={on3rdLoginClicked}
              textButton={faceBookLabel || 'Sign in with Facebook'}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SocialLogin;
