export const appIosPng = 'https://www.car.co.uk/images/app-ios.png';
export const appAndroidPng = 'https://www.car.co.uk/images/app-android.png';
export const mastercardPng = 'https://www.car.co.uk/images/mastercard.png';
export const paypalPng = 'https://www.car.co.uk/images/paypal.png';
export const bitcoin2Png = 'https://www.car.co.uk/images/bitcoin2.png';
export const bitcoinPng = 'https://www.car.co.uk/images/bitcoin.png';
export const diamondPng = 'https://www.car.co.uk/images/diamond.png';
export const logoPng = 'https://www.car.co.uk/images/logo.v2.png';
export const media2Jpg = 'https://www.car.co.uk/images/media2.jpg';
export const media4Jpg = 'https://www.car.co.uk/images/media4.jpg';
export const media20Jpg = 'https://www.car.co.uk/images/media20.jpg';
export const auction4Jpg = 'https://www.car.co.uk/images/auction4.jpg';
export const auction5Jpg = 'https://www.car.co.uk/images/auction5.jpg';
export const auction6Jpg = 'https://www.car.co.uk/images/auction6.jpg';

export const auction4Mobile = 'https://www.car.co.uk/images/auction4-mobile.jpg';
export const auction4Mobile2x = 'https://www.car.co.uk/images/auction4-mobile-2x.jpg';
export const auction4 = 'https://www.car.co.uk/images/auction4.jpg';
export const auction42x = 'https://www.car.co.uk/images/auction4-2x.jpg';
export const cabriolet = 'https://www.car.co.uk/images/a5-cabriolet.jpg';

export const auctionsContent = 'https://www.car.co.uk/images/auctions-content.png';
export const auctionsContent2x = 'https://www.car.co.uk/images/auctions-content-2x.png';

export const story4Mobile = 'https://www.car.co.uk/images/story4-mobile.jpg';
export const story4Mobile2x = 'https://www.car.co.uk/images/story4-mobile-2x.jpg';
export const story4 = 'https://www.car.co.uk/images/story4.jpg';
export const story42x = 'https://www.car.co.uk/images/story4-2x.jpg';

export const media2 = 'https://www.car.co.uk/images/media2.jpg';
export const media22x = 'https://www.car.co.uk/images/media2-2x.jpg';
export const media2Mobile2x = 'https://www.car.co.uk/images/media2-mobile.jpg';
export const media2mobile = 'https://www.car.co.uk/images/media2-mobile-2x.jpg';

export const auction1 = 'https://www.car.co.uk/images/auction1.jpg';
export const auction12x = 'https://www.car.co.uk/images/auction1-2x.jpg';
export const auction1Mobile = 'https://www.car.co.uk/images/auction1-mobile.jpg';
export const auction1Mobile2x = 'https://www.car.co.uk/images/auction1-mobile-2x.jpg';

export const auction2 = 'https://www.car.co.uk/images/auction2.jpg';
export const auction22x = 'https://www.car.co.uk/images/auction2-2x.jpg';
export const auction2Mobile = 'https://www.car.co.uk/images/auction2-mobile.jpg';
export const auction2Mobile2x = 'https://www.car.co.uk/images/auction2-mobile-2x.jpg';

export const auction3 = 'https://www.car.co.uk/images/auction3.jpg';
export const auction32x = 'https://www.car.co.uk/images/auction3-2x.jpg';
export const auction3Mobile = 'https://www.car.co.uk/images/auction3-mobile.jpg';
export const auction3Mobile2x = 'https://www.car.co.uk/images/auction3-mobile-2x.jpg';

export const industryModelMobile = 'https://www.car.co.uk/images/industry-model-mobile.png';
export const industryModelMobile2x = 'https://www.car.co.uk/images/industry-model-mobile-2x.png';
export const industryModel = 'https://www.car.co.uk/images/industry-model.png';
export const industryModel2x = 'https://www.car.co.uk/images/industry-model-2x.png';

export const homepageBg = 'https://www.car.co.uk/images/homepage-background.png';
export const homepageBg2x = 'https://www.car.co.uk/images/homepage-background@2x.png';
export const homepageBgWp = 'https://www.car.co.uk/images/homepage-background.webp';
export const homepageBg2xWp = 'https://www.car.co.uk/images/homepage-background@2x.webp';

export const iphoneXInHand = 'https://www.car.co.uk/images/iPhone-X-in-Hand-Free-PSD-Mockup-2.jpg';
export const hunterNewtonUn = 'https://www.car.co.uk/images/hunter-newton-unsplash.jpg';

export const mainHeaderImage = 'https://www.car.co.uk/images/main-header-valuation-image.png';

export const imgSignup = 'https://www.car.co.uk/images/img-signup.png';
export const imgSignup2x = 'https://www.car.co.uk/images/img-signup-2x.png';
export const auction7 = 'https://www.car.co.uk/images/auction7.jpg';
export const mobileApp = 'https://www.car.co.uk/images/mobile-app.png';

export const noImage = 'https://www.car.co.uk/images/no-image.jpg';

export const mapMarker = 'https://www.car.co.uk/images/map-marker.png';
export const mapMarker2x = 'https://www.car.co.uk/images/map-marker@2x.png';

export const postRise = 'https://www.car.co.uk/images/post-rise.jpg';
export const postRise2x = 'https://www.car.co.uk/images/post-rise-2x.jpg';
export const postRiseMobile = 'https://www.car.co.uk/images/post-rise-mobile.jpg';
export const postRiseMobile2x = 'https://www.car.co.uk/images/post-rise-mobile-2x.jpg';
export const services13 = 'https://www.car.co.uk/images/car-finance@2x.jpg';
export const services15 = 'https://www.car.co.uk/images/car-warranties@2x.jpg';
export const services12 = 'https://www.car.co.uk/images/car-finance.jpg';
export const services14 = 'https://www.car.co.uk/images/car-warranties.jpg';
export const services13Wp = 'https://www.car.co.uk/images/car-finance@2x.webp';
export const services15Wp = 'https://www.car.co.uk/images/car-warranties@2x.webp';
export const services12Wp = 'https://www.car.co.uk/images/car-finance.webp';
export const services14Wp = 'https://www.car.co.uk/images/car-warranties.webp';

export const iconThanks = 'https://www.car.co.uk/images/icon-thanks.svg';

export const lloydsBank = 'https://www.car.co.uk/images/lloyds-bank-logo.png';
export const hsbc = 'https://www.car.co.uk/images/hsbc-bank-logo.png';
export const santander = 'https://www.car.co.uk/images/santander-bank-logo.png';

export const carImage = 'https://www.car.co.uk/images/car-image.jpeg';
export const socialValue = 'https://www.car.co.uk/images/social-value.png';
export const socialValue2x = 'https://www.car.co.uk/images/social-value@2x.png';

export const socialValueMainPeople1 = 'https://www.car.co.uk/images/social-value-right-image.jpg';
export const socialValueMainPeople2 = 'https://www.car.co.uk/images/social-value-right-image@2x.jpg';
export const socialValueMainPeople3 = 'https://www.car.co.uk/images/social-value-right-image-1.jpg';
export const socialValueMainPeople4 = 'https://www.car.co.uk/images/social-value-right-image-1@2x.jpg';

export const socialValueFoodReDistribution1 = 'https://www.car.co.uk/images/social-value-food-re-distribution-1.png';
export const socialValueFoodReDistribution2 = 'https://www.car.co.uk/images/social-value-food-re-distribution-2.png';

export const hmpAcademiesImage11 = 'https://www.car.co.uk/images/hmp-academies-right-image-1.jpg';
export const hmpAcademiesImage12 = 'https://www.car.co.uk/images/hmp-academies-right-image-1@2x.jpg';
export const hmpAcademiesImage21 = 'https://www.car.co.uk/images/hmp-academies-right-image-2.jpg';
export const hmpAcademiesImage22 = 'https://www.car.co.uk/images/hmp-academies-right-image-2@2x.jpg';
export const hmpAcademiesImage31 = 'https://www.car.co.uk/images/hmp-academies-right-image-3.jpg';
export const hmpAcademiesImage32 = 'https://www.car.co.uk/images/hmp-academies-right-image-3@2x.jpg';

export const socialValueCta1 = 'https://www.car.co.uk/images/social-value-homepage-hmp-academies-cta.jpg';
export const socialValueCta2 = 'https://www.car.co.uk/images/social-value-homepage-hmp-academies-cta@2x.jpg';

export const residentialProgrammeImage11 = 'https://www.car.co.uk/images/residential-programme-right-image-1.jpg';
export const residentialProgrammeImage12 = 'https://www.car.co.uk/images/residential-programme-right-image-1@2x.jpg';
export const residentialProgrammeImage21 = 'https://www.car.co.uk/images/residential-programme-right-image-2.jpg';
export const residentialProgrammeImage22 = 'https://www.car.co.uk/images/residential-programme-right-image-2@2x.jpg';

export const foodArticle1 = 'https://www.car.co.uk/images/food-redistribution-right-image.jpg';
export const foodArticle2 = 'https://www.car.co.uk/images/food-redistribution-right-image@2x.jpg';

export const foodArticle3 = 'https://www.car.co.uk/images/food-article-2@2x.jpg';

export const schemeResidentialProgramme1 = 'https://www.car.co.uk/images/scheme-residential-programme.jpg';
export const schemeResidentialProgramme2 = 'https://www.car.co.uk/images/scheme-residential-programme@2x.jpg';

export const schemeHMPAcademies1 = 'https://www.car.co.uk/images/scheme-hmp-academies.jpg';
export const schemeHMPAcademies2 = 'https://www.car.co.uk/images/scheme-hmp-academies@2x.jpg';

export const schemeFoodReDistribution1 = 'https://www.car.co.uk/images/scheme-food-re-distribution.jpg';
export const schemeFoodReDistribution2 = 'https://www.car.co.uk/images/scheme-food-re-distribution@2x.jpg';

export const hmpArticle11 = 'https://www.car.co.uk/images/hmp-article-1.jpg';
export const hmpArticle12 = 'https://www.car.co.uk/images/hmp-article-1@2x.jpg';

export const hmpArticle21 = 'https://www.car.co.uk/images/hmp-article-2.jpg';
export const hmpArticle22 = 'https://www.car.co.uk/images/hmp-article-2@2x.jpg';

export const hmpArticle31 = 'https://www.car.co.uk/images/hmp-article-3.jpg';
export const hmpArticle32 = 'https://www.car.co.uk/images/hmp-article-3@2x.jpg';

export const howItWorks11 = 'https://www.car.co.uk/images/bg-how-it-works-stage-1.jpg';
export const howItWorks12 = 'https://www.car.co.uk/images/bg-how-it-works-stage-1@2x.jpg';

export const howItWorks21 = 'https://www.car.co.uk/images/bg-how-it-works-stage-2.jpg';
export const howItWorks22 = 'https://www.car.co.uk/images/bg-how-it-works-stage-2@2x.jpg';

export const howItWorks31 = 'https://www.car.co.uk/images/bg-how-it-works-stage-3.jpg';
export const howItWorks32 = 'https://www.car.co.uk/images/bg-how-it-works-stage-3@2x.jpg';

export const howItWorks41 = 'https://www.car.co.uk/images/bg-how-it-works-stage-4.jpg';
export const howItWorks42 = 'https://www.car.co.uk/images/bg-how-it-works-stage-4@2x.jpg';

export const howItWorks51 = 'https://www.car.co.uk/images/bg-how-it-works-stage-5.jpg';
export const howItWorks52 = 'https://www.car.co.uk/images/bg-how-it-works-stage-5@2x.jpg';

export const howItWorks61 = 'https://www.car.co.uk/images/bg-how-it-works-stage-6.jpg';
export const howItWorks62 = 'https://www.car.co.uk/images/bg-how-it-works-stage-6@2x.jpg';

export const howItWorksStep1 = 'https://www.car.co.uk/images/how-it-works-step-1.png';
export const howItWorksStep12x = 'https://www.car.co.uk/images/how-it-works-step-1@2x.png';

export const howItWorksStep2 = 'https://www.car.co.uk/images/how-it-works-step-2.png';
export const howItWorksStep22x = 'https://www.car.co.uk/images/how-it-works-step-2@2x.png';

export const howItWorksStep3 = 'https://www.car.co.uk/images/how-it-works-step-3.png';
export const howItWorksStep32x = 'https://www.car.co.uk/images/how-it-works-step-3@2x.png';

export const howItWorksStep4 = 'https://www.car.co.uk/images/how-it-works-step-4.png';
export const howItWorksStep42x = 'https://www.car.co.uk/images/how-it-works-step-4@2x.png';

export const howItWorksStep5 = 'https://www.car.co.uk/images/how-it-works-step-5.png';
export const howItWorksStep52x = 'https://www.car.co.uk/images/how-it-works-step-5@2x.png';

export const visaPng = 'https://www.car.co.uk/images/visa2.png';
export const visaDebitPng = 'https://www.car.co.uk/images/visa-debit.png';
export const mastercard2Png = 'https://www.car.co.uk/images/mastercard2.png';
export const americanExpress2Png = 'https://www.car.co.uk/images/american-express2.png';

export const deliveryPackage = 'https://www.car.co.uk/images/icons/package-transport-for-delivery.png';
export const deliveryTruck = 'https://www.car.co.uk/images/icons/delivery-truck.png';
export const deliveryTruckCircular = 'https://www.car.co.uk/images/icons/delivery-truck-with-circular-clock.png';

export const hmpEllipse1 = 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/ellipse1.png';
export const hmpEllipse2 = 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/ellipse2.png';
export const hmpEllipse3 = 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/ellipse3.png';

export const collectionStep1 = 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/collection-step1.png';
export const collectionStep2 = 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/collection-step2.png';
export const collectionStep3 = 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/collection-step3.png';
export const collectionCarLogo = 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/collection-car-logo.png';
export const collectionStepLeftBg = 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/collection-step-bg-left.png';
export const collectionStepLeftBgSm =
  'https://stage-drupal.car.co.uk/s3fs-public/2023-10/collection-step-bg-left-small.png';
export const collectionStepRightBg = 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/collection-step-bg-right.png';
export const collectionStepRightBgSm =
  'https://stage-drupal.car.co.uk/s3fs-public/2023-10/collection-step-bg-right-small.png';
