import * as Yup from 'yup';
import {
  MOBILE_PHONE_NUMBER_VALIDATION,
  TEXT_VALIDATION,
  NAME_VALIDATION,
  EMAIL_LOCAL_PART_VALIDATION,
  ALPHANUMERIC_VALIDATION,
  REGISTRATION_NUMBER_VALIDATION,
} from './regexp';

// Common fields
export const email = Yup.string()
  .email('Incorrect email format')
  .matches(EMAIL_LOCAL_PART_VALIDATION, `Maximum length of the email's local part should be 64 characters`)
  .max(100, 'Maximum length of the email should be 100 characters')
  .required('Email should not be empty');

export const password = Yup.string()
  .trim()
  .min(6, 'Password field should be at least 6 characters')
  .max(100, 'Password field should not be more than 100 characters')
  .matches(/(^\S+$)/, 'Space character is not allowed')
  .required('Password field should not be empty');

export const contactNumber = isRequired =>
  isRequired
    ? Yup.string()
        .transform(value => {
          return value ? value.replace(/\s+/g, '') : value;
        })
        .max(14, 'The phone number should be maximum 14 characters long')
        .matches(MOBILE_PHONE_NUMBER_VALIDATION, 'The phone number entered is incorrect')
        .required('The phone number should not be empty')
    : Yup.string()
        .transform(value => (value ? value.replace(/\s+/g, '') || ' ' : value)) // all string is blank character should not pass
        .max(14, 'The phone number should be maximum 14 characters long')
        .matches(MOBILE_PHONE_NUMBER_VALIDATION, {
          excludeEmptyString: true,
          message: 'The phone number entered is incorrect',
        });

export const enquiry = Yup.string()
  .min(6, 'The enquiry should be minimum 6 characters long')
  .max(300, 'The enquiry should be maximum 300 characters long')
  .matches(TEXT_VALIDATION, 'The enquiry entered is incorrect')
  .required('The enquiry should not be empty');

export const commonNameField = fieldName =>
  Yup.string()
    .trim()
    .min(2, `The ${fieldName} should be at least 2 characters long`)
    .max(100, `The ${fieldName} should be maximum 100 characters long`)
    .matches(NAME_VALIDATION, 'Latin letters, space, dot, dash and single quote only')
    .required(`The ${fieldName} should not be empty`);

export const commonAlphaFieldMaxValue = (fieldName, maxValue) =>
  Yup.string()
    .trim()
    .min(2, `The ${fieldName} should be at least 2 characters long`)
    .max(maxValue, `The ${fieldName} should be maximum ${maxValue} characters long`)
    .matches(NAME_VALIDATION, 'Latin letters, space, dot, dash and single quote only')
    .required(`The ${fieldName} should not be empty`);

export const commonAlphanumericFieldMaxValue = (fieldName, maxValue) =>
  Yup.string()
    .trim()
    .min(2, `The ${fieldName} should be at least 2 characters long`)
    .matches(ALPHANUMERIC_VALIDATION, 'Latin letters, space, dot, dash and single quote only')
    .max(maxValue, `The ${fieldName} should be maximum ${maxValue} characters long`)
    .required(`The ${fieldName} should not be empty`);

// Schemas
export const signInSchema = Yup.object({
  email,
  password,
});

export const signUpSchema = Yup.object({
  firstName: commonAlphaFieldMaxValue('first name', 40),
  lastName: commonAlphaFieldMaxValue('last name', 40),
  email,
  password,
  terms: Yup.boolean().oneOf([true], 'Please, accept terms and conditions'),
});

export const contactUsSchema = Yup.object({
         firstName: commonAlphaFieldMaxValue('first name', 40),
         lastName: commonAlphaFieldMaxValue('last name', 40),
         registration: Yup.string().matches(
           REGISTRATION_NUMBER_VALIDATION,
           'The car registration number entered is incorrect',
         ),
         email,
         contactNumber: contactNumber(true),
         enquiry,
       });

export const forgotYourPasswordSchema = Yup.object({ email });

export const resetPasswordSchema = Yup.object({
  newPassword: Yup.string()
    .trim()
    .min(6, 'New password field must be at least 6 characters long')
    .max(100, 'New password field should not be more than 100 characters')
    .notOneOf([Yup.ref('oldPassword'), null], 'New password field should be different from the old one')
    .required('New password field should not be empty')
    .matches(/(^\S+$)/, 'Space character is not allowed'),
  confirmPassword: Yup.string()
    .trim()
    .min(6, 'Confirm new password field must be at least 6 characters long')
    .max(100, 'Confirm new password field should not be more than 100 characters')
    .required('Confirm new password field should not be empty')
    .oneOf([Yup.ref('newPassword'), null], `The passwords you entered don't match`)
    .matches(/(^\S+$)/, 'Space character is not allowed'),
});

export const editProfileSchema = Yup.object({
  firstName: commonAlphaFieldMaxValue('first name', 40),
  lastName: commonAlphaFieldMaxValue('last name', 40),
  email,
  phoneNumber: contactNumber(false),
  day: Yup.string(),
  month: Yup.string(),
  year: Yup.string(),
});

// Initial values
export const signInInitialValues = {
  email: '',
  password: '',
};

export const signUpInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  terms: false,
};

export const contactUsValues = {
  firstName: '',
  lastName: '',
  email: '',
  contactNumber: '',
  enquiry: '',
};

export const forgotYourPasswordValues = {
  email: '',
};

export const resetPasswordValues = {
  newPassword: '',
  confirmPassword: '',
};
