export default class ValuationQuoteClient {
  constructor(client) {
    this.client = client;
    this.resourceUrl = '/car-valuation';
  }

  getValuation = data => this.client.post(`${this.resourceUrl}/valuate`, data);

  updateValuationUser = data => this.client.post(`${this.resourceUrl}/update-user-id`, data);
}
