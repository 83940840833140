import { handleActions } from 'redux-actions';
import immutable, { extend } from 'immutability-helper';
import MotQuoteTypes from '../action-types/motQuoteProcess';
import { STATUS } from '../constants';

extend('$setIfUpdated', (value, original) => (value === undefined ? original : value));

export const MotQuoteState = {
  registrationNumber: '',
  status: '',
  BillingAccount: '',
  motRequest: '',
  motResponse: '',
  motTaxID: '',
};

export default {
  motQuoteProcess: handleActions(
    {
      [MotQuoteTypes.CREATE_MOT_QUOTE.REQUEST]: (state, { payload: { registrationNumber } }) =>
        immutable(state, {
          registrationNumber: { $setIfUpdated: registrationNumber },
          status: { $set: STATUS.RUNNING },
        }),
      [MotQuoteTypes.CREATE_MOT_QUOTE.SUCCESS]: (
        state,
        { payload: { BillingAccount, Request, Response, motTaxID, vehicleImage, registrationNumber } },
      ) =>
        immutable(state, {
          BillingAccount: { $set: BillingAccount },
          motRequest: { $set: Request },
          motResponse: { $set: Response },
          motTaxID: { $set: motTaxID },
          vehicleImage: { $set: vehicleImage },
          status: { $set: STATUS.SUCCESS },
          registrationNumber: { $set: registrationNumber },
        }),
      [MotQuoteTypes.CLEAR_MOT_QUOTE_PROCESS_DATA.actionName]: () => MotQuoteState,
    },
    MotQuoteState,
  ),
};
