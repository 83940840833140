import { call, put, takeLatest, select } from 'redux-saga/effects';
import { magento } from 'api-client/apiClientInstance/magento';
import { numberPlateClient } from 'api-client/apiClientInstance/numberPlate';
import { auth } from 'api-client/apiClientInstance/auth';
import { formatCurrency } from 'lib/formatters';
import { reportToBugsnag } from 'lib/bugsnag';
import { CHECK_OUT_STATUS, CHECK_OUT_URL } from 'containers/plateCheckout/constant';
import { setCookie, removeCookie, getCookie } from 'lib/cookie';
import {
  getCheckRegResultSelector,
  getPersonalDetailSelector,
  getCartId,
  getInclusiveTotal,
} from 'store/selectors/numberPlate';
import PlateHelper from 'containers/plateCheckout/helper';
import { segmentTrack, segmentIdentify } from 'lib/segment';
import { md5 } from 'lib/encrypt';
import moment from 'moment';
import NumberPlate from '../action-types/numberPlate';
import { showToast, userSignupSuccess } from '../actions';
import { SOME_ERROR_OCCURRED, SUCCESSFULLY_SIGNED_UP } from '../constants';
import {
  checkRegSuccess,
  checkRegError,
  submitPersonalDetailSuccess,
  submitPersonalDetailError,
  submitCustomTemplateSuccess,
  submitCustomTemplateError,
  submitTransferDeliverySuccess,
  submitTransferDeliveryError,
  fetchCartSuccess,
  fetchCartError,
  getCustomizeOptionSuccess,
  getCustomizeOptionError,
  createCartSuccess,
  createCartError,
  getTransferDeliverySuccess,
  getTransferDeliveryError,
  updateTransferAndGetDeliverySuccess,
  updateTransferAndGetDeliveryError,
  getDeliveryMethodSuccess,
  getDeliveryMethodError,
  confirmCartSuccess,
  confirmCartError,
  getStripeSetupIntentSuccess,
  getStripeSetupIntentError,
  getCustomerCardsSuccess,
  getCustomerCardsError,
  placeOrderSuccess,
  placeOrderError,
  goHome,
  dropCart,
  restartSuccess,
  restartError,
  checkUserLoginSuccess,
  checkUserAccountError,
  checkUserAccountSuccess,
  placeOrderRequest,
} from '../actions/numberPlate';

function* errorHandler(name, err, neverGoHome) {
  yield put(showToast({ message: err?.message || SOME_ERROR_OCCURRED, kind: 'error' }));
  reportToBugsnag(err, name);

  if (err.status === 401) {
    yield put(dropCart());
    if (!neverGoHome) {
      yield put(goHome());
    }
  } else if (err.status === 404) {
    yield put(dropCart());
    if (!neverGoHome) {
      yield put(goHome());
    }
  }
}

export function* checkRegRequest({ payload }) {
  try {
    const result = yield call(numberPlateClient.checkReg, payload);
    yield put(checkRegSuccess(result));
  } catch (err) {
    yield put(showToast({ message: err?.error?.messages?.common?.[0] || SOME_ERROR_OCCURRED, kind: 'error' }));
    reportToBugsnag(err, 'checkRegRequest');

    yield put(checkRegError(err));
  }
}

export function* restartCart({ payload: { cartId } }) {
  try {
    const result = yield call(magento.resetCart, { cartId });
    setCookie('plate-cart-status', CHECK_OUT_STATUS.PERSONAL_DETAIL);
    yield put(restartSuccess(result));
    PlateHelper.navigateRoute(null, CHECK_OUT_URL.PERSONAL_DETAIL);
  } catch (err) {
    yield put(showToast({ message: err?.message || SOME_ERROR_OCCURRED, kind: 'error' }));
    reportToBugsnag(err, 'restartCart');

    yield put(restartError(err));
  }
}

export function* submitPersonalDetail({ payload: { values, setSubmitting } }) {
  const formatPersonalData = (values, checkRegResult) => {
    const { isManualAddress, loquateAddress, address1, address2, town, county } = values;
    let region = '';
    const street = [];
    let city = '';
    const countryId = 'GB';
    if (isManualAddress) {
      region = county;
      street.push(address1);
      street.push(address2);
      city = town;
    } else {
      const addresses = loquateAddress?.address?.split(',');
      const len = addresses.length;
      if (len == 7) {
        region = addresses[6].trim() ? addresses[6] : addresses[5];
        if (addresses[0].trim()) street.push(addresses[0]);
        if (addresses[1].trim()) street.push(addresses[1]);
        city = addresses[5];
      }
    }

    const address = {
      region,
      country_id: countryId,
      street,
      prefix: values.title.value,
      postcode: values.PostCode,
      city,
      firstname: values.firstName,
      lastname: values.surname,
      email: values.email,
      telephone: values.mobile,
    };
    const address_information = { shipping_address: address, billing_address: address };

    const additional = {
      old_plate_number: values.checkReg,
      plate_check_result: checkRegResult,
      customer: {
        email: values.email,
        prefix: values.title.value,
        firstname: values.firstName,
        lastname: values.surname,
        mobile: values.mobile,
        nominee_name: values.nomineeName,
      },
    };

    return { current_step: values.status, address_information, additional };
  };

  try {
    if (setSubmitting) setSubmitting(true);
    const cartId = yield select(getCartId);
    const checkRegResult = yield select(getCheckRegResultSelector);
    const total = yield select(getInclusiveTotal);

    let accessToken = getCookie('access_token');
    if (!accessToken) {
      const data = yield call(numberPlateClient.getUserAccess, values.email);
      accessToken = getCookie('access_token');
      if (!accessToken) {
        setCookie('access_token', data?.token);
      }
    }

    if (cartId && typeof cartId === 'string' && cartId.length > 10) {
      const customerInfo = yield call(magento.getCustomerInfo);
      const result = yield call(magento.addCartToCustomer, {
        cartId,
        data: { customerId: customerInfo.id, storeId: customerInfo.store_id },
      });

      if (!result) {
        yield put(showToast({ message: 'Add cart to customer failed.', kind: 'error' }));
      }
    }

    const data = formatPersonalData(values, checkRegResult);
    yield call(magento.setPersonDetail, { cartId, data });

    setCookie('plate-cart-status', CHECK_OUT_STATUS.CUSTOMIZE_PLATE);
    yield put(submitPersonalDetailSuccess());

    if (setSubmitting) setSubmitting(false);

    // jump to next step page
    const nextNav = PlateHelper.getNavStatus(values.status);
    PlateHelper.navigateRoute(values.status, nextNav);

    // segment tracking
    segmentTrack('Checkout Step Viewed', { checkout_id: cartId, vertical: 'Number Plates', step: 1 });
    segmentTrack('Checkout Step Completed', { checkout_id: cartId, vertical: 'Number Plates', step: 1 });
    segmentTrack('Order Updated', { checkout_id: cartId, vertical: 'Number Plates', total });
    segmentIdentify(md5(values.email), {
      email: values.email,
      title: values.title.value,
      firstname: values.firstName,
      lastname: values.surname,
      mobile: values.mobile,
      first_name: values.firstName,
      last_name: values.surname,
      phone: values.mobile,
      createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
    });
  } catch (err) {
    yield errorHandler('submitPersonalDetail', err);
    yield put(submitPersonalDetailError(err));
  } finally {
    if (setSubmitting) setSubmitting(false);
  }
}

export function* submitCustomTemplate({
  payload: {
    values: { buyAcrylic, font, flag, border, size, status },
    setSubmitting,
  },
}) {
  try {
    if (setSubmitting) setSubmitting(true);

    const cartId = yield select(getCartId);
    const data = { status, cartId, buyArcylic: buyAcrylic == 'Yes' ? 1 : 0, customOptions: [font, flag, border, size] };
    const total = yield select(getInclusiveTotal);

    const result = yield call(magento.setCustomPlate, data);
    setCookie('plate-cart-status', CHECK_OUT_STATUS.TRANSFER_DELIVERY);
    yield put(submitCustomTemplateSuccess(result));

    if (setSubmitting) setSubmitting(false);
    // jump to next step page
    const nextNav = PlateHelper.getNavStatus(status);
    PlateHelper.navigateRoute(status, nextNav);

    // segment tracking
    segmentTrack('Checkout Step Viewed', { checkout_id: cartId, vertical: 'Number Plates', step: 2 });
    segmentTrack('Checkout Step Completed', { checkout_id: cartId, vertical: 'Number Plates', step: 2 });
    segmentTrack('Order Updated', { checkout_id: cartId, vertical: 'Number Plates', total });
  } catch (err) {
    yield errorHandler('submitCustomTemplate', err);
    yield put(submitCustomTemplateError(err));
  } finally {
    if (setSubmitting) setSubmitting(false);
  }
}

export function* submitTransferDelivery({
  payload: {
    values: { status, transfer, carrierCode, methodCode, gift },
    setSubmitting,
  },
}) {
  try {
    if (setSubmitting) setSubmitting(true);

    const transferService = transfer == '0' ? 0 : 1;
    const giftWrapping = gift == '50' ? 0 : 1;

    const cartId = yield select(getCartId);
    const data = { cartId, data: { currentStep: status, transferService, giftWrapping, carrierCode, methodCode } };

    const result = yield call(magento.setTransferDelivery, data);
    const total = yield select(getInclusiveTotal);

    setCookie('plate-cart-status', CHECK_OUT_STATUS.ORDER_SUMMARY);
    yield put(submitTransferDeliverySuccess(result));

    if (setSubmitting) setSubmitting(false);
    // jump to next step page
    const nextNav = PlateHelper.getNavStatus(status);
    PlateHelper.navigateRoute(status, nextNav);

    // segment tracking
    segmentTrack('Checkout Step Viewed', { checkout_id: cartId, vertical: 'Number Plates', step: 3 });
    segmentTrack('Checkout Step Completed', { checkout_id: cartId, vertical: 'Number Plates', step: 3 });
    segmentTrack('Order Updated', { checkout_id: cartId, vertical: 'Number Plates', total });
  } catch (err) {
    yield errorHandler('submitTransferDelivery', err);
    yield put(submitTransferDeliveryError(err));
  } finally {
    if (setSubmitting) setSubmitting(false);
  }
}

export function* confirmCart({
  payload: {
    values: { Password, status, redirect },
    setSubmitting,
  },
}) {
  try {
    if (setSubmitting) setSubmitting(true);

    const result = true;
    let token = '';

    const { title, firstName, surname, mobile, email, nomineeName } = yield select(getPersonalDetailSelector);

    const cartId = yield select(getCartId);
    const total = yield select(getInclusiveTotal);

    if (Password) {
      const newCustomerData = {
        customer: {
          prefix: title?.value || '',
          email,
          firstname: firstName,
          lastname: surname,
          extension_attributes: {
            mobile,
            nominee_name: nomineeName,
          },
          addresses: [],
        },
        password: Password,
      };

      const newUser = {
        firstName,
        lastName: surname,
        email,
        password: Password,
      };

      const { userJWT } = yield call(auth.customerSignUp, newUser);
      token = userJWT;
    }

    if (result) {
      yield call(magento.setCheckoutStatus, { status, cartId });
      setCookie('plate-cart-status', CHECK_OUT_STATUS.PAYMENT);
      yield put(confirmCartSuccess());

      if (Password) {
        yield put(userSignupSuccess({ token, redirect }));

        yield put(
          showToast({
            message: SUCCESSFULLY_SIGNED_UP,
            kind: 'success',
          }),
        );
      }
    } else {
      yield put(showToast({ message: 'Add cart to customer failed.', kind: 'error' }));
      yield put(confirmCartError(err));
    }

    if (setSubmitting) setSubmitting(false);
    // jump to next step page
    const nextNav = PlateHelper.getNavStatus(status);
    PlateHelper.navigateRoute(status, nextNav);

    // segment tracking
    segmentTrack('Checkout Step Viewed', { checkout_id: cartId, vertical: 'Number Plates', step: 4 });
    segmentTrack('Checkout Step Completed', { checkout_id: cartId, vertical: 'Number Plates', step: 4 });
    segmentTrack('Order Updated', { checkout_id: cartId, vertical: 'Number Plates', total });
  } catch (err) {
    yield errorHandler('confirmCart', err);
    yield put(confirmCartError(err));
  } finally {
    if (setSubmitting) setSubmitting(false);
  }
}

export function* placeOrder({
  payload: {
    card: { brand, last4, methodId },
    stripe,
  },
}) {
  if (!brand || !last4) {
    const result = yield call(magento.getCustomerCards);
    if (result && result.length > 0) {
      const card = result.find(x => x.id == methodId);
      if (card) {
        brand = card.brand;
        last4 = card.last4;
      }
    }
  }
  const cartId = yield select(getCartId);
  try {
    const data = {
      method: {
        method: 'stripe_payments',
        additional_data: {
          save_card: true,
          cc_stripejs_token: `${methodId}:${brand}:${last4}`,
          pid: methodId,
        },
      },
    };
    const result = yield call(magento.placeOrder, data);
    setCookie('plate-cart-status', CHECK_OUT_STATUS.CONFIRMATION);
    yield put(placeOrderSuccess(result));

    // segment tracking
    segmentTrack('Checkout Step Viewed', { checkout_id: cartId, vertical: 'Number Plates', step: 5 });
    segmentTrack('Checkout Step Completed', { checkout_id: cartId, vertical: 'Number Plates', step: 5 });
    segmentTrack('Payment Info Entered', {
      checkout_id: methodId,
      payment_method: brand,
      vertical: 'Number Plates',
    });
  } catch (err) {
    if (err.message) {
      const regex = /(Authentication Required)\:(.*)/;
      if (regex.test(err.message)) {
        const values = err.message.match(regex);
        const pi = values[2];
        const { error } = yield call(stripe.handleCardAction, pi);
        if (error) {
          yield errorHandler('placeOrder', err);
          yield put(placeOrderError(err));
        }

        yield put(placeOrderRequest({ card: { methodId }, stripe }));
      } else {
        yield errorHandler('placeOrder', err);
        yield put(placeOrderError(err));
      }
    } else {
      yield errorHandler('placeOrder', err);
      yield put(placeOrderError(err));
    }
  }
}

export function* fetchCart({ payload: { cartId, navStatus, checkLogin } }) {
  try {
    const maskedId = cartId || '';
    const result = yield call(magento.fetchCart, maskedId);
    result.navStatus = navStatus;

    const email = result.customer?.email;
    // check user exists for customize template: to show login
    if (checkLogin && email) {
      try {
        const { NeedAuth } = yield call(numberPlateClient.checkUserExists, email);
        result.NeedAuth = NeedAuth;
      } catch (err) {
        yield put(showToast({ message: err?.error?.messages?.common?.[0] || SOME_ERROR_OCCURRED, kind: 'error' }));
        reportToBugsnag(err, 'checkUserExists');

        yield put(goHome());
        return;
      }
    }
    result.minimumYear = getCookie('plate-cart-miniYear');
    yield put(fetchCartSuccess(result));
  } catch (err) {
    yield errorHandler('fetchCart', err);
    yield put(fetchCartError(err));
  }
}

export function* checkUserAccountHandler() {
  try {
    const { email } = yield call(magento.getCustomerInfo);
    const { isNew } = yield call(numberPlateClient.IsNewUser, email);
    const hasAccount = !isNew;

    yield put(checkUserAccountSuccess({ hasAccount }));
  } catch (err) {
    reportToBugsnag(err, 'checkUserAccountHandler');

    yield put(checkUserAccountError(err));
  }
}

export function* checkUserLoginHandler({ payload: { email } }) {
  try {
    if (email) {
      const { NeedAuth } = yield call(numberPlateClient.checkUserExists, email);
      yield put(checkUserLoginSuccess({ NeedAuth }));
    }
  } catch (err) {
    yield put(showToast({ message: err?.error?.messages?.common?.[0] || SOME_ERROR_OCCURRED, kind: 'error' }));
    reportToBugsnag(err, 'checkUserLoginHandler');

    yield put(goHome());
  }
}

export function* getCustomizeOption({ payload: { cartId } }) {
  const customizeOptionConverter = ({ buy_physical_plate, customise_options: data }) => {
    const mapOption = (data, sourceName, optionName) => {
      const fontItems = data.find(x => x.name == sourceName);
      if (fontItems && fontItems.options) {
        return fontItems.options.map(option => {
          const price = +option.price;
          return {
            id: option.id,
            src: option.icon_url,
            price,
            title: price == 0 ? 'Standard' : `£${formatCurrency(price)}`,
            name: optionName,
            description: option.description,
            code: option.code,
          };
        });
      }
      return [];
    };

    const result = { acrylicPrice: +buy_physical_plate, fonts: [], flags: [], borders: [], sizes: [] };
    if (data && data instanceof Array) {
      result.fonts = mapOption(data, 'Font', 'font');
      result.flags = mapOption(data, 'Flag', 'flag');
      result.borders = mapOption(data, 'Border', 'border');
      result.sizes = mapOption(data, 'Size', 'size');
    }
    return result;
  };

  try {
    const data = yield call(magento.getCustomizeOptions, cartId);
    const result = customizeOptionConverter(data);
    yield put(getCustomizeOptionSuccess(result));
  } catch (err) {
    yield errorHandler('getCustomizeOption', err);
    yield put(getCustomizeOptionError(err));
  }
}

export function* createCart({ payload: { registration, minimumYear } }) {
  try {
    const maskedId = yield call(magento.createCart, registration);
    setCookie('plate-cart-status', CHECK_OUT_STATUS.PERSONAL_DETAIL);
    setCookie('plate-cart-miniYear', minimumYear);
    yield put(createCartSuccess({ registration, maskedId }));
  } catch (err) {
    yield errorHandler('createCart', err, true);
    yield put(createCartError(err));
  }
}

export function* getTransferDelivery({ payload }) {
  try {
    const maskedId = payload;
    const result = yield call(magento.getTransferDelivery, maskedId);
    yield put(getTransferDeliverySuccess(result));
  } catch (err) {
    yield errorHandler('getTransferDelivery', err);
    yield put(getTransferDeliveryError(err));
  }
}

export function* updateTransferAndGetDelivery({ payload: { callback } }) {
  try {
    const cartId = yield select(getCartId);
    const data = { cartId };

    const result = yield call(magento.setTransferServiceSelf, data);
    yield put(updateTransferAndGetDeliverySuccess(result));
    if (callback) {
      callback(true);
    }
  } catch (err) {
    if (callback) {
      callback(false);
    }
    yield errorHandler('putTransfer', err);
    yield put(updateTransferAndGetDeliveryError(err));
  }
}

export function* getDeliveryMethods({ payload: { isTransfer } }) {
  try {
    const maskedId = yield select(getCartId);

    if (!isTransfer) {
      yield call(magento.setTransferService, maskedId);
    }
    const result = yield call(magento.getDeliveryMethods, maskedId);

    yield put(getDeliveryMethodSuccess(result));
  } catch (err) {
    yield errorHandler('getDeliveryMethods', err);
    yield put(getDeliveryMethodError(err));
  }
}

export function* getCustomerCards() {
  try {
    const result = yield call(magento.getCustomerCards);

    yield put(getCustomerCardsSuccess(result));
  } catch (err) {
    yield errorHandler('getCustomerCards', err);
    yield put(getCustomerCardsError(err));
  }
}

export function* getStripeSetupIntent() {
  try {
    const result = yield call(magento.getStripSetupIntent);

    yield put(getStripeSetupIntentSuccess(result));
  } catch (err) {
    yield errorHandler('getStripeSetupIntent', err);
    yield put(getStripeSetupIntentError(err));
  }
}

export function* setNavStatus({ payload }) {
  setCookie('plate-cart-status', payload);
}

export function* dropCartHandler() {
  removeCookie('plate-cart-status');
  removeCookie('plate-cart-miniYear');
}

export default [
  takeLatest(NumberPlate.RESTART.REQUEST, restartCart),
  takeLatest(NumberPlate.CHECK_REG.REQUEST, checkRegRequest),
  takeLatest(NumberPlate.SUBMIT_PERSONAL_DETAIL.REQUEST, submitPersonalDetail),
  takeLatest(NumberPlate.SUBMIT_CUSTOM_TEMPLATE.REQUEST, submitCustomTemplate),
  takeLatest(NumberPlate.SUBMIT_TRANSFER_DELIVERY.REQUEST, submitTransferDelivery),
  takeLatest(NumberPlate.CONFIRM_CART.REQUEST, confirmCart),
  takeLatest(NumberPlate.PLACE_ORDER.REQUEST, placeOrder),

  takeLatest(NumberPlate.FETCH_CART.REQUEST, fetchCart),
  takeLatest(NumberPlate.GET_CUSTOMIZE_OPTION.REQUEST, getCustomizeOption),
  takeLatest(NumberPlate.CREATE_CART.REQUEST, createCart),
  takeLatest(NumberPlate.GET_TRANSFER_DELIVERY.REQUEST, getTransferDelivery),
  takeLatest(NumberPlate.UPDATE_TRANSFER_AND_GET_DELIVERY.REQUEST, updateTransferAndGetDelivery),
  takeLatest(NumberPlate.GET_DELIVERY_METHOD.REQUEST, getDeliveryMethods),

  takeLatest(NumberPlate.GET_CUSTOMER_CARDS.REQUEST, getCustomerCards),
  takeLatest(NumberPlate.GET_STRIPE_SETUP_INTENT.REQUEST, getStripeSetupIntent),

  takeLatest(NumberPlate.SET_NAV_ROUTE, setNavStatus),
  takeLatest(NumberPlate.DROP_CART, dropCartHandler),

  takeLatest(NumberPlate.CHECK_USER_LOGIN.REQUEST, checkUserLoginHandler),
  takeLatest(NumberPlate.CHECK_USER_ACCOUNT.REQUEST, checkUserAccountHandler),
];
