import { takeLatest } from 'redux-saga/effects';
import { Router } from 'server/pages';
import ActionType from 'store/action-types/contactUs';
import UserTypes from 'store/action-types/user';

function* redirectEnquiry() {
  yield Router.pushRoute('/contact/thank-you');
}

function* redirectThankYouPage() {
  yield Router.pushRoute('/my-account/support-request/thank-you');
}

function* redirectContactUs() {
  yield Router.pushRoute('/my-account/support-request');
}

export default [
  takeLatest(ActionType.SET_CONTACT_US_ENQUIRY.SUCCESS, redirectEnquiry),
  takeLatest(UserTypes.CREATE_SUPPORT_TICKET.SUCCESS, redirectThankYouPage),
  takeLatest(ActionType.CONTACT_US.REQUEST, redirectContactUs),
];
