export const CHECK_OUT_STATUS = {
  CREATED: 'CREATED',
  PERSONAL_DETAIL: 'PERSONAL_DETAIL',
  CUSTOMIZE_PLATE: 'CUSTOMIZE_PLATE',
  TRANSFER_DELIVERY: 'TRANSFER_DELIVERY',
  ORDER_SUMMARY: 'ORDER_SUMMARY',
  PAYMENT: 'PAYMENT',
  CONFIRMATION: 'CONFIRMATION',
};

export const CHECK_OUT_URL = {
  PERSONAL_DETAIL: '/number-plates/personal-detail',
  CUSTOMIZE_PLATE: '/number-plates/customise-plate',
  TRANSFER_DELIVERY: '/number-plates/transfer-delivery',
  ORDER_SUMMARY: '/number-plates/order-summary',
  PAYMENT: '/number-plates/payment',
  CONFIRMATION: '/number-plates/confirmation',
  HOME: '/number-plates/search-results',
};

export const STEPS = ['Personal details', 'Customise your plate', 'Transfer & delivery', 'Order summary', 'Payment'];
