import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAuctionPlaceBidFormProps, getAuctionPlaceBidError } from 'store/selectors/auction';

import { placeBidRequest } from 'store/actions/auction';

import { modalClose } from 'store/actions/modal';

import { CarAuctionPlaceBidForm } from 'components/carAuctionTemplates/carAuctionPlaceBidForm';

const mapStateToProps = createStructuredSelector({
  formData: getAuctionPlaceBidFormProps(),
  error: getAuctionPlaceBidError(),
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(placeBidRequest(data)),
  onCancel: data => dispatch(modalClose(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarAuctionPlaceBidForm);
