import { createActions } from 'redux-actions';
import AuctionTypes from '../action-types/scrappedCars';

export const {
  deleteQuoteRequest,
  deleteQuoteSuccess,
  deleteQuoteError,

  cancelQuoteMyAccountRequest,
  cancelQuoteMyAccountSuccess,
  cancelQuoteMyAccountError,

  amendQuoteCollectionRequest,
  amendQuoteCollectionSuccess,
  amendQuoteCollectionError,

  getQuoteHistoryRequest,
  getQuoteHistorySuccess,
  getQuoteHistoryError,

  getActiveQuotesRequest,
  getActiveQuotesSuccess,
  getActiveQuotesError,

  setActionQuoteId,

  initializeQuoteHistory,

  setReQuoteVrm,
} = createActions({
  [AuctionTypes.DELETE_QUOTE.REQUEST]: payload => payload,
  [AuctionTypes.DELETE_QUOTE.SUCCESS]: payload => payload,
  [AuctionTypes.DELETE_QUOTE.ERROR]: payload => payload,

  [AuctionTypes.CANCEL_QUOTE_MY_ACCOUNT.REQUEST]: payload => payload,
  [AuctionTypes.CANCEL_QUOTE_MY_ACCOUNT.SUCCESS]: payload => payload,
  [AuctionTypes.CANCEL_QUOTE_MY_ACCOUNT.ERROR]: payload => payload,

  [AuctionTypes.AMEND_QUOTE_COLLECTION.REQUEST]: payload => payload,
  [AuctionTypes.AMEND_QUOTE_COLLECTION.SUCCESS]: payload => payload,
  [AuctionTypes.AMEND_QUOTE_COLLECTION.ERROR]: payload => payload,

  [AuctionTypes.GET_QUOTE_HISTORY.REQUEST]: payload => payload,
  [AuctionTypes.GET_QUOTE_HISTORY.SUCCESS]: payload => payload,
  [AuctionTypes.GET_QUOTE_HISTORY.ERROR]: payload => payload,

  [AuctionTypes.GET_ACTIVE_QUOTES.REQUEST]: payload => payload,
  [AuctionTypes.GET_ACTIVE_QUOTES.SUCCESS]: payload => payload,
  [AuctionTypes.GET_ACTIVE_QUOTES.ERROR]: payload => payload,

  [AuctionTypes.SET_ACTION_QUOTE_ID]: payload => payload,

  [AuctionTypes.INITIALIZE_QUOTE_HISTORY]: () => {},

  [AuctionTypes.SET_RE_QUOTE_VRM]: payload => payload,
});
