import numberPlate from 'containers/numberPlate/store/saga/numberPlates';
import auctions from './auctions';
import auction from './auction';
import contactUs from './contactUs';
import browseByMake from './browseByMake';
import longAuctions from './longAuctions';
import liveAuction from './liveAuction';
import awaitingCollection from './awaitingCollection';
import pendingPayments from './pendingPayments';
import quotesArchive from './quotesArchive';
import carModelsAuction from './carModels';
import user from './user';
import toasts from './toasts';
import quote from './quote';
import scrappedCars from './scrappedCars';
import media from './media';
import insurance from './insurance';
import cms from './cms';
import faq from './faq';
import scrapMyCar from './scrapMyCar';
import financeQuoteProcess from './financeQuoteProcess';
import finance from './finance';
import warranties from './warranties';
import valuations from './valuations';
import vehicleInspections from './vehicleInspections';
import valuationQuoteProcess from './valuationQuoteProcess';
import warrantiesAgQuoteProcess from './warrantiesAgQuoteProcess';
import homePage from './homePage';
import homePageV2 from './homePageV2';
import mots from './mots';
import motQuoteProcess from './motQuoteProcess';
import carTax from './carTax';
import carTaxProcess from './carTaxProcess';
import myCar from './myCar';
import numberPlateCart from './numberPlate';
import warrantiesQuotes from './warrantiesQuotes';
import common from './common';

export default [
  ...auctions,
  ...contactUs,
  ...auction,
  ...longAuctions,
  ...liveAuction,
  ...awaitingCollection,
  ...pendingPayments,
  ...quotesArchive,
  ...browseByMake,
  ...carModelsAuction,
  ...user,
  ...toasts,
  ...quote,
  ...scrappedCars,
  ...media,
  ...insurance,
  ...cms,
  ...faq,
  ...scrapMyCar,
  ...financeQuoteProcess,
  ...finance,
  ...warranties,
  ...valuations,
  ...vehicleInspections,
  ...valuationQuoteProcess,
  ...warrantiesAgQuoteProcess,
  ...homePage,
  ...homePageV2,
  ...mots,
  ...motQuoteProcess,
  ...carTax,
  ...carTaxProcess,
  ...myCar,
  ...numberPlate,
  ...numberPlateCart,
  ...warrantiesQuotes,
  ...common,
];
