import { put, takeLatest, select, call } from 'redux-saga/effects';
import { reportToBugsnag } from 'lib/bugsnag';
import { quotes as quotesClient } from 'api-client/apiClientInstance/quotes';
import { isUserAuthenticatedSelector } from 'store/selectors/userAuth';
import { segmentTrack } from 'lib/segment';
import { QUOTE_CURRENCY, QUOTE_FUNNEL_NAME, QUOTE_FUNNEL_STEP_NUMBER } from 'modules/ScrapMyCar/QuoteProcess/constants';
import { formatPhoneNumber, postToOutcode } from 'lib/formatters';
import ScrappedCarsTypes from '../action-types/scrappedCars';
import { getRootModalState } from '../selectors/modal';
import { SOME_ERROR_OCCURRED } from '../constants';
import {
  getActionQuoteId,
  getQuoteHistoryLimit,
  getQuoteHistoryOffset,
  getQuoteHistoryItems,
} from '../selectors/scrappedCars';
import { modalClose } from '../actions/modal';
import {
  deleteQuoteError,
  deleteQuoteSuccess,
  getQuoteHistoryError,
  getQuoteHistorySuccess,
  cancelQuoteMyAccountSuccess,
  cancelQuoteMyAccountError,
  amendQuoteCollectionError,
  amendQuoteCollectionSuccess,
  getActiveQuotesSuccess,
} from '../actions/scrappedCars';
import { showToast } from '../actions/toasts';
import {
  getUserEmailSelector,
} from '../selectors/user';
import { BOOKING_JOB_STATUS } from 'modules/ScrapMyCar/QuoteProcess/constants';

export function* deleteQuote() {
  const { modalType } = yield select(getRootModalState());
  const deletedQuoteId = yield select((...args) => getActionQuoteId(...args));

  try {
    yield call(quotesClient.deleteQuote, deletedQuoteId);
    const quotes = yield select((...args) => getQuoteHistoryItems(...args));
    const quoteIndex = quotes.findIndex(quote => quote.id === deletedQuoteId);
    yield put(deleteQuoteSuccess({ quoteIndex }));
    yield put(showToast({ message: 'Quote has been successfully deleted.', kind: 'success' }));
    if (modalType === 'deleteQuotePopup') {
      yield put(modalClose());
    }
  } catch (err) {
    reportToBugsnag(err, 'deleteQuote');

    if (err.status !== 401) {
      yield put(showToast({ message: err?.error?.messages?.common?.[0] || SOME_ERROR_OCCURRED, kind: 'error' }));
      if (modalType === 'deleteQuotePopup') {
        yield put(modalClose());
      }
    }
    yield put(deleteQuoteError(err));
  }
}

export function* cancelQuote(data) {
  const { modalType } = yield select(getRootModalState());
  try {
    const quoteId = yield select(getActionQuoteId);
    const quotes = yield select((...args) => getQuoteHistoryItems(...args));
    const quoteIndex = quotes.findIndex(quote => quote.id === quoteId);
    yield call(quotesClient.cancelQuote, { quoteId, reason: data.payload });
    const quote = quotes[quoteIndex];
    const email = yield select(getUserEmailSelector);
    const phoneNumber = quote?.phone;
    segmentTrack('Scrap_Car_Cancel_Quote', {
      car_colour: quote.colour,
      carMake: quote.manufacturerName, // TODO: this field has the same value with car_make, may be removed in a future version
      car_make: quote.manufacturerName,
      carModel: quote.modelName, // TODO: this field has the same value with car_model, may be removed in a future version
      car_model: quote.modelName,
      price: quote.cost, // TODO: this field has the same value with car_price, may be removed in a future version
      car_price: quote.cost,
      vehicleWeight: quote.weight, // TODO: this field has the same value with car_weight, may be removed in a future version
      car_weight: quote.weight,
      car_year: quote.vehicleYear,
      currency: QUOTE_CURRENCY.GBP,
      postcode: quote.postcode,
      outcode: postToOutcode(quote.postcode),
      quote_id: quote.id,
      // need to pass new job status value after call cancel quote
      quote_status: BOOKING_JOB_STATUS.STATUS_CANCELLED, // TODO: this field need to be confirmed, should we use the value "accept" for scrap_car_cancel_quote?
      token: quote.token, // TODO: this field has the same value with quote_token, may be removed in a future version
      quote_token: quote.token,
      registrationNumber: quote.registrationNumber, // TODO: this field has the same value with registration_number, may be removed in a future version
      registration_number: quote.registrationNumber,
      short_url: `https://www.car.co.uk/q/s/${quote.token}`,
      funnel_name: QUOTE_FUNNEL_NAME,
      funnel_step: QUOTE_FUNNEL_STEP_NUMBER.CANCEL_QUOTE,
      partner_id: quote.entityId,
      is_manual: Boolean(quote.isManual),
      email,
      to_user: email,
      mobile: formatPhoneNumber(phoneNumber)
    });
    yield put(cancelQuoteMyAccountSuccess({ quoteIndex }));
    yield put(showToast({ message: 'Quote has been successfully deleted.', kind: 'success' }));
    if (modalType === 'cancelQuotePopup') {
      yield put(modalClose());
    }
  } catch (err) {
    reportToBugsnag(err, 'cancelQuote');

    if (err.status !== 401) {
      yield put(showToast({ message: err?.error?.messages?.common?.[0] || SOME_ERROR_OCCURRED, kind: 'error' }));

      if (modalType === 'cancelQuotePopup') {
        yield put(modalClose());
      }
    }

    yield put(cancelQuoteMyAccountError(err));
  }
}

export function* amentQuoteCollection(data) {
  const { modalType } = yield select(getRootModalState());
  try {
    const quoteId = yield select(getActionQuoteId);
    const { date, startTime, endTime } = data.payload;
    const startDate = new Date(`${date?.replace(/-/g, '/')} ${startTime}`);
    const slot = startDate.getHours() < 12 ? 'AM Slot' : 'PM Slot';
    const { updatedQuote } = yield call(quotesClient.amendQuoteCollection, { quoteId, ...data.payload, slot });

    const quotes = yield select((...args) => getQuoteHistoryItems(...args));
    const quoteIndex = quotes.findIndex(quote => quote.id === quoteId);
    const quote = {
      ...updatedQuote[0],
      ...{
        bookingDate: date,
        bookingStartTime: `${date} ${startTime}:00`,
        bookingEndTime: `${date} ${endTime}:00`,
      },
    };

    yield put(amendQuoteCollectionSuccess({ quoteIndex, quote }));
    yield put(showToast({ message: 'Quote has been rescheduled.', kind: 'success' }));
    if (modalType === 'amendCollectionPopup') {
      yield put(modalClose());
    }
  } catch (err) {
    reportToBugsnag(err, 'amentQuoteCollection');

    if (err.status !== 401) {
      yield put(
        showToast({ message: err?.message || err?.error?.messages?.common?.[0] || SOME_ERROR_OCCURRED, kind: 'error' })
      );

      if (modalType === 'amendCollectionPopup') {
        yield put(modalClose());
      }
    }

    yield put(amendQuoteCollectionError(err));
  }
}

export function* getQuoteHistory({ payload }) {
  const limit = yield select((...args) => getQuoteHistoryLimit(...args));
  const offset = yield select((...args) => getQuoteHistoryOffset(...args));
  const quotes = yield select((...args) => getQuoteHistoryItems(...args));

  try {
    const { items, total } = yield call(quotesClient.getHistory, { limit, offset, req: payload?.req });

    let hideQty = 0;

    const allItems = quotes.concat(items);

    const showItem = items.filter(item => {
      if (!item.isExpired) {
        return item;
      }

      const newItemIndex = allItems.findIndex(x => x.registrationNumber === item.registrationNumber && !x.isExpired);
      if (newItemIndex > -1) {
        hideQty++;
      } else {
        return item;
      }
    });

    yield put(getQuoteHistorySuccess({ items: showItem, total, hideQty }));
  } catch (err) {
    reportToBugsnag(err, 'getQuoteHistory');

    yield put(getQuoteHistoryError(err));
  }
}

export function* getActiveQuotes() {
  try {
    const isAuthenticated = yield select(isUserAuthenticatedSelector);
    if (isAuthenticated) {
      const data = yield call(quotesClient.getActiveQuotes);
      yield put(getActiveQuotesSuccess(data));
    }
  } catch (err) {
    reportToBugsnag(err, 'getActiveQuotes');
  }
}

export default [
  takeLatest(ScrappedCarsTypes.DELETE_QUOTE.REQUEST, deleteQuote),
  takeLatest(ScrappedCarsTypes.CANCEL_QUOTE_MY_ACCOUNT.REQUEST, cancelQuote),
  takeLatest(ScrappedCarsTypes.AMEND_QUOTE_COLLECTION.REQUEST, amentQuoteCollection),
  takeLatest(ScrappedCarsTypes.GET_QUOTE_HISTORY.REQUEST, getQuoteHistory),
  takeLatest(ScrappedCarsTypes.GET_ACTIVE_QUOTES.REQUEST, getActiveQuotes),
];
