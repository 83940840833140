import { createAsyncActionSet } from '../lib/action-helper';

export default {
  GET_FAQ: createAsyncActionSet('GET_FAQ'),
  SEARCH_FAQ: createAsyncActionSet('SEARCH_FAQ'),
  FAQ_LANDING: createAsyncActionSet('FAQ_LANDING'),
  GET_FAQ_BY_PATH: createAsyncActionSet('GET_FAQ_BY_PATH'),
  GET_FAQ_CATEGORIES: createAsyncActionSet('GET_FAQ_CATEGORIES'),
  SET_SEARCH_PHRASE: 'SET_SEARCH_PHRASE',
};
