import { createAsyncActionSet } from '../lib/action-helper';

export default {
  GET_AWAITING_COLLECTION_PAGE: createAsyncActionSet('GET_AWAITING_COLLECTION_PAGE'),
  GET_AWAITING_COLLECTION_CAR_LIST: createAsyncActionSet('GET_AWAITING_COLLECTION_CAR_LIST'),
  SET_AWAITING_COLLECTION_CAR_LIST_PAGING: 'SET_AWAITING_COLLECTION_CAR_LIST_PAGING',
  RESET_AWAITING_COLLECTION_CAR_LIST_PAGING: 'RESET_AWAITING_COLLECTION_CAR_LIST_PAGING',
  DELETE_AWAITING_COLLECTION_CAR_LIST: 'DELETE_AWAITING_COLLECTION_CAR_LIST',
  RESET_AWAITING_COLLECTION_CAR_LIST: 'RESET_AWAITING_COLLECTION_CAR_LIST',
};
