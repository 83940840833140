/* eslint-disable camelcase */
import { combineActions, handleActions } from 'redux-actions';
import * as warrantiesQuoteProcessActions from '../actions/warrantiesAgQuoteProcess';

// vehicle info
const vehicleInitState = {
  registration: '',
  model: '',
  make: '',
  private_average: '',
  odometer_in_miles: 0,
  fuel_type: '',
  engine_cc: '',
  mileage: '',
  purchasePrice: '',
  purchaseDate: '',
};

// customer info
const customerInitState = {
  hasAccount: false,
  email: '',
  title: '',
  firstName: '',
  lastName: '',
  mobile: '',
  address1: '',
  address2: '',
  city: '',
  county: '',
  Postcode: '',
};

// product info

const warrantiesAgQuoteProcessInitState = {
  id: '',
  vehicle: vehicleInitState,
  customer: customerInitState,
  product: {
    startDate: '',
    availableProducts: [],
    productId: '',
    documentLink: '',
  },
  clientSecret: '',
};

const warrantiesAgQuoteProcess = handleActions(
  {
    [warrantiesQuoteProcessActions.createAgWarrantiesQuoteRequest]: (state, { payload }) => ({
      ...state,
      vehicle: {
        ...state.vehicle,
        registration: payload.data.registration,
        mileage: payload.data.mileage,
      },
    }),
    [warrantiesQuoteProcessActions.updateQuoteInfoSuccess]: (state, { payload }) => {
      const { hasAccount } = payload;
      return {
        ...state,
        customer: {
          ...state.customer,
          hasAccount,
        },
      };
    },
    [warrantiesQuoteProcessActions.clearQuoteInfo]: () => ({
      id: '',
      token: '',
      vehicle: vehicleInitState,
      customer: customerInitState,
      product: {
        startDate: '',
        availableProducts: [],
        productId: '',
      },
      clientSecret: '',
    }),
    [warrantiesQuoteProcessActions.selectProductSuccess]: (state, { payload }) => ({
      ...state,
      product: {
        ...state.product,
        documentLink: payload?.policy_documentation_link || '',
      },
    }),
    [combineActions(
      warrantiesQuoteProcessActions.createAgWarrantiesQuoteSuccess,
      warrantiesQuoteProcessActions.storeQuoteInfo
    )]: (state, { payload }) => {
      const {
        id = state.id,
        token = state.token,

        registration = state.registration,
        mileage = state.mileage,
        vehicleType = state.vehicleType,
        engineCc = state.engineCc,
        make = state.make,
        model = state.model,
        fuelType = state.fuelType,
        transmission = state.transmission,
        marketValue = state.marketValue,
        registrationDate = state.registrationDate,
        vehicleImageCdnKey = state.vehicleImageCdnKey,

        email = undefined,
        title = undefined,
        firstName = undefined,
        lastName = undefined,
        mobile = undefined,
        address1 = undefined,
        address2 = undefined,
        city = undefined,
        county = undefined,
        PostCode = undefined,
        address = undefined,
        purchasePrice = undefined,
        purchaseDate = undefined,
        startDate = undefined,
        gdprPhone = undefined,
        gdprEmail = undefined,

        productType = undefined,
        productId = undefined,
        claim_limit = undefined,
        period_of_cover_months = undefined,
        price_retail = undefined,
        excess = undefined,
        policy_documentation_link: documentLink = state.documentLink,
      } = payload;

      const customer = {
        email,
        title,
        firstName,
        lastName,
        mobile,
        address1,
        address2,
        city,
        county,
        PostCode,
        address,
        gdprPhone,
        gdprEmail,
      };
      const vehicle = {
        purchasePrice,
        purchaseDate,
        registration,
        mileage,
        vehicleType,
        engineCc,
        make,
        model,
        fuelType,
        transmission,
        marketValue,
        registrationDate,
        vehicleImageCdnKey,
      };
      const product = {
        documentLink,
        startDate,
        productType,
        productId,
        price_retail,
        excess,
        claim_limit,
        period_of_cover_months,
      };

      const list = [customer, product, vehicle];
      list.forEach(obj => {
        const objKeys = Object.keys(obj);
        objKeys.forEach(key => {
          if (obj[key] === undefined) {
            // eslint-disable-next-line no-param-reassign
            delete obj[key];
          }
        });
      });

      return {
        ...state,
        id,
        token,
        customer: {
          ...state.customer,
          ...customer,
        },
        vehicle: {
          ...state.vehicle,
          ...vehicle,
        },
        product: {
          ...state.product,
          ...product,
        },
      };
    },
    [warrantiesQuoteProcessActions.getAvailableProductsSuccess]: (state, { payload }) => ({
      ...state,
      product: {
        ...state.product,
        availableProducts: payload,
      },
    }),
    [warrantiesQuoteProcessActions.getClientSecretSuccess]: (state, { payload }) => ({
      ...state,
      clientSecret: payload.client_secret,
    }),
    [warrantiesQuoteProcessActions.clearClientSecret]: state => ({
      ...state,
      clientSecret: '',
    }),
  },
  warrantiesAgQuoteProcessInitState
);

export default { warrantiesAgQuoteProcess };
