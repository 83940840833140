import immutable, { extend } from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import { immutablySetOrMergeDeeply } from '../lib/mergeToStateHelper';
import * as financeActions from '../actions/finance';
import { STATUS } from '../constants';

extend('$setIfUpdated', (value, original) => (value === undefined ? original : value));

const initialState = {
  financeIsLoading: false,
  financeError: null,
  financePage: {},
  settings: {},
  byPath: {},
  calculatorData: {
    price: [60],
    years: [3.5],
  },
};

export default {
  finance: handleActions(
    {
      [combineActions(
        financeActions.getFinanceByTypeRequest,
        financeActions.getFinanceByPathRequest,
        financeActions.getFinanceSettingsRequest,
        financeActions.getFinanceManufacturersRequest,
      )]: state =>
        immutable(state, {
          financeIsLoading: { $set: true },
          financeError: { $set: null },
        }),

      [combineActions(
        financeActions.getFinanceByTypeSuccess,
        financeActions.getFinanceByPathSuccess,
        financeActions.getFinanceSettingsSuccess,
        financeActions.getFinanceManufacturersSuccess,
      )]: (state, { payload }) => {
        const update = immutablySetOrMergeDeeply(state, payload, {
          financeIsLoading: { $set: false },
          financeError: { $set: null },
        });
        return immutable(state, update);
      },

      [combineActions(
        financeActions.getFinanceByTypeError,
        financeActions.getFinanceByPathError,
        financeActions.getFinanceSettingsError,
        financeActions.getFinanceManufacturersError,
      )]: (state, { payload }) =>
        immutable(state, {
          financeIsLoading: { $set: false },
          financeError: { $set: payload },
        }),

      [financeActions.setCalculatorData]: (state, { payload }) =>
        immutable(state, {
          calculatorData: { $set: payload },
        }),

      [combineActions(
        financeActions.getContinueWithFinanceQuoteShortLinkRequest,
        financeActions.getContinueWithFinanceQuoteShortLinkSuccess,
      )]: (state, { payload: { token, status } }) =>
        immutable(state, {
          restarted: { $set: true },
          message: { $set: '' },
          status: { $set: '' },
          quoteStatus: { $setIfUpdated: status },
          quoteToken: { $setIfUpdated: token },
        }),

      [combineActions(financeActions.getContinueWithFinanceQuoteShortLinkError)]: (
        state,
        { payload: { quoteStatus } },
      ) =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
          quoteStatus: { $setIfUpdated: quoteStatus },
        }),
    },
    initialState,
  ),
};
