import { call, put, select, takeLatest } from 'redux-saga/effects';
import { redirect } from 'lib/routes';
import { userLogoutRequest } from 'store/actions/user';
import { md5 } from 'lib/encrypt';
import moment from 'moment';
import { reportToBugsnag } from 'lib/bugsnag';
import { getRegistrationNumber } from '../selectors/carTaxProcess';
import CarTaxTypes from '../action-types/carTaxProcess';
import { createCarTaxSuccess } from '../actions/carTaxProcess';
import { showToast } from '../actions/toasts';
import { carTax } from 'api-client/apiClientInstance/carTax';
import mobileNumberFormat from '../lib/mobilePhoneHandler';
import { segmentTrack, segmentIdentify } from 'lib/segment';
import { getErrorMessage } from 'lib/message';

import {
  getUserEmailSelector,
  getUserFirstNameSelector,
  getUserLastNameSelector,
  getUserPhoneNumberSelector,
  getGdprConsent,
} from '../selectors/user';

export function* createCarTaxQuote({
  payload: {
    actions: { setSubmitting },
  },
}) {
  try {
    setSubmitting(true);
    const registrationNumber = yield select(getRegistrationNumber);
    const firstName = yield select(getUserFirstNameSelector);
    const lastName = yield select(getUserLastNameSelector);
    const email = yield select(getUserEmailSelector);
    const phoneNumber = yield select(getUserPhoneNumberSelector);
    const gdprConsent = yield select(getGdprConsent);
    if (email) {
      segmentIdentify(md5(email), {
        email: email,
        firstname: firstName,
        lastname: lastName,
        first_name: firstName,
        last_name: lastName,
        GDPRConsent: gdprConsent,
        mobile: mobileNumberFormat(phoneNumber),
        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
      });
    }

    const taxResponse = yield call(carTax.getTax, {
      carRegistration: registrationNumber,
    });
    const {
      vedCurrentlyValid,
      vedExpiryDate,
      vedDaysRemaining,
      motSornInPlace,
      vedCo2band,
      vedCo2Emissions,
      vedBand,
      twelveMonth,
      vehicleRegistration,
      vehicleImage,
      response,
    } = taxResponse;

    if (response && response.StatusCode === 'Success') {
      segmentTrack('Car_Tax_Complete_Quote', {
        ...response.DataItems,
      });
    }

    yield put(
      createCarTaxSuccess({
        vedCurrentlyValid,
        vedExpiryDate,
        vedDaysRemaining,
        motSornInPlace,
        vedCo2band,
        vedCo2Emissions,
        vedBand,
        twelveMonth,
        vehicleRegistration,
        vehicleImage,
        response,
      }),
    );

    yield redirect({}, '/check-car-tax/car-tax-details');

    setSubmitting(false);
  } catch (err) {
    reportToBugsnag(err, 'createCarTaxQuote');
    if (err.status == 401) {
      yield redirect({}, '/check-car-tax/register');
    }
    const errMsg = getErrorMessage(err);
    if (err?.exception === 'ErrorException') {
      yield put(showToast({ message: 'Invalid Registration Number', kind: 'error' }));
    } else if (errMsg.search('Token Expired') != -1) {
      yield put(showToast({ message: 'Login Expired', kind: 'error' }));
      yield put(userLogoutRequest({}, {}, '/check-car-tax/register'));
    } else if (errMsg.search('daily limit') != -1 || errMsg.search('No data results') != -1) {
      yield put(showToast({ message: err.error.messages.common[0], kind: 'error' }));
      yield redirect({}, '/check-car-tax');
    } else if (err?.exception === 'Exception') {
      yield put(showToast({ message: err?.message, kind: 'error' }));
      yield redirect({}, '/check-car-tax');
    } else {
      yield put(
        createCarTaxSuccess({
          vedCurrentlyValid: null,
          vedExpiryDate: null,
          vedDaysRemaining: null,
          motSornInPlace: null,
          vedCo2band: null,
          vedCo2Emissions: null,
          vedBand: null,
          twelveMonth: null,
          vehicleRegistration: null,
          vehicleImage: null,
          response: null,
        }),
      );
    }
    setSubmitting(false);
  }
}

export default [takeLatest([CarTaxTypes.CREATE_CAR_TAX.REQUEST], createCarTaxQuote)];
