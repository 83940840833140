import immutable from 'immutability-helper';
import { handleActions } from 'redux-actions';
import WarrantiesQuotesTypes from '../action-types/warrantiesQuotes';

const initialState = {
  total: 0,
  limit: 15,
  items: [],
  loading: false,
};

export default {
  warrantiesQuotes: handleActions(
    {
      [WarrantiesQuotesTypes.GET_WARRANTIES_QUOTES_HISTORY.REQUEST]: state =>
        immutable(state, {
          loading: { $set: true },
        }),
      [WarrantiesQuotesTypes.GET_WARRANTIES_QUOTES_HISTORY.SUCCESS]: (
        state,
        { payload: { total, resetList = false, data: items } }
      ) =>
        immutable(state, {
          total: { $set: total },
          items: { $set: resetList ? items : state.items.concat(items) },
          loading: { $set: false },
        }),
      [WarrantiesQuotesTypes.DELETE_WARRANTIES_QUOTE.SUCCESS]: (state, { payload: { quoteId } }) =>
        immutable(state, {
          items: { $set: state.items.filter(item => item.id != quoteId) },
          total: { $set: state.total - 1 },
        }),
      [WarrantiesQuotesTypes.GET_WARRANTIES_QUOTES_HISTORY.ERROR]: state =>
        immutable(state, {
          loading: { $set: false },
        }),
    },
    initialState
  ),
};
