import { createAsyncActionSet } from '../lib/action-helper';

export default {
  GET_SCRAP_MY_CAR_SETTINGS: createAsyncActionSet('GET_SCRAP_MY_CAR_SETTINGS'),
  GET_SCRAP_MY_CAR_BY_TYPE: createAsyncActionSet('GET_SCRAP_MY_CAR_BY_TYPE'),
  GET_SCRAP_MY_CAR_MANUFACTURERS: createAsyncActionSet('GET_SCRAP_MY_CAR_MANUFACTURERS'),
  GET_SCRAP_MY_CAR_BY_PATH: createAsyncActionSet('GET_SCRAP_MY_CAR_BY_PATH'),
  GET_SCRAP_MY_CAR_MEDIAS: createAsyncActionSet('GET_SCRAP_MY_CAR_MEDIAS'),
  GET_NEAREST_LOCATIONS: createAsyncActionSet('GET_NEAREST_LOCATIONS'),
};
