import { put, take, takeLatest, call } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { reportToBugsnag} from 'lib/bugsnag';
import InsuranceTypes from '../action-types/insurance';
import { snakeToCamelCase } from '../lib/snakeToCamelCase';
import CmsTypes from '../action-types/cms';
import { wrapWith, extractStoreIds } from '../lib/mergeToStateHelper';
import { SOME_ERROR_OCCURRED } from '../constants';

export function* getCMSData({ payload }) {
  const STORE_AS = 'insurancePage';
  const chan = yield call(channel);
  try {
    // const insuranceChannel = yield actionChannel(CmsTypes.FETCH_CMS.SUCCESS);
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { section: 'motor-insurance', ...payload },
      chan,
    });
    const {
      payload: { data, links },
      path,
    } = yield take(chan);
    yield put({
      type: InsuranceTypes.GET_INSURANCE_PAGES.SUCCESS,
      payload: wrapWith(STORE_AS, extractStoreIds(data, path, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getCMSData');


    yield put({
      type: InsuranceTypes.GET_INSURANCE_PAGES.ERROR,
      payload: err,
    });
  }
}

export function* getManufacturers() {
  const STORE_AS = 'allManufacturers';
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.FETCH_TAXONOMY_MANUFACTURERS.REQUEST,
      chan,
    });
    const {
      payload: { data, links, type },
      path,
    } = yield take(chan);
    yield put({
      type: InsuranceTypes.GET_INSURANCE_MANUFACTURERS.SUCCESS,
      payload: wrapWith(STORE_AS, wrapWith(type, extractStoreIds(data, path, links))),
    });
  } catch (err) {
    reportToBugsnag(err, 'getManufacturers');


    yield put({
      type: InsuranceTypes.GET_INSURANCE_MANUFACTURERS.ERROR,
      payload: err,
    });
  }
}

export function* getSettings() {
  const fingerprint = 'insuranceSettings';
  const chan = yield call(channel);
  try {
    const type = 'settings';
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { section: 'motor-insurance', type, includes: ['banner.image.file', 'pages.paragraphs'] },
      fingerprint,
      chan,
    });
    const fullData = yield take(chan);
    // ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp,
    // );
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: InsuranceTypes.GET_INSURANCE_SETTINGS.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getSettings');


    yield put({
      type: InsuranceTypes.GET_INSURANCE_SETTINGS.ERROR,
      payload: err,
    });
  }
}

export function* getIndividualByPath({ payload: { specificIncludes, ...payload } }) {
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.RESOLVE_CMS_ENTITY.REQUEST,
      payload,
      chan,
    });
    const {
      payload: {
        data,
        path: resourcePath,
        jsonapi: { resourceName },
      },
      path,
    } = yield take(chan);
    let outData = data;
    if (resourceName !== 'insurance_page') {
      yield put({
        type: CmsTypes.FETCH_CMS_INDIVIDUAL.REQUEST,
        payload: { path: resourcePath, includes: specificIncludes[snakeToCamelCase(resourceName)] },
        path,
      });
      const {
        payload: { data: includedData },
      } = yield take(CmsTypes.FETCH_CMS_INDIVIDUAL.SUCCESS);
      outData = includedData;
    }
    yield put({
      type: InsuranceTypes.GET_INSURANCE_BY_PATH.SUCCESS,
      payload: extractStoreIds(outData, path),
    });
  } catch (err) {
    reportToBugsnag(err, 'getIndividualByPath');

    // eslint-disable-next-line no-console
    yield put({
      type: InsuranceTypes.GET_INSURANCE_BY_PATH.ERROR,
      payload: err,
    });
  }
}

export default [
  takeLatest(InsuranceTypes.GET_INSURANCE_BY_TYPE.REQUEST, getCMSData),
  takeLatest(InsuranceTypes.GET_INSURANCE_BY_PATH.REQUEST, getIndividualByPath),
  takeLatest(InsuranceTypes.GET_INSURANCE_SETTINGS.REQUEST, getSettings),
  takeLatest(InsuranceTypes.GET_INSURANCE_MANUFACTURERS.REQUEST, getManufacturers),
];
