import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { CMS_GENERIC_INDIVIDUAL_INCLUDES, CMS_GENERIC_LIST_INCLUDES } from 'api-client/api/cms';
import { cms } from 'api-client/apiClientInstance/cms';
import { Router } from 'server/pages';
import { reportToBugsnag} from 'lib/bugsnag';
import CmsTypes from '../action-types/cms';
import { SOME_ERROR_OCCURRED } from '../constants';

const parseSubRequests = data =>
  Object.keys(data).reduce((accumulator, key) => {
    let items = null;
    if (data[key] && data[key].body) {
      try {
        items = JSON.parse(data[key].body);
      } catch (e) {
        reportToBugsnag(e, 'parseSubRequests');
      }
    }
    return { ...accumulator, [key]: items };
  }, {});

export function* fetchCMS({
  payload: { type, id, includes, limit, sort, filter, section },
  storeAs,
  fingerprint,
  chan,
}) {
  let out = {};
  try {
    const data = yield call(cms.requestCMS, type, id, includes, limit, sort, filter, section);
    out = {
      type: CmsTypes.FETCH_CMS.SUCCESS,
      payload: { type, ...data },
      storeAs,
      fingerprint,
    };
  } catch (err) {
    reportToBugsnag(err, 'fetchCMS');
    out = {
      type: CmsTypes.FETCH_CMS.ERROR,
      payload: err,
      storeAs,
      fingerprint,
    };
  }
  yield put(out);
  if (chan) {
    yield put(chan, out);
  }
}

export function* subRequestCms({ payload, headers = {}, storeAs, fingerprint, chan }) {
  let out = {};
  try {
    const data = yield call(cms.subRequestCMS, payload, headers);
    const mapped = parseSubRequests(data);
    out = {
      type: CmsTypes.MULTI_FETCH_CMS.SUCCESS,
      payload: mapped,
      storeAs,
      fingerprint,
    };
  } catch (err) {
    reportToBugsnag(err, 'subRequestCms');
    out = {
      type: CmsTypes.MULTI_FETCH_CMS.ERROR,
      payload: err,
      storeAs,
      fingerprint,
    };
  }
  yield put(out);
  if (chan) {
    yield put(chan, out);
  }
  return out;
}

export function* resolveAndFetch({
  payload: { path: canonicalPath, includes = [...CMS_GENERIC_INDIVIDUAL_INCLUDES, ...CMS_GENERIC_LIST_INCLUDES] },
  storeAs,
  fingerprint,
  chan,
}) {
  let out = {};
  try {
    const { jsonapi, entity, meta, data = {}, included, links, ...rest } = yield call(
      cms.resolve,
      canonicalPath,
      includes,
    );
    const { individual, resourceName: type, entryPoint } = jsonapi || {};
    const { uuid: id, canonical, id: cmsId } = entity || {};
    const path = individual && individual.replace(entryPoint, '');
    const individualStoreData = { ...rest, jsonapi, data, links, type, path, id, cmsId, canonical };
    const post = { jsonapi, data, included, links, ...rest };
    const { redirect } = { ...rest }

    if (redirect && Array.isArray(redirect)) Router.replaceRoute(redirect[0].to);

    out = {
      type: CmsTypes.RESOLVE_CMS_ENTITY.SUCCESS,
      payload: individualStoreData,
      path: canonicalPath,
      storeAs,
      fingerprint,
    };
    yield put(out);

    if (data.id) {
      const fetchOut = {
        type: CmsTypes.FETCH_CMS_INDIVIDUAL.SUCCESS,
        payload: post,
        path: canonicalPath,
        storeAs,
        fingerprint,
      };
      yield put(fetchOut);
    }
  } catch (err) {
    reportToBugsnag(err, 'resolveAndFetch');


    out = {
      type: CmsTypes.RESOLVE_CMS_ENTITY.ERROR,
      payload: err,
      path: canonicalPath,
      storeAs,
      fingerprint,
    };
    yield put(out);
  }

  if (chan) {
    yield put(chan, out);
  }
}

export function* fetchIndividual({
  payload: { path, includes = [...CMS_GENERIC_INDIVIDUAL_INCLUDES, ...CMS_GENERIC_LIST_INCLUDES] },
  path: canonicalPath,
  storeAs,
  fingerprint,
  chan,
}) {
  let out = {};
  try {
    const post = yield call(cms.callCMS, path, includes);
    out = {
      type: CmsTypes.FETCH_CMS_INDIVIDUAL.SUCCESS,
      payload: post,
      path: canonicalPath,
      storeAs,
      fingerprint,
    };
  } catch (err) {
    reportToBugsnag(err, 'fetchIndividual');


    out = {
      type: CmsTypes.FETCH_CMS_INDIVIDUAL.ERROR,
      payload: err,
      path: canonicalPath,
      storeAs,
      fingerprint,
    };
  }
  yield put(out);
  if (chan) {
    yield put(chan, out);
  }
}

export function* fetchCategories({ storeAs, fingerprint, chan }) {
  let out = {};
  try {
    const data = yield call(cms.fetchCategories);
    out = {
      type: CmsTypes.FETCH_TAXONOMY_CATEGORIES.SUCCESS,
      payload: data,
      storeAs,
      fingerprint,
    };
  } catch (err) {
    reportToBugsnag(err, 'fetchCategories');


    out = {
      type: CmsTypes.FETCH_TAXONOMY_CATEGORIES.ERROR,
      payload: err,
      storeAs,
      fingerprint,
    };
  }
  yield put(out);
  if (chan) {
    yield put(chan, out);
  }
}

export function* fetchManufacturers({ chan }) {
  let out = {};
  try {
    const data = yield call(cms.fetchManufacturers);
    out = {
      type: CmsTypes.FETCH_TAXONOMY_MANUFACTURERS.SUCCESS,
      payload: data,
    };
  } catch (err) {
    reportToBugsnag(err, 'fetchManufacturers');


    out = {
      type: CmsTypes.FETCH_TAXONOMY_MANUFACTURERS.ERROR,
      payload: err,
    };
  }
  yield put(out);
  if (chan) {
    yield put(chan, out);
  }
}

export default [
  takeEvery(CmsTypes.FETCH_CMS.REQUEST, fetchCMS),
  takeEvery(CmsTypes.MULTI_FETCH_CMS.REQUEST, subRequestCms),
  takeLatest(CmsTypes.RESOLVE_CMS_ENTITY.REQUEST, resolveAndFetch),
  takeLatest(CmsTypes.FETCH_CMS_INDIVIDUAL.REQUEST, fetchIndividual),
  takeLatest(CmsTypes.FETCH_TAXONOMY_CATEGORIES.REQUEST, fetchCategories),
  takeLatest(CmsTypes.FETCH_TAXONOMY_MANUFACTURERS.REQUEST, fetchManufacturers),
];
