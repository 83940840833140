import { createActions } from 'redux-actions';
import MotsTypes from '../action-types/mots';

export const {
  getMotsSettingsRequest,
  getMotsSettingsSuccess,
  getMotsSettingsError,

  getMotsQuotesRequest,
  getMotsQuotesSuccess,
  getMotsQuotesError,

  getMotsByPathRequest,
  getMotsByPathSuccess,
  getMotsByPathError,

  waitingMotContent,
} = createActions({
  [MotsTypes.GET_MOTS_SETTINGS.REQUEST]: payload => payload,
  [MotsTypes.GET_MOTS_SETTINGS.SUCCESS]: payload => payload,
  [MotsTypes.GET_MOTS_SETTINGS.ERROR]: payload => payload,

  [MotsTypes.GET_MOTS_QUOTES.REQUEST]: payload => payload,
  [MotsTypes.GET_MOTS_QUOTES.SUCCESS]: payload => payload,
  [MotsTypes.GET_MOTS_QUOTES.ERROR]: payload => payload,

  [MotsTypes.GET_MOTS_BY_PATH.REQUEST]: payload => payload,
  [MotsTypes.GET_MOTS_BY_PATH.SUCCESS]: payload => payload,
  [MotsTypes.GET_MOTS_BY_PATH.ERROR]: payload => payload,

  [MotsTypes.WAITING_MOT_CONTENT]: payload => payload,
});
