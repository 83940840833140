import { combineReducers } from 'redux';
import { CHECK_OUT_STATUS } from 'containers/plateCheckout/constant';

import { HYDRATE } from 'next-redux-wrapper';
import numberPlates from 'containers/numberPlate/store/reducers/numberPlates';
import auctions from './auctions';
import auction from './auction';
import user from './user';
import userAuth from './userAuth';
import car from './car';
import longAuction from './longAuction';
import dropDown from './dropDown';
import liveAuction from './liveAuction';
import awaitingCollection from './awaitingCollection';
import pendingPayments from './pendingPayments';
import quotesArchive from './quotesArchive';
import modal from './modal';
import browseByMake from './browseByMake';
import location from './location';
import carModels from './carModels';
import message from './message';
import toasts from './toasts';
import quote from './quote';
import scrappedCars from './scrappedCars';
import redirect from './redirect';
import media from './media';
import insurance from './insurance';
import cms from './cms';
import faq from './faq';
import scrapMyCar from './scrapMyCar';
import financeQuoteProcess from './financeQuoteProcess';
import finance from './finance';
import warranties from './warranties';
import valuations from './valuations';
import vehicleInspections from './vehicleInspections';
import warrantiesAgQuoteProcess from './warrantiesAgQuoteProcess';
import valuationQuoteProcess from './valuationQuoteProcess';
import homePage from './homePage';
import homePageV2 from './homePageV2';
import mots from './mots';
import motQuoteProcess from './motQuoteProcess';
import carTaxProcess from './carTaxProcess';
import carTax from './carTax';
import myCar from './myCar';
import numberPlateCart from './numberPlate';
import warrantiesQuotes from './warrantiesQuotes';

const combineReducer = combineReducers({
  ...auctions,
  ...auction,
  ...car,
  ...user,
  ...longAuction,
  ...dropDown,
  ...liveAuction,
  ...awaitingCollection,
  ...pendingPayments,
  ...quotesArchive,
  ...modal,
  ...browseByMake,
  ...location,
  ...carModels,
  ...message,
  ...toasts,
  ...quote,
  ...scrappedCars,
  ...userAuth,
  ...redirect,
  ...cms,
  ...media,
  ...insurance,
  ...faq,
  ...scrapMyCar,
  financeQuoteProcess,
  ...finance,
  ...warranties,
  ...valuations,
  ...vehicleInspections,
  ...warrantiesAgQuoteProcess,
  ...valuationQuoteProcess,
  ...homePage,
  ...homePageV2,
  ...mots,
  ...motQuoteProcess,
  ...carTaxProcess,
  ...carTax,
  ...myCar,
  ...numberPlates,
  ...numberPlateCart,
  ...warrantiesQuotes,
});

const reducer = (state = {}, action) => {
  switch (action.type) {
    case HYDRATE:
      // eslint-disable-next-line no-case-declarations
      const np = action.payload.numberPlate;
      if (np) {
        np.start = state.numberPlate?.start;
        np.keyword = state.numberPlate?.keyword;
        np.searchLoading = state.numberPlate?.searchLoading;
      }
      return {
        ...state,
        ...action.payload,
        quote: state.quote?.quoteId ? state.quote : action.payload.quote,
        toasts: state.toasts,
        numberPlateCart: state.numberPlateCart,
        numberPlateCart_init: state.numberPlateCart_init,
        numberPlate: np,
        motQuoteProcess: state.motQuoteProcess.motResponse ? state.motQuoteProcess : action.payload.quote,
        carTaxProcess: state.carTaxProcess.vehicleRegistration ? state.carTaxProcess : action.payload.quote,
      };
    default:
      return combineReducer(state, action);
  }
};

export default reducer;
