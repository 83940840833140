import { createSelector } from 'reselect';

const getModal = state => state.modalState;

export const getRootModalState = () =>
  createSelector(getModal, ({ isModalOpen, modalType, modalInfo, backdrop }) => ({
    isModalOpen,
    modalType,
    modalInfo,
    backdrop,
  }));
