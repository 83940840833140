import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { modalClose } from 'store/actions/modal';

const BaseConfirmationPopup = ({
  title,
  description,
  confirmTitle,
  rejectTitle,
  confirmAction,
  isConfirmButtonDisabled,
}) => {
  const dispatch = useDispatch();

  const closeModal = () => dispatch(modalClose());

  return (
    <>
      <header className="base-confirmation-popup__header-block">
        {title && <h1 className="base-confirmation-popup__title" dangerouslySetInnerHTML={{ __html: title }} />}
        {description && (
          <div className="base-confirmation-popup__description" dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </header>
      <div className="base-confirmation-popup__diving-line" />
      <ButtonGroup className="base-confirmation-popup__buttons">
        <Button
          onClick={confirmAction}
          disabled={isConfirmButtonDisabled}
          className="base-confirmation-popup__button__primary"
          type="submit"
        >
          {confirmTitle}
        </Button>
        <Button onClick={closeModal} className="base-confirmation-popup__button__secondary">
          {rejectTitle}
        </Button>
      </ButtonGroup>
    </>
  );
};

BaseConfirmationPopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  confirmTitle: PropTypes.string,
  rejectTitle: PropTypes.string,
  confirmAction: PropTypes.func.isRequired,
  isConfirmButtonDisabled: PropTypes.bool,
};

BaseConfirmationPopup.defaultProps = {
  title: '',
  description: '',
  confirmTitle: 'Yes',
  rejectTitle: 'No',
  isConfirmButtonDisabled: false,
};

export default BaseConfirmationPopup;
