import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import * as cmsActions from '../actions/cms';
import mergeToState from '../lib/mergeToStateHelper';

const initialState = {
  cmsIsLoading: false,
  cmsError: null,
  news: {},
  mediaImage: {},
  fileFile: {},
  users: {},
  categories: {},
  blogs: {},
  videos: {},
  mediaVideo: {},
  reviews: {},
  guides: {},
  allCategories: {},
  individual: {},
};

export default {
  cms: handleActions(
    {
      [cmsActions.resolveCmsEntityRequest]: state =>
        immutable(state, {
          cmsIsLoading: { $set: true },
          mediaError: { $set: null },
          individual: { $set: {} },
        }),

      [cmsActions.resolveCmsEntitySuccess]: (state, { payload: individualFingerPrint }) =>
        immutable(state, {
          cmsIsLoading: { $set: false },
          cmsError: { $set: null },
          individual: { $set: individualFingerPrint },
        }),

      [cmsActions.resolveCmsEntityError]: (state, { payload }) =>
        immutable(state, {
          cmsIsLoading: { $set: false },
          cmsError: { $set: payload },
          individual: { $set: {} },
        }),

      [cmsActions.multiFetchCmsError]: (state, { payload }) =>
        immutable(state, {
          cmsIsLoading: { $set: false },
          cmsError: { $set: payload },
          individual: { $set: {} },
        }),

      [combineActions(
        cmsActions.fetchCmsError,
        cmsActions.fetchCmsIndividualError,
        cmsActions.fetchTaxonomyManufacturersError,
        cmsActions.fetchTaxonomyCategoriesError,
      )]: (state, { payload }) =>
        immutable(state, {
          cmsIsLoading: { $set: false },
          cmsError: { $set: payload },
        }),

      [cmsActions.fetchCmsRequest]: state =>
        immutable(state, {
          cmsIsLoading: { $set: true },
          cmsError: { $set: null },
        }),

      [combineActions(
        cmsActions.fetchCmsSuccess,
        cmsActions.fetchCmsIndividualSuccess,
        cmsActions.fetchTaxonomyCategoriesSuccess,
        cmsActions.fetchTaxonomyManufacturersSuccess,
      )]: (state, { payload }) =>  {
        payload.included?.forEach(includeItem => {
          if (includeItem.type === 'file--file' && includeItem.attributes.styles) {
            payload.included.forEach(mediaImageItem => {
              if (
                mediaImageItem.type === 'media--image' &&
                mediaImageItem.relationships.file.data.id === includeItem.id
              ) {
                const { links } = includeItem.attributes.styles.meta.imageDerivatives;
                // eslint-disable-next-line no-restricted-syntax
                for (const key of Object.keys(links)) {
                  const { meta } = mediaImageItem.relationships.file.data;
                  includeItem.attributes.styles.meta.imageDerivatives.links[key].meta.alt = meta.alt;
                }
              }
            });
          }
        });
        const result = mergeToState(state, payload);
        return result;
      },

      [cmsActions.fetchCmsError]: (state, { payload }) =>
        immutable(state, {
          cmsIsLoading: { $set: false },
          cmsError: { $set: payload },
        }),
    },
    initialState,
  ),
};
