import { call, put, takeLatest } from 'redux-saga/effects';
import { reportToBugsnag} from 'lib/bugsnag';
import { quotesArchive } from 'api-client/apiClientInstance/quotesArchive';
import QuotesArchive from '../action-types/quotesArchive';
import { SOME_ERROR_OCCURRED } from '../constants';

export function* loadCarList({ payload }) {
  try {
    const response = yield call(quotesArchive.getQuotesArchiveCarList, payload);

    const { items, totalNumberOfAuctions, limit, offset } = response.data;

    yield put({
      type: QuotesArchive.GET_QUOTES_ARCHIVE_CAR_LIST.SUCCESS,
      payload: items,
    });

    yield put({
      type: QuotesArchive.SET_QUOTES_ARCHIVE_CAR_LIST_PAGING,
      payload: { totalNumberOfAuctions, limit, offset },
    });
  } catch ({ err }) {
    reportToBugsnag(err, 'loadCarList');

    // TODO: refactor set just error in payload
    yield put({
      type: QuotesArchive.GET_QUOTES_ARCHIVE_CAR_LIST.ERROR,
      payload: err && err.messages,
    });
  }
}

export default [takeLatest(QuotesArchive.GET_QUOTES_ARCHIVE_CAR_LIST.REQUEST, loadCarList)];
