import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import moment from 'moment';
import * as financeQuoteProcessActions from '../actions/financeQuoteProcess';
// import financeQuoteProcess from 'redux/action-types/financeQuoteProcess';
import * as financeActions from '../actions/finance';

const extractByKey = (data = [], key) => {
  let out = {};
  const [applicant] = data;
  if (applicant && applicant[key]) {
    out = applicant[key];
  }
  return out;
};

const allSuccess = combineActions(
  financeQuoteProcessActions.initApplicationSuccess,
  financeQuoteProcessActions.fetchApplicationSuccess,
  financeQuoteProcessActions.appendApplicationSuccess,
  financeActions.getContinueWithFinanceQuoteShortLinkSuccess
);
const mutationRequests = combineActions(
  financeQuoteProcessActions.initApplicationRequest,
  financeQuoteProcessActions.appendApplicationRequest,
  financeQuoteProcessActions.completeApplicationRequest
);

const applicationDetailsInitialState = {
  CreditLimit: null,
  TermLength: null,
  VehicleType: '',
  Deposit: null,
  HasGuarantor: false,
  HasLicenceGuarantor: false,
  FinanceType: 'UNKNOWN',
};

const applicationDetails = handleActions(
  {
    [financeQuoteProcessActions.setApplicationDetails]: (state, { payload }) => ({ ...state, ...payload }),

    [allSuccess]: (state, { payload: { ApplicationDetails = {} } }) => ({ ...state, ...ApplicationDetails }),

    [financeQuoteProcessActions.completeApplicationSuccess]: () => ({
      ...applicationDetailsInitialState,
    }),

    [financeQuoteProcessActions.clearFinanceQuoteProcessData]: state => ({
      ...applicationDetailsInitialState,
      CreditLimit: state.CreditLimit,
      TermLength: state.TermLength,
    }),
  },
  applicationDetailsInitialState
);

const basicDetailsInitialState = {
  Gender: 'MALE',
  Title: '',
  Forename: '',
  MiddleNames: null,
  Surname: '',
  DateOfBirth: '',
  PhoneNumbers: [
    {
      Type: '',
      Value: '',
    },
  ],
  Email: '',
  ApplicantType: 'PRIMARY',
};

const BasicDetails = handleActions(
  {
    [financeQuoteProcessActions.setBasicDetails]: (state, { payload }) => ({ ...state, ...payload }),

    [allSuccess]: (state, data) => {
      const {
        payload: { Applicants = [] },
      } = data;
      const basicDetail = extractByKey(Applicants, 'BasicDetails');
      basicDetail.DateOfBirth = basicDetail.DateOfBirth ? new moment(basicDetail.DateOfBirth).format('YYYY-MM-DD') : '';
      return {
        ...state,
        ...basicDetail,
      };
    },

    [financeQuoteProcessActions.completeApplicationSuccess]: () => ({
      ...basicDetailsInitialState,
    }),

    [financeQuoteProcessActions.clearFinanceQuoteProcessData]: () => ({ ...basicDetailsInitialState }),
  },
  basicDetailsInitialState
);

const marketingOptInInitialState = {
  Email: false,
  Sms: false,
  Phone: false,
  ThirdPartyReferral: null,
};

const MarketingOptIn = handleActions(
  {
    [financeQuoteProcessActions.setMarketingOptIn]: (state, { payload: { password, ...payload } }) => ({
      ...marketingOptInInitialState,
      // the unclicked checkboxes are just not available in the payload to override the values
      Email: false,
      Sms: false,
      Phone: false,
      ThirdPartyReferral: null,
      ...payload,
    }),

    [allSuccess]: (state, { payload: { Applicants = [] } }) => ({
      ...state,
      ...extractByKey(Applicants, 'MarketingOptIn'),
    }),

    [financeQuoteProcessActions.completeApplicationSuccess]: () => ({
      ...marketingOptInInitialState,
    }),

    [financeQuoteProcessActions.clearFinanceQuoteProcessData]: () => ({ ...marketingOptInInitialState }),
  },
  marketingOptInInitialState
);

const additionalDetailsInitialState = {
  MaritalStatus: '',
  LicenceType: '',
  ValidUkPassport: null,
  OtherMonthlyIncome: null,
};

const AdditionalDetails = handleActions(
  {
    [financeQuoteProcessActions.setAdditionalDetails]: (state, { payload }) => ({ ...state, ...payload }),

    [allSuccess]: (state, { payload: { Applicants = [] } }) => ({
      ...state,
      ...extractByKey(Applicants, 'AdditionalDetails'),
      OtherMonthlyIncome:
        Applicants && Applicants.length && Applicants[0].ApplicantEmployment && Applicants[0].ApplicantEmployment.length
          ? Applicants[0].ApplicantEmployment[0].NetMonthlyIncome
          : 0,
    }),

    [financeQuoteProcessActions.completeApplicationSuccess]: () => ({
      ...additionalDetailsInitialState,
    }),

    [financeQuoteProcessActions.completeApplicationSuccess]: () => ({
      ...additionalDetailsInitialState,
    }),

    [financeQuoteProcessActions.clearFinanceQuoteProcessData]: () => ({ ...additionalDetailsInitialState }),
  },
  additionalDetailsInitialState
);

const applicantAddressInitialState = [
  {
    NameNumber: '',
    Street: '',
    TownCity: null,
    County: '',
    PostCode: '',
    Years: -1,
    Months: -1,
    ResidentialStatus: '',
  },
];

const ApplicantAddress = handleActions(
  {
    [financeQuoteProcessActions.setApplicantAddress]: (state, { payload }) => [...payload],

    [allSuccess]: (state, { payload: { Applicants = [] } }) => [...extractByKey(Applicants, 'ApplicantAddress')],

    [financeQuoteProcessActions.completeApplicationSuccess]: () => ({
      ...applicantAddressInitialState,
    }),

    [financeQuoteProcessActions.clearFinanceQuoteProcessData]: () => ([ ...applicantAddressInitialState]),
  },
  applicantAddressInitialState
);

const applicantEmploymentInitialState = [
  {
    Occupation: '',
    EmployerName: '',
    EmploymentStatus: '',
    Telephone: '',
    Years: -1,
    Months: -1,
    NetMonthlyIncome: 0,
    EmploymentAddress: {
      NameNumber: '',
      Street: '',
      TownCity: null,
      County: '',
      PostCode: '',
    },
  },
];

const ApplicantEmployment = handleActions(
  {
    [financeQuoteProcessActions.setApplicantEmployment]: (state, { payload }) => [...payload],

    [allSuccess]: (state, { payload: { Applicants = [] } }) => [...extractByKey(Applicants, 'ApplicantEmployment')],

    [financeQuoteProcessActions.completeApplicationSuccess]: () => ({
      ...applicantEmploymentInitialState,
    }),

    [financeQuoteProcessActions.clearFinanceQuoteProcessData]: () => ({ ...applicantEmploymentInitialState }),
  },
  applicantEmploymentInitialState
);

const internalsInitialState = {
  hasAccount: false,
  stepIsLoading: false,
  stepError: null,
  password: null,
  lookedUpLoqateAddresses: [],
  selectedLoqateAddress: { label: '' },
  step: null,
};

const internals = handleActions(
  {
    [financeQuoteProcessActions.setInternals]: (state, { payload }) => ({ ...state, ...payload }),

    [allSuccess]: (state, { payload: { hasAccount, Applicants } }) => {
      const newState = {
        ...state,
        hasAccount,
        stepIsLoading: false,
        stepError: null,
        step: window.location.pathname,
      };

      if (
        Applicants &&
        Applicants.length > 0 &&
        Applicants[0].ApplicantAddress &&
        Applicants[0].ApplicantAddress.length > 0
      ) {
        const addresses = Applicants[0].ApplicantAddress;
        const LastAddress = addresses[addresses.length - 1];
        newState.selectedLoqateAddress = { label: LastAddress.Street };
      }

      return newState;
    },

    [mutationRequests]: state => ({ ...state, stepIsLoading: true, stepError: null }),

    [financeQuoteProcessActions.completeApplicationSuccess]: () => ({
      ...internalsInitialState,
    }),

    [financeQuoteProcessActions.setMarketingOptIn]: (state, { payload: { password } }) => ({
      ...state,
      password,
    }),

    [financeQuoteProcessActions.clearFinanceQuoteProcessData]: state => ({
      ...state,
      lookedUpLoqateAddresses: internalsInitialState.lookedUpLoqateAddresses,
      selectedLoqateAddress: internalsInitialState.selectedLoqateAddress,
    }),
  },
  internalsInitialState
);

const otherInfo = handleActions(
  {
    [allSuccess]: (state, { payload: { short_url: shortLink, short_url_token: shortLinkToken } }) => ({
      ...state,
      shortLink,
      shortLinkToken,
    }),
  },
  {}
);

const applicantDetails = combineReducers({
  BasicDetails,
  MarketingOptIn,
  AdditionalDetails,
  ApplicantAddress,
  ApplicantEmployment,
});

export default combineReducers({
  applicantDetails,
  applicationDetails,
  internals,
  otherInfo,
});
