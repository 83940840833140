import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getUserEmailSelector, getUserStatusSelector } from 'store/selectors/user';
import { forgotPasswordRequest } from 'store/actions';
import { STATUS } from 'store/constants';
import BaseConfirmationPopup from '../../components/BaseConfirmationPopup';

const ForgotPasswordPopup = () => {
  const dispatch = useDispatch();

  const userEmail = useSelector(getUserEmailSelector);

  const forgotPassword = () => dispatch(forgotPasswordRequest({ email: userEmail }));
  const status = useSelector(getUserStatusSelector);

  return (
    <BaseConfirmationPopup
      title="Are you sure you want to reset your password?"
      description="Password reset link will be sent to your email. Click the link in the email and follow the instructions to enter a new password."
      confirmAction={forgotPassword}
      isConfirmButtonDisabled={status === STATUS.RUNNING}
    />
  );
};

export default ForgotPasswordPopup;
