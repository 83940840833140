import * as Yup from 'yup';

const validationSchemaService = Yup.object().shape({
  serviceDueDate: Yup.date().typeError('date is required for service due.'),
});

const validationSchemaInsurance = Yup.object().shape({
  insuranceDueDate: Yup.date().typeError('date is required for insurance due.'),
});

const validationSchemaWarranty = Yup.object().shape({
  warrantyDueDate: Yup.date().typeError('date is required for warranty due.'),
});

const validationSchemaAll = Yup.object().shape({
  serviceDueDate: Yup.date().typeError('date is required for service due.'),
  insuranceDueDate: Yup.date().typeError('date is required for insurance due.'),
  warrantyDueDate: Yup.date().typeError('date is required for warranty due.'),
});

export { validationSchemaService, validationSchemaInsurance, validationSchemaWarranty, validationSchemaAll };
