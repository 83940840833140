import { handleActions, combineActions } from 'redux-actions';
import immutable from 'immutability-helper';
import ActionTypes from '../action-types/numberPlate';
import UserTypes from '../action-types/user';
import { STATUS } from '../constants';
import { CHECK_OUT_STATUS } from 'containers/plateCheckout/constant';
import PlateHelper from 'containers/plateCheckout/helper';
import { deliveryPackage, deliveryTruck, deliveryTruckCircular } from 'public/images';
import { formatCurrency } from 'lib/formatters';
import moment from 'moment';
import { Router } from 'server/pages';

const initialValues = {
  cartId: '',
  registration: '',
  status: STATUS.IDLE,
  minimumYear: '',
  navStatus: '',
  checkNumberStatus: -1, //default -1, good 0, bad 1.
  checkNumberDescription: '',
  personalDetail: {
    title: '',
    firstName: '',
    surname: '',
    mobile: '',
    email: '',
    checkReg: '',
    PostCode: '',
    nomineeName: '',
    loquateAddress: '',
    address1: '',
    address2: '',
    town: '',
    county: '',
    isManualAddress: false,
    terms: false,
    checkRegTerms: false,
  },
  customise: {
    buyAcrylic: '',
    font: '',
    flag: '',
    border: '',
    size: '',
  },
  transferDelivery: {
    transfer: '1',
    delivery: '',
    gift: '50',
  },
  payment: {
    fullName: '',
    setupIntent: '',
  },
};

const init = {
  hasAccount: true,
  needLogin: false,
  currentStep: '',
  buy_physical_plate: '',
  infoUpdated: 0,

  customizeOption: {
    acrylicPrice: 0,
    fonts: [],
    flags: [],
    borders: [],
    sizes: [],
  },

  customizePrice: {
    acrylicPrice: 0,

    font: '',
    flag: '',
    border: '',
    size: '',

    fontPrice: 0,
    flagPrice: 0,
    borderPrice: 0,
    sizePrice: 0,

    fontDesc: '',
    flagDesc: '',
    borderDesc: '',
    sizeDesc: '',
  },

  //used to present number plate control with user selection
  customPlate: {
    border: false,
    flag: '',
    font: false, //true: 3D, false: standard
    size: '', //standard, large, square, mc
  },

  transferDeliveryOption: {
    transferPrice: 0,
    deliveryOptions: [],
    giftPrice: 0,
  },

  priceItem: {
    vrmPrice: 0,
    acrylicPrice: 0,
    deliveryPrice: 0,
    giftPrice: 0,
    transferPrice: 0,
    subTotal: 0,
    vatTitle: '',
    vatPrice: 0,
    dvlaPrice: 0,
    total: 0,
    customText: '',
    vatType: '',
    vatValue: 0,
    dvlaType: '',
    dvlaValue: 0,
    // custom price tax
    physicalPlatePrice: 0,
    physicalPlateTax: 0,
    fontVatPrice: 0,
    flagVatPrice: 0,
    borderVatPrice: 0,
    sizeVatPrice: 0,
    customizePriceVatTotal: 0,
    // transfer delivery price tax
    transferVatPrice: 0,
    deliveryVatPrice: 0,
    giftVatPrice: 0,
    deliveryAndOptionTaxConfig: 0,
  },

  cards: [],
};

export default {
  numberPlateCart: handleActions(
    {
      //restart
      [ActionTypes.RESTART.SUCCESS]: state =>
        immutable(state, {
          status: { $set: initialValues.status },
          navStatus: { $set: CHECK_OUT_STATUS.PERSONAL_DETAIL },
          minimumYear: { $set: initialValues.minimumYear },
          checkNumberStatus: { $set: initialValues.checkNumberStatus },
          checkNumberDescription: { $set: initialValues.checkNumberDescription },
          personalDetail: { $set: initialValues.personalDetail },
          customise: { $set: initialValues.customise },
          transferDelivery: { $set: initialValues.transferDelivery },
          payment: { $set: initialValues.payment },
        }),

      //set nav route
      [ActionTypes.SET_NAV_ROUTE]: (state, { payload }) =>
        immutable(state, {
          navStatus: { $set: payload },
        }),

      //create cart
      [ActionTypes.CREATE_CART.SUCCESS]: (state, { payload: { registration, maskedId } }) =>
        immutable(state, {
          cartId: { $set: maskedId },
          status: { $set: STATUS.SUCCESS },
          registration: { $set: registration },
          navStatus: { $set: CHECK_OUT_STATUS.PERSONAL_DETAIL },
          personalDetail: { $set: { ...initialValues.personalDetail } },
          customise: { $set: { ...initialValues.customise } },
          transferDelivery: { $set: { ...initialValues.transferDelivery } },
        }),

      //fetch cart info
      [ActionTypes.FETCH_CART.SUCCESS]: (
        state,
        {
          payload: {
            totals,
            current_step,
            navStatus,
            maskedId,
            shipping_address,
            customer,
            plate_check_result,
            old_number_plate,
            customise_options,
            transfer_service,
            gift_wrapping,
            shipping_method,
            minimumYear,
            id,
          },
        },
      ) => {
        return immutable(state, {
          cartId: { $set: maskedId || id },
          registration: {
            $set: JSON.parse(totals.items[0].options).find(item => item.label === 'Number Plate').value || '',
          },
          navStatus: { $set: navStatus || PlateHelper.getNavStatus(current_step) },
          // checkNumberStatus: { $set: current_step === null ? -1 : 0 },
          checkNumberStatus: { $set: -1 },
          checkNumberDescription: {
            $set: plate_check_result || getCheckMessage(minimumYear, JSON.parse(totals.items[0].options)[0].value),
          },
          minimumYear: { $set: minimumYear },
          personalDetail: {
            $set: {
              ...state.personalDetail,
              title: customer ? { label: customer.prefix, value: customer.prefix } : '',
              firstName: customer?.firstname || '',
              surname: customer?.lastname || '',
              mobile: customer?.mobile || '',
              email: customer?.email || '',
              checkReg: old_number_plate || '',
              PostCode: shipping_address?.postcode || '',
              nomineeName: customer?.nominee_name || '',
            },
          },
          customise: { $set: customTemplateConverter(customise_options, current_step) },
          transferDelivery: {
            $set: {
              transfer:
                transfer_service == 0 && current_step === CHECK_OUT_STATUS.CUSTOMIZE_PLATE
                  ? '1'
                  : transfer_service + '',
              gift: gift_wrapping == 0 ? '50' : '51',
              delivery: shipping_method || '',
            },
          },
        });
      },

      //submit personal
      [ActionTypes.SUBMIT_PERSONAL_DETAIL.REQUEST]: (state, { payload: { values } }) =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
          personalDetail: { $set: { ...values, terms: false } },
        }),
      [ActionTypes.SUBMIT_PERSONAL_DETAIL.SUCCESS]: state =>
        immutable(state, {
          status: { $set: STATUS.SUCCESS },
          navStatus: { $set: CHECK_OUT_STATUS.CUSTOMIZE_PLATE },
        }),

      //submit custom template
      [ActionTypes.SUBMIT_CUSTOM_TEMPLATE.REQUEST]: (state, { payload: { values } }) =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
          customise: { $set: { ...values } },
        }),
      [ActionTypes.SUBMIT_CUSTOM_TEMPLATE.SUCCESS]: (state, { payload: { status } }) =>
        immutable(state, {
          status: { $set: STATUS.SUCCESS },
          navStatus: { $set: CHECK_OUT_STATUS.TRANSFER_DELIVERY },
        }),

      //submit transfer delivery
      [ActionTypes.SUBMIT_TRANSFER_DELIVERY.REQUEST]: (state, { payload: { values } }) =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
          transferDelivery: { $set: { ...values } },
        }),
      [ActionTypes.SUBMIT_TRANSFER_DELIVERY.SUCCESS]: state =>
        immutable(state, {
          status: { $set: STATUS.SUCCESS },
          navStatus: { $set: CHECK_OUT_STATUS.ORDER_SUMMARY },
        }),

      //confirm cart
      [ActionTypes.CONFIRM_CART.SUCCESS]: state =>
        immutable(state, {
          status: { $set: STATUS.SUCCESS },
          navStatus: { $set: CHECK_OUT_STATUS.PAYMENT },
        }),

      //place order
      [ActionTypes.PLACE_ORDER.SUCCESS]: state =>
        immutable(state, {
          status: { $set: STATUS.SUCCESS },
          navStatus: { $set: CHECK_OUT_STATUS.CONFIRMATION },
        }),

      //address
      [ActionTypes.MANUALLY_ENTER_ADDRESS]: (state, { payload }) =>
        immutable(state, {
          personalDetail: { $set: { ...payload } },
        }),

      //check reg request
      [ActionTypes.CHECK_REG.REQUEST]: state =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
          checkNumberStatus: { $set: -1 },
        }),
      [ActionTypes.CHECK_REG.SUCCESS]: (state, { payload: { status, message } }) =>
        immutable(state, {
          status: { $set: STATUS.SUCCESS },
          checkNumberStatus: { $set: status ? 0 : 1 },
        }),

      [ActionTypes.RESET_CHECK_STATUS]: (state, { payload }) =>
        immutable(state, {
          checkNumberStatus: { $set: -1 },
        }),

      //get setup intent
      [ActionTypes.GET_STRIPE_SETUP_INTENT.SUCCESS]: (state, { payload }) =>
        immutable(state, {
          payment: { $set: { setupIntent: payload, fullName: '' } },
          status: { $set: STATUS.SUCCESS },
        }),

      //create cart
      [ActionTypes.CREATE_CART.REQUEST]: (state, { payload: { registration, minimumYear } }) =>
        immutable(state, {
          minimumYear: { $set: minimumYear },
          status: { $set: STATUS.RUNNING },
          checkNumberStatus: { $set: -1 }, //default -1, good 0, bad 1.
          checkNumberDescription: { $set: getCheckMessage(minimumYear, registration) },
        }),

      //start request
      [combineActions(
        ActionTypes.GET_TRANSFER_DELIVERY.REQUEST,
        ActionTypes.RESTART.REQUEST,
        ActionTypes.GET_DELIVERY_METHOD.REQUEST,
        ActionTypes.UPDATE_TRANSFER_AND_GET_DELIVERY.REQUEST,
        ActionTypes.CONFIRM_CART.REQUEST,
        ActionTypes.GET_STRIPE_SETUP_INTENT.REQUEST,
        ActionTypes.PLACE_ORDER.REQUEST,
        ActionTypes.START_ACTION,
      )]: state =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
        }),
      //request error
      [combineActions(
        ActionTypes.FETCH_CART.ERROR,
        ActionTypes.CREATE_CART.ERROR,
        ActionTypes.CHECK_REG.ERROR,
        ActionTypes.SUBMIT_PERSONAL_DETAIL.ERROR,
        ActionTypes.GET_CUSTOMIZE_OPTION.ERROR,
        ActionTypes.SUBMIT_CUSTOM_TEMPLATE.ERROR,
        ActionTypes.GET_TRANSFER_DELIVERY.ERROR,
        ActionTypes.UPDATE_TRANSFER_AND_GET_DELIVERY.ERROR,
        ActionTypes.GET_DELIVERY_METHOD.ERROR,
        ActionTypes.SUBMIT_TRANSFER_DELIVERY.ERROR,
        ActionTypes.CONFIRM_CART.ERROR,
        ActionTypes.GET_STRIPE_SETUP_INTENT.ERROR,
        ActionTypes.PLACE_ORDER.ERROR,
      )]: state =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),

      //request succeed
      [combineActions(
        ActionTypes.GET_CUSTOMIZE_OPTION.SUCCESS,
        ActionTypes.GET_TRANSFER_DELIVERY.SUCCESS,
        ActionTypes.UPDATE_TRANSFER_AND_GET_DELIVERY.SUCCESS,
        ActionTypes.GET_DELIVERY_METHOD.SUCCESS,
        ActionTypes.STOP_ACTION,
      )]: state =>
        immutable(state, {
          status: { $set: STATUS.SUCCESS },
        }),
    },
    initialValues,
  ),

  numberPlateCart_init: handleActions(
    {
      //restart
      [ActionTypes.RESTART.SUCCESS]: state =>
        immutable(state, {
          customPlate: { $set: init.customPlate },
        }),

      //get customize options
      [ActionTypes.GET_CUSTOMIZE_OPTION.SUCCESS]: (state, { payload }) =>
        immutable(state, {
          customizeOption: { $set: payload },
        }),

      //get transfer delivery option
      [ActionTypes.GET_TRANSFER_DELIVERY.SUCCESS]: (
        state,
        { payload: { transfer_service, gift_wrapping, shipping_methods } },
      ) => {
        return immutable(state, {
          transferDeliveryOption: {
            $set: {
              transferPrice: +transfer_service?.value,
              deliveryOptions: shipMethodTransfer(shipping_methods),
              giftPrice: +gift_wrapping?.value,
            },
          },
        });
      },

      // update transfer and get delivery option, because some transfer options can't get delivery options
      [ActionTypes.UPDATE_TRANSFER_AND_GET_DELIVERY.SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          transferDeliveryOption: {
            deliveryOptions: { $set: shipMethodTransfer(payload) },
          },
        });
      },

      //get delivery methods
      [ActionTypes.GET_DELIVERY_METHOD.SUCCESS]: (state, { payload }) =>
        immutable(state, {
          transferDeliveryOption: {
            $set: {
              transferPrice: state.transferDeliveryOption.transferPrice,
              deliveryOptions: shipMethodTransfer(payload),
              giftPrice: state.transferDeliveryOption.giftPrice,
            },
          },
        }),

      //fetch cart info
      [ActionTypes.FETCH_CART.SUCCESS]: (
        state,
        {
          payload: {
            totals,
            customise_options,
            vat_fee_config,
            dvla_fee_config,
            hasAccount,
            current_step,
            NeedAuth,
            option_and_delivery_tax_config,
            physical_plate_config,
            buy_physical_plate,
            transfer_service_config,
          },
        },
      ) => {
        return immutable(state, {
          infoUpdated: { $set: moment().format('YYYY-MM-DD HH:mm:ss') },
          currentStep: { $set: current_step },
          buy_physical_plate: { $set: buy_physical_plate },
          priceItem: {
            $set: {
              ...state.priceItem,
              ...priceItemConverter(
                totals,
                customise_options,
                vat_fee_config,
                dvla_fee_config,
                state,
                current_step,
                option_and_delivery_tax_config,
                physical_plate_config,
                buy_physical_plate,
                transfer_service_config,
              ),
            },
          },
          customizePrice: { $set: { ...customizePriceConverter(customise_options, state, current_step) } },
          needLogin: { $set: NeedAuth || state.needLogin },
          customPlate: { $set: getCustomPlate(customise_options, state) },
        });
      },

      //check user account
      [ActionTypes.CHECK_USER_ACCOUNT.SUCCESS]: (state, { payload: { hasAccount } }) => {
        return immutable(state, {
          hasAccount: { $set: hasAccount || false },
        });
      },

      //check user login
      [ActionTypes.CHECK_USER_LOGIN.SUCCESS]: (state, { payload: { NeedAuth } }) => {
        return immutable(state, {
          needLogin: { $set: NeedAuth },
        });
      },

      //user login
      [UserTypes.USER_LOGIN.SUCCESS]: (state, { payload: { token } }) =>
        immutable(state, {
          needLogin: { $set: false },
        }),

      //get customer cards
      [ActionTypes.GET_CUSTOMER_CARDS.SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          cards: { $set: payload },
        });
      },

      //set custom price
      [ActionTypes.SET_CUSTOM]: (state, { payload }) => {
        return immutable(state, {
          customizePrice: { $set: { ...manualCustomPrice(state, payload) } },
          priceItem: { $set: { ...manualPriceItem(state, payload) } },
          customPlate: { $set: manuallyCustomPlate(state, payload) },
        });
      },

      //set transfer price
      [ActionTypes.SET_TRANSFER_CUSTOM]: (state, { payload }) => {
        return immutable(state, {
          priceItem: {
            $set: {
              ...state.priceItem,
              ...manualPriceItem_transfer(state, payload),
            },
          },
        });
      },
    },
    init,
  ),
};

const shipMethodTransfer = shippingMethods => {
  let result = [];
  if (shippingMethods && shippingMethods.length > 0) {
    result = shippingMethods.map(({ carrier_code, method_code, carrier_title, method_title, amount }) => {
      let imgSrc = '';
      switch (carrier_code) {
        case 'car_express':
          imgSrc = deliveryTruckCircular;
          break;
        case 'car_standard':
          imgSrc = deliveryTruck;
          break;
        case 'flatrate':
          imgSrc = deliveryPackage;
          break;
      }

      return {
        id: carrier_code + '_' + method_code,
        carrier_code,
        method_code,
        name: 'delivery',
        amount,
        imgSrc,
        line1: carrier_title,
        line2: '£' + formatCurrency(amount || 0),
        line4: method_title,
      };
    });
  }

  return result;
};

const customTemplateConverter = (customise_options, current_step) => {
  const result = { ...initialValues.customise };
  if (customise_options) {
    const options = JSON.parse(customise_options);

    if (options && options.index && options.index.length === 4) {
      result.font = options.index[0];
      result.flag = options.index[1];
      result.border = options.index[2];
      result.size = options.index[3];
      result.buyAcrylic = 'Yes';
    } else {
      if (current_step == CHECK_OUT_STATUS.PERSONAL_DETAIL) {
        result.buyAcrylic = 'Yes';
      }
    }
  }

  return result;
};

const getCustomPlate = (customise_options, state) => {
  const result = { ...state.customPlate };

  if (customise_options) {
    const options = JSON.parse(customise_options);

    if (options && options.info && options.info.length === 4) {
      result.font = options.info[0].code === '3D';
      result.flag = +options.info[1].price > 0 ? result.flag || 'flag' : '';
      result.border = options.info[2].code === 'bordered';
      result.size = options.info[3].code || '';
    }
  }

  return result;
};

const priceItemConverter = (
  totals,
  customise_options,
  vat_fee_config,
  dvla_fee_config,
  state,
  current_step,
  option_and_delivery_tax_config,
  physical_plate_config,
  buy_physical_plate,
  transfer_service_config,
) => {
  const getPrice = (segments, code) => {
    const item = segments.find(x => x.code == code);
    return item ? { title: item.title, price: item.value || 0 } : { title: '', price: 0 };
  };

  const result = {};
  if (totals && totals.total_segments) {
    result.vrmPrice = getPrice(totals.total_segments, 'subtotal').price;
    result.acrylicPrice = getPrice(totals.total_segments, 'customise_plate_fee').price;
    result.giftPrice = getPrice(totals.total_segments, 'gift_wrapping_fee').price;
    result.deliveryPrice = getPrice(totals.total_segments, 'shipping').price;
    result.transferPrice = getPrice(totals.total_segments, 'transfer_service_fee').price;
    // set default transfer options to premius
    if (current_step === CHECK_OUT_STATUS.CUSTOMIZE_PLATE) {
      result.transferPrice = transfer_service_config.value * 1;
    }
    result.subTotal =
      result.vrmPrice + result.acrylicPrice + result.giftPrice + result.deliveryPrice + result.transferPrice;

    result.vatTitle = getPrice(totals.total_segments, 'vat_fee').title;
    result.vatPrice = getPrice(totals.total_segments, 'vat_fee').price;
    result.dvlaPrice = getPrice(totals.total_segments, 'dvla_fee').price;
    result.vatType = vat_fee_config.type;
    result.vatValue = +vat_fee_config.value;
    result.dvlaType = dvla_fee_config.type;
    result.dvlaValue = +dvla_fee_config.value;
    const acrylicPrice =
      buy_physical_plate === 0 && current_step !== 'PERSONAL_DETAIL'
        ? 0
        : result.acrylicPrice
        ? result.acrylicPrice
        : state.customizeOption.acrylicPrice;

    result.deliveryAndOptionTaxConfig = option_and_delivery_tax_config.value * 1 || 0;
    result.transferVatPrice = result.transferPrice * result.deliveryAndOptionTaxConfig;
    result.deliveryVatPrice = result.deliveryPrice * result.deliveryAndOptionTaxConfig;
    result.giftVatPrice = result.giftPrice * result.deliveryAndOptionTaxConfig;
    result.physicalPlatePrice = physical_plate_config.value * 1;
    result.physicalPlateTax = result.physicalPlatePrice * result.deliveryAndOptionTaxConfig;

    // init vat prices
    result.fontVatPrice = 0;
    result.flagVatPrice = 0;
    result.borderVatPrice = 0;
    result.sizeVatPrice = 0;

    if (customise_options && customise_options != '[]') {
      const options = JSON.parse(customise_options);
      if (options && options.info) {
        const optionValues = options.info.map(x => x.value);
        result.customText = optionValues.join(', ');
        // init customize tax
        options.info.forEach(item => {
          switch (item.type) {
            case 'Font':
              result.fontVatPrice = item.price * result.deliveryAndOptionTaxConfig;
              break;
            case 'Flag':
              result.flagVatPrice = item.price * result.deliveryAndOptionTaxConfig;
              break;
            case 'Border':
              result.borderVatPrice = item.price * result.deliveryAndOptionTaxConfig;
              break;
            case 'Size':
              result.sizeVatPrice = item.price * result.deliveryAndOptionTaxConfig;
              break;
          }
        });
      }
    } else if (current_step == CHECK_OUT_STATUS.PERSONAL_DETAIL) {
      result.acrylicPrice = state.customizeOption.acrylicPrice || 0;
    }

    const customizePriceTaxTotal = acrylicPrice * result.deliveryAndOptionTaxConfig;
    const transferDeliveryTaxTotal = result.transferVatPrice + result.deliveryVatPrice + result.giftVatPrice;
    result.customizePriceVatTotal = customizePriceTaxTotal;
    result.total =
      result.vrmPrice +
      result.vatPrice +
      result.dvlaPrice +
      result.deliveryPrice +
      result.transferPrice +
      result.giftPrice +
      acrylicPrice +
      customizePriceTaxTotal +
      transferDeliveryTaxTotal;
  }
  return result;
};

const customizePriceConverter = (customise_options, state, current_step) => {
  const result = { ...init.customizePrice };

  if (customise_options && customise_options != '[]') {
    const options = JSON.parse(customise_options);

    if (options && options.info && options.info.length == 4) {
      result.font = options.info[0].id;
      result.fontPrice = +options.info[0].price;
      result.fontDesc = options.info[0].value;

      result.flag = options.info[1].id;
      result.flagPrice = +options.info[1].price;
      result.flagDesc = options.info[1].value;

      result.border = options.info[2].id;
      result.borderPrice = +options.info[2].price;
      result.borderDesc = options.info[2].value;

      result.size = options.info[3].id;
      result.sizePrice = +options.info[3].price;
      result.sizeDesc = options.info[3].value;

      result.acrylicPrice = state.customizeOption.acrylicPrice || 0;
    }
  } else if (current_step == CHECK_OUT_STATUS.PERSONAL_DETAIL) {
    result.acrylicPrice = state.customizeOption.acrylicPrice || 0;
  }

  return result;
};

const manualCustomPrice = (state, { type, id }) => {
  const customOptions = state.customizeOption;
  const customizePrices = state.customizePrice;
  const priceItem = state.priceItem;
  const customPriceTax = priceItem.deliveryAndOptionTaxConfig || 0.2;

  let item = {};
  switch (type) {
    case 'font':
      item = customOptions.fonts.find(x => x.id == id);
      customizePrices.font = id;
      customizePrices.fontPrice = item?.price || 0;
      customizePrices.fontDesc = item?.description || '';
      priceItem.fontVatPrice = customizePrices.fontPrice * customPriceTax;
      break;

    case 'flag':
      item = customOptions.flags.find(x => x.id == id);
      customizePrices.flag = id;
      customizePrices.flagPrice = item?.price || 0;
      customizePrices.flagDesc = item?.description || '';
      priceItem.flagVatPrice = customizePrices.flagPrice * customPriceTax;
      break;

    case 'border':
      item = customOptions.borders.find(x => x.id == id);
      customizePrices.border = id;
      customizePrices.borderPrice = item?.price || 0;
      customizePrices.borderDesc = item?.description || '';
      priceItem.borderVatPrice = customizePrices.borderPrice * customPriceTax;

      break;

    case 'size':
      item = customOptions.sizes.find(x => x.id == id);
      customizePrices.size = id;
      customizePrices.sizePrice = item?.price || 0;
      customizePrices.sizeDesc = item?.description || '';
      priceItem.sizeVatPrice = customizePrices.sizePrice * customPriceTax;
      break;

    case 'buyAcrylic':
      customizePrices.acrylicPrice = id == 'Yes' ? customOptions.acrylicPrice : 0;
      break;
  }

  return { ...customizePrices };
};

const manualPriceItem = (state, { type, id }) => {
  const priceItem = state.priceItem;
  const prices = manualCustomPrice(state, { type, id });
  const {
    vrmPrice,
    deliveryPrice,
    giftPrice,
    transferPrice,
    vatType,
    vatValue,
    dvlaType,
    dvlaValue,
    dvlaPrice,
  } = priceItem;

  const acrylicPrice =
    prices.acrylicPrice > 0
      ? prices.fontPrice + prices.flagPrice + prices.borderPrice + prices.sizePrice + prices.acrylicPrice
      : 0;
  const subTotal = vrmPrice + deliveryPrice + giftPrice + transferPrice + acrylicPrice;

  const _dvlaPrice = dvlaType == 'fixed' ? dvlaPrice : vrmPrice * dvlaValue;
  const vatPrice = vatType == 'fixed' ? vatValue : vrmPrice * vatValue;

  // custom price tax
  const fontVatPrice = priceItem.fontVatPrice;
  const flagVatPrice = priceItem.flagVatPrice;
  const borderVatPrice = priceItem.borderVatPrice;
  const sizeVatPrice = priceItem.sizeVatPrice;
  const transferDeliveryVatTotal = (deliveryPrice + giftPrice + transferPrice) * priceItem.deliveryAndOptionTaxConfig;
  let physicalVatPrice = priceItem.deliveryAndOptionTaxConfig * priceItem.physicalPlatePrice;
  let customizePriceVatTotal = fontVatPrice + flagVatPrice + borderVatPrice + sizeVatPrice + physicalVatPrice;

  if (type === 'buyAcrylic' && id === 'No') {
    physicalVatPrice = 0;
    customizePriceVatTotal = 0;
  }

  const total = subTotal + _dvlaPrice + vatPrice + customizePriceVatTotal + transferDeliveryVatTotal;
  const descArray = [];

  if (acrylicPrice) {
    if (prices.fontDesc) descArray.push(prices.fontDesc);
    if (prices.flagDesc) descArray.push(prices.flagDesc);
    if (prices.borderDesc) descArray.push(prices.borderDesc);
    if (prices.sizeDesc) descArray.push(prices.sizeDesc);
  }

  return {
    ...priceItem,
    acrylicPrice: acrylicPrice,
    customText: descArray.join(', '),
    vatPrice,
    dvlaPrice: _dvlaPrice,
    subTotal,
    total,
    physicalPlateTax: physicalVatPrice,
    customizePriceVatTotal,
  };
};

const manuallyCustomPlate = (state, { type, id }) => {
  const customOptions = state.customizeOption;
  const customizePrice = state.customizePrice;
  let customPlate = { ...state.customPlate };

  let item = {};
  switch (type) {
    case 'flag':
      item = customOptions.flags.find(x => x.id == id);
      customPlate.flag = (item?.price || 0) > 0 ? item.src : '';
      break;

    case 'font':
      item = customOptions.fonts.find(x => x.id == id);
      customPlate.font = item?.code === '3D' || false;
      break;

    case 'border':
      item = customOptions.borders.find(x => x.id == id);
      customPlate.border = item?.code === 'bordered' || false;
      break;

    case 'size':
      item = customOptions.sizes.find(x => x.id == id);
      customPlate.size = item?.code || '';
      break;

    case 'buyAcrylic':
      item = customOptions.flags.find(x => x.id == customizePrice.flag);
      customPlate.flag = (item?.price || 0) > 0 ? item.src : '';

      item = customOptions.fonts.find(x => x.id == customizePrice.font);
      customPlate.font = (item?.price || 0) > 0;

      item = customOptions.borders.find(x => x.id == customizePrice.border);
      customPlate.border = (item?.price || 0) > 0;

      item = customOptions.sizes.find(x => x.id == customizePrice.size);
      customPlate.size = item?.code || '';

      customPlate = id == 'No' ? init.customPlate : customPlate;
      break;
  }

  return customPlate;
};

const manualPriceItem_transfer = (state, { type, id, buyPhysical }) => {
  const { priceItem, transferDeliveryOption } = state;

  let {
    vrmPrice,
    acrylicPrice,
    deliveryPrice,
    giftPrice,
    transferPrice,
    vatType,
    vatValue,
    dvlaType,
    dvlaValue,
    dvlaPrice,
    deliveryAndOptionTaxConfig,
    fontVatPrice,
    flagVatPrice,
    borderVatPrice,
    sizeVatPrice,
    physicalPlateTax,
  } = priceItem;

  switch (type) {
    case 'transfer':
      transferPrice = id == '1' ? transferDeliveryOption.transferPrice : 0;
      deliveryPrice = id == 1 || buyPhysical ? deliveryPrice : 0;
      break;

    case 'delivery':
      deliveryPrice = transferDeliveryOption.deliveryOptions.find(x => x.id == id)?.amount || 0;
      break;

    case 'gift':
      giftPrice = id == '51' ? transferDeliveryOption.giftPrice : 0;
      break;
  }
  if (!buyPhysical) physicalPlateTax = 0;
  const subTotal = vrmPrice + deliveryPrice + giftPrice + transferPrice + acrylicPrice;

  const _dvlaPrice = dvlaType == 'fixed' ? dvlaPrice : vrmPrice * dvlaValue;
  const vatPrice = vatType == 'fixed' ? vatValue : vrmPrice * vatValue;
  const transferVatPrice = transferPrice * deliveryAndOptionTaxConfig;
  const deliveryVatPrice = deliveryPrice * deliveryAndOptionTaxConfig;
  const giftVatPrice = giftPrice * deliveryAndOptionTaxConfig;

  const transferDeliveryTaxTotal = transferVatPrice + deliveryVatPrice + giftVatPrice;
  const customizePriceTaxTotal = fontVatPrice + flagVatPrice + borderVatPrice + sizeVatPrice + physicalPlateTax;

  const total = subTotal + _dvlaPrice + vatPrice + transferDeliveryTaxTotal + customizePriceTaxTotal;

  return {
    ...priceItem,
    deliveryPrice,
    giftPrice,
    transferPrice,
    vatPrice,
    dvlaPrice: _dvlaPrice,
    subTotal,
    total,
    transferVatPrice,
    deliveryVatPrice,
    giftVatPrice,
  };
};

const getCheckMessage = (minimumYear, registration) => {
  return minimumYear && minimumYear !== '1900-01-01' && minimumYear !== '0000-00-00'
    ? 'The registration ' +
        (() => {
          const length = registration?.length || 0;
          let result = '';
          switch (length) {
            case 5:
              result = `${registration.slice(0, 2)} ${registration.slice(2)}`;
              break;
            case 6:
              result = `${registration.slice(0, 3)} ${registration.slice(3)}`;
              break;
            case 7:
              result = `${registration.slice(0, 4)} ${registration.slice(4)}`;
              break;
            default:
              break;
          }
          return result;
        })() +
        ' can only be assigned to a vehicle first manufactured on or after ' +
        moment(minimumYear).format('Do MMM YYYY')
    : 'The registration can be assigned to any vehicle, regardless of age.';
};
