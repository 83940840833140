import fetch from 'cross-fetch';
import RestClient from './restClient';

export default class MagentoClient extends RestClient {
  constructor(baseUrl, config) {
    super(baseUrl);
    this.defaultConfig = config;
  }

  getConfig(method, data) {
    const config = {
      method: method.toUpperCase(),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      timeout: 30000,
    };

    if (this.accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${this.accessToken}`,
      };
    }

    if (data) {
      config.body = JSON.stringify(data);
    }

    return { ...config, ...this.defaultConfig };
  }

  request(endpoint, config) {
    return fetch(endpoint, config).then(response => {
      const { status } = response;

      if (status >= 200 && status < 300) {
        return response
          .json()
          .then(data => data)
          .catch(error => {
            throw error;
          });
      }

      return response.json().then(err => {
        const error = err;
        error.status = response.status;
        throw error;
      });
    });
  }
}
