import { createAsyncActionSet } from '../lib/action-helper';

export default {
  GET_QUOTES_ARCHIVE_PAGE: createAsyncActionSet('GET_QUOTES_ARCHIVE_PAGE'),
  GET_QUOTES_ARCHIVE_CAR_LIST: createAsyncActionSet('GET_QUOTES_ARCHIVE_CAR_LIST'),
  SET_QUOTES_ARCHIVE_CAR_LIST_PAGING: 'SET_QUOTES_ARCHIVE_CAR_LIST_PAGING',
  RESET_QUOTES_ARCHIVE_CAR_LIST_PAGING: 'RESET_QUOTES_ARCHIVE_CAR_LIST_PAGING',
  DELETE_QUOTES_ARCHIVE_CAR_LIST: 'DELETE_QUOTES_ARCHIVE_CAR_LIST',
  RESET_QUOTES_ARCHIVE_CAR_LIST: 'RESET_QUOTES_ARCHIVE_CAR_LIST',
};
