import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import { immutablySetOrMergeDeeply } from '../lib/mergeToStateHelper';
import * as motsActions from '../actions/mots';

const initialState = {
  motsIsLoading: false,
  motsError: null,
  motsPage: {},
  settings: {},
  byPath: {}
};

export default {
  mots: handleActions(
    {
      [combineActions(
        motsActions.getMotsSettingsRequest,
        motsActions.getMotsQuotesRequest,
        motsActions.getMotsByPathRequest,
      )]: state =>
        immutable(state, {
          motsIsLoading: { $set: true },
          motsError: { $set: null },
        }),
      [combineActions(motsActions.getMotsSettingsSuccess, motsActions.getMotsQuotesSuccess, motsActions.getMotsByPathSuccess)]: (
        state,
        { payload },
      ) => {
        const update = immutablySetOrMergeDeeply(state, payload, {
          motsIsLoading: { $set: false },
          motsError: { $set: null },
        });
        return immutable(state, update);
      },
      [combineActions(motsActions.getMotsSettingsError, motsActions.getMotsQuotesError, motsActions.getMotsByPathError)]: (
        state,
        { payload },
      ) =>
        immutable(state, {
          motsIsLoading: { $set: false },
          motsError: { $set: payload },
        }),
    },
    initialState,
  ),
};
