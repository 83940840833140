import absoluteUrl from 'next-absolute-url';
import { getCookie } from 'lib/cookie';
import queryString from 'query-string';
import { addCacheResistanceToParams } from '../lib/cache';
export default class ScrapCarQuoteClient {
  constructor(client) {
    this.client = client;
    this.resourceUrl = '/scrap-car-quote';
  }

  create = data => this.client.post(`${this.resourceUrl}/create`, data);

  createAffiliated = (data, { slug }) => this.client.post(`${this.resourceUrl}/create/affiliated?uc=${slug || 'csg'}`, data);

  enterDetails = (id, data) => this.client.post(`${this.resourceUrl}/${id}/enter-details`, data);

  arrangeCollection = (id, data) => this.client.post(`${this.resourceUrl}/${id}/arrange-collection`, data);

  paymentMethod = (id, data) => this.client.post(`${this.resourceUrl}/${id}/payment-method`, data);

  continueWithQuote = (token, req, acceptOffer, companyToken, action) =>
    this.client.get(
      `${this.resourceUrl}/continue/${token}/`,
      addCacheResistanceToParams(
        queryString.stringify({
          ...(acceptOffer ? { acceptOffer: true } : {}),
          ...(companyToken ? { companyToken } : {}),
          ...(action ? { action } : {}),
        })
      ),
      req
    );

  saveAbandonedData = (id, data) => this.client.sendBeacon(`${this.resourceUrl}/${id}/pending`, data);

  getBookingSlots = (id, params) =>
    this.client.get(
      `${this.resourceUrl}/${id}/getBookingSlots?${addCacheResistanceToParams(queryString.stringify(params))}`
    );

  addAuth = params => {
    const token = getCookie('scrap-authorization');
    if (token) {
      return {
        ...params,
        scrapQuoteToken: token,
      };
    }

    return params;
  };

  fetch = () => this.client.post(`${this.resourceUrl}/getByToken`, this.addAuth());

  associateQuoteWithCustomer = quote_id =>
    this.client.post(`${this.resourceUrl}/associate-quote-with-customer`, { quote_id });
}
