import { call, put, takeLatest } from 'redux-saga/effects';
import { enquiry } from 'api-client/apiClientInstance/enquiry';
import { reportToBugsnag } from 'lib/bugsnag';
import { showToast } from 'store/actions/toasts';
import { segmentIdentify, segmentTrack } from 'lib/segment';
import { md5 } from 'lib/encrypt';
import ActionType from '../action-types/contactUs';
import { SOME_ERROR_OCCURRED } from '../constants';

function* sendEnquiry({
  payload: {
    data,
    actions: { setErrors, setSubmitting },
  },
}) {
  try {
    yield call(enquiry.sendEnquiry, data);
    segmentIdentify(md5(data.email), {
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.contactNumber,
      email: data.email,
    });
    segmentTrack('Contact_Enquiry', {
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.contactNumber,
      email: data.email,
      registration_number: data.registration,
    });
    yield put({
      type: ActionType.SET_CONTACT_US_ENQUIRY.SUCCESS,
    });
  } catch (err) {
    reportToBugsnag(err, 'sendEnquiry');
    if (err.status !== 401) {
      const message = err?.message || err?.error?.messages;
      setSubmitting(false);
      if (message) {
        yield put(
          showToast({
            message,
            kind: 'error',
          }),
        );
      }
    }
    yield put({
      type: ActionType.SET_CONTACT_US_ENQUIRY.ERROR,
      payload: err,
    });
  }
}

export default [takeLatest(ActionType.SET_CONTACT_US_ENQUIRY.REQUEST, sendEnquiry)];
