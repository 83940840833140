import { extend } from 'immutability-helper';

extend('$mergeArray', (newvals, original) =>
  newvals.reduce((acc, item) => (acc.indexOf(item) === -1 ? [...acc, item] : acc), original),
);

export const byId = '$mergeArray';
export const links = '$set';

export const DEFAULT_MISSING_COMMAND = '$set';
export const DEFAULT_OBJECT_COMMAND = '$merge';
export const DEFAULT_ARRAY_COMMAND = '$push';
