const QUOTE_STATUS = {
  CREATED: 'created',
  PENDING: 'pending',
  EXPIRED: 'expired',
  PAID: 'paid',
  DELETED: 'deleted',
  COMPLETED: 'completed',
  UNAUTHORIZED: '401',
  FORBIDDEN: '403',
  BAD: '400',
  CONTINUE_FORBIDDEN: 'CONTINUE_FORBIDDEN',
  ADDRESS_COMPLETED: 'address_completed',
  EMPLOYMENT_COMPLETED: 'employment_completed',
};

const QUOTE_ROUTES = {
  YOUR_DETAILS: '/car-finance/your-details',
  YOUR_ADDRESS: '/car-finance/your-address',
  YOUR_EMPLOYMENT_DETAILS: '/car-finance/your-employment-details',
  CONTACT_DETAILS: '/car-finance/contact-details',
  CONFIRMATION: '/car-finance/contact-details',
  HOMEPAGE: '/car-finance',
  LOGIN: '/sign-in',
};

// Use construction of module.exports is required
// because of the absence of server support for export keyword
module.exports = {
  QUOTE_STATUS,
  QUOTE_ROUTES,
};
