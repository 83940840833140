const isBrowser = typeof window !== 'undefined';

export const scrollToTop = () => {
  if (isBrowser) {
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0);
  }
};


export const isMobile = val => isBrowser && window.innerWidth <= val;
