// eslint-disable-next-line no-bitwise
export const getRandomString = () => (((1 + Math.random()) * 0x10000) | 0).toString(16);

export const unescapeHTML = str => {
  return str
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'");
};

export const escapeHTML = str => {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
};

export const isImageFile = file => {
  return file.type.includes('image');
};

export const isVideoFile = file => {
  return file.type.includes('video');
};

export const formatPrice = number => {
  return number ? new Intl.NumberFormat().format(number) : number;
};