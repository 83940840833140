import { put, take, takeLatest, call, select } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { isUserAuthenticatedSelector } from 'store/selectors/userAuth';
import { getLinkToken, getQuoteStatus } from 'store/selectors/finance';
import { financeCarQuote } from 'api-client/apiClientInstance/financeCarQuote';
import { reportToBugsnag } from 'lib/bugsnag';
import {
  showToast,
  getContinueWithFinanceQuoteShortLinkRequest,
  getContinueWithFinanceQuoteShortLinkSuccess,
  getContinueWithFinanceQuoteShortLinkError,
} from 'store/actions';
import { redirect } from 'lib/routes';
import { QUOTE_STATUS, QUOTE_ROUTES } from 'modules/finance/QuoteProcess/constants';
import { redirectToStepRequest, redirectToStepSuccess } from 'store/actions/redirect';
import { Router } from 'server/pages';
import { wrapWith, extractStoreIds } from '../lib/mergeToStateHelper';
import { snakeToCamelCase } from '../lib/snakeToCamelCase';
import CmsTypes from '../action-types/cms';
import FinanceTypes from '../action-types/finance';
import UserTypes from 'store/action-types/user';
import { setCookie, removeCookie } from '../lib/cookie';

export function* getCMSData({ payload }) {
  const chan = yield call(channel);
  const STORE_AS = 'financePage';
  try {
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { section: 'finance', ...payload },
      chan,
    });
    const {
      payload: { data, links },
      path,
    } = yield take(chan);
    yield put({
      type: FinanceTypes.GET_FINANCE_PAGES.SUCCESS,
      payload: wrapWith(STORE_AS, extractStoreIds(data, path, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getCMSData');

    yield put({
      type: FinanceTypes.GET_FINANCE_PAGES.ERROR,
      payload: err,
    });
  }
}

export function* getFinanceManufacturers() {
  const chan = yield call(channel);
  const STORE_AS = 'allManufacturers';
  try {
    yield put({
      type: CmsTypes.FETCH_TAXONOMY_MANUFACTURERS.REQUEST,
      chan,
    });
    const {
      payload: { data, links, type },
      path,
    } = yield take(chan);
    yield put({
      type: FinanceTypes.GET_FINANCE_MANUFACTURERS.SUCCESS,
      payload: wrapWith(STORE_AS, wrapWith(type, extractStoreIds(data, path, links))),
    });
  } catch (err) {
    reportToBugsnag(err, 'getFinanceManufacturers');

    yield put({
      type: FinanceTypes.GET_FINANCE_MANUFACTURERS.ERROR,
      payload: err,
    });
  }
}

export function* getSettings() {
  const fingerprint = 'financeSettings';
  const chan = yield call(channel);
  try {
    const type = 'settings';
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { section: 'finance', type, includes: ['image.file', 'homepage', 'calculator'] },
      fingerprint,
      chan,
    });
    const fullData = yield take(chan);
    //   ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp,
    // );
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: FinanceTypes.GET_FINANCE_SETTINGS.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getSettings');

    yield put({
      type: FinanceTypes.GET_FINANCE_SETTINGS.ERROR,
      payload: err,
    });
  }
}

export function* getIndividualByPath({ payload: { specificIncludes, ...payload } }) {
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.RESOLVE_CMS_ENTITY.REQUEST,
      payload,
      chan,
    });
    const {
      payload: {
        data,
        path: resourcePath,
        jsonapi: { resourceName },
      },
      path,
    } = yield take(chan);

    let outData = data;
    const camelResourceName = snakeToCamelCase(resourceName);

    if (camelResourceName !== 'nodeCarFinancePage') {
      yield put({
        type: CmsTypes.FETCH_CMS_INDIVIDUAL.REQUEST,
        payload: { path: resourcePath, includes: specificIncludes[camelResourceName] },
        path,
      });
      const {
        payload: { data: includedData },
      } = yield take(CmsTypes.FETCH_CMS_INDIVIDUAL.SUCCESS);
      outData = includedData;
    }

    yield put({
      type: FinanceTypes.GET_FINANCE_BY_PATH.SUCCESS,
      payload: extractStoreIds(outData, path),
    });
  } catch (err) {
    reportToBugsnag(err, 'getIndividualByPath');

    yield put({
      type: FinanceTypes.GET_FINANCE_BY_PATH.ERROR,
      payload: err,
    });
  }
}

const setQuoteDataToCookies = (quoteStatus = '') => {
  setCookie('quoteStatus', quoteStatus);
};

const isRequestedStatusEqualToCurrent = (quoteStatusIndex, requestedQuoteStatusIndex) => {
  const isQuoteStatusValid = quoteStatusIndex > -1;
  const isRequestedQuoteStatusValid = requestedQuoteStatusIndex > -1;
  return isQuoteStatusValid && isRequestedQuoteStatusValid && requestedQuoteStatusIndex === quoteStatusIndex;
};

const getStatusIndex = status => {
  const quoteStatuses = [
    QUOTE_STATUS.CREATED,
    QUOTE_STATUS.PENDING,
    QUOTE_STATUS.CONFIRMED,
    QUOTE_STATUS.ADDRESS_COMPLETED,
    QUOTE_STATUS.EMPLOYMENT_COMPLETED,
  ];
  return quoteStatuses.indexOf(status);
};

export const requestedStatusCheck = (quoteStatus = '', requestedQuoteStatus = '') => {
  const quoteStatusIndex = getStatusIndex(quoteStatus);
  const requestedQuoteStatusIndex = getStatusIndex(requestedQuoteStatus);
  return isRequestedStatusEqualToCurrent(quoteStatusIndex, requestedQuoteStatusIndex);
};

const getStepRoute = (quoteStatus = '', requestedQuoteStatus = '') => {
  const isRequestedStatusValid = requestedStatusCheck(quoteStatus, requestedQuoteStatus);
  const status = isRequestedStatusValid ? requestedQuoteStatus : quoteStatus;

  switch (status) {
    case QUOTE_STATUS.PENDING:
      return QUOTE_ROUTES.YOUR_DETAILS;
    case QUOTE_STATUS.CREATED:
      return QUOTE_ROUTES.YOUR_ADDRESS;
    case QUOTE_STATUS.ADDRESS_COMPLETED:
      return QUOTE_ROUTES.YOUR_EMPLOYMENT_DETAILS;
    case QUOTE_STATUS.EMPLOYMENT_COMPLETED:
      return QUOTE_ROUTES.CONFIRMATION;
    case QUOTE_STATUS.UNAUTHORIZED:
      return QUOTE_ROUTES.LOGIN;
    case QUOTE_STATUS.FORBIDDEN:
      return QUOTE_ROUTES.HOMEPAGE;
    case QUOTE_STATUS.BAD:
      return QUOTE_ROUTES.HOMEPAGE;
    default:
      return QUOTE_ROUTES.HOMEPAGE;
  }
};

export function* redirectToStep(stepRouteToRedirect) {
  yield put(redirectToStepRequest());
  const quoteStatus = yield select(getQuoteStatus);
  yield setQuoteDataToCookies(quoteStatus);

  try {
    Router.replaceRoute(stepRouteToRedirect);
    // Router.pushRoute(`${settings.steps[0].uri}?refId=${refId}`);
    // yield call(Router.pushRoute(`refId=${refId}`), stepRouteToRedirect);
  } catch (err) {
    reportToBugsnag(err, 'redirectToStep');
  } finally {
    yield put(redirectToStepSuccess());
  }
}

export function* handleUnauthorizedQuote() {
  let quoteStatus = yield select(getQuoteStatus);

  if (quoteStatus === QUOTE_STATUS.CONTINUE_FORBIDDEN) {
    yield put(getContinueWithFinanceQuoteShortLinkRequest({}));
  }
}

const removeQuoteDataFromCookies = () => {
  removeCookie('quoteStatus');
};

export function* cancelQuote() {
  yield removeQuoteDataFromCookies();
  // yield redirect({}, '/car-finance');
}

export function* dropQuoteData() {
  yield removeQuoteDataFromCookies();
}

export function* continueWithFinanceQuoteShortLinkSuccess() {
  const quoteStatus = yield select(getQuoteStatus);
  const stepRouteToRedirect = getStepRoute(quoteStatus);
  yield redirectToStep(stepRouteToRedirect);
}

export function* continueWithFinanceQuoteShortLink() {
  let isAuthenticated;
  try {
    isAuthenticated = yield select(isUserAuthenticatedSelector);
    const token = yield select(getLinkToken);
    const data = yield call(financeCarQuote.continueWithQuote, token);
    const { financeQuoteToken } = data;
    sessionStorage && sessionStorage.setItem('finance-quote-authorization', financeQuoteToken);
    yield put(getContinueWithFinanceQuoteShortLinkSuccess(data));
  } catch (err) {
    if (err.status === 401 && !isAuthenticated) {
      yield put(getContinueWithFinanceQuoteShortLinkError({ quoteStatus: QUOTE_STATUS.CONTINUE_FORBIDDEN }));
      redirect({}, '/sign-in');
    } else if (err.status === 403) {
      yield put(showToast({ message: 'Sorry, you do not have enough rights to access this resource', kind: 'error' }));
      redirect({}, '/car-finance');
    } else if (err.status === 400) {
      yield put(showToast({ message: 'Sorry, quote is already completed', kind: 'error' }));
      redirect({}, '/car-finance');
    } else if (err.status === 404) {
      redirect({}, '/not-found');
    } else {
      reportToBugsnag(err, 'continueWithFinanceQuoteShortLink');
      yield put(getContinueWithFinanceQuoteShortLinkError(err));
    }
  }
}

export default [
  takeLatest(FinanceTypes.GET_FINANCE_BY_TYPE.REQUEST, getCMSData),
  takeLatest(FinanceTypes.GET_FINANCE_BY_PATH.REQUEST, getIndividualByPath),
  takeLatest(FinanceTypes.GET_FINANCE_MANUFACTURERS.REQUEST, getFinanceManufacturers),
  takeLatest(FinanceTypes.GET_FINANCE_SETTINGS.REQUEST, getSettings),
  takeLatest(FinanceTypes.GET_CONTINUE_WITH_FINANCE_QUOTE_SHORT_LINK.REQUEST, continueWithFinanceQuoteShortLink),
  takeLatest(FinanceTypes.GET_CONTINUE_WITH_FINANCE_QUOTE_SHORT_LINK.SUCCESS, continueWithFinanceQuoteShortLinkSuccess),
  takeLatest([UserTypes.USER_LOGIN.SUCCESS], handleUnauthorizedQuote),
];
