import { call, put, takeEvery, select } from 'redux-saga/effects';
import { warranties } from 'api-client/apiClientInstance/warranties';
import { reportToBugsnag } from 'lib/bugsnag';
import { showToast } from 'store/actions';
import {
  getWarrantiesQuoteId,
  getCustomerData,
  getProductData,
  getWarrantiesQuoteToken,
} from 'store/selectors/warrantiesAgQuoteProcess';
import { setCookie, getCookie } from 'lib/cookie';
import { segmentTrack, segmentIdentify } from 'lib/segment';
import { getUserFirstNameSelector, getUserLastNameSelector } from 'store/selectors/user';
import moment from 'moment';
import { md5 } from 'lib/encrypt';
import { isUserAuthenticatedSelector } from 'store/selectors/userAuth';
import { getErrorMessage } from 'lib/message';
import { modalOpen } from 'store/actions/modal';
import { MODAL_TYPE } from 'containers/rootModalContainer';
import warrantiesQuoteProcessTypes from '../action-types/warrantiesAgQuoteProcess';
import { userLoginSuccess } from '../actions';

const isObject = obj => Object.prototype.toString.call(obj) === '[object Object]';

const transferVariableToCamelCase = originObj => {
  if (!isObject(originObj)) return originObj;

  const result = {};

  const reg = /_{1,2}\w/g;
  const keys = Object.keys(originObj);
  // eslint-disable-next-line no-restricted-syntax
  for (let key of keys) {
    const value = originObj[key];
    key = key.replace(reg, match => match.replace(/_/g, '').toLocaleUpperCase());
    result[key] = isObject(value) ? transferVariableToCamelCase(value) : value;
  }

  return result;
};

const transformQuoteVariable = obj => {
  const transferData = transferVariableToCamelCase(obj);
  const productInfo = obj?.detail?.product_variant_data ? JSON.parse(obj?.detail?.product_variant_data) : {};
  return {
    ...transferData?.detail,
    mileage: transferData?.detail?.mileagePointOfSale,
    PostCode: transferData?.detail?.postcode,
    ...productInfo,
    productId: productInfo?.id,
    productType: productInfo?.product,
    ...transferData,
  };
};

function* errorHandler(err) {
  const isAuthenticated = yield select(isUserAuthenticatedSelector);
  if (err.status === 401 && isAuthenticated) {
    yield put(
      showToast({
        message:
          'These details are currently active in another quote, please login to your account in order to continue',
        kind: 'success',
      })
    );
  } else if (err.status === 401 && !isAuthenticated) {
    yield put(
      showToast({
        message:
          'These details are currently active in another quote, please login to your account in order to continue',
        kind: 'success',
      })
    );
  } else {
    const errorMessages = getErrorMessage(err);
    if (errorMessages) {
      yield put(showToast({ message: `${errorMessages}`, kind: 'error' }));
    }
  }
}

function* submitSegment(step, { trackData }) {
  const customer = yield select(getCustomerData);
  const email = (trackData.email ? trackData.email : customer?.email) || '';
  if (email && step === 'Enter_Detail') {
    const firstname = trackData.firstName ? trackData.firstName : yield select(getUserFirstNameSelector);
    const lastname = trackData.lastName ? trackData.lastName : yield select(getUserLastNameSelector);
    const mobile = trackData.mobile || customer?.mobile || '';
    let { address } = trackData;
    if (!address) {
      address = `${trackData.address1 ? `${trackData.address1} ` : ''}${
        trackData.address2 ? `${trackData.address2} ` : ''
      }${trackData.city} ${trackData.county}`;
    }
    segmentIdentify(md5(email), {
      email,
      firstname,
      lastname,
      mobile,
      first_name: firstname,
      last_name: lastname,
      phone: mobile,
      address,
      createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
    });
  }
  const token = yield select(getWarrantiesQuoteToken);
  const shortUrl = `${window.location.origin}/q/w/${token}`;
  const id = yield select(getWarrantiesQuoteId);
  segmentTrack(
    `Car_Warranty_${step}`,
    Object.assign(
      {
        token,
        shortUrl,
        id,
      },
      trackData
    )
  );
}

export function* createQuote({ payload }) {
  const { data, continueCallback, finallyCallback, setFieldError } = payload;
  try {
    const response = yield call(warranties.createQuote, {
      ...data,
    });
    const { applicationToken } = response;
    setCookie('warranties-quote-authorization', applicationToken);
    const transferData = response?.success ? transformQuoteVariable(response) : {};
    yield put({
      type: warrantiesQuoteProcessTypes.CLEAR_QUOTE_INFO,
    });
    yield put({
      type: warrantiesQuoteProcessTypes.CREATE_AG_WARRANTIES_QUOTE.SUCCESS,
      payload: transferData,
    });
    let cannotContinue = true;
    if (response?.success) {
      // conditions
      const start = moment(response.registration_date);
      const age = -start.diff(moment(), 'year');
      // mileage
      if (response?.mot_odometer_in_miles && data.mileage < response.mot_odometer_in_miles) {
        setFieldError('mileage', `Mileage must greater than ${response.mot_odometer_in_miles}`);
      } else if (response?.mot_odometer_in_miles > 120000) {
        yield put(
          showToast({
            kind: 'error',
            message: 'Sorry, we can not offer a quote for this vehicle due to vehicles that exceed 120,000 miles.',
          })
        );
      } else if (age > 12) {
        // vehicle age
        yield put(
          showToast({
            kind: 'error',
            message: 'Sorry, we can not offer a quote for this vehicle due to vehicles that exceed 11 year old.',
          })
        );
      } else {
        cannotContinue = false;
      }
    } else if (response?.notInLoadingsTable) {
      yield put({ type: warrantiesQuoteProcessTypes.STORE_QUOTE_INFO, payload: data });
      yield put(modalOpen({ modalType: MODAL_TYPE.warrantiesCustomQuote }));
    }
    if (!cannotContinue) {
      continueCallback(response);
    }
    yield submitSegment('Create_Quote', { trackData: { ...data, status: response.status } });
  } catch (err) {
    reportToBugsnag(err, 'createQuote');
    yield put({
      type: warrantiesQuoteProcessTypes.CREATE_AG_WARRANTIES_QUOTE.ERROR,
      payload: err,
    });
    yield errorHandler(err);
  } finally {
    finallyCallback();
  }
}

export function* updateQuoteInfo({ payload }) {
  const { data, continueCallback, finallyCallback } = payload;
  const quoteId = yield select(getWarrantiesQuoteId);
  try {
    const response = yield call(
      warranties.updateQuoteInfo,
      {
        ...data,
      },
      quoteId
    );
    yield put({
      type: warrantiesQuoteProcessTypes.UPDATE_QUOTE_INFO.SUCCESS,
      payload: response,
    });
    continueCallback(response);
    yield submitSegment('Enter_Detail', { trackData: data });
  } catch (err) {
    reportToBugsnag(err, 'updateQuoteInfo');

    yield put({
      type: warrantiesQuoteProcessTypes.UPDATE_QUOTE_INFO.ERROR,
      payload: err,
    });
    yield errorHandler(err);
  } finally {
    finallyCallback();
  }
}

export function* getAvailableProductsRequest() {
  const quoteId = yield select(getWarrantiesQuoteId);
  try {
    const response = yield call(warranties.getAvailableProducts, quoteId);
    yield put({
      type: warrantiesQuoteProcessTypes.GET_AVAILABLE_PRODUCTS.SUCCESS,
      payload: response,
    });
  } catch (err) {
    reportToBugsnag(err, 'getAvailableProducts');

    yield put({
      type: warrantiesQuoteProcessTypes.GET_AVAILABLE_PRODUCTS.ERROR,
      payload: err,
    });
    yield errorHandler(err);
  }
}

export function* selectProduct({ payload }) {
  const { data, continueCallback, finallyCallback } = payload;
  const quoteId = yield select(getWarrantiesQuoteId);
  try {
    const response = yield call(warranties.selectProduct, data, quoteId);
    yield put({
      type: warrantiesQuoteProcessTypes.SELECT_PRODUCT.SUCCESS,
      payload: { ...response },
    });
    continueCallback(response);
    const storeActiveProduct = yield select(getProductData);
    const activeProduct = { ...storeActiveProduct, ...data };
    delete activeProduct.availableProducts;
    yield submitSegment('Select_Product', { trackData: activeProduct });
  } catch (err) {
    reportToBugsnag(err, 'selectProduct');
    yield put({
      type: warrantiesQuoteProcessTypes.SELECT_PRODUCT.ERROR,
      payload: err,
    });
    yield errorHandler(err);
  } finally {
    finallyCallback();
  }
}

export function* getQuoteInfoByToken({ payload }) {
  const token = payload?.token;
  try {
    const response = yield call(warranties.getQuoteInfoByToken, token);

    const transferData = transformQuoteVariable(response);

    yield put({
      type: warrantiesQuoteProcessTypes.STORE_QUOTE_INFO,
      payload: transferData,
    });
    yield put({
      type: warrantiesQuoteProcessTypes.GET_QUOTE_INFO_BY_TOKEN.SUCCESS,
    });
  } catch (err) {
    reportToBugsnag(err, 'getQuoteInfoByToken');
    yield put({
      type: warrantiesQuoteProcessTypes.GET_QUOTE_INFO_BY_TOKEN.ERROR,
      payload: err,
    });
    yield errorHandler(err);
  }
}

export function* getClientSecret() {
  const quoteId = yield select(getWarrantiesQuoteId);
  try {
    const response = yield call(warranties.getClientSecret, quoteId);
    yield put({
      type: warrantiesQuoteProcessTypes.GET_CLIENT_SECRET.SUCCESS,
      payload: response,
    });
  } catch (err) {
    reportToBugsnag(err, 'getClientSecret');
    yield put({
      type: warrantiesQuoteProcessTypes.GET_CLIENT_SECRET.ERROR,
      payload: err,
    });
    yield errorHandler(err);
  }
}

export function* submitPayment({ payload }) {
  const { data, resolve, reject } = payload;
  const quoteId = yield select(getWarrantiesQuoteId);
  try {
    const response = yield call(warranties.submitPayment, data, quoteId);
    if (response.userJWT) {
      const token = response.userJWT;
      const accessToken = getCookie('access_token');
      if (!accessToken) {
        setCookie('access_token', token);
      }
      yield put(userLoginSuccess({ token, redirect: '/car-warranties/payment' }));
    }
    if (!response.paid) {
      if (response.status === 'requires_action') {
        resolve(response);
      } else {
        yield put(
          showToast({
            message: `Payment failed. Please try again`,
            kind: 'error',
          })
        );
      }
    }
    if (response.paid) {
      resolve(response);
      yield put({
        type: warrantiesQuoteProcessTypes.SUBMIT_PAYMENT.SUCCESS,
      });
    } else {
      reject(response);
    }
    yield submitSegment('Payment', {
      trackData: {
        paid: response.paid,
      },
    });
  } catch (err) {
    reportToBugsnag(err, 'submitPayment');
    reject(err);
    yield put({
      type: warrantiesQuoteProcessTypes.SUBMIT_PAYMENT.ERROR,
      payload: err,
    });
    yield errorHandler(err);
  }
}

export function* checkPayment({ payload }) {
  const { resolve, reject } = payload;
  const quoteId = yield select(getWarrantiesQuoteId);
  try {
    const response = yield call(warranties.checkPayment, quoteId);
    if (response.paid) {
      resolve(response);
    } else {
      reject(response);
    }
  } catch (err) {
    reportToBugsnag(err, 'checkPayment');
    reject(err);
    yield put({
      type: warrantiesQuoteProcessTypes.CHECK_PAYMENT.ERROR,
      payload: err,
    });
    yield errorHandler(err);
  }
}

export function* requireCustomQuote({ payload }) {
  const { data, continueCallback, finallyCallback } = payload;

  try {
    const response = yield call(warranties.requireCustomQuote, data);
    continueCallback(response);
    yield put({
      type: warrantiesQuoteProcessTypes.REQUIRE_CUSTOM_QUOTE.SUCCESS,
    });
  } catch (err) {
    reportToBugsnag(err, 'requireCustomQuote');
    finallyCallback(err);
    yield put({
      type: warrantiesQuoteProcessTypes.REQUIRE_CUSTOM_QUOTE.ERROR,
      payload: err,
    });
    yield errorHandler(err);
  }
}

export default [
  takeEvery(warrantiesQuoteProcessTypes.CREATE_AG_WARRANTIES_QUOTE.REQUEST, createQuote),
  takeEvery(warrantiesQuoteProcessTypes.UPDATE_QUOTE_INFO.REQUEST, updateQuoteInfo),
  takeEvery(warrantiesQuoteProcessTypes.GET_AVAILABLE_PRODUCTS.REQUEST, getAvailableProductsRequest),
  takeEvery(warrantiesQuoteProcessTypes.SELECT_PRODUCT.REQUEST, selectProduct),
  takeEvery(warrantiesQuoteProcessTypes.GET_QUOTE_INFO_BY_TOKEN.REQUEST, getQuoteInfoByToken),
  takeEvery(warrantiesQuoteProcessTypes.GET_CLIENT_SECRET.REQUEST, getClientSecret),
  takeEvery(warrantiesQuoteProcessTypes.SUBMIT_PAYMENT.REQUEST, submitPayment),
  takeEvery(warrantiesQuoteProcessTypes.CHECK_PAYMENT.REQUEST, checkPayment),
  takeEvery(warrantiesQuoteProcessTypes.REQUIRE_CUSTOM_QUOTE.REQUEST, requireCustomQuote),
];
