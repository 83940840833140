import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getScrappedCarsStatus, getActionQuotePreferTime } from 'store/selectors/scrappedCars';
import { STATUS } from 'store/constants';
import BookingJobSlot from 'components/BookingJobSlot';
import { getBookingSlots } from 'store/selectors/quote';

import { Formik, Form } from 'formik';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { modalClose } from 'store/actions/modal';
import { amendQuoteCollectionRequest } from 'store/actions/scrappedCars';

const AmendCollectionPopup = ({ isManual }) => {
  const dispatch = useDispatch();
  const bookingSlots = useSelector(getBookingSlots);
  const oldDateTime = useSelector(getActionQuotePreferTime);
  AmendCollectionPopup.defaultProps.preferredCollectionDateTime = oldDateTime;
  const closeModal = () => dispatch(modalClose());

  const amendQuote = ({ preferredCollectionDateTime: { date, startTime, endTime, slotDefaultType } }) => {
    dispatch(amendQuoteCollectionRequest({ date, startTime, endTime, slotDefaultType }));
  };
  const status = useSelector(getScrappedCarsStatus);

  const description = isManual
    ? '<strong>Select your desired collection slot</strong>'
    : '<strong>Choose your collection time</strong>';
  return (
    <Formik
      enableReinitialize={false}
      initialValues={AmendCollectionPopup.defaultProps}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        amendQuote(values);
      }}
      render={({ values, isSubmitting, setFieldValue }) => (
        <Form name="confirm-with-reason-pop-up">
          <header className="base-confirmation-popup__header-block">
            {values.title && (
              <h1 className="base-confirmation-popup__title" dangerouslySetInnerHTML={{ __html: values.title }} />
            )}
            {description && (
              <div className="base-confirmation-popup__description" dangerouslySetInnerHTML={{ __html: description }} />
            )}
          </header>
          <div className="select-booking-slot-pop-up-slots">
            <BookingJobSlot
              title=""
              name="preferredCollectionDateTime"
              value={values.preferredCollectionDateTime}
              values={bookingSlots}
              originCollectionDate={oldDateTime}
              setFieldValue={setFieldValue}
              error=""
              className="pb-3 pt-4"
            />
          </div>

          <div className="amend-collection-popup__diving-line" />
          <ButtonGroup className="base-confirmation-popup__buttons">
            <Button
              disabled={values.isConfirmButtonDisabled || isSubmitting}
              className="base-confirmation-popup__button__primary"
              type="submit"
            >
              {values.confirmTitle}
            </Button>
            <Button onClick={closeModal} className="base-confirmation-popup__button__secondary">
              {values.rejectTitle}
            </Button>
          </ButtonGroup>
        </Form>
      )}
    />
  );
};

AmendCollectionPopup.defaultProps = {
  preferredCollectionDateTime: '',
  title: '<strong>Amend collection</strong>',
  confirmTitle: 'Ok',
  rejectTitle: 'Cancel',
  isConfirmButtonDisabled: false,
  isManual: false,
};

export default AmendCollectionPopup;
