import { createSelector } from 'reselect';
import { STATUS } from '../constants';
import cookie from 'js-cookie';

const getCart = state => state.numberPlateCart;
const getInitOptions = state => state.numberPlateCart_init;

export const getRegistrationSelector = createSelector(getCart, cart => cart.registration);

// this should be updated once solor api return formatted registration from magento (confirmed with cam)
export const getFormattedRegSelector = createSelector(getCart, cart => {
  const text = cart.registration.toUpperCase();
  const length = text?.length || 0;
  const category = cookie.get('currentPlateType');
  let result = '';
  if (category === 'cherished') {
    const numberIndex = text.search(/[0-9]/);
    const letterIndex = text.search(/[A-Z]/);
    result =
      numberIndex !== 0
        ? `${text.slice(0, numberIndex)} ${text.slice(numberIndex)}`
        : `${text.slice(0, letterIndex)} ${text.slice(letterIndex)}`;
  } else if (category === 'suffix') {
    result = `${text.slice(0, 3)} ${text.slice(3)}`;
  } else if (category === 'prefix') {
    result = `${text.slice(0, length - 3)} ${text.slice(length - 3)}`;
  } else {
    switch (length) {
      case 5:
        result = `${text.slice(0, 2)} ${text.slice(2)}`;
        break;
      case 6:
        result = `${text.slice(0, 3)} ${text.slice(3)}`;
        break;
      case 7:
        result = `${text.slice(0, 4)} ${text.slice(4)}`;
        break;
      default:
        result = text;
        break;
    }
  }
  return result;
});

export const getMinimumYearSelector = createSelector(getCart, cart => cart.minimumYear);
export const getNavStatus = createSelector(getCart, cart => cart.navStatus);
export const getCartId = createSelector(getCart, cart => cart.cartId);
export const getCheckoutBusySelector = createSelector(getCart, cart => cart.status === STATUS.RUNNING);

export const getCheckNumStatusSelector = createSelector(getCart, cart => cart.checkNumberStatus);
export const manuallyEnterSelector = createSelector(getCart, cart => cart.personalDetail.isManualAddress);
export const getPersonalDetailSelector = createSelector(getCart, cart => cart.personalDetail);
export const getCheckRegResultSelector = createSelector(getCart, cart => cart.checkNumberDescription);

export const getCustomizeOptionSelector = createSelector(getInitOptions, cart => cart.customizeOption);
export const getCustomDataSelector = createSelector(getCart, cart => cart.customise);

export const getTransferDeliveryOptionSelector = createSelector(getInitOptions, cart => cart.transferDeliveryOption);
export const getTransferDeliverySelector = createSelector(getCart, cart => cart.transferDelivery);

export const getSummarySelector = createSelector([getCart, getInitOptions], (cart, summary) => ({
  personalDetail: cart.personalDetail,
  checkNumberDescription: cart.checkNumberDescription,
  priceItem: summary.priceItem,
  currentStep: summary.currentStep,
  buyPhysicalPlate: summary.buy_physical_plate,
}));

export const getInclusiveTotal = createSelector(getInitOptions, cart => cart?.priceItem?.total);

export const getCustomPlateSelector = createSelector(getInitOptions, cart => cart.customPlate);

export const getHasAccountSelector = createSelector(getInitOptions, cart => cart.hasAccount);

export const getInfoUpdated = createSelector(getInitOptions, cart => cart.infoUpdated);

// payment
export const getPaymentInfo = createSelector(getCart, cart => cart.payment);

export const getCustomerCardsSelector = createSelector(getInitOptions, cart => cart.cards);

export const getNeedLoginSelector = createSelector(getInitOptions, cart => cart.needLogin);
