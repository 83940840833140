import immutable from 'immutability-helper';
import normalize from 'json-api-normalizer';
import * as immutabilityCommands from '../schemas/cmsImmutabilityCommands';

const {
  DEFAULT_MISSING_COMMAND,
  DEFAULT_OBJECT_COMMAND,
  DEFAULT_ARRAY_COMMAND,
  ...defaultCommandMapping
} = immutabilityCommands;

const mapCommand = (state, payload, key, mapping) => {
  let command = DEFAULT_MISSING_COMMAND;
  if (state[key] && payload[key] instanceof Object) {
    if (mapping[key]) {
      command = mapping[key];
    } else if (payload[key] instanceof Array) {
      command = DEFAULT_ARRAY_COMMAND;
    } else {
      command = DEFAULT_OBJECT_COMMAND;
    }
  }
  return command;
};

export const wrapWith = (wrapper, payload) => {
  let out = payload;
  if (wrapper) {
    out = { [wrapper]: payload };
  }
  return out;
};

export const extractStoreIds = (data, path, links) => {
  let payload;

  if (data instanceof Array) {
    payload = { byId: data.map(item => item.id), links };
  } else {
    payload = { byPath: { [path]: data.id } };
  }
  return payload;
};

export const immutablySetOrMerge = (state, payload, acc = {}, commandMapping = defaultCommandMapping) =>
  Object.keys(payload).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [key]: { [mapCommand(state, payload, key, commandMapping)]: payload[key] },
    }),
    acc,
  );

export const immutablySetOrMergeDeeply = (
  state,
  payload,
  acc = {},
  depth = 1,
  commandMapping = defaultCommandMapping,
) => {
  const reducer = (accumulator, key) => {
    const shallRecurse = depth > 0 && state[key];
    const value = !shallRecurse
      ? payload[key]
      : immutable(state[key], immutablySetOrMergeDeeply(state[key], payload[key], {}, depth - 1, commandMapping));
    return { ...accumulator, [key]: { [mapCommand(state, payload, key, commandMapping)]: value } };
  };
  return Object.keys(payload).reduce(reducer, acc);
};

const mergeToState = (state, payload) => {
  const { links } = payload;
  const normalized = normalize(payload);
  const update = immutablySetOrMerge(state, normalized, {
    mediaIsLoading: { [DEFAULT_MISSING_COMMAND]: false },
  });
  update.links = { [DEFAULT_MISSING_COMMAND]: links };
  return immutable(state, update);
};

export default mergeToState;
