import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import { immutablySetOrMergeDeeply } from '../lib/mergeToStateHelper';
import * as scrapMyCarActions from '../actions/scrapMyCar';
import * as immutabilityCommands from '../schemas/cmsImmutabilityCommands';

const {
  DEFAULT_MISSING_COMMAND,
  DEFAULT_OBJECT_COMMAND,
  DEFAULT_ARRAY_COMMAND,
  ...defaultCommandMapping
} = immutabilityCommands;

const initialState = {
  scrapMyCarIsLoading: false,
  scrapMyCarError: null,
  scrapMyCarPage: {},
  settings: {},
  manufacturers: {},
  allManufacturers: {},
  byPath: {},
  medias: [],
};

export default {
  scrapMyCar: handleActions(
    {
      [combineActions(
        scrapMyCarActions.getScrapMyCarByTypeRequest,
        scrapMyCarActions.getScrapMyCarByPathRequest,
        scrapMyCarActions.getScrapMyCarSettingsRequest,
        scrapMyCarActions.getScrapMyCarManufacturersRequest,
        scrapMyCarActions.getNearestLocationsRequest,
        scrapMyCarActions.getScrapMyCarMediasRequest,
      )]: state =>
        immutable(state, {
          scrapMyCarIsLoading: { $set: true },
          scrapMyCarError: { $set: null },
        }),

      [combineActions(
        scrapMyCarActions.getScrapMyCarByTypeSuccess,
        scrapMyCarActions.getScrapMyCarByPathSuccess,
        scrapMyCarActions.getScrapMyCarSettingsSuccess,
        scrapMyCarActions.getScrapMyCarManufacturersSuccess,
        scrapMyCarActions.getNearestLocationsSuccess,
        scrapMyCarActions.getScrapMyCarMediasSuccess,
      )]: (state, { payload }) => {
        const update = immutablySetOrMergeDeeply(state, payload, {
          scrapMyCarIsLoading: { $set: false },
          scrapMyCarError: { $set: null },
        });
        return immutable(state, update);
      },

      [scrapMyCarActions.getNearestLocationsSuccess]: (state, { payload }) => {
        const mapping = { ...defaultCommandMapping, byId: '$set' };
        const update = immutablySetOrMergeDeeply(
          state,
          payload,
          {
            faqIsLoading: { $set: false },
            faqError: { $set: null },
          },
          1,
          mapping,
        );
        return immutable(state, update);
      },

      [combineActions(
        scrapMyCarActions.getScrapMyCarByTypeError,
        scrapMyCarActions.getScrapMyCarByPathError,
        scrapMyCarActions.getScrapMyCarSettingsError,
        scrapMyCarActions.getScrapMyCarManufacturersError,
        scrapMyCarActions.getNearestLocationsError,
        scrapMyCarActions.getScrapMyCarMediasError,
      )]: (state, { payload }) =>
        immutable(state, {
          scrapMyCarIsLoading: { $set: false },
          scrapMyCarError: { $set: payload },
        }),
    },
    initialState,
  ),
};
