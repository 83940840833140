import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import { immutablySetOrMergeDeeply } from '../lib/mergeToStateHelper';
import * as homePageActions from '../actions/homePageV2';

const initialState = {
  medias: [],
};

export default {
  homePageV2: handleActions(
    {
      [combineActions(homePageActions.getHomePageMediasV2Request)]: state =>
        immutable(state, {
          homePageIsLoading: { $set: true },
          homePageError: { $set: null },
        }),

      [combineActions(homePageActions.getHomePageMediasV2Success)]: (state, { payload }) => {
        const update = immutablySetOrMergeDeeply(state, payload, {
          homePageIsLoading: { $set: false },
          homePageError: { $set: null },
        });
        return immutable(state, update);
      },

      [combineActions(homePageActions.getHomePageMediasV2Error)]: (state, { payload }) =>
        immutable(state, {
          homePageIsLoading: { $set: false },
          homePageError: { $set: payload },
        }),
    },
    initialState,
  ),
};
