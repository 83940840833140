import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Formik, Form } from 'formik';
import { TextAreaFormGroup } from 'components/form';
import { modalClose } from 'store/actions/modal';

const ConfirmationWithReasonPopup = ({
  title,
  description,
  confirmTitle,
  rejectTitle,
  confirmAction,
  isConfirmButtonDisabled,
}) => {
  const dispatch = useDispatch();

  const closeModal = () => dispatch(modalClose());

  return (
    <Formik
      enableReinitialize={false}
      initialValues={ConfirmationWithReasonPopup.defaultProps}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        confirmAction(values);
      }}
      render={({ values, handleChange, handleBlur, isSubmitting }) => (
        <Form name="confirm-with-reason-pop-up">
          <div>
            <header className="base-confirmation-popup__header-block">
              {title && <h1 className="base-confirmation-popup__title" dangerouslySetInnerHTML={{ __html: title }} />}
              {description && (
                <div
                  className="base-confirmation-popup__description"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </header>

            <div className="confirm-with-reason-popup-reason">
              <TextAreaFormGroup
                id="reason"
                rounded
                value={values['reason']}
                name="reason"
                placeholder="please enter reason here"
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>

            <div className="base-confirmation-popup__diving-line" />
            <ButtonGroup className="base-confirmation-popup__buttons">
              <Button
                disabled={isConfirmButtonDisabled || isSubmitting}
                className="base-confirmation-popup__button__primary"
                type="submit"
              >
                {confirmTitle}
              </Button>
              <Button onClick={closeModal} className="base-confirmation-popup__button__secondary">
                {rejectTitle}
              </Button>
            </ButtonGroup>
          </div>
        </Form>
      )}
    />
  );
};

ConfirmationWithReasonPopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  confirmTitle: PropTypes.string,
  rejectTitle: PropTypes.string,
  confirmAction: PropTypes.func.isRequired,
  isConfirmButtonDisabled: PropTypes.bool,
};

ConfirmationWithReasonPopup.defaultProps = {
  title: '',
  description: '',
  confirmTitle: 'Yes',
  rejectTitle: 'No',
  isConfirmButtonDisabled: false,
  reason: '',
};

export default ConfirmationWithReasonPopup;
