import { createSelector } from 'reselect';
import * as defaultSchemas from '../schemas/cmsDenormalizer';
import { snakeToCamelCase } from '../lib/snakeToCamelCase';

export const getCms = state => state.cms;
export const getLinks = state => state.cms.links;

const makeExtractRelationshipByTypeId = store => ({ id, type }) => (store[type] && store[type][id]) || {};
const makeApplySchema = schemas => entity => (schemas[entity.type] ? schemas[entity.type](entity) : entity);
const makeApplyKeySchema = schemas => (key, entity) => {
  let out = key;
  const { [key]: newKey } = schemas;
  if (newKey) {
    if (typeof newKey === 'string') {
      out = newKey;
    } else if (typeof newKey === 'function') {
      out = newKey(entity);
    }
  }
  return out;
};
const makeGetRelationships = extractRelationshipsByTypeId => {
  const { keyMapping, ...defSchemas } = defaultSchemas;
  const applySchema = makeApplySchema(defSchemas);
  const applyKeySchema = makeApplyKeySchema(keyMapping);
  const getRelationships = (entity, dejaVu = {}, depth = 10) => {
    let output = {};
    if (entity) {
      const { attributes, links, id, type, relationships } = entity;
      output = { id, type, links, ...attributes };
      const dejaVuKey = id;
      if (depth === 0) {
        return output;
      }
      if (dejaVu[dejaVuKey]) {
        return dejaVu[dejaVuKey];
      }
      if (dejaVuKey) {
        // I need to pass a reference for this recursion
        // eslint-disable-next-line no-param-reassign
        dejaVu[dejaVuKey] = output;
      }

      if (relationships) {
        const keys = Object.keys(relationships);
        output = Object.values(relationships).reduce((accumulator, relationship, index) => {
          const name = applyKeySchema(keys[index], relationship);
          let out = { ...accumulator };
          if (keys[index] !== 'parent' && relationship && relationship.data) {
            const { data } = relationship;
            const myDepth = depth - 1;
            out = {
              ...accumulator,
              [name]:
                data instanceof Array
                  ? data.map(item => getRelationships(extractRelationshipsByTypeId(item), dejaVu, myDepth))
                  : getRelationships(extractRelationshipsByTypeId(data), dejaVu, myDepth),
            };
          }
          return out;
        }, output);
      }
      output = applySchema(output);
      if (dejaVuKey) {
        // I need to pass a reference for this recursion
        // eslint-disable-next-line no-param-reassign
        dejaVu[dejaVuKey] = output;
      }
    }
    return output;
  };
  return getRelationships;
};

export const makeGetCmsByType = type =>
  createSelector(
    [getCms],
    cms => {
      const getRelationshipByTypeId = makeExtractRelationshipByTypeId(cms);
      const getRelationships = makeGetRelationships(getRelationshipByTypeId);
      if (cms[type]) {
        return Object.values(cms[type])
          .map(item => getRelationships(item))
          .reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
      }
      return {};
    },
  );

export const makeGetIndividual = () =>
  createSelector(
    [getCms],
    media => {
      const getRelationshipByTypeId = makeExtractRelationshipByTypeId(media);
      const getRelationships = makeGetRelationships(getRelationshipByTypeId);
      if (media.individual) {
        const { type, id } = media.individual;
        const dataSource = type && id && media[snakeToCamelCase(type)];
        return dataSource && id && getRelationships(dataSource[id]);
      }
      return {};
    },
  );
