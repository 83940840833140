import { all, put, take, takeLatest, takeEvery, select, call } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { reportToBugsnag} from 'lib/bugsnag';
// import { redirect } from 'lib/routes';
import {
  SORT_BY_DATE_DESCENDING,
  MEDIA_TYPES,
  MEDIA_CENTER_LIMIT,
  MEDIA_CENTER_OVERLAY_ITEMS_COUNT,
  MEDIA_CENTER_SECTION_ITEMS_COUNT,
  SOME_ERROR_OCCURRED
} from '../constants';
import MediaTypes from '../action-types/media';
import CmsTypes from '../action-types/cms';
import { wrapWith, extractStoreIds } from '../lib/mergeToStateHelper';
import uuid from '../lib/uuidv4-builder';
import { getCategories as selectCategories } from '../selectors/media';
import { subRequestCms } from './cms';

function* fetchCategoryHasContentsByType(categories = [], chan) {
  const fingerprint = 'categoriesByType';
  const multiHeaders = { 'Cache-Control': 'no-cache' };
  try {
    const multiPayload = categories.reduce(
      (accumulator, cat) => [
        ...accumulator,
        ...MEDIA_TYPES.map(type => ({
          key: `${cat.id}_${type}`,
          prefix: type,
          includes: [],
          limit: { limit: 1 },
          filter: { '[category.id][value]': cat.id },
        })),
      ],
      [],
    );
    const data = yield subRequestCms({
      type: CmsTypes.MULTI_FETCH_CMS.REQUEST,
      payload: multiPayload,
      headers: multiHeaders,
      fingerprint,
      chan,
    });
    const dataByType = categories.reduce(
      (accumulatorCat, cat) => ({
        ...accumulatorCat,
        [cat.id]: MEDIA_TYPES.reduce((accumulator, type) => {
          const {
            payload: { [`${cat.id}_${type}`]: typeData },
          } = data;
          return { ...accumulator, [type]: !!typeData.links.next || typeData.data.length > 0 };
        }, {}),
      }),
      {},
    );
    yield put({
      type: MediaTypes.CATEGORIES_CONTENT_BY_TYPE,
      payload: dataByType,
    });
  } catch (err) {
    reportToBugsnag(err, 'fetchCategoryHasContentsByType');


  }
}

export function* getCategories() {
  const chan = yield call(channel);
  const STORE_AS = 'allCategories';
  try {
    yield put({
      mediaTypes: MEDIA_TYPES,
      type: CmsTypes.FETCH_TAXONOMY_CATEGORIES.REQUEST,
      chan,
    });
    const {
      payload: { data, links, type },
      path,
    } = yield take(chan);
    yield put({
      type: MediaTypes.GET_MEDIA_CATEGORIES.SUCCESS,
      payload: wrapWith(STORE_AS, wrapWith(type, extractStoreIds(data, path, links))),
    });
    yield call(fetchCategoryHasContentsByType, data, chan);
  } catch (err) {
    reportToBugsnag(err, 'getCategories');


    yield put({
      type: MediaTypes.GET_MEDIA_CATEGORIES.ERROR,
      payload: err,
    });
  }
}

export function* getRelated({ payload: { category, type, id }, chan }) {
  const STORE_AS = 'related';
  // const chan = yield call(channel);
  const fingerprint = 'relatedRequest';
  const RELATED_TARGET_COUNT = 3;
  const MULTI_RELATED_KEYS = ['categoryRelated', 'globalRelated'];
  const [CATEGORY_RELATED, GLOBAL_RELATED] = MULTI_RELATED_KEYS;
  const excludeFilter = {
    '[excluded][condition][path]': 'id',
    '[excluded][condition][operator]': '%3C%3E',
    '[excluded][condition][value]': id,
  };

  const multiPayload = [
    {
      key: CATEGORY_RELATED,
      id: null,
      prefix: type,
      limit: { limit: RELATED_TARGET_COUNT },
      sort: SORT_BY_DATE_DESCENDING,
      filter: { ...excludeFilter, '[category.id][value]': category },
    },
    {
      key: GLOBAL_RELATED,
      id: null,
      prefix: type,
      filter: {
        '[global-cat][group][conjunction]': 'AND',
        ...excludeFilter,
        '[excluded][condition][memberOf]': 'global-cat',
        '[excluded-cat][condition][path]': 'category.id',
        '[excluded-cat][condition][operator]': '%3C%3E',
        '[excluded-cat][condition][value]': category,
        '[excluded-cat][condition][memberOf]': 'global-cat',
      },
      limit: { limit: RELATED_TARGET_COUNT },
      sort: SORT_BY_DATE_DESCENDING,
    },
  ];

  try {
    const { payload: data } = yield subRequestCms({
      type: CmsTypes.MULTI_FETCH_CMS.REQUEST,
      payload: multiPayload,
      fingerprint,
      chan,
    });

    const actions = Object.values(data).map(actionPayload =>
      put({
        type: CmsTypes.FETCH_CMS.SUCCESS,
        payload: actionPayload,
      }),
    );

    yield all(actions);

    const { data: globalRel } = data[GLOBAL_RELATED] || {};
    const { data: catRel } = data[CATEGORY_RELATED] || {};
    let out = catRel;

    if (catRel instanceof Array && catRel.length < RELATED_TARGET_COUNT) {
      out = [...catRel, ...globalRel].slice(0, RELATED_TARGET_COUNT);
    }

    const outActions = Object.keys(data).map(key => {
      const actionPayload =
        key === GLOBAL_RELATED
          ? extractStoreIds(globalRel, null, null)
          : wrapWith(category, extractStoreIds(out, null, null));
      return put({
        type: MediaTypes.GET_RELATED.SUCCESS,
        payload: wrapWith(STORE_AS, wrapWith(type, actionPayload)),
      });
    });

    return yield all(outActions);
  } catch (err) {
    reportToBugsnag(err, 'getRelated');


    yield put({
      type: MediaTypes.GET_RELATED.ERROR,
      payload: err,
    });
  }
}

export function* getMedia({ payload: { category, filter, ...payloadIn } }) {
  const chan = yield call(channel);
  const fingerprint = uuid();
  try {
    const categoryFilter = {};
    if (category) {
      let categories = yield select(selectCategories);
      if (categories.length === 0) {
        yield take(getCategories);
        yield take(MediaTypes.GET_MEDIA_CATEGORIES.SUCCESS);
        categories = yield select(selectCategories);
      }
      const foundCat = categories.find(item => item.url.match(new RegExp(`${category}$`, 'ig')));
      if (foundCat) {
        categoryFilter['[category.id][value]'] = foundCat.id;
      } else {
        categoryFilter['[category.name][value]'] = category;
      }
    }
    const payload = { ...payloadIn, filter: { ...filter, ...categoryFilter } };
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload,
      fingerprint,
      chan,
    });

    const {
      payload: { data, links, type },
      path,
    } = yield take(chan); // ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp);

    const toStoreData = extractStoreIds(data, path, links);
    const toStore = category ? wrapWith(category, toStoreData) : toStoreData;

    const realEnd = Object.keys(toStore)[0];

    if (toStore && realEnd && toStore[realEnd] && toStore[realEnd].byId && toStore[realEnd].byId.length <= 0) {
      redirect({}, '/not-found');
    }

    yield put({
      type: MediaTypes.GET_MEDIA.SUCCESS,
      payload: wrapWith(type, toStore),
    });
  } catch (err) {
    reportToBugsnag(err, 'getMedia');


    yield put({
      type: MediaTypes.GET_MEDIA.ERROR,
      payload: err,
    });
  }
}

export function* getMediaCentre() {
  const STORE_AS = 'mediaCentre';
  const chan = yield call(channel);
  const fingerprint = uuid();
  const requests = MEDIA_TYPES.map(type => ({
    key: type,
    id: null,
    prefix: type,
    limit: { limit: MEDIA_CENTER_LIMIT },
    includes: ['media.file', 'author', 'category', 'poster.file', 'media.thumbnail'],
    sort: SORT_BY_DATE_DESCENDING,
  }));
  const sortMediaByDate = ({ attributes: A }, { attributes: B }) => {
    let out = 0;
    const dateA = new Date(A.date);
    const dateB = new Date(B.date);
    if (dateA < dateB) {
      out = 1;
    }
    if (dateA > dateB) {
      out = -1;
    }
    return out;
  };

  try {
    yield put({
      type: CmsTypes.MULTI_FETCH_CMS.REQUEST,
      payload: requests,
      fingerprint,
      chan,
    });
    const { payload: allSections } = yield take(chan);
    //   ({ type: t, fingerprint: fp }) => t === CmsTypes.MULTI_FETCH_CMS.SUCCESS && fingerprint === fp,
    // );

    const sectionsArray = Object.values(allSections);
    const mediaActions = sectionsArray.map(data =>
      put({
        type: CmsTypes.FETCH_CMS.SUCCESS,
        payload: data,
      }),
    );
    yield all(mediaActions);
    const topSection = sectionsArray
      .reduce((accumulator, { data }) => [...accumulator, ...data], [])
      .sort(sortMediaByDate)
      .slice(0, MEDIA_CENTER_OVERLAY_ITEMS_COUNT);
    const output = sectionsArray.reduce((accumulator, { data, links }) => {
      let out = accumulator;
      if (data instanceof Array && data.length > 0) {
        const { type } = data[0];
        let filteredTypeData = data;
        const topSectionIds = topSection.filter(({ type: t }) => t === type).map(({ id }) => id);
        if (topSectionIds > 0) {
          filteredTypeData = data.filter(({ id }) => topSectionIds.indexOf(id) > -1);
        }
        out = {
          ...accumulator,
          ...wrapWith(
            type,
            extractStoreIds(filteredTypeData.slice(0, MEDIA_CENTER_SECTION_ITEMS_COUNT), undefined, links),
          ),
        };
      }
      return out;
    }, {});
    output.topSection = topSection.map(({ type, id }) => ({ type, id }));
    yield put({
      type: MediaTypes.GET_MEDIA_CENTRE.SUCCESS,
      payload: wrapWith(STORE_AS, output),
    });
  } catch (err) {
    reportToBugsnag(err, 'getMediaCentre');

    yield put({
      type: MediaTypes.GET_MEDIA_CENTRE.ERROR,
      payload: err,
    });
  }
}

export function* getIndividualByPath({ payload }) {
  const chan = yield call(channel);
  const fingerprint = uuid();
  try {
    yield put({
      type: CmsTypes.RESOLVE_CMS_ENTITY.REQUEST,
      payload,
      fingerprint,
      chan,
    });
    const {
      payload: { data },
      path,
    } = yield take(chan); // ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS_INDIVIDUAL.SUCCESS && fingerprint === fp);
    const {
      id,
      type,
      relationships: {
        category: {
          data: { id: category },
        },
      },
    } = data;
    yield put({
      type: MediaTypes.GET_INDIVIDUAL_BY_PATH.SUCCESS,
      payload: extractStoreIds(data, path),
    });
    yield call(getRelated, { payload: { category, type, id }, chan });
  } catch (err) {
    reportToBugsnag(err, 'getIndividualByPath');


    yield put({
      type: MediaTypes.GET_INDIVIDUAL_BY_PATH.ERROR,
      payload: err,
    });
  }
}

export default [
  takeEvery(MediaTypes.GET_MEDIA.REQUEST, getMedia),
  takeLatest(MediaTypes.GET_RELATED.REQUEST, getRelated),
  takeLatest(MediaTypes.GET_INDIVIDUAL_BY_PATH.REQUEST, getIndividualByPath),
  takeLatest(MediaTypes.GET_MEDIA_CENTRE.REQUEST, getMediaCentre),
  takeLatest(MediaTypes.GET_MEDIA_CATEGORIES.REQUEST, getCategories),
];
