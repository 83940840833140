import { createActions } from 'redux-actions';
import CarTaxTypes from '../action-types/carTax';

export const {
  getCarTaxSettingsRequest,
  getCarTaxSettingsSuccess,
  getCarTaxSettingsError,

  getCarTaxQuotesRequest,
  getCarTaxQuotesSuccess,
  getCarTaxQuotesError,

  getCarTaxByPathRequest,
  getCarTaxByPathSuccess,
  getCarTaxByPathError,

  waitingTaxContent,
} = createActions({
  [CarTaxTypes.GET_CAR_TAX_SETTINGS.REQUEST]: payload => payload,
  [CarTaxTypes.GET_CAR_TAX_SETTINGS.SUCCESS]: payload => payload,
  [CarTaxTypes.GET_CAR_TAX_SETTINGS.ERROR]: payload => payload,

  [CarTaxTypes.GET_CAR_TAX_QUOTES.REQUEST]: payload => payload,
  [CarTaxTypes.GET_CAR_TAX_QUOTES.SUCCESS]: payload => payload,
  [CarTaxTypes.GET_CAR_TAX_QUOTES.ERROR]: payload => payload,

  [CarTaxTypes.GET_CAR_TAX_BY_PATH.REQUEST]: payload => payload,
  [CarTaxTypes.GET_CAR_TAX_BY_PATH.SUCCESS]: payload => payload,
  [CarTaxTypes.GET_CAR_TAX_BY_PATH.ERROR]: payload => payload,

  [CarTaxTypes.WAITING_TAX_CONTENT]: payload => payload,
});
