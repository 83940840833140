export const CMS_GENERIC_LIST_INCLUDES = ['media.file', 'author', 'category', 'poster.file', 'media.thumbnail'];
export const CMS_GENERIC_INDIVIDUAL_INCLUDES = [
  'author.image.file',
  'category',
  'paragraphs.image.file',
  'paragraphs.video.file',
  'paragraphs.video.thumbnail',
  'media.file',
  'media.thumbnail',
  'paragraphs.images',
  'paragraphs.images.file',
  'paragraphs.images.thumbnail',
];

export default class CMS {
  constructor(client) {
    this.client = client;
  }

  addIncludes(includes) {
    const out = [];
    if (includes instanceof Array && includes.length > 0) {
      out.push(`include=${includes.join(',')}`);
    }
    return out;
  }

  addLimits(limit = {}) {
    const out = [];
    const { limit: lim, offset } = limit;
    if (lim) {
      out.push(`page[limit]=${lim}`);
    }
    if (offset) {
      out.push(`page[offset]=${offset}`);
    }
    return out;
  }

  addSort(sort) {
    const out = [];
    if (sort) {
      out.push(`sort=${sort}`);
    }
    return out;
  }

  addFilter(filter = {}) {
    let out = [];
    out = [...out, ...Object.keys(filter).map(key => `filter${key}=${filter[key]}`)];
    return out;
  }

  generateFullRequestPath(
    prefix,
    id,
    includes = CMS_GENERIC_LIST_INCLUDES,
    limit = {},
    sort,
    filter = {},
    section = 'media',
  ) {
    const qs = [
      ...this.addIncludes(includes),
      ...this.addLimits(limit),
      ...this.addSort(sort),
      ...this.addFilter(filter),
    ];
    const path = [section];
    if (prefix) {
      path.push(prefix);
    }
    if (id) {
      path.push(id);
    }
    return `/${path.join('/')}${(qs.length > 0 && '?') || ''}${qs.join('&')}&${Math.random()}`;
  }

  requestCMS = (prefix, id, includes = CMS_GENERIC_LIST_INCLUDES, limit = {}, sort, filter = {}, section = 'media') =>
    this.client.get(this.generateFullRequestPath(prefix, id, includes, limit, sort, filter, section));

  subRequestCMS = (requests, headers = {}) => {
    const payload = requests.map(
      ({
        key,
        prefix,
        id,
        includes = CMS_GENERIC_LIST_INCLUDES,
        limit = {},
        sort,
        filter = {},
        section = 'media',
      }) => ({
        requestId: key,
        action: 'view',
        uri: `${process.env.CMS_BASE_URL}${this.generateFullRequestPath(
          prefix,
          id,
          includes,
          limit,
          sort,
          filter,
          section,
        )}`,
        headers: { Accept: 'application/vnd.api+json', ...headers },
      }),
    );
    return this.client.post('/subrequests?_format=json', payload);
  };

  callCMS = (path, includes) => {
    const qs = [...this.addIncludes(includes)];
    return this.client.get(`${path}${(qs.length > 0 && '?') || ''}${qs.join('&')}`);
  };

  fetchCategories = () => this.client.get(`/media/categories`);

  // fetchManufacturers = () => this.client.get(`/manufacturers?include=image.image`);
  fetchManufacturers = () => this.client.get(`/manufacturers?include=image`);

  resolve = (detachedURL, includes) => {
    const qs = [`path=${detachedURL}`, ...this.addIncludes(includes)];
    return this.client.get(`/path-resolver?${qs.join('&')}`);
  };
}
