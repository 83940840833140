import { call, put, take, takeLatest, all } from 'redux-saga/effects';
import { reportToBugsnag } from 'lib/bugsnag';
import { channel, END } from 'redux-saga';
import CarTaxTypes from '../action-types/carTax';
import CmsTypes from '../action-types/cms';
import { wrapWith, extractStoreIds } from '../lib/mergeToStateHelper';

export function* getSettings() {
  const fingerprint = 'carTaxSettings';
  try {
    const type = 'settings';
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: {
        section: 'car-tax',
        type,
        includes: [
          'field_top_banner',
          'field_content_item',
          'field_top_banner.image.file',
          'field_confirmation_page',
          'field_confirmation_page.items,field_login_section,field_signup_section',
          'field_options_section',
        ],
      },
      fingerprint,
    });
    const fullData = yield take(
      ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp
    );
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: CarTaxTypes.GET_CAR_TAX_SETTINGS.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    yield put({
      type: CarTaxTypes.GET_CAR_TAX_SETTINGS.ERROR,
      payload: err,
    });
    reportToBugsnag(err, 'getSettings');
  }
}

export function* getQuote() {
  const fingerprint = 'taxQuotes';
  try {
    const type = 'pages';
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: {
        section: 'car-tax',
        type,
        includes: [
          'field_paragraphs',
          'field_paragraphs.items',
          'field_paragraphs.image',
          'field_paragraphs.image.file',
          'field_paragraphs.items.image',
          'field_paragraphs.items.image.file',
          'field_paragraphs.items.media.file',
          'field_paragraphs.items.paragraphs',
          'field_paragraphs.items.paragraphs.items',
          'field_paragraphs.items.paragraphs.image',
          'field_paragraphs.items.paragraphs.image.file',
          'field_paragraphs.items.paragraphs.items.image',
          'field_paragraphs.items.paragraphs.items.image.file',
          'field_paragraphs.items.paragraphs.items.paragraphs',
          'field_paragraphs.items.paragraphs.items.media.file',
          'field_paragraphs.items.paragraphs.items.category',
          'field_paragraphs.left_col',
          'field_paragraphs.left_col.image.file',
          'field_paragraphs.right_col',
          'field_paragraphs.right_col.image.file',
          'field_paragraphs.field_logo',
          'field_paragraphs.field_logo.file',
        ],
      },
      fingerprint,
    });
    const fullData = yield take(
      ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp
    );
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: CarTaxTypes.GET_CAR_TAX_QUOTES.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    yield put({
      type: CarTaxTypes.GET_CAR_TAX_QUOTES.ERROR,
      payload: err,
    });
    reportToBugsnag(err, 'getQuote');
  }
}

export function* getIndividualByPath({ payload: { specificIncludes, ...payload } }) {
  const chan = yield call(channel);
  const { includes } = payload;
  try {
    yield put({
      type: CmsTypes.RESOLVE_CMS_ENTITY.REQUEST,
      payload,
      chan,
    });
    const {
      payload: {
        data,
        path: resourcePath,
        jsonapi: { resourceName },
      },
      path,
    } = yield take(chan);
    let outData = data;
    if (resourceName !== 'node--car_tax') {
      yield put({
        type: CmsTypes.FETCH_CMS_INDIVIDUAL.REQUEST,
        payload: { path: resourcePath, includes },
        path,
      });
      const {
        payload: { data: includedData },
      } = yield take(CmsTypes.FETCH_CMS_INDIVIDUAL.SUCCESS);
      outData = includedData;
    }
    yield put({
      type: CarTaxTypes.GET_CAR_TAX_BY_PATH.SUCCESS,
      payload: extractStoreIds(outData, path),
    });
  } catch (err) {
    yield put({
      type: CarTaxTypes.GET_CAR_TAX_BY_PATH.ERROR,
      payload: err,
    });
    reportToBugsnag(err, 'getQuote');
  }
}

export function* waitingTaxContent() {
  // Only used for server rendering
  try {
    yield all({
      settings: take(CarTaxTypes.GET_CAR_TAX_SETTINGS.SUCCESS),
      path: take(CarTaxTypes.GET_CAR_TAX_BY_PATH.SUCCESS),
    });
  } finally {
    yield put(END);
  }
}

export default [
  takeLatest(CarTaxTypes.GET_CAR_TAX_SETTINGS.REQUEST, getSettings),
  takeLatest(CarTaxTypes.GET_CAR_TAX_QUOTES.REQUEST, getQuote),
  takeLatest(CarTaxTypes.GET_CAR_TAX_BY_PATH.REQUEST, getIndividualByPath),
  takeLatest(CarTaxTypes.WAITING_TAX_CONTENT, waitingTaxContent),
];
