import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import ScrappedCarsActionsType from '../action-types/scrappedCars';
import { STATUS } from '../constants';

const DEFAULT_LIMIT = 10;
const DEFAULT_OFFSET = 0;

const initialState = {
  status: STATUS.IDLE,
  actionQuoteId: null,
  quoteHistory: {
    items: [],
    total: 0,
    limit: DEFAULT_LIMIT,
    offset: DEFAULT_OFFSET,
    isInitialized: false,
    hideQty: 0,
  },
  reQuoteVrm: '',
  activeQuotes: [],
};

export default {
  scrappedCars: handleActions(
    {
      [ScrappedCarsActionsType.SET_ACTION_QUOTE_ID]: (state, { payload: { quoteId } }) =>
        immutable(state, {
          actionQuoteId: { $set: quoteId },
        }),
      [ScrappedCarsActionsType.DELETE_QUOTE.REQUEST]: state =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
        }),
      [combineActions(
        ScrappedCarsActionsType.DELETE_QUOTE.SUCCESS,
        ScrappedCarsActionsType.CANCEL_QUOTE_MY_ACCOUNT.SUCCESS,
      )]: (state, { payload: { quoteIndex } }) =>
        immutable(state, {
          status: { $set: STATUS.SUCCESS },
          quoteHistory: {
            items: { $splice: [[quoteIndex, 1]] },
            total: { $set: state.quoteHistory.total - 1 },
            offset: { $set: state.quoteHistory.offset - 1 },
          },
        }),
      [combineActions(
        ScrappedCarsActionsType.DELETE_QUOTE.ERROR,
        ScrappedCarsActionsType.CANCEL_QUOTE_MY_ACCOUNT.ERROR,
        ScrappedCarsActionsType.AMEND_QUOTE_COLLECTION.ERROR,
      )]: state =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),

      [ScrappedCarsActionsType.AMEND_QUOTE_COLLECTION.SUCCESS]: (state, { payload: { quoteIndex, quote } }) =>
        immutable(state, {
          status: { $set: STATUS.SUCCESS },
          quoteHistory: {
            items: { $splice: [[quoteIndex, 1, quote]] },
          },
        }),
      [ScrappedCarsActionsType.GET_QUOTE_HISTORY.REQUEST]: state =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
        }),

      [ScrappedCarsActionsType.GET_QUOTE_HISTORY.SUCCESS]: (state, { payload: { items, total, hideQty } }) =>
        immutable(state, {
          status: { $set: STATUS.SUCCESS },
          quoteHistory: {
            total: { $set: total },
            offset: { $set: state.quoteHistory.items.length + state.quoteHistory.hideQty + items.length + hideQty },
            hideQty: { $set: state.quoteHistory.hideQty + hideQty },
            items: { $push: items },
            limit: { $set: 5 },
            isInitialized: { $set: true },
          },
        }),

      [ScrappedCarsActionsType.GET_ACTIVE_QUOTES.SUCCESS]: (state, { payload: { items } }) =>
        immutable(state, {
          activeQuotes: { $set: items },
        }),

      [ScrappedCarsActionsType.GET_QUOTE_HISTORY.ERROR]: state =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
          quoteHistory: {
            isInitialized: { $set: true },
          },
        }),

      [ScrappedCarsActionsType.INITIALIZE_QUOTE_HISTORY]: state =>
        immutable(state, {
          quoteHistory: {
            total: { $set: 0 },
            hideQty: { $set: 0 },
            items: { $set: [] },
            offset: { $set: DEFAULT_OFFSET },
            limit: { $set: DEFAULT_LIMIT },
            isInitialized: { $set: false },
          },
        }),

      [ScrappedCarsActionsType.SET_RE_QUOTE_VRM]: (state, { payload }) =>
        immutable(state, {
          reQuoteVrm: { $set: payload },
        }),
    },
    initialState,
  ),
};
