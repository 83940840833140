import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import { immutablySetOrMergeDeeply } from '../lib/mergeToStateHelper';
import * as mediaActions from '../actions/media';

const initialState = {
  mediaIsLoading: false,
  mediaError: null,
  related: {},
  news: {},
  blogs: {},
  videos: {},
  reviews: {},
  guides: {},
  categories: {},
  allCategories: {},
  byPath: {},
  categoriesContentByType: {},
};

export default {
  media: handleActions(
    {
      [combineActions(
        mediaActions.getMediaRequest,
        mediaActions.getRelatedRequest,
        mediaActions.getMediaCentreRequest,
        mediaActions.getMediaCategoriesRequest,
      )]: state =>
        immutable(state, {
          mediaIsLoading: { $set: true },
          mediaError: { $set: null },
        }),

      [combineActions(
        mediaActions.getMediaSuccess,
        mediaActions.getRelatedSuccess,
        mediaActions.getMediaCentreSuccess,
        mediaActions.getMediaCategoriesSuccess,
        mediaActions.getIndividualByPathSuccess,
      )]: (state, { payload }) => {
        const update = immutablySetOrMergeDeeply(state, payload, {
          mediaIsLoading: { $set: false },
          mediaError: { $set: null },
        }, 2);
        return immutable(state, update);
      },

      [combineActions(
        mediaActions.getMediaError,
        mediaActions.getRelatedError,
        mediaActions.getMediaCentreError,
        mediaActions.getMediaCategoriesError,
        mediaActions.getIndividualByPathError,
      )]: (state, { payload }) =>
        immutable(state, {
          mediaIsLoading: { $set: false },
          mediaError: { $set: payload },
        }),

      [mediaActions.categoriesContentByType]: (state, { payload }) =>
        immutable(state, {
          categoriesContentByType: { $merge: payload || {} },
        }),
    },
    initialState,
  ),
};
