import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import ActionType from '../action-types/modal';

const initialState = {
  isModalOpen: false,
  modalType: null,
  modalInfo: {},
  backdrop: 'static',
};

export default {
  modalState: handleActions(
    {
      [ActionType.OPEN_MODAL]: (state, { payload: { modalType, modalInfo = {}, backdrop = 'static' } }) =>
        immutable(state, {
          isModalOpen: { $set: true },
          modalType: { $set: modalType },
          modalInfo: { $set: modalInfo },
          backdrop: { $set: backdrop },
        }),

      [ActionType.CLOSE_MODAL]: state =>
        immutable(state, {
          isModalOpen: { $set: false },
          modalType: { $set: null },
          modalInfo: { $set: {} },
          backdrop: { $set: 'static' },
        }),
    },
    initialState,
  ),
};
