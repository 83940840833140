const data = {
  header: 'Fill Dates for your car',
  headerSingleDate: 'Fill Date for your car',
  serviceDue: {
    id: 'serviceDueDate',
    name: 'serviceDueDate',
    label: 'Please enter the date your next car service is due',
  },
  insuranceDue: {
    id: 'insuranceDueDate',
    name: 'insuranceDueDate',
    label: 'Please enter date your car insurance policy is due',
  },
  warrantyDue: {
    id: 'warrantyDueDate',
    name: 'warrantyDueDate',
    label: 'Please enter the date your car warranty expires',
  },
};

export default data;
