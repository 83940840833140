import { call, put, take, takeLatest, select } from 'redux-saga/effects';
import { channel, END } from 'redux-saga';
import { warranrtyEnquiry } from 'api-client/apiClientInstance/warranrtyEnquiry';
import { warranties } from 'api-client/apiClientInstance/warranties';
import { reportToBugsnag } from 'lib/bugsnag';
import { redirectToStepRequest, redirectToStepSuccess } from 'store/actions/redirect';
import { showToast, getWarrantiesShortLinkSuccess, getWarrantiesShortLinkError } from 'store/actions';
import { getToken, getWarrantiesQuoteStatus, getWarrantyError } from 'store/selectors/warranties';
import { isUserAuthenticatedSelector } from 'store/selectors/userAuth';
import { redirect } from 'lib/routes';
import { QUOTE_STATUS, QUOTE_ROUTES } from 'modules/carWarranties/QuoteProcess/constants';
import UserTypes from 'store/action-types/user';
import { preserveToastOnSSR, removeCookie, setCookie, getCookie } from 'lib/cookie';
import { getErrorMessage } from 'lib/message';
import WarrantiesTypes from '../action-types/warranties';
import CmsTypes from '../action-types/cms';
import { wrapWith, extractStoreIds } from '../lib/mergeToStateHelper';
import { snakeToCamelCase } from '../lib/snakeToCamelCase';

export function* getCMSData({ payload }) {
  const STORE_AS = 'warrantyPage';
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { section: 'car-warranties', ...payload },
      chan,
    });
    const {
      payload: { data, links },
      path,
    } = yield take(chan);
    yield put({
      // pass the needed type in all getCMSData
      type: WarrantiesTypes.GET_FINANCE_PAGES.SUCCESS,
      payload: wrapWith(STORE_AS, extractStoreIds(data, path, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getCMSData');

    yield put({
      type: WarrantiesTypes.GET_FINANCE_PAGES.ERROR,
      payload: err,
    });
  }
}

export function* getSettings() {
  const fingerprint = 'warrantySettings';
  const chan = yield call(channel);
  try {
    const type = 'settings';
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { section: 'car-warranties', type, includes: ['homepage', 'top_banner', 'top_banner.image.file'] },
      fingerprint,
      chan,
    });

    const fullData = yield take(chan);
    //   ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp,
    // );
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: WarrantiesTypes.GET_WARRANTIES_SETTINGS.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getSettings');

    yield put({
      type: WarrantiesTypes.GET_WARRANTIES_SETTINGS.ERROR,
      payload: err,
    });
  }
}

export function* getFleetWarrantiesSettings() {
  const fingerprint = 'fleetWarrantySettings';
  const chan = yield call(channel);
  try {
    const type = 'fleet-settings';
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { section: 'car-warranties', type, includes: ['confirmation_content'] },
      fingerprint,
      chan,
    });

    const fullData = yield take(chan);
    //   ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp,
    // );
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: WarrantiesTypes.GET_FLEET_WARRANTIES_SETTINGS.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getFleetWarrantiesSettings');

    yield put({
      type: WarrantiesTypes.GET_FLEET_WARRANTIES_SETTINGS.ERROR,
      payload: err,
    });
  }
}

export function* getQuote() {
  const fingerprint = 'warrantyQuote';
  const chan = yield call(channel);
  try {
    const type = 'quote';
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: {
        section: 'car-warranties',
        type,
        includes: [
          'type',
          'payment',
          'payment.items',
          'vehicle_details_init',
          'vehicle_details_init.items',
          'vehicle_details_confirm',
          'vehicle_details_confirm.items',
          'vehicle_details_login',
          'vehicle_details_login.items',
          'your_quote',
          'your_quote.items',
          'warranty_details',
          'warranty_details.items',
          'your_details',
          'your_details.items',
          'confirmation',
          'confirmation.items',
          'confirmation.image.file',
        ],
      },
      fingerprint,
      chan,
    });
    const fullData = yield take(chan);
    //   ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp,
    // );
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: WarrantiesTypes.GET_WARRANTIES_QUOTE.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getQuote');

    yield put({
      type: WarrantiesTypes.GET_WARRANTIES_QUOTE.ERROR,
      payload: err,
    });
  }
}

export function* getIndividualByPath({ payload: { specificIncludes, ...payload } }) {
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.RESOLVE_CMS_ENTITY.REQUEST,
      payload,
      chan,
    });
    const {
      payload: {
        data,
        path: resourcePath,
        jsonapi: { resourceName },
      },
      path,
    } = yield take(chan);
    let outData = data;
    const camelResourceName = snakeToCamelCase(resourceName);
    if (camelResourceName !== 'nodeCarWarrantiesPage') {
      yield put({
        type: CmsTypes.FETCH_CMS_INDIVIDUAL.REQUEST,
        payload: { path: resourcePath, includes: specificIncludes[camelResourceName] },
        path,
      });
      const {
        payload: { data: includedData },
      } = yield take(CmsTypes.FETCH_CMS_INDIVIDUAL.SUCCESS);
      outData = includedData;
    }

    yield put({
      type: WarrantiesTypes.GET_WARRANTIES_BY_PATH.SUCCESS,
      payload: extractStoreIds(outData, path),
    });
  } catch (err) {
    reportToBugsnag(err, 'sendBegetIndividualByPathacon');

    yield put({
      type: WarrantiesTypes.GET_WARRANTIES_BY_PATH.ERROR,
      payload: err,
    });
  }
}

function* sendContactUsEnquiry({
  payload: {
    data,
    actions: { setErrors },
  },
}) {
  try {
    yield call(warranrtyEnquiry.sendContactUsEnquiry, data);
    yield put({
      type: WarrantiesTypes.SEND_CONTACT_US_ENQUIRY.SUCCESS,
    });
  } catch (err) {
    reportToBugsnag(err, 'sendContactUsEnquiry');

    if (err.status !== 401) {
      yield setErrors(err?.error?.messages);
    }
    yield put({
      type: WarrantiesTypes.SEND_CONTACT_US_ENQUIRY.ERROR,
      payload: err,
    });
  }
}

export function* getManufacturers() {
  const STORE_AS = 'allManufacturers';
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.FETCH_TAXONOMY_MANUFACTURERS.REQUEST,
      chan,
    });
    const {
      payload: { data, links, type },
      path,
    } = yield take(chan);
    yield put({
      type: WarrantiesTypes.GET_WARRANTIES_MANUFACTURERS.SUCCESS,
      payload: wrapWith(STORE_AS, wrapWith(type, extractStoreIds(data, path, links))),
    });
  } catch (err) {
    reportToBugsnag(err, 'getManufacturers');

    yield put({
      type: WarrantiesTypes.GET_WARRANTIES_MANUFACTURERS.ERROR,
      payload: err,
    });
  }
}

export function* getShortLinkRequest({ payload }) {
  const { req, res } = payload;
  const token = yield select(getToken);
  let isAuthenticated;
  try {
    isAuthenticated = yield select(isUserAuthenticatedSelector);
    const data = yield call(warranties.continueWithQuote, token, req);
    const { applicationToken } = data;
    setCookie('warranties-quote-authorization', applicationToken, req, res);
    yield put(getWarrantiesShortLinkSuccess({ ...data, req, res }));
  } catch (err) {
    reportToBugsnag(err, 'getShortLinkRequest');

    if (err.status === 401 && !isAuthenticated) {
      yield put(getWarrantiesShortLinkError({ quoteStatus: QUOTE_STATUS.CONTINUE_FORBIDDEN }));
      setCookie('warranties_quote_status', QUOTE_STATUS.CONTINUE_FORBIDDEN, req, res);
      setCookie('warranties_quote_token', token, req, res);
      redirect({ res }, '/sign-in');
    } else if (err.status === 400 || err.status === 403) {
      const message = getErrorMessage(err);
      yield put(showToast({ message, kind: 'error' }));
      if (req) {
        preserveToastOnSSR(message, 'error', req, res);
      } else {
        redirect({ res }, '/car-warranties');
      }
    } else if (err.status === 404) {
      redirect({ res }, '/not-found');
    } else {
      yield put(getWarrantiesShortLinkError(err));
    }
  }
  if (req) {
    yield put(END);
  }
}

export function* handleUnauthorizedQuote() {
  let quoteStatus = yield select(getWarrantiesQuoteStatus);
  if (!quoteStatus) {
    quoteStatus = getCookie('warranties_quote_status');
    removeCookie('warranties_quote_status');
  }
  const quoteError = yield select(getWarrantyError);
  const errorStatus = quoteError?.quoteStatus;
  if (quoteStatus === QUOTE_STATUS.CONTINUE_FORBIDDEN || errorStatus === QUOTE_STATUS.CONTINUE_FORBIDDEN) {
    let token = yield select(getToken);
    if (!token) {
      token = getCookie('warranties_quote_token');
      removeCookie('warranties_quote_token');
    }
    yield put({
      type: WarrantiesTypes.GET_WARRANTIES_SHORT_LINK.REQUEST,
      payload: { token },
    });
  }
}

const getStatusIndex = status => {
  const quoteStatuses = [
    QUOTE_STATUS.PENDING,
    QUOTE_STATUS.CONFIRMED,
    QUOTE_STATUS.VEHICLE_DETAILS_COMPLETED,
    QUOTE_STATUS.QUOTE_COMPLETED,
    QUOTE_STATUS.WARRANTIES_COMPLETED,
    QUOTE_STATUS.YOUR_DETAILS_COMPLETED,
  ];
  return quoteStatuses.indexOf(status);
};

const setQuoteDataToCookies = (quoteStatus = '') => {
  setCookie('quoteStatus', quoteStatus);
};

export function* redirectToStep(stepRouteToRedirect, res) {
  yield put(redirectToStepRequest());
  const quoteStatus = yield select(getWarrantiesQuoteStatus);
  yield setQuoteDataToCookies(quoteStatus);
  try {
    redirect({ res }, stepRouteToRedirect);
  } catch (err) {
    reportToBugsnag(err, 'getStatusIndex');
  } finally {
    yield put(redirectToStepSuccess());
  }
}
const removeQuoteDataFromCookies = () => {
  removeCookie('quoteStatus');
};

const isRequestedStatusEqualToCurrent = (quoteStatusIndex, requestedQuoteStatusIndex) => {
  const isQuoteStatusValid = quoteStatusIndex > -1;
  const isRequestedQuoteStatusValid = requestedQuoteStatusIndex > -1;
  return isQuoteStatusValid && isRequestedQuoteStatusValid && requestedQuoteStatusIndex === quoteStatusIndex;
};

export const requestedStatusCheck = (quoteStatus = '', requestedQuoteStatus = '') => {
  const quoteStatusIndex = getStatusIndex(quoteStatus);
  const requestedQuoteStatusIndex = getStatusIndex(requestedQuoteStatus);
  return isRequestedStatusEqualToCurrent(quoteStatusIndex, requestedQuoteStatusIndex);
};

export function* cancelQuote() {
  yield removeQuoteDataFromCookies();
  yield redirect({}, '/car-warranties');
}

export function* dropQuoteData() {
  yield removeQuoteDataFromCookies();
}

const getStepRoute = (quoteStatus = '', requestedQuoteStatus = '') => {
  const isRequestedStatusValid = requestedStatusCheck(quoteStatus, requestedQuoteStatus);
  const status = isRequestedStatusValid ? quoteStatus : quoteStatus;
  switch (status) {
    case QUOTE_STATUS.DRAFT:
      return QUOTE_ROUTES.YOUR_DETAILS;

    case QUOTE_STATUS.CREATED:
      return QUOTE_ROUTES.YOUR_QUOTE;

    case QUOTE_STATUS.PENDING_PAYMENT:
      return QUOTE_ROUTES.WARRANTIES_DETAILS;

    case QUOTE_STATUS.FORBIDDEN:
      return QUOTE_ROUTES.HOMEPAGE;

    case QUOTE_STATUS.BAD:
      return QUOTE_ROUTES.HOMEPAGE;

    default:
      return QUOTE_ROUTES.HOMEPAGE;
  }
};

export function* getShortLinkSuccess({ payload }) {
  const { res } = payload;
  const quoteStatus = yield select(getWarrantiesQuoteStatus);
  const stepRouteToRedirect = getStepRoute(quoteStatus);
  yield redirectToStep(stepRouteToRedirect, res);
}

export default [
  takeLatest(WarrantiesTypes.GET_WARRANTIES_BY_TYPE.REQUEST, getCMSData),
  takeLatest(WarrantiesTypes.GET_WARRANTIES_BY_PATH.REQUEST, getIndividualByPath),
  takeLatest(WarrantiesTypes.GET_WARRANTIES_SETTINGS.REQUEST, getSettings),
  takeLatest(WarrantiesTypes.GET_FLEET_WARRANTIES_SETTINGS.REQUEST, getFleetWarrantiesSettings),
  takeLatest(WarrantiesTypes.GET_WARRANTIES_QUOTE.REQUEST, getQuote),
  takeLatest(WarrantiesTypes.SEND_CONTACT_US_ENQUIRY.REQUEST, sendContactUsEnquiry),
  takeLatest(WarrantiesTypes.GET_WARRANTIES_MANUFACTURERS.REQUEST, getManufacturers),
  takeLatest(WarrantiesTypes.GET_WARRANTIES_SHORT_LINK.REQUEST, getShortLinkRequest),
  takeLatest(WarrantiesTypes.GET_WARRANTIES_SHORT_LINK.SUCCESS, getShortLinkSuccess),
  takeLatest(UserTypes.USER_LOGIN.SUCCESS, handleUnauthorizedQuote),
];
