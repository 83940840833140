import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import Button from 'react-bootstrap/Button';
import { DatePickerFormGroup } from 'components/form';
import { updateYourCarRequest } from 'store/actions/myCar';
import { STATUS, MY_CAR_DATE_TARGET } from 'store/constants';
import { getSelectedCarSelector, getMyCarStatusSelector, getMyCarDateTargetSelector } from 'store/selectors/myCar';
import constant from './constants';
import {
  validationSchemaService,
  validationSchemaInsurance,
  validationSchemaWarranty,
  validationSchemaAll,
} from './validationSchema';

const AddYourCarPopup = () => {
  const dispatch = useDispatch();
  const myCarStatus = useSelector(getMyCarStatusSelector);
  const dateTarget = useSelector(getMyCarDateTargetSelector);
  const { serviceDueDate, insuranceDueDate, warrantyDueDate } = useSelector(getSelectedCarSelector);

  const onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const getValidationSchema = target => {
    switch (target) {
      case MY_CAR_DATE_TARGET.SERVICE:
        return validationSchemaService;
      case MY_CAR_DATE_TARGET.INSURANCE:
        return validationSchemaInsurance;
      case MY_CAR_DATE_TARGET.WARRANTY:
        return validationSchemaWarranty;
      case MY_CAR_DATE_TARGET.ALL:
        return validationSchemaAll;
      default:
        break;
    }
  };

  const validationSchema = getValidationSchema(dateTarget);

  const onSubmit = (values, { setErrors, setSubmitting }) => {
    if (
      dateTarget === MY_CAR_DATE_TARGET.ALL &&
      !values[serviceDue.name] &&
      !values[insuranceDue.name] &&
      !values[warrantyDue.name]
    )
      return;
    dispatch(updateYourCarRequest({ actions: { setErrors, setSubmitting }, values }));
  };

  const { serviceDue, insuranceDue, warrantyDue, header, headerSingleDate } = constant;

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{ serviceDueDate, insuranceDueDate, warrantyDueDate }}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        setSubmitting(true);
        onSubmit(values, { setErrors, setSubmitting });
      }}
    >
      {({ values, errors, touched, handleBlur, setFieldTouched, setFieldValue }) => (
        <div className="container add-your-car-pop">
          <Form onKeyDown={onKeyDown} name="add-your-car-pop-up">
            <div className="form-container ">
              <div className="header">
                <h1>{dateTarget === MY_CAR_DATE_TARGET.ALL ? header : headerSingleDate}</h1>
              </div>

              <div className="content-area">
                {(dateTarget === MY_CAR_DATE_TARGET.ALL || dateTarget === MY_CAR_DATE_TARGET.SERVICE) && (
                  <div>
                    <label>{serviceDue.label}</label>
                    <DatePickerFormGroup
                      {...serviceDue}
                      value={values[serviceDue.name]}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      onBlur={handleBlur}
                      error={touched[serviceDue.name] && errors[serviceDue.name]}
                    />
                  </div>
                )}

                {(dateTarget === MY_CAR_DATE_TARGET.ALL || dateTarget === MY_CAR_DATE_TARGET.INSURANCE) && (
                  <div>
                    <label>{insuranceDue.label}</label>
                    <DatePickerFormGroup
                      {...insuranceDue}
                      value={values[insuranceDue.name]}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      onBlur={handleBlur}
                      error={touched[insuranceDue.name] && errors[insuranceDue.name]}
                    />
                  </div>
                )}

                {(dateTarget === MY_CAR_DATE_TARGET.ALL || dateTarget === MY_CAR_DATE_TARGET.WARRANTY) && (
                  <div>
                    <label>{warrantyDue.label}</label>
                    <DatePickerFormGroup
                      {...warrantyDue}
                      value={values[warrantyDue.name]}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      onBlur={handleBlur}
                      error={touched[warrantyDue.name] && errors[warrantyDue.name]}
                    />
                  </div>
                )}
              </div>

              <div className="submit-btn">
                <Button className="btn-lg" type="submit" disabled={myCarStatus === STATUS.RUNNING}>
                  Ok
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default AddYourCarPopup;
