import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import { immutablySetOrMergeDeeply } from '../lib/mergeToStateHelper';
import * as valuationsActions from '../actions/valuations';

const initialState = {
  valuationsIsLoading: false,
  valuationsError: null,
  valuationsPage: {},
  settings: {},
  byPath: {},
};

export default {
  valuations: handleActions(
    {
      [combineActions(
        valuationsActions.getValuationsSettingsRequest,
        valuationsActions.getValuationsQuotesRequest,
        valuationsActions.getValuationsByPathRequest,
      )]: state =>
        immutable(state, {
          valuationsIsLoading: { $set: true },
          valuationsError: { $set: null },
        }),
      [combineActions(
        valuationsActions.getValuationsSettingsSuccess, 
        valuationsActions.getValuationsQuotesSuccess,
        valuationsActions.getValuationsByPathSuccess,
        )]: (
        state,
        { payload },
      ) => {
        const update = immutablySetOrMergeDeeply(state, payload, {
          valuationsIsLoading: { $set: false },
          valuationsError: { $set: null },
        });
        return immutable(state, update);
      },
      [combineActions(valuationsActions.getValuationsSettingsError, valuationsActions.getValuationsQuotesError)]: (
        state,
        { payload },
      ) =>
        immutable(state, {
          valuationsIsLoading: { $set: false },
          valuationsError: { $set: payload },
        }),
    },
    initialState,
  ),
};
