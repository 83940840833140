import { createActions } from 'redux-actions';
import CmsTypes from '../action-types/cms';

export const {
  fetchCmsRequest,
  fetchCmsSuccess,
  fetchCmsError,

  multiFetchCmsRequest,
  multiFetchCmsSuccess,
  multiFetchCmsError,

  resolveCmsEntityRequest,
  resolveCmsEntitySuccess,
  resolveCmsEntityError,

  fetchTaxonomyCategoriesRequest,
  fetchTaxonomyCategoriesSuccess,
  fetchTaxonomyCategoriesError,

  fetchTaxonomyManufacturersRequest,
  fetchTaxonomyManufacturersSuccess,
  fetchTaxonomyManufacturersError,

  fetchCmsIndividualRequest,
  fetchCmsIndividualSuccess,
  fetchCmsIndividualError,
} = createActions({
  [CmsTypes.FETCH_CMS.REQUEST]: payload => payload,
  [CmsTypes.FETCH_CMS.SUCCESS]: payload => payload,
  [CmsTypes.FETCH_CMS.ERROR]: payload => payload,

  [CmsTypes.MULTI_FETCH_CMS.REQUEST]: payload => payload,
  [CmsTypes.MULTI_FETCH_CMS.SUCCESS]: payload => payload,
  [CmsTypes.MULTI_FETCH_CMS.ERROR]: payload => payload,

  [CmsTypes.RESOLVE_CMS_ENTITY.REQUEST]: payload => payload,
  [CmsTypes.RESOLVE_CMS_ENTITY.SUCCESS]: payload => payload,
  [CmsTypes.RESOLVE_CMS_ENTITY.ERROR]: payload => payload,

  [CmsTypes.FETCH_TAXONOMY_CATEGORIES.REQUEST]: payload => payload,
  [CmsTypes.FETCH_TAXONOMY_CATEGORIES.SUCCESS]: payload => payload,
  [CmsTypes.FETCH_TAXONOMY_CATEGORIES.ERROR]: payload => payload,

  [CmsTypes.FETCH_TAXONOMY_MANUFACTURERS.REQUEST]: payload => payload,
  [CmsTypes.FETCH_TAXONOMY_MANUFACTURERS.SUCCESS]: payload => payload,
  [CmsTypes.FETCH_TAXONOMY_MANUFACTURERS.ERROR]: payload => payload,

  [CmsTypes.FETCH_CMS_INDIVIDUAL.REQUEST]: payload => payload,
  [CmsTypes.FETCH_CMS_INDIVIDUAL.SUCCESS]: payload => payload,
  [CmsTypes.FETCH_CMS_INDIVIDUAL.ERROR]: payload => payload,
});
