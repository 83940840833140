import { createSelector } from 'reselect';

export const getValuationQuote = state => state.valuationQuoteProcess;

export const getRegistrationNumber = createSelector(
  getValuationQuote,
  valuationQuote => valuationQuote.registrationNumber,
);

export const getMileage = createSelector(getValuationQuote, valuationQuote => valuationQuote.mileage);

export const getValuationStatus = createSelector(getValuationQuote, valuationQuote => valuationQuote.status);

export const getValuationData = createSelector(getValuationQuote, valuationQuote => valuationQuote);
