import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { FormGroupWrapper, InputFormGroup } from 'components/form';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getVehicleData } from 'store/selectors/warrantiesAgQuoteProcess';
import { clearQuoteInfo, requireCustomQuoteRequest } from 'store/actions/warrantiesAgQuoteProcess';
import { modalClose } from 'store/actions/modal';
import validationSchema from './validationSchema';
const CustomQuotePopup = ({ title, description }) => {
  const vehicle = useSelector(getVehicleData);
  const dispatch = useDispatch();
  return (
    <>
      <header className="base-confirmation-popup__header-block">
        {title && <h1 className="edit-confirmation-popup__title" dangerouslySetInnerHTML={{ __html: title }} />}
        {description && (
          <div className="edit-confirmation-popup__description" dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </header>
      <div className="edit-confirmation-popup__diving-line" />
      <div className="pt-5 pb-5 container form-large form-bordered b-validation">
        <Formik
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            const data = {
              email: values.email,
              registration: vehicle?.registration,
              mileage: vehicle?.mileage,
            };
            dispatch(
              requireCustomQuoteRequest({
                data,
                finallyCallback() {
                  setSubmitting(false);
                },
                continueCallback() {
                  dispatch(clearQuoteInfo());
                  dispatch(modalClose());
                },
              }),
            );
          }}
          validationSchema={validationSchema}
          render={formikProps => {
            const { isSubmitting, isValid, values, errors, touched, handleChange, handleBlur } = formikProps;
            return (
              <Form name="warranties-custom-quote" className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <FormGroupWrapper id="email" name="email">
                    <InputFormGroup
                      name="email"
                      value={values.email}
                      type="email"
                      placeholder="email"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      validationMarks
                      valid={touched.email && !errors.email}
                      error={touched.email && errors.email}
                    />
                  </FormGroupWrapper>
                  <ButtonGroup className="w-100 mb-4">
                    <Button
                      disabled={!isValid || isSubmitting}
                      type="submit"
                      style={{ height: '50px', fontSize: '1.1rem' }}
                    >
                      Submit
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </>
  );
};

CustomQuotePopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

CustomQuotePopup.defaultProps = {
  title: 'Request custom quote',
  description:
    'Sorry! We are unable to provide an automated quote for your vehicle. Please enter your email address and we will provide a custom quote for you.',
};

export default CustomQuotePopup;
