import { handleActions, combineActions } from 'redux-actions';
import immutable from 'immutability-helper';

import { STATUS, MY_CAR_DATE_TARGET } from '../constants';
import MyCarType from '../action-types/myCar';

export const defaultMyCarState = {
  allCars: [],
  selectedCar: '',
  status: STATUS.IDLE,
  updateDateTarget: MY_CAR_DATE_TARGET.ALL,
};

export default {
  myCar: handleActions(
    {
      [combineActions(
        MyCarType.ADD_YOUR_CAR.REQUEST,
        MyCarType.UPDATE_YOUR_CAR.REQUEST,
        MyCarType.DELETE_YOUR_CAR.REQUEST,
      )]: state =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
        }),

      [combineActions(
        MyCarType.ADD_YOUR_CAR.ERROR,
        MyCarType.UPDATE_YOUR_CAR.ERROR,
        MyCarType.DELETE_YOUR_CAR.ERROR,
      )]: state =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),

      // ADD_YOUR_CAR
      [MyCarType.ADD_YOUR_CAR.SUCCESS]: (state, { payload }) =>
        immutable(state, {
          allCars: { $push: [payload] },
          status: { $set: STATUS.SUCCESS },
        }),

      // GET_YOUR_CARS
      [MyCarType.GET_YOUR_CARS.SUCCESS]: (state, { payload }) =>
        immutable(state, {
          allCars: { $set: payload },
          status: { $set: STATUS.SUCCESS },
        }),

      // UPDATE_YOUR_CAR
      [MyCarType.UPDATE_YOUR_CAR.SUCCESS]: (state, { payload: { index, newDates } }) =>
        immutable(state, {
          allCars: { $splice: [[index, 1, newDates]] },
          status: { $set: STATUS.SUCCESS },
        }),

      // DELETE_YOUR_CAR
      [MyCarType.DELETE_YOUR_CAR.SUCCESS]: (state, { payload: { index } }) =>
        immutable(state, {
          allCars: { $splice: [[index, 1]] },
          status: { $set: STATUS.SUCCESS },
        }),

      [MyCarType.SET_SELECTED_CAR]: (state, { payload }) =>
        immutable(state, {
          selectedCar: { $set: payload },
        }),

      [MyCarType.SET_DATE_TARGET]: (state, { payload }) =>
        immutable(state, {
          updateDateTarget: { $set: payload },
        }),
    },
    defaultMyCarState,
  ),
};
