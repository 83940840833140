import { Router } from 'server/pages';
import queryString from 'query-string';
import { isEmpty } from 'lodash';

const isUserAuthenticated = ctx => ctx.store.getState().userAuth.isAuthenticated;

export const redirect = (ctx, redirectRoute = '/', params = {}) => {
  if (ctx && ctx.res) {
    const query = !isEmpty(params) ? `?${queryString.stringify(params)}` : '';
    // Server-side
    ctx.res.writeHead(302, {
      Location: redirectRoute + query,
    });
    ctx.res.end();
  } else {
    // Client-side
    Router.pushRoute(redirectRoute, params);
  }
};

export const authentificatedRoute = (ctx, redirectRoute) => {
  if (!isUserAuthenticated(ctx)) {
    redirect(ctx, redirectRoute);
  }
};

export const unauthentificatedRoute = (ctx, redirectRoute) => {
  if (isUserAuthenticated(ctx)) {
    redirect(ctx, redirectRoute);
  }
};
