export const byPath = path => (list, cms) => {
  let out = {};
  const { byPath: paths } = list;
  if (paths && paths[path]) {
    out = cms[paths[path]];
  } else {
    // eslint-disable-next-line no-console
    console.log(`Could not find individual by path: ${path}`);
  }
  return out;
};

export const byId = (list, cms) => {
  if (list && list.byId instanceof Array) {
    return list.byId.map(item => cms[item]);
  }
  return [];
};

export const makeGetByPath = type => (state, path) => {
  let out = '';
  const {
    [type]: { byPath: paths },
  } = state;
  if (paths && paths[path]) {
    out = paths[path];
  }

  return out;
};
