import { call, put, takeLatest, takeEvery, select, delay, cancelled, take, fork, cancel } from 'redux-saga/effects';
import { END } from 'redux-saga';
import moment from 'moment';
import { md5 } from 'lib/encrypt';
import { reportToBugsnag } from 'lib/bugsnag';
import { scrapCarQuote } from 'api-client/apiClientInstance/scrapCarQuote';
import { newLocations } from 'api-client/apiClientInstance/newLocations';
import { quotes } from 'api-client/apiClientInstance/quotes';

import { QUOTE_STATUS, QUOTE_ROUTES, QUOTE_FUNNEL_NAME, QUOTE_FUNNEL_STEP_NUMBER, QUOTE_CURRENCY, HAS_QUOTES_OFFER_STATUSES, QUOTE_OFFER_TYPE } from 'modules/ScrapMyCar/QuoteProcess/constants';
import { contactNumber, email as emailValidation } from 'common/user';
import Geocode from 'react-geocode';
import { segmentTrack, segmentIdentify } from 'lib/segment';
import setFormikErrors from '../lib/setFormikErrors';
import { formatPhoneNumber, postToOutcode, formatDateToWeek, formatPartHideBankAccount } from 'lib/formatters';
import { setCookie, removeCookie, preserveToastOnSSR, getCookie } from 'lib/cookie';
import { Router } from 'server/pages';
import { getErrorMessage } from 'lib/message';
import { BOOKING_JOB_STATUS } from 'modules/ScrapMyCar/QuoteProcess/constants';

import {
  getRegistrationNumber,
  getPostcode,
  getQuoteId,
  getEntityId,
  getPhoneNumber,
  getQuoteStatus,
  getUserPersonalDetails,
  getUserAddressDetails,
  getHasAccount,
  getPaymentDetails,
  getIsAddressManual,
  getQuoteToken,
  getQuote,
  getCoordinate,
  getPreferredCollectionDateTime,
  getOfferConditions,
  getSalvageOfferConditionsData,
  getOfferType,
} from '../selectors/quote';
import {
  getUserEmailSelector,
  getUserFirstNameSelector,
  getUserLastNameSelector,
  getUserPhoneNumberSelector,
} from '../selectors/user';
import QuoteTypes from '../action-types/quote';
import {
  createQuoteSuccess,
  createQuoteError,
  createQuoteWithoutInstantPrice,
  cancelQuoteSuccess,
  cancelQuoteError,
  handleQuoteForbiddenStatus,
  handleQuoteBadStatus,
  handleQuoteUnauthorizedStatus,
  enterDetailsError,
  enterDetailsSuccess,
  arrangeCollectionSuccess,
  arrangeCollectionError,
  getAddressesByPostcodeError,
  getAddressesByPostcodeSuccess,
  setPaymentMethodSuccess,
  setPaymentMethodError,
  completeArrangeCollection,
  saveAbandonedDataRequest,
  setOnlineQuoteSuccess,
  setOnlineQuoteError,
  getBookingSlotsSuccess,
  getAddressesByPostcodeRequest,
  getBookingSlotsRequest,
  cancelConditionOfferError,
  cancelConditionOfferSuccess,
  submitVehicleDetailRequest,
  submitVehicleDetailSuccess,
  submitVehicleDetailError,
  continueWithCaptureShortLinkSuccess,
  continueWithCaptureShortLinkError,
  setSendSmsButtonStatusRequest,
  uploadCaptureFormVideoRequest,
  uploadCaptureFormVideoSuccess,
  updateVehicleDetailError,
  cancelConditionOfferByTokenRequest,
  customerRequestCallBackError,
  getSalvageOfferFormConfigSuccess,
  getSalvageOfferFormConfigError,
  getSalvageOfferPriceSuccess,
  getSalvageOfferPriceError,
} from '../actions/quote';
import { userLoginRequest, getUserDataRequest } from '../actions/user';
import { showToast } from '../actions/toasts';
import {
  continueWithQuoteError,
  continueWithQuoteSuccess,
  refreshPriceError,
  refreshPriceSuccess,
  saveAbandonedDataError,
  saveAbandonedDataSuccess,
  stopEnterDetailsTimer,
} from '../actions';
import { isUserAuthenticatedSelector } from '../selectors/userAuth';
import { ENTER_DETAILS_TIMEOUT } from '../constants';
import { getRandomString } from 'lib/helper';
import { getStepRoute, redirectToStep } from 'webSpecificRedux/sagas/scrapMyCarRedirectSaga';
import { continueWithQuoteShortLinkSuccess } from 'store/actions';
import { redirect } from 'lib/routes';

const getShortUrl = quoteToken => {
  let frontUrl = '';
  switch (process.env.ENV) {
    case 'production':
      frontUrl = 'https://www.car.co.uk/q/s/';
      break;
    default:
      frontUrl = 'https://dev.car.co.uk/q/s/';
      break;
  }

  return `${frontUrl}${quoteToken}`;
};

function* errorHandler(err, setActionError, setFormErrors, setSubmitting) {
  const isAuthenticated = yield select(isUserAuthenticatedSelector);
  if (err.status === 401 && isAuthenticated) {
    yield put(
      showToast({
        message:
          'These details are currently active in another quote, please login to your account in order to continue',
        kind: 'success',
      }),
    );
    if (setActionError) yield put(setActionError(err));
  } else if (err.status === 401 && !isAuthenticated) {
    yield put(
      showToast({
        message:
          'These details are currently active in another quote, please login to your account in order to continue',
        kind: 'success',
      }),
    );
    yield put(handleQuoteUnauthorizedStatus());
  } else {
    const errorMessages = getErrorMessage(err);
    if (errorMessages) {
      yield put(showToast({ message: `${errorMessages}`, kind: 'error' }));
    } else {
      if (setFormErrors) setFormErrors();
    }
    if (err.status === 403) {
      yield put(handleQuoteForbiddenStatus());
    }
    if (err.status === 400) {
      // won't redirect if on media & faq pages
      if (
        Router.asPath.indexOf('/media') !== -1 ||
        Router.asPath.indexOf('/faqs') !== -1 ||
        errorMessages.indexOf('Invalid email address') !== -1
      ) {
        if (setSubmitting) setSubmitting(false);
        return;
      }
      yield put(handleQuoteBadStatus());
    }
    if (setSubmitting) setSubmitting(false);
  }
}

export function* createQuote({
  payload: {
    actions: { setErrors, setSubmitting },
    quoteType,
  },
}) {
  try {
    setSubmitting(true);
    const registrationNumber = yield select(getRegistrationNumber);
    const postcode = yield select(getPostcode);
    let utmTracking = {};
    try {
      const utmCookieInfo = getCookie('utm_tracking');
      if (utmCookieInfo) {
        utmTracking = JSON.parse(utmCookieInfo);
      }
    } catch (err) {
      reportToBugsnag(err, 'utmTracking');
    }

    // Remove old fake access token.
    const isAuthenticated = yield select(isUserAuthenticatedSelector);
    if (!isAuthenticated) {
      removeCookie('access_token');
    }
    removeCookie('scrap-authorization');
    let slug = '';
    const createMethod = !quoteType
      ? scrapCarQuote.create
      : (() => {
        let result;
        if (quoteType.includes('affiliated')) {
          result = scrapCarQuote.createAffiliated;
          slug = quoteType.split('-')[1];
        }
        // can extend more methods here
        return result;
      })();
    const { scrapQuoteId, entityId, quoteToken, status, applicationToken, ...data } = yield call(
      createMethod,
      {
        registration: registrationNumber,
        postcode,
        ...utmTracking
      },
      { slug }
    );
    setCookie('scrap-authorization', applicationToken);
    if(data.pendingType === 'no_instant_quote' && status === 'deleted'){
      yield put(
        createQuoteWithoutInstantPrice({
          quoteId: scrapQuoteId,
          quoteToken,
          quoteStatus: status,
          image: data.image
        }),
      );
      setSubmitting(false);
      return;
    }
    if (status === 'created') {
      yield put(
        enterDetailsSuccess({
          ...data,
          quoteId: scrapQuoteId,
          quoteToken: data.token,
          registrationNumber,
          carWeight: data.weight,
          carPrice: data.price,
          quoteStatus: status,
          carImage: data.image,
          carYear: data.year,
          carColour: data.color,
          phoneNumber: data.mobile,
        }),
      );
    }

    const hasAccount = yield select(getHasAccount);
    const [address] = yield select(getUserAddressDetails);
    const firstName = yield select(getUserFirstNameSelector);
    const lastName = yield select(getUserLastNameSelector);
    const email = yield select(getUserEmailSelector);
    const phoneNumber = yield select(getPhoneNumber);
    const shortUrl = getShortUrl(quoteToken);

    // call here instead of the action
    if (hasAccount && email) {
      segmentIdentify(md5(email), {
        email: email,
        firstname: firstName,
        lastname: lastName,
        address: address,
        mobile: formatPhoneNumber(phoneNumber),
        first_name: firstName,
        last_name: lastName,
        phone: formatPhoneNumber(phoneNumber),
        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
      });
    }

    segmentTrack('Scrap_Car_Create_Quote', {
      id: scrapQuoteId,//TODO: this field has the same value with quote_id, may be removed in a future version
      quote_id: scrapQuoteId,
      postcode: postcode,
      quoteStatus: status,
      quote_status: status,
      token: quoteToken,//TODO: this field has the same value with quote_token, may be removed in a future version
      quote_token: quoteToken,
      shortUrl: shortUrl,//TODO: this field has the same value with short_url, may be removed in a future version
      short_url: shortUrl,
      registrationNumber: registrationNumber,//TODO: this field has the same value with registration_number, may be removed in a future version
      registration_number: registrationNumber,
      funnel_name: QUOTE_FUNNEL_NAME,
      funnel_step: QUOTE_FUNNEL_STEP_NUMBER.CREATE_QUOTE,
    });

    if (HAS_QUOTES_OFFER_STATUSES.includes(status)) {
      yield put(
        continueWithQuoteShortLinkSuccess({ scrapQuoteId, entityId, quoteToken, status, applicationToken, ...data }),
      );
    } else {
      yield put(
        createQuoteSuccess({
          quoteId: scrapQuoteId,
          quoteToken,
          quoteStatus: status,
          entityId,
          isManual: data.isManual,
          hintText: data.hintText,
          image: data.image,
          salvagePrice: data.salvagePrice,
          maxMotMileage: data.maxMotMileage,
          auction: data.auction
        }),
      );
    }
    setSubmitting(false);
  } catch (err) {
    reportToBugsnag(err, 'createQuote');
    const setFormFieldsErrors = () => {
      const { registration: registrationNumber, ...restErrorMessages } = err?.error?.messages;
      setFormikErrors(setErrors, { registrationNumber, ...restErrorMessages });
    };

    yield errorHandler(err, createQuoteError, setFormFieldsErrors, setSubmitting);
    if (
      err?.message?.indexOf('already been scrapped') !== -1 ||
      err?.error?.messages?.common?.indexOf('Please login') !== -1
    ) {
      // yield call(Router.pushRoute, `/my-account`);
      window.location.href = '/my-account';
    }
  }
}

export function* enterDetails({
  payload: {
    actions: { setErrors, setSubmitting, setFieldTouched },
    values,
  },
}) {
  try {
    if (setSubmitting) setSubmitting(true);
    const quoteId = yield select(getQuoteId);
    const [address] = yield select(getUserAddressDetails);
    const firstName = yield select(getUserFirstNameSelector);
    const lastName = yield select(getUserLastNameSelector);
    const email = yield select(getUserEmailSelector);
    let mobile = yield select(getUserPhoneNumberSelector);
    mobile = mobile || values?.phoneNumber;
    const gdprConsent = true;
    const quoteStatus = yield select(getQuoteStatus);

    if (mobile) {
      mobile = formatPhoneNumber(mobile);
    }

    if (
      quoteStatus === QUOTE_STATUS.DRAFT ||
      quoteStatus === QUOTE_STATUS.CREATED ||
      quoteStatus === QUOTE_STATUS.ARRANGE
    ) {
      const { scrapQuoteId, quoteToken, registration, weight, price, status, image, year, color, ...data } = yield call(
        scrapCarQuote.enterDetails,
        quoteId,
        {
          email,
          mobile,
          gdpr_consent: gdprConsent ? 1 : 0,
        },
      );
      yield put(stopEnterDetailsTimer());
      yield put(
        enterDetailsSuccess({
          ...data,
          quoteId: scrapQuoteId,
          quoteToken,
          registrationNumber: registration,
          phoneNumber: mobile,
          carWeight: weight,
          carPrice: price,
          quoteStatus: status,
          carImage: image,
          carYear: year,
          carColour: color,
        }),
      );
    } else {
      yield put(enterDetailsSuccess({}));
    }
    const fullQuote = yield select(getQuote);
    const shortUrl = getShortUrl(fullQuote.quoteToken);

    if (email) {
      segmentIdentify(md5(email), {
        email,
        firstname: firstName,
        lastname: lastName,
        first_name: firstName,
        last_name: lastName,
        address,
        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
      });
    }

    segmentTrack('Scrap_Car_Enter_Details', {
      car_colour: fullQuote.carColour,
      carMake: fullQuote.carMake,//TODO: this field has the same value with car_make, may be removed in a future version
      car_make: fullQuote.carMake,
      carModel: fullQuote.carRange,//TODO: this field has the same value with car_model, may be removed in a future version
      car_model: fullQuote.carRange,
      price: fullQuote.carPrice,//TODO: this field has the same value with car_price, may be removed in a future version
      car_price: fullQuote.carPrice,
      vehicleWeight: fullQuote.carWeight,//TODO: this field has the same value with car_weight, may be removed in a future version
      car_weight: fullQuote.carWeight,
      car_year: fullQuote.carYear,
      currency: QUOTE_CURRENCY.GBP,
      postcode: fullQuote.postcode,
      outcode: postToOutcode(fullQuote.postcode),
      quote_id: fullQuote.quoteId,
      quoteStatus,//TODO: this field has the same value with quote_status, may be removed in a future version
      quote_status: quoteStatus,
      token: fullQuote.quoteToken,//TODO: this field has the same value with quote_token, may be removed in a future version
      quote_token: fullQuote.quoteToken,
      registrationNumber: fullQuote.registrationNumber,//TODO: this field has the same value with registration_number, may be removed in a future version
      registration_number: fullQuote.registrationNumber,
      shortUrl,//TODO: this field has the same value with short_url, may be removed in a future version
      short_url: shortUrl,
      ...(mobile ? { mobile } : {}),//TODO: this field may be removed in a future version
      funnel_name: QUOTE_FUNNEL_NAME,
      funnel_step: QUOTE_FUNNEL_STEP_NUMBER.ENTER_DETAILS,
      partner_id: fullQuote.entityId,
      email,
      car_auction_value: fullQuote.auction
    });

    if (setSubmitting) setSubmitting(false);
  } catch (err) {
    reportToBugsnag(err, 'enterDetails');

    const setFormFieldsErrors = () => {
      const { ...restErrorMessages } = err?.error?.messages;
      if (setErrors && setFieldTouched) setFormikErrors(setErrors, { ...restErrorMessages }, setFieldTouched);
    };
    yield errorHandler(err, enterDetailsError, setFormFieldsErrors, setSubmitting);
  }
}

export function* arrangeCollection({ payload: { password, formikBag } }) {
  try {
    formikBag.setSubmitting(true);
    const quoteId = yield select(getQuoteId);
    const quoteStatus = yield select(getQuoteStatus);
    const [terms, preferredCollectionDateTime] = yield select(getUserPersonalDetails);
    let isAddressManual = yield select(getIsAddressManual);
    const hasAccount = yield select(getHasAccount);
    const isAuthenticated = yield select(isUserAuthenticatedSelector);
    const [address, address1, address2, addressCityTown, county] = yield select(getUserAddressDetails);
    let postCoordinate = yield select(getCoordinate);
    const firstName = yield select(getUserFirstNameSelector);
    const lastName = yield select(getUserLastNameSelector);
    const mobile = yield select(getPhoneNumber);
    const email = yield select(getUserEmailSelector);
    const { date, startTime, endTime, slotDefaultType } = preferredCollectionDateTime;

    // call here instead of the action
    const fullQuote = yield select(getQuote);
    const postcode = fullQuote.postcode;
    const outcode = postToOutcode(postcode);
    const shortUrl = getShortUrl(fullQuote.quoteToken);

    const entityId = yield select(getEntityId);

    if (email) {
      segmentIdentify(md5(email), {
        email,
        firstname: firstName,
        lastname: lastName,
        address,
        mobile: formatPhoneNumber(mobile),
        first_name: firstName,
        last_name: lastName,
        phone: formatPhoneNumber(mobile),
        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
      });
    }

    // get coordination from address
    const finalAddress = isAddressManual
      ? (address1 && `${address1} `) +
      (address2 && `${address2} `) +
      (addressCityTown && `${addressCityTown} `) +
      (county && county)
      : address?.label;

    const assignAddressInfo = {};
    if (!isAddressManual) {
      // address label is transform api info, come from: https://getaddress.io/Documentation
      const addressItems = address?.label?.split(',');
      if (Array.isArray(addressItems) && addressItems.length > 2) {
        const { length } = addressItems;
        const countyFromApi = addressItems[length - 1];
        const townFromApi = addressItems[length - 2];
        if (countyFromApi?.replace(/\s+/g, '')) {
          assignAddressInfo.county = countyFromApi;
        }
        if (townFromApi?.replace(/\s+/g, '')) {
          assignAddressInfo.addressCityTown = townFromApi;
        }
      }
    } else {
      if (county?.replace(/\s+/g, '')) {
        assignAddressInfo.county = county;
      }
      if (addressCityTown?.replace(/\s+/g, '')) {
        assignAddressInfo.addressCityTown = addressCityTown;
      }
    }
    try {
      Geocode.setApiKey(process.env.GOOGLE_GEO_KEY_ONLY);
      const coordinate = yield call(Geocode.fromAddress, `${finalAddress} ${postcode}`);
      if (coordinate?.status === 'OK' && coordinate?.results?.length) {
        postCoordinate = coordinate.results[0].geometry.location;
      } else {
        // we cannot get address from google api, just use postcode geo location.
        isAddressManual = true;
      }
    } catch (err) {
      // any exception, we will use postcode geo location
      isAddressManual = true;
    }

    segmentTrack('Scrap_Car_Arrange_Collection', {
      car_colour: fullQuote.carColour,
      carMake: fullQuote.carMake,//TODO: this field has the same value with car_make, may be removed in a future version
      car_make: fullQuote.carMake,
      carModel: fullQuote.carRange,//TODO: this field has the same value with car_model, may be removed in a future version
      car_model: fullQuote.carRange,
      price: fullQuote.carPrice,//TODO: this field has the same value with car_price, may be removed in a future version
      car_price: fullQuote.carPrice,
      vehicleWeight: fullQuote.carWeight,//TODO: this field has the same value with car_weight, may be removed in a future version
      car_weight: fullQuote.carWeight,
      car_year: fullQuote.carYear,
      currency: QUOTE_CURRENCY.GBP,
      postcode,
      outcode,
      quote_id: fullQuote.quoteId,
      quoteStatus,//TODO: this field has the same value with quote_status, may be removed in a future version
      quote_status: quoteStatus,
      token: fullQuote.quoteToken,//TODO: this field has the same value with quote_token, may be removed in a future version
      quote_token: fullQuote.quoteToken,
      registrationNumber: fullQuote.registrationNumber,//TODO: this field has the same value with registration_number, may be removed in a future version
      registration_number: fullQuote.registrationNumber,
      shortUrl,  //TODO: this field has the same value with short_url, may be removed in a future version
      short_url: shortUrl,
      collection_time: `${date} ${startTime}-${endTime}`,
      collection_day: formatDateToWeek(date),
      collection_address: finalAddress,
      funnel_name: QUOTE_FUNNEL_NAME,
      funnel_step: QUOTE_FUNNEL_STEP_NUMBER.ARRANGE_COLLECTION,
      mobile: formatPhoneNumber(mobile),
      email,
      partner_id: entityId,
      car_auction_value: fullQuote.auction
    });

    const result = yield call(scrapCarQuote.arrangeCollection, quoteId, {
      firstName,
      lastName,
      mobile,
      isAddressManual,
      address: finalAddress,
      ...postCoordinate,
      date,
      startTime,
      endTime,
      ...assignAddressInfo,
      ...(!hasAccount && { password, terms }),
      slotDefaultType,
    });
    const { offerType, salvageOfferConditionsData } = result;
    yield put(
      arrangeCollectionSuccess({
        quoteId: quoteId,
        quoteToken: fullQuote.quoteToken,
        quoteStatus: 'pending_payment',
        offerType,
        salvageOfferConditionsData,
      }),
    );
    if (!isAuthenticated) {
      yield put(userLoginRequest({ email, password }, formikBag, QUOTE_ROUTES.PAYMENT_METHOD, !!hasAccount));
    } else {
      yield put(completeArrangeCollection({}));
    }
    formikBag?.setSubmitting(false);
  } catch (err) {
    formikBag?.setSubmitting(false);
    const errorMessages = getErrorMessage(err);
    if (err.status === 409) {
      if (err.refreshOnly) {
        const quoteId = yield select(getQuoteId);
        yield put(getBookingSlotsRequest({ quoteId }));
        yield put(showToast({ message: `${errorMessages}`, kind: 'error' }));
      }
    } else if (errorMessages.indexOf('has been confirmed') !== -1) {
      yield put(showToast({ message: `${errorMessages}`, kind: 'error' }));
      redirect({}, '/my-account/scrap-my-car');
    } else {
      reportToBugsnag(err, 'arrangeCollection');

      const setFormFieldsErrors = () => {
        const { addressCityTown: town, ...restErrorMessages } = err?.error?.messages;
        setFormikErrors(
          formikBag?.setErrors,
          {
            town,
            ...restErrorMessages,
          },
          formikBag?.setFieldTouched,
        );
      };
      yield errorHandler(err, arrangeCollectionError, setFormFieldsErrors, formikBag?.setSubmitting);
    }
  }
}

export function* getBookingSlots({ payload: { quoteId } }) {
  try {
    const isArrangeCollection = window?.location?.href?.includes('arrange-a-collection');
    let data = yield call(scrapCarQuote.getBookingSlots, quoteId, { isArrangeCollection });
    const quoteToken = yield select(getQuoteToken);
    let freeSlotDate = '';
    if (data instanceof Array && data.length > 0) {
      const slots = [];
      data.forEach(item => {
        if (!freeSlotDate && item.availableSlots) freeSlotDate = moment(item.date).format('YYYY-MM-DD');
        slots.push({
          date: moment(item.date).format('MM/DD/YYYY'),
          day: item.day,
          slotType: item.slotType,
          totalSlots: item.slotNum,
          availableSlots: item.availableSlots,
        });
      });
      data = data.map(item => ({
        ...item,
        key: getRandomString(),
      }));
    }
    yield put(getBookingSlotsSuccess(data));
  } catch (err) {
    reportToBugsnag(err, 'getBookingSlots');
  }
}

// eslint-disable-next-line consistent-return
export function* getAddresses({ payload }) {
  try {
    let postcode = yield select(getPostcode);

    const payloadPostcode = typeof payload === 'string' ? payload : payload?.postcode;
    if (!postcode || postcode !== payloadPostcode) {
      postcode = payloadPostcode;
    }
    if (!postcode) return null;

    const data = yield call(newLocations.getLocations, postcode, payload?.expandAddress);
    if (!payload?.expandAddress) {
      const filterCommas = value => {
        let newVal = value.replace(', , , , , ', ', ');
        newVal = newVal.replace(', , , , ', ', ');
        newVal = newVal.replace(', , , ', ', ');
        return newVal;
      };

      if (data) {
        let addressesOptions = [];
        if (data?.addresses?.length)
          addressesOptions = data.addresses.map((address, index) => ({
            value: index,
            label: filterCommas(address),
            address,
          }));
        yield put(
          getAddressesByPostcodeSuccess({
            latitude: data.latitude,
            longitude: data.longitude,
            addresses: addressesOptions,
          }),
        );
      }
    } else if (data) {
      let addressesOptions = [];
      if (data?.addresses?.length)
        addressesOptions = data.addresses.map((address, index) => ({
          value: index,
          label: address.formatted_address.filter(str => !!str).join(', '),
          ...address,
        }));
      yield put(
        getAddressesByPostcodeSuccess({
          latitude: data.latitude,
          longitude: data.longitude,
          addresses: addressesOptions,
        }),
      );
    }
  } catch (err) {
    reportToBugsnag(err, 'getAddresses');

    if (err.status !== 401) {
      let errorMessage = err?.error?.messages?.common?.[0];
      if (!errorMessage) {
        errorMessage = 'We encountered a problem. Please try again.';
      }
      yield put(showToast({ message: `${errorMessage}`, kind: 'error' }));
    }
    yield put(getAddressesByPostcodeError(err));
  }
}

export function* setPaymentMethodInputChangeSegment() {
  try {
    const fullQuote = yield select(getQuote);
    const quoteStatus = yield select(getQuoteStatus);
    const [paymentType, bankFullName] = yield select(getPaymentDetails);
    const shortUrl = getShortUrl(fullQuote.quoteToken);
    const entityId = yield select(getEntityId);
    const email = yield select(getUserEmailSelector);
    const phoneNumber = yield select(getPhoneNumber);

    segmentTrack('Scrap_Car_Set_Payment_Method', {
      car_colour: fullQuote.carColour,
      carMake: fullQuote.carMake,//TODO: this field has the same value with car_make, may be removed in a future version
      car_make: fullQuote.carMake,
      carModel: fullQuote.carRange,//TODO: this field has the same value with car_model, may be removed in a future version
      car_model: fullQuote.carRange,
      price: fullQuote.carPrice,//TODO: this field has the same value with car_price, may be removed in a future version
      car_price: fullQuote.carPrice,
      vehicleWeight: fullQuote.carWeight,//TODO: this field has the same value with car_weight, may be removed in a future version
      car_weight: fullQuote.carWeight,
      car_year: fullQuote.carYear,
      currency: QUOTE_CURRENCY.GBP,
      outcode: postToOutcode(fullQuote.postcode),
      postcode: fullQuote.postcode,
      quote_id: fullQuote.quoteId,
      quoteStatus,//TODO: this field has the same value with quote_status, may be removed in a future version
      quote_status: quoteStatus,
      token: fullQuote.quoteToken,//TODO: this field has the same value with quote_token, may be removed in a future version
      quote_token: fullQuote.quoteToken,
      registrationNumber: fullQuote.registrationNumber,//TODO: this field has the same value with registration_number, may be removed in a future version
      registration_number: fullQuote.registrationNumber,
      shortUrl,//TODO: this field has the same value with short_url, may be removed in a future version
      short_url: shortUrl,
      paymentType,//TODO: this field has the same value with payment_method, may be removed in a future version
      payment_method: paymentType.label,
      bankFullName,//TODO: may be removed in a future version
      funnel_name: QUOTE_FUNNEL_NAME,
      funnel_step: QUOTE_FUNNEL_STEP_NUMBER.SET_PAYMENT_METHOD,
      partner_id: entityId,
      email,
      mobile: formatPhoneNumber(phoneNumber),
      car_auction_value: fullQuote.auction,
    });
  } catch (err) {
    reportToBugsnag(err, 'setPaymentMethodInputChangeSegment');
  }
}

export function* setPaymentMethod({
  payload: {
    actions: { setErrors, setSubmitting, setFieldTouched },
  },
}) {
  try {
    setSubmitting(true);
    const quoteId = yield select(getQuoteId);
    const quoteStatus = yield select(getQuoteStatus);
    const [paymentType, bankFullName, bankSortCode, bankAccountNumber, referralCode] = yield select(getPaymentDetails);
    const [address] = yield select(getUserAddressDetails);
    const firstName = yield select(getUserFirstNameSelector);
    const lastName = yield select(getUserLastNameSelector);
    const email = yield select(getUserEmailSelector);
    const phoneNumber = yield select(getPhoneNumber);

    if (email) {
      segmentIdentify(md5(email), {
        email,
        firstname: firstName,
        lastname: lastName,
        address,
        mobile: formatPhoneNumber(phoneNumber),
        first_name: firstName,
        last_name: lastName,
        phone: formatPhoneNumber(phoneNumber),
        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
      });
    }

    // call here instead of the action
    const fullQuote = yield select(getQuote);
    const postcode = fullQuote.postcode;
    const outcode = postToOutcode(postcode);
    const shortUrl = getShortUrl(fullQuote.quoteToken);

    if (quoteStatus === QUOTE_STATUS.PENDING_PAYMENT) {
      const { scrapQuoteId, status, price, schedule, isSalvageOfferQuote = false } = yield call(scrapCarQuote.paymentMethod, quoteId, {
        paymentType: paymentType?.value,
        bankFullName,
        bankSortCode,
        bankAccountNumber,
        referralCode,
      });
      yield put(
        setPaymentMethodSuccess({
          quoteId: scrapQuoteId,
          quoteStatus: status,
          carPrice: price,
          schedule,
          isSalvageOfferQuote,
        }),
      );

      const preferredCollectionDateTime = yield select(getPreferredCollectionDateTime);
      const offerConditions = yield select(getOfferConditions);
      const { date, startTime, endTime } = preferredCollectionDateTime;
      let isAddressManual = yield select(getIsAddressManual);
      const [address, address1, address2, addressCityTown, county] = yield select(getUserAddressDetails);
      const email = yield select(getUserEmailSelector);

      // get coordination from address
      const finalAddress = isAddressManual
        ? (address1 && `${address1} `) +
        (address2 && `${address2} `) +
        (addressCityTown && `${addressCityTown} `) +
        (county && county)
        : address?.label;
      const salvageOfferConditionsData = yield select(getSalvageOfferConditionsData);
      const offerType = yield select(getOfferType);

      const segmentParam = {
        car_colour: fullQuote.carColour,
        car_make: fullQuote.carMake,
        car_model: fullQuote.carRange,
        car_price: fullQuote.carPrice,
        car_weight: fullQuote.carWeight,
        car_year: fullQuote.carYear,
        currency: QUOTE_CURRENCY.GBP,
        outcode: postToOutcode(fullQuote.postcode),
        postcode: fullQuote.postcode,
        partner_id: fullQuote.entityId,
        quote_id: fullQuote.quoteId,
        quote_status: status,
        quote_token: fullQuote.quoteToken,
        registration_number: fullQuote.registrationNumber,
        short_url: shortUrl,
        collection_time: `${date} ${startTime}-${endTime}`,
        collection_day: formatDateToWeek(date),
        collection_address: finalAddress,
        payment_method: paymentType.label,
        account_code: formatPartHideBankAccount(bankAccountNumber),
        sort_code: bankSortCode,
        is_manual: Boolean(fullQuote.isManual),
        email,
        to_user: email,
        funnel_name: QUOTE_FUNNEL_NAME,
        funnel_step: QUOTE_FUNNEL_STEP_NUMBER.CONFIRM_QUOTE,
      }

      switch (offerType) {
        case QUOTE_OFFER_TYPE.SALVAGE_OFFER: {
          segmentTrack('Customer_Salvage_Offer_Accepted', {
            ...segmentParam,
            offer_conditions: salvageOfferConditionsData?.offerConditions,
            partner_filters: salvageOfferConditionsData?.partnerFilters,
            highest_deduction: salvageOfferConditionsData?.highestDeduction,
            auction_price: salvageOfferConditionsData?.auctionPrice,
          });
          break;
        }
        case QUOTE_OFFER_TYPE.CONDITION_OFFER: {
          segmentTrack('Customer_Condition_Offer_Accepted', {
            ...segmentParam,
            offer_conditions: offerConditions?.filter(item => !!item?.value)?.map(item => item.name),
          });
          break;
        }
        case QUOTE_OFFER_TYPE.SCRAP_OFFER: {
          segmentTrack('Customer_Scrap_Offer_Accepted', {
            ...segmentParam,
          });
          break;
        }
        default: {
          segmentTrack('Scrap_Car_Confirm_Quote', {
            ...segmentParam,
            mobile: formatPhoneNumber(phoneNumber),
            car_auction_value: fullQuote.auction
          });
        }
      }
    } else {
      yield put(setPaymentMethodSuccess({}));
    }
    setSubmitting(false);
  } catch (err) {
    setSubmitting(false);

    if (err.status === 409) {
      if (err.redirect) {
        const errorMessages = getErrorMessage(err);
        const quoteId = yield select(getQuoteId);
        yield put(getBookingSlotsRequest({ quoteId }));
        yield put(showToast({ message: `${errorMessages}`, kind: 'error' }));
        const quoteStatus = QUOTE_STATUS.ARRANGE;
        const stepRouteToRedirect = getStepRoute(quoteStatus);
        yield redirectToStep(stepRouteToRedirect);
      }
    } else {
      reportToBugsnag(err, 'setPaymentMethod');

      const setFormFieldsErrors = () => {
        const { paymentType: payment, bankFullName: fullName, ...restErrorMessages } = err?.error?.messages;
        setFormikErrors(setErrors, { payment, fullName, ...restErrorMessages }, setFieldTouched);
      };
      yield errorHandler(err, setPaymentMethodError, setFormFieldsErrors, setSubmitting);
    }
  }
}

export function* continueWithQuote({ payload: { token } }) {
  try {
    const quoteToken = yield select(getQuoteToken);
    if (token || quoteToken) {
      const data = yield call(scrapCarQuote.continueWithQuote, token || quoteToken);
      const { applicationToken } = data;
      setCookie('scrap-authorization', applicationToken);
      yield put(continueWithQuoteSuccess(data));
    }
  } catch (err) {
    reportToBugsnag(err, 'continueWithQuote');

    yield put(continueWithQuoteError(err));
  }
}

export function* refreshPrice({ payload: { id } }) {
  try {
    const data = yield call(quotes.refreshPrice, id);
    yield put(refreshPriceSuccess(data));
  } catch (err) {
    reportToBugsnag(err, 'refreshPrice');

    yield put(refreshPriceError(err));
  }
}

export function* setOnlineQuoteData({ payload: { quoteStatus } }) {
  try {
    const quoteId = yield select(getQuoteId);
    const entityId = yield select(getEntityId);
    const [address] = yield select(getUserAddressDetails);
    const firstName = yield select(getUserFirstNameSelector);
    const lastName = yield select(getUserLastNameSelector);
    const email = yield select(getUserEmailSelector);
    const phoneNumber = yield select(getPhoneNumber);

    if (email) {
      segmentIdentify(md5(email), {
        email,
        firstname: firstName,
        lastname: lastName,
        address,
        mobile: formatPhoneNumber(phoneNumber),
        first_name: firstName,
        last_name: lastName,
        phone: formatPhoneNumber(phoneNumber),
        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
      });
    }

    // call here instead of the action
    const fullQuote = yield select(getQuote);
    const shortUrl = getShortUrl(fullQuote.quoteToken);

    segmentTrack('Scrap_Car_Accept_Quote', {
      car_colour: fullQuote.carColour,
      carMake: fullQuote.carMake,//TODO: this field has the same value with car_make, may be removed in a future version
      car_make: fullQuote.carMake,
      carModel: fullQuote.carRange,//TODO: this field has the same value with car_model, may be removed in a future version
      car_model: fullQuote.carRange,
      price: fullQuote.carPrice,//TODO: this field has the same value with car_price, may be removed in a future version
      car_price: fullQuote.carPrice,
      vehicleWeight: fullQuote.carWeight,//TODO: this field has the same value with car_weight, may be removed in a future version
      car_weight: fullQuote.carWeight,
      car_year: fullQuote.carYear,
      currency: QUOTE_CURRENCY.GBP,
      postcode: fullQuote.postcode,
      outcode: postToOutcode(fullQuote.postcode),
      partnerID: entityId,//TODO: this field has the same value with partner_id, may be removed in a future version
      partner_id: entityId,
      id: quoteId,//TODO: this field has the same value with quote_id, may be removed in a future version
      quote_id: quoteId,
      quote_status: fullQuote.quoteStatus,
      token: fullQuote.quoteToken,//TODO: this field has the same value with quote_token, may be removed in a future version
      quote_token: fullQuote.quoteToken,
      registrationNumber: fullQuote.registrationNumber,//TODO: this field has the same value with registration_number, may be removed in a future version
      registration_number: fullQuote.registrationNumber,
      shortUrl,//TODO: this field has the same value with short_url, may be removed in a future version
      short_url: shortUrl,
      funnel_name: QUOTE_FUNNEL_NAME,
      funnel_step: QUOTE_FUNNEL_STEP_NUMBER.ACCEPT_QUOTE,
      email,
      mobile: formatPhoneNumber(phoneNumber),
      car_auction_value: fullQuote.auction
    });

    yield put(setOnlineQuoteSuccess({ quoteStatus }));
  } catch (err) {
    reportToBugsnag(err, 'setOnlineQuoteData');

    yield put(setOnlineQuoteError(err));
  }
}

export function* cancelQuote() {
  try {
    const quoteId = yield select(getQuoteId);
    const [address] = yield select(getUserAddressDetails);
    const firstName = yield select(getUserFirstNameSelector);
    const lastName = yield select(getUserLastNameSelector);
    const email = yield select(getUserEmailSelector);
    const phoneNumber = yield select(getPhoneNumber);
    if (email) {
      segmentIdentify(md5(email), {
        email,
        firstname: firstName,
        lastname: lastName,
        address,
        mobile: formatPhoneNumber(phoneNumber),
        first_name: firstName,
        last_name: lastName,
        phone: formatPhoneNumber(phoneNumber),
        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
      });
    }

    yield call(quotes.cancelQuote, { quoteId });
    yield put(cancelQuoteSuccess());

    // call here instead of the action
    const fullQuote = yield select(getQuote);
    const shortUrl = getShortUrl(fullQuote.quoteToken);

    segmentTrack('Scrap_Car_Cancel_Quote', {
      car_colour: fullQuote.carColour,
      carMake: fullQuote.carMake,//TODO: this field has the same value with car_make, may be removed in a future version
      car_make: fullQuote.carMake,
      carModel: fullQuote.carRange,//TODO: this field has the same value with car_model, may be removed in a future version
      car_model: fullQuote.carRange,
      price: fullQuote.carPrice,//TODO: this field has the same value with car_price, may be removed in a future version
      car_price: fullQuote.carPrice,
      vehicleWeight: fullQuote.carWeight,//TODO: this field has the same value with car_weight, may be removed in a future version
      car_weight: fullQuote.carWeight,
      car_year: fullQuote.carYear,
      currency: QUOTE_CURRENCY.GBP,
      postcode: fullQuote.postcode,
      outcode: postToOutcode(fullQuote.postcode),
      quote_id: fullQuote.quoteId,
      // need to pass new job status value after call cancel quote
      quote_status: BOOKING_JOB_STATUS.STATUS_CANCELLED,//TODO: this field need to be confirmed, should we use the value "accept" for scrap_car_cancel_quote?
      token: fullQuote.quoteToken,//TODO: this field has the same value with quote_token, may be removed in a future version
      quote_token: fullQuote.quoteToken,
      registrationNumber: fullQuote.registrationNumber,//TODO: this field has the same value with registration_number, may be removed in a future version
      registration_number: fullQuote.registrationNumber,
      short_url: shortUrl,
      funnel_name: QUOTE_FUNNEL_NAME,
      funnel_step: QUOTE_FUNNEL_STEP_NUMBER.CANCEL_QUOTE,
      partner_id: fullQuote.entityId,
      is_manual: Boolean(fullQuote.isManual),
      email,
      to_user: email,
      mobile: formatPhoneNumber(phoneNumber)
    });
  } catch (err) {
    reportToBugsnag(err, 'cancelQuote');

    yield put(cancelQuoteError(err));
  }
}

export function* saveAbandonedData() {
  try {
    const quoteStatus = yield select(getQuoteStatus);
    if (quoteStatus === QUOTE_STATUS.DRAFT) {
      const quoteId = yield select(getQuoteId);
      const email = yield select(getUserEmailSelector);
      const phoneNumber = yield select(getUserPhoneNumberSelector);
      const isEmailValid = yield emailValidation.isValid(email);
      const isMobileValid = yield contactNumber(true).isValid(phoneNumber);

      const dataToSend = {
        ...(isEmailValid && { email }),
        ...(isMobileValid && { mobile: phoneNumber }),
      };

      if (Object.keys(dataToSend).length) {
        yield call(scrapCarQuote.saveAbandonedData, quoteId, dataToSend);
      }
      yield put(saveAbandonedDataSuccess());
    }
  } catch (err) {
    reportToBugsnag(err, 'saveAbandonedData');

    yield put(saveAbandonedDataError(err));
  }
}

export function* setEnterDetailsTimer() {
  try {
    yield delay(ENTER_DETAILS_TIMEOUT);

    yield put(saveAbandonedDataRequest());
  } catch (err) {
    reportToBugsnag(err, 'setEnterDetailsTimer');
  } finally {
    if (yield cancelled()) {
      // If you want to do something on canceled timer do it here
    }
  }
}

export function* watchEnterDetailsTimer() {
  const timerTask = yield fork(setEnterDetailsTimer);

  yield take(QuoteTypes.STOP_ENTER_DETAILS_TIMER);
  yield cancel(timerTask);
}

export function* fetchApplication({ payload }) {
  try {
    const response = yield call(scrapCarQuote.fetch);
    yield put({
      type: QuoteTypes.FETCH_SCRAP_APPLICATION.SUCCESS,
      payload: response,
    });

    // refresh at arrange collection
    if (payload == 2) {
      const isAuthenticated = yield select(isUserAuthenticatedSelector);
      const postcode = yield select(getPostcode);
      const quoteId = yield select(getQuoteId);

      if (isAuthenticated) {
        yield put(getUserDataRequest());
      }

      if (postcode) {
        yield put(getAddressesByPostcodeRequest(postcode));
      }
      if (quoteId) yield put(getBookingSlotsRequest({ quoteId }));
    }
  } catch (e) {
    reportToBugsnag(e, 'fetchApplication');

    yield put({
      type: QuoteTypes.FETCH_SCRAP_APPLICATION.ERROR,
      payload: e,
    });
  }
}

export function* cancelConditionOffer() {
  try {
    const email = yield select(getUserEmailSelector);
    const quoteId = yield select(getQuoteId);
    const fullQuote = yield select(getQuote);
    const phoneNumber = yield select(getPhoneNumber);
    setCookie('capture_form_token', fullQuote.quoteToken);
    const shortUrl = getShortUrl(fullQuote.quoteToken);
    segmentTrack('Scrap_Car_Cancel_Quote', {
      car_colour: fullQuote.carColour,
      carMake: fullQuote.carMake, // TODO: this field has the same value with car_make, may be removed in a future version
      car_make: fullQuote.carMake,
      carModel: fullQuote.carRange, // TODO: this field has the same value with car_model, may be removed in a future version
      car_model: fullQuote.carRange,
      price: fullQuote.carPrice, // TODO: this field has the same value with car_price, may be removed in a future version
      car_price: fullQuote.carPrice,
      vehicleWeight: fullQuote.carWeight, // TODO: this field has the same value with car_weight, may be removed in a future version
      car_weight: fullQuote.carWeight,
      car_year: fullQuote.carYear,
      currency: QUOTE_CURRENCY.GBP,
      postcode: fullQuote.postcode,
      outcode: postToOutcode(fullQuote.postcode),
      quote_id: fullQuote.quoteId,
      // need to pass new job status value after call cancel quote
      quote_status: BOOKING_JOB_STATUS.STATUS_CANCELLED, // TODO: this field need to be confirmed, should we use the value "accept" for scrap_car_cancel_quote?
      token: fullQuote.quoteToken, // TODO: this field has the same value with quote_token, may be removed in a future version
      quote_token: fullQuote.quoteToken,
      registrationNumber: fullQuote.registrationNumber, // TODO: this field has the same value with registration_number, may be removed in a future version
      registration_number: fullQuote.registrationNumber,
      short_url: shortUrl,
      funnel_name: QUOTE_FUNNEL_NAME,
      funnel_step: QUOTE_FUNNEL_STEP_NUMBER.CANCEL_QUOTE,
      partner_id: fullQuote.entityId,
      is_manual: Boolean(fullQuote.isManual),
      email,
      to_user: email,
      mobile: formatPhoneNumber(phoneNumber),
    });
    yield call(quotes.cancelConditionOffer, { quoteId });
    redirect({}, '/scrap-my-car/vehicle-details', { cancelledOffer: true });
  } catch (err) {
    reportToBugsnag(err, 'cancelQuote');

    yield put(cancelConditionOfferError(err));
  }
}

export function* cancelConditionOfferByToken({ payload }) {
  try {
    const { token, req } = payload;
    yield call(quotes.cancelConditionOfferByToken, { token, req });
  } catch (err) {
    reportToBugsnag(err, 'cancelQuoteByToken');
  }
}

export function* sendCaptureFormSMS({ payload }) {
  try {
    yield put(setSendSmsButtonStatusRequest(true));
    const quoteId = yield select(getQuoteId);
    yield call(quotes.sendCaptureFormSMS, { phoneNumber: payload, quoteId });
    yield put(
      showToast({
        message: 'A message contains a link for this page has been send to number: ' + payload,
        kind: 'success',
      }),
    );
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put(setSendSmsButtonStatusRequest(false));
    yield put(showToast({ message: `${errMsg}`, kind: 'error' }));
    reportToBugsnag(err, 'sendCaptureFormSMS');
  }
}

export function* submitVehicleDetail({ payload }) {
  const quoteId = yield select(getQuoteId);
  const {
    video,
    dashboardImg,
    interiorImgOne,
    interiorImgTwo,
    exteriorImgOne,
    exteriorImgTwo,
    exteriorImgThree,
    exteriorImgFour,
    mileage,
    description,
    interiorCondition,
    exteriorCondition,
    phoneNumber,
    requestCallBack,
    priceWanted,
    actions,
    isOfferCaptureForm,
    ...rest
  } = payload;
  const { setSubmitting } = actions;
  try {
    const formData = new FormData();
    formData.append('mileage', mileage || 0);
    formData.append('description', description);
    formData.append('interiorCondition', interiorCondition);
    formData.append('exteriorCondition', exteriorCondition);
    formData.append('phoneNumber', phoneNumber);
    formData.append('requestCallBack', requestCallBack);
    formData.append('quoteId', quoteId);
    formData.append('priceWanted', priceWanted);
    formData.append('isOfferCaptureForm', isOfferCaptureForm);
    formData.append('conditions', JSON.stringify(rest));
    yield call(quotes.captureDetail, formData);
    segmentTrack('Capture_Form_Submitted', {
      quoteId,
      dashboardImg,
      interiorImgOne,
      interiorImgTwo,
      exteriorImgOne,
      exteriorImgTwo,
      exteriorImgThree,
      exteriorImgFour,
      mileage,
      description,
      interiorCondition,
      exteriorCondition,
    });
    yield put(
      showToast({
        message: 'Vehicle details submitted.',
        kind: 'success',
      }),
    );
    yield put(
      showToast({
        message: 'Thank you for your vehicle info.',
        kind: 'success',
      }),
    );
    redirect({}, '/scrap-my-car');
  } catch (err) {
    setSubmitting(false);
    reportToBugsnag(err, 'submitVehicleDetail');
    yield put(submitVehicleDetailError(err));
    yield errorHandler(err);
  }
}

export function* updateVehicleDetail({ payload }) {
  const quoteId = yield select(getQuoteId);
  const {
    actions: { setSubmitting },
  } = payload;
  try {
    const data = {
      quoteId,
      phoneNumber: payload.phoneNumber,
      requestCallBack: payload.requestCallBack,
    };
    if (payload?.isRequestPhoneNumber) {
      data.isRequestPhoneNumber = true;
    }
    yield call(quotes.updateCaptureDetail, data);
    yield put(
      showToast({
        message: 'Vehicle details submitted.',
        kind: 'success',
      })
    );
    yield put(
      showToast({
        message: 'Thank you for your vehicle info.',
        kind: 'success',
      })
    );
    redirect({}, '/scrap-my-car');
  } catch (err) {
    setSubmitting(false);
    reportToBugsnag(err, 'updateVehicleDetail');
    yield put(updateVehicleDetailError(err));
    yield errorHandler(err);
  }
}

export function* continueWithCaptureShortLink({ payload }) {
  const { token, req, res, cancelOffer, requestCallback } = payload;
  if (token) {
    if (cancelOffer) {
      yield put(cancelConditionOfferByTokenRequest({ token, req, res }));
    }
    setCookie('capture_form_token', token, req, res);
    redirect(
      { res },
      '/scrap-my-car/vehicle-details',
      Object.assign(
        { ...(requestCallback ? { requestCallback: true } : {}) },
        { ...(cancelOffer ? { cancelledOffer: true } : {}) }
      )
    );
  }
}

export function* fetchCaptureFormConditions({ payload }) {
  const { token, req, res } = payload;
  if (token) {
    try {
      const data = yield call(quotes.fetchCaptureForm, token, req);
      const { applicationToken } = data;
      setCookie('scrap-authorization', applicationToken, req, res);
      yield put(continueWithCaptureShortLinkSuccess(data));
    } catch (err) {
      const errMsg = getErrorMessage(err);
      if (errMsg.indexOf('Thank') !== -1 || errMsg.indexOf('Sorry') !== -1) {
        preserveToastOnSSR(errMsg, 'success', req, res);
        redirect({ res }, '/scrap-my-car');
      }
      yield put(continueWithCaptureShortLinkError(err));
    }
  } else {
    redirect({ res }, '/scrap-my-car');
  }
  if (req) yield put(END);
}

export function* uploadCaptureFormMedia({ payload }) {
  const quoteId = yield select(getQuoteId);
  try {
    const { name, file } = payload;
    const formData = new FormData();
    formData.append(name, file.src);
    formData.append('quoteId', quoteId);
    const data = yield call(quotes.uploadCaptureFormMedia, formData);
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put(showToast({ message: errMsg, kind: 'error' }));
  }
}

export function* uploadCaptureFormVideo({ payload }) {
  const quoteId = yield select(getQuoteId);
  try {
    const { name, file } = payload;
    const formData = new FormData();
    formData.append(name, file.src);
    formData.append('quoteId', quoteId);
    yield call(quotes.uploadCaptureFormMedia, formData);
    yield put(uploadCaptureFormVideoSuccess());
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put(showToast({ message: errMsg, kind: 'error' }));
  }
}

export function* customerRequestCallBack({ payload }) {
  const quoteId = yield select(getQuoteId);
  const {
    actions: { setSubmitting },
  } = payload;
  try {
    const data = {
      quoteId,
      phoneNumber: payload.phoneNumber,
    };
    yield call(quotes.customerRequestCallBack, data);

    yield put(
      showToast({
        message: 'Thank you. Our operators will contact you soon.',
        kind: 'success',
      })
    );
    redirect({}, '/scrap-my-car');
  } catch (err) {
    setSubmitting(false);
    reportToBugsnag(err, 'customerRequestCallBack');
    yield put(customerRequestCallBackError(err));
    yield errorHandler(err);
  }
}

export function* getSalvageOfferFormConfig({ payload }) {
  try {
    const data = yield call(quotes.getSalvageOfferFormConfig, { req: payload?.req });

    yield put(getSalvageOfferFormConfigSuccess(data));
  } catch (err) {
    reportToBugsnag(err, 'getSalvageOfferFormConfig');
    yield put(getSalvageOfferFormConfigError(err));
    yield errorHandler(err);
  }
}

export function* setSalvagerOfferStartSegment() {
  try {
    const fullQuote = yield select(getQuote);
    const quoteStatus = yield select(getQuoteStatus);
    const shortUrl = getShortUrl(fullQuote.quoteToken);
    const email = yield select(getUserEmailSelector);
    const mobile = fullQuote.phoneNumber ? formatPhoneNumber(fullQuote.phoneNumber): null;
    segmentTrack('Customer_Start_Salvage_Offer', {
      car_colour: fullQuote.carColour,
      car_make: fullQuote.carMake,
      car_model: fullQuote.carRange,
      car_price: fullQuote.carPrice,
      car_weight: fullQuote.carWeight,
      car_year: fullQuote.carYear,
      currency: QUOTE_CURRENCY.GBP,
      outcode: postToOutcode(fullQuote.postcode),
      postcode: fullQuote.postcode,
      quote_id: fullQuote.quoteId,
      quote_status: quoteStatus,
      quote_token: fullQuote.quoteToken,
      registration_number: fullQuote.registrationNumber,
      short_url: shortUrl,
      partner_id: fullQuote.entityId,
      is_manual: Boolean(fullQuote.isManual),
      salvage_offer_price: fullQuote.salvagePrice,
      email,
      to_user: email,
      mobile
    });

    Router.pushRoute(QUOTE_ROUTES.SALVAGE_DETAILS);
  } catch (err) {
    Router.pushRoute(QUOTE_ROUTES.SALVAGE_DETAILS);
    reportToBugsnag(err, 'customerStartSalvagerOfferSegment');
  }
}

export function* setBetterThanScrapSegment() {
  try {
    const fullQuote = yield select(getQuote);
    const quoteStatus = yield select(getQuoteStatus);
    const shortUrl = getShortUrl(fullQuote.quoteToken);
    const mobile = fullQuote.phoneNumber ? formatPhoneNumber(fullQuote.phoneNumber): null;
    const quoteHighestPriceCookieKey = 'QuoteHighestPrice' + fullQuote.quoteId;
    const quoteHighestPrice = +getCookie(quoteHighestPriceCookieKey);

    if (!quoteHighestPrice || quoteHighestPrice !== fullQuote.salvagePrice) {
      segmentTrack('Scrap_Car_Better_Than_Scrap', {
        car_colour: fullQuote.carColour,
        carMake: fullQuote.carMake,//TODO: this field has the same value with car_make, may be removed in a future version
        car_make: fullQuote.carMake,
        carModel: fullQuote.carRange,//TODO: this field has the same value with car_model, may be removed in a future version
        car_model: fullQuote.carRange,
        price: fullQuote.carPrice,//TODO: this field has the same value with car_price, may be removed in a future version
        car_price: fullQuote.carPrice,
        vehicleWeight: fullQuote.carWeight,//TODO: this field has the same value with car_weight, may be removed in a future version
        car_weight: fullQuote.carWeight,
        car_year: fullQuote.carYear,
        currency: QUOTE_CURRENCY.GBP,
        postcode: fullQuote.postcode,
        outcode: postToOutcode(fullQuote.postcode),
        quote_id: fullQuote.quoteId,
        quoteStatus,//TODO: this field has the same value with quote_status, may be removed in a future version
        quote_status: quoteStatus,
        token: fullQuote.quoteToken,//TODO: this field has the same value with quote_token, may be removed in a future version
        quote_token: fullQuote.quoteToken,
        registrationNumber: fullQuote.registrationNumber,//TODO: this field has the same value with registration_number, may be removed in a future version
        registration_number: fullQuote.registrationNumber,
        shortUrl,//TODO: this field has the same value with short_url, may be removed in a future version
        short_url: shortUrl,
        ...(mobile ? { mobile } : {}),//TODO: this field may be removed in a future version
        funnel_name: QUOTE_FUNNEL_NAME,
        funnel_step: QUOTE_FUNNEL_STEP_NUMBER.BETTER_THAN_SCRAP,
        highest_price: fullQuote.salvagePrice
      });

      setCookie(quoteHighestPriceCookieKey, fullQuote.salvagePrice);
    }
  } catch (err) {
    reportToBugsnag(err, 'betterThanScrapSegment');
  }
}

export function* getSalvageOfferPrice({ payload }) {
  const quoteId = yield select(getQuoteId);
  const {
    data,
    actions: { setSubmitting },
  } = payload;
  try {
    setSubmitting(true);
    const result = yield call(quotes.getSalvageOfferPrice, { quoteId, responses: data });
    yield put(getSalvageOfferPriceSuccess(result));
  } catch (err) {
    setSubmitting(false);
    reportToBugsnag(err, 'getSalvageOfferPrice');
    yield put(getSalvageOfferPriceError(err));
    yield errorHandler(err);
  }
}

export function* uploadSalvageOfferMedia({ payload }) {
  try {
    const { name = 'file', quoteId, file, onUploadSuccess } = payload;

    const formData = new FormData();
    formData.append(name, file);
    formData.append('quoteId', quoteId);
    const res = yield call(quotes.uploadSalvageOfferMedia, formData);
    if (onUploadSuccess) {
      onUploadSuccess(res.url);
    }
    yield put({
      type: QuoteTypes.UPLOAD_SALVAGE_OFFER_MEDIA.SUCCESS,
    });
  } catch (err) {
    if (payload.onUploadFailed) {
      payload.onUploadFailed(err);
    }
    yield put({
      type: QuoteTypes.UPLOAD_SALVAGE_OFFER_MEDIA.ERROR,
      payload: err,
    });
    reportToBugsnag(err, 'uploadSalvageOfferMedia');
  }
}

export default [
  takeLatest([QuoteTypes.CREATE_QUOTE.REQUEST], createQuote),
  takeLatest([QuoteTypes.CANCEL_QUOTE.REQUEST], cancelQuote),
  takeLatest([QuoteTypes.CANCEL_CONDITION_OFFER.REQUEST], cancelConditionOffer),
  takeLatest([QuoteTypes.CANCEL_CONDITION_OFFER_BY_TOKEN.REQUEST], cancelConditionOfferByToken),
  takeLatest([QuoteTypes.ENTER_DETAILS.REQUEST], enterDetails),
  takeLatest([QuoteTypes.ARRANGE_COLLECTION.REQUEST], arrangeCollection),
  takeLatest([QuoteTypes.GET_ADDRESSES_BY_POSTCODE.REQUEST], getAddresses),
  takeLatest([QuoteTypes.SET_PAYMENT_METHOD.REQUEST], setPaymentMethod),
  takeLatest([QuoteTypes.SET_PAYMENT_METHOD_INPUT_CHANGE_SEGMENT.REQUEST], setPaymentMethodInputChangeSegment),
  takeLatest(QuoteTypes.CONTINUE_WITH_QUOTE.REQUEST, continueWithQuote),
  takeLatest(QuoteTypes.REFRESH_PRICE.REQUEST, refreshPrice),
  takeLatest(QuoteTypes.SAVE_ABANDONED_DATA.REQUEST, saveAbandonedData),
  takeLatest(QuoteTypes.START_ENTER_DETAILS_TIMER, watchEnterDetailsTimer),
  takeLatest(QuoteTypes.SET_ONLINE_QUOTE.REQUEST, setOnlineQuoteData),
  takeLatest([QuoteTypes.GET_BOOKING_SLOTS.REQUEST], getBookingSlots),
  takeLatest([QuoteTypes.FETCH_SCRAP_APPLICATION.REQUEST], fetchApplication),
  takeLatest([QuoteTypes.SUBMIT_VEHICLE_DETAIL.REQUEST], submitVehicleDetail),
  takeLatest([QuoteTypes.UPDATE_VEHICLE_DETAIL.REQUEST], updateVehicleDetail),
  takeLatest([QuoteTypes.CUSTOMER_REQUEST_CALL_BACK.REQUEST], customerRequestCallBack),
  takeLatest([QuoteTypes.SEND_CAPTURE_FORM_SMS.REQUEST], sendCaptureFormSMS),
  takeLatest([QuoteTypes.CONTINUE_WITH_CAPTURE_SHORT_LINK.REQUEST], continueWithCaptureShortLink),
  takeLatest([QuoteTypes.FETCH_CAPTURE_FORM_CONDITIONS.REQUEST], fetchCaptureFormConditions),
  takeLatest([QuoteTypes.UPLOAD_CAPTURE_FORM_MEDIA.REQUEST], uploadCaptureFormMedia),
  takeLatest([QuoteTypes.UPLOAD_CAPTURE_FORM_VIDEO.REQUEST], uploadCaptureFormVideo),
  takeLatest([QuoteTypes.GET_SALVAGE_OFFER_FORM_CONFIG.REQUEST], getSalvageOfferFormConfig),
  takeLatest([QuoteTypes.SET_SALVAGE_OFFER_START_SEGMENT.REQUEST], setSalvagerOfferStartSegment),
  takeLatest([QuoteTypes.GET_SALVAGE_OFFER_PRICE.REQUEST], getSalvageOfferPrice),
  takeEvery([QuoteTypes.UPLOAD_SALVAGE_OFFER_MEDIA.REQUEST], uploadSalvageOfferMedia),
  takeLatest([QuoteTypes.SET_BETTER_THAN_SCRAP_SEGMENT.REQUEST], setBetterThanScrapSegment),
];
