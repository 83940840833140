import { addCacheResistanceToParams } from '../lib/cache';

export default class MyCarClient {
  constructor(client) {
    this.client = client;
    this.resourceUrl = '/my-cars';
  }

  addYourCar = data => this.client.post(`${this.resourceUrl}`, data);

  deleteYourCar = id => this.client.post(`${this.resourceUrl}/${id}/delete`);

  updateYourCar = ({ id, serviceDueDate, insuranceDueDate, warrantyDueDate }) =>
    this.client.post(`${this.resourceUrl}/${id}/update`, { serviceDueDate, insuranceDueDate, warrantyDueDate });

  getYourCars = () => {
    const cacheResistanceParams = addCacheResistanceToParams('');
    return this.client.get(`${this.resourceUrl}`, cacheResistanceParams);
  };
}
