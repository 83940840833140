/* eslint-disable no-plusplus */
import immutable from 'immutability-helper';
import { handleActions, combineActions } from 'redux-actions';
import { immutablySetOrMergeDeeply } from 'store/lib/mergeToStateHelper';
import * as numberPlateActions from '../actions/numberPlates';

const initialState = {
  settings: {},
  byPath: {},
  docs: [],
  random_plates: [],
  numFound: 0,
  start: -1,
  keyword: '',
  loading: false,
  plateType: [],
  carYear: [],
  plateLength: [],
  priceRange: '0-500',
  page: 1,
  hideLoadMore: true,
  searchLoading: false,
  filters: {
    sort: -1,
    startWith: '*',
    endWith: '*',
    contains: '*',
    length: '*',
    plateType: '*',
    carYear: '*',
    priceRange: '*',
  },
  searchResultWithGroup: {
    listing: {
      results: [],
    },
    feature: {
      results: [],
    },
    exact: {
      results: [],
    },
    total: 0,
  },
};

export default {
  numberPlate: handleActions(
    {
      [combineActions(
        numberPlateActions.getSettingRequest,
        numberPlateActions.getIdeasPagesRequest,
        numberPlateActions.getPrivateNumberPlatesByPathRequest,
        numberPlateActions.getFaqsByPathRequest,
      )]: state => immutable(state, {}),
      [combineActions(
        numberPlateActions.getSettingSuccess,
        numberPlateActions.getIdeasPagesSuccess,
        numberPlateActions.getPrivateNumberPlatesByPathSuccess,
        numberPlateActions.getFaqsByPathSuccess,
      )]: (state, { payload }) => {
        const update = immutablySetOrMergeDeeply(state, payload, {});
        return immutable(state, update);
      },
      [combineActions(numberPlateActions.addFilterSuccess)]: (state, { payload }) => {
        return {
          ...state,
          filters: {
            ...state.filters,
            ...payload,
          },
        };
      },
      [combineActions(numberPlateActions.numberPlateSearchRequest)]: (state, { payload }) => {
        const searchResultWithGroup =
          payload.start === 0
            ? {
                listing: {
                  results: [],
                },
                feature: {
                  results: [],
                },
                exact: {
                  results: [],
                },
                total: 0,
              }
            : state.searchResultWithGroup;
        return {
          ...state,
          searchLoading: true,
          searchResultWithGroup,
        };
      },
      [combineActions(numberPlateActions.numberPlateSearchSuccess)]: (state, { payload }) => {
        const { list, start, keyword, numFound, loading } = payload;
        let totalNumberPlates = 0;
        Object.values(list).forEach(group => {
          group.results.forEach(item => {
            const { base_price, price } = item;
            let total_price;
            if (base_price) {
              total_price = parseInt(price) * 1.2 + 80;
            }
            Object.assign(item, { total_price });
          });
          totalNumberPlates += group.results?.length;
        });
        const listingTotalPages = Math.round(list?.listing?.details?.totalPages); // just listing can paging
        return {
          ...state,
          searchResultWithGroup: { ...list, total: totalNumberPlates },
          start,
          page: 1,
          keyword,
          numFound,
          loading,
          hideLoadMore: totalNumberPlates >= numFound || totalNumberPlates === 0 || listingTotalPages <= 1,
          searchLoading: false,
        };
      },
      [combineActions(numberPlateActions.clearFilterSuccess)]: state => {
        return {
          ...state,
          filters: {
            ...initialState.filters,
          },
        };
      },
      [combineActions(numberPlateActions.numberPlateSearchError)]: state => {
        return {
          ...state,
          searchLoading: false,
        };
      },
      [combineActions(numberPlateActions.numberPlateLoadMoreRequest)]: state => {
        return {
          ...state,
          searchLoading: true,
        };
      },
      [combineActions(numberPlateActions.numberPlateLoadMoreSuccess)]: (state, { payload }) => {
        const { list, start, page } = payload;
        const newListingResult =
          list?.listing?.results.map(item => {
            const { base_price, price } = item;
            let total_price;
            if (base_price) {
              total_price = parseInt(price) * 1.2 + 80;
            }
            return {
              ...item,
              total_price,
            };
          }) || [];
        const previousSearchResultWithGroup = state.searchResultWithGroup;
        const previousListing = previousSearchResultWithGroup.listing;
        const previousListingResult = previousListing.results;
        const listingResult = previousListingResult.concat(newListingResult);
        const listing = {
          ...previousListing,
          results: listingResult,
        };
        // update the total number
        const total = previousSearchResultWithGroup.total + newListingResult.length;
        const listingTotalPages = Math.round(list?.listing?.details?.totalPages); // just listing can paging
        return {
          ...state,
          searchResultWithGroup: { ...previousSearchResultWithGroup, listing, total },
          start,
          page,
          searchLoading: false,
          numFound: total > state.numFound ? total : state.numFound,
          hideLoadMore: total >= state.numFound || newListingResult.length === 0 || listingTotalPages <= page,
        };
      },
      [combineActions(numberPlateActions.numberPlateLoadMoreError)]: state => {
        return {
          ...state,
          searchLoading: false,
        };
      },
      [combineActions(numberPlateActions.randomFetchPlatesSuccess)]: (state, { payload }) => {
        const { docs } = payload;
        return {
          ...state,
          random_plates: docs,
        };
      },
      [combineActions(numberPlateActions.setPlateKeywordForSearchSuccess)]: (state, { payload }) => {
        const { keyword, start } = payload;
        return {
          ...state,
          keyword,
          start,
        };
      },
      [combineActions(numberPlateActions.changeLoading)]: (state, { payload }) => {
        const { isLoading = false } = payload;
        return {
          ...state,
          searchLoading: isLoading,
        };
      },
    },
    initialState,
  ),
};
