import { call, put, takeLatest, takeLeading, select } from 'redux-saga/effects';
import { myCar } from 'api-client/apiClientInstance/myCar';
import { reportToBugsnag } from 'lib/bugsnag';
import MyCarTypes from '../action-types/myCar';
import setFormikErrors from '../lib/setFormikErrors';
import { getRootModalState } from '../selectors/modal';
import { modalClose, modalOpen } from '../actions/modal';
import { MODAL_TYPE } from 'containers/rootModalContainer';
import {
  addYourCarSuccess,
  addYourCarError,
  deleteYourCarError,
  deleteYourCarSuccess,
  getYourCarsError,
  getYourCarsSuccess,
  updateYourCarSuccess,
  updateYourCarError,
  setSelectedCar,
  setDateTarget,
} from '../actions/myCar';

import { getSelectedCarSelector, getMyCarsSelector } from '../selectors/myCar';
import { MY_CAR_DATE_TARGET, SOME_ERROR_OCCURRED } from '../constants';

import { showToast } from '../actions';
import moment from 'moment';
import { getErrorMessage } from 'lib/message';

export function* addYourCar({
  payload: {
    values: { registrationNumber, mileage },
    actions: { setErrors, setSubmitting, resetForm },
  },
}) {
  try {
    setSubmitting(true);
    const data = yield call(myCar.addYourCar, { registration: registrationNumber, mileage });
    setSubmitting(false);
    resetForm({ registrationNumber: '', mileage: '' });
    yield put(addYourCarSuccess(data));

    //open data filled pop up
    yield put(setSelectedCar(data));
    yield put(setDateTarget(MY_CAR_DATE_TARGET.ALL));
    yield put(modalOpen({ modalType: MODAL_TYPE.addYourCarPopup }));
  } catch (err) {
    reportToBugsnag(err, 'addYourCar');

    if (err.status !== 401) {
      setSubmitting(false);
      const errorMessages = err?.error?.messages;
      const commonErrorMessage = getErrorMessage(err);

      if (err.errors.mileage) {
        yield put(
          showToast({
            message: err.errors.mileage[0],
            kind: 'error',
          }),
        );
      } else if (commonErrorMessage) {
        yield put(
          showToast({
            message: commonErrorMessage || [SOME_ERROR_OCCURRED],
            kind: 'error',
          }),
        );
      }
      setFormikErrors(setErrors, errorMessages);
    }

    yield put(addYourCarError(err));
  }
}

export function* getYourCars() {
  try {
    const data = yield call(myCar.getYourCars);

    yield put(getYourCarsSuccess(data));
  } catch (err) {
    reportToBugsnag(err, 'getYourCars');

    if (err.status !== 401) {
      const errorMessages = err?.error?.messages;
      const commonErrorMessage = errorMessages?.common?.[0];

      if (commonErrorMessage) {
        yield put(
          showToast({
            message: commonErrorMessage || [SOME_ERROR_OCCURRED],
            kind: 'error',
          }),
        );
      }
    }

    yield put(getYourCarsError(err));
  }
}

export function* updateYourCar({
  payload: {
    values: { serviceDueDate, insuranceDueDate, warrantyDueDate },
    actions: { setErrors, setSubmitting },
  },
}) {
  const { modalType } = yield select(getRootModalState());
  try {
    setSubmitting(true);

    const selectedCar = yield select(getSelectedCarSelector);

    if (selectedCar) {
      const newDates = {
        ...selectedCar,
        serviceDueDate: serviceDueDate && moment(serviceDueDate).format('YYYY-MM-DD'),
        insuranceDueDate: insuranceDueDate && moment(insuranceDueDate).format('YYYY-MM-DD'),
        warrantyDueDate: warrantyDueDate && moment(warrantyDueDate).format('YYYY-MM-DD'),
      };

      yield call(myCar.updateYourCar, newDates);

      const allCars = yield select(getMyCarsSelector);
      const index = allCars.findIndex(car => car.id === selectedCar.id);

      yield put(updateYourCarSuccess({ index, newDates }));
      if (modalType === 'addYourCarPopup') {
        yield put(modalClose());
      }
    }

    setSubmitting(false);
  } catch (err) {
    reportToBugsnag(err, 'updateYourCar');

    if (err.status !== 401) {
      setSubmitting(false);
      const errorMessages = err?.error?.messages;
      const commonErrorMessage = errorMessages?.common?.[0];

      if (commonErrorMessage) {
        yield put(
          showToast({
            message: commonErrorMessage || [SOME_ERROR_OCCURRED],
            kind: 'error',
          }),
        );
      }

      if (modalType === 'addYourCarPopup') {
        yield put(modalClose());
      }

      setFormikErrors(setErrors, errorMessages);
    }

    yield put(updateYourCarError(err));
  }
}

export function* deleteYourCar() {
  const { modalType } = yield select(getRootModalState());
  try {
    const selectedCar = yield select(getSelectedCarSelector);
    if (selectedCar) {
      yield call(myCar.deleteYourCar, selectedCar.id);

      const allCars = yield select(getMyCarsSelector);
      const index = allCars.findIndex(car => car.id === selectedCar.id);

      yield put(showToast({ message: 'Car has been deleted successfully.', kind: 'success' }));
      if (modalType === 'deleteMyCarPopup') {
        yield put(modalClose());
      }
      yield put(deleteYourCarSuccess({ index }));
    }
  } catch (err) {
    reportToBugsnag(err, 'deleteYourCar');

    if (err.status !== 401) {
      const errorMessages = err?.error?.messages;
      const commonErrorMessage = errorMessages?.common?.[0];

      if (commonErrorMessage) {
        yield put(
          showToast({
            message: commonErrorMessage || [SOME_ERROR_OCCURRED],
            kind: 'error',
          }),
        );
      }

      if (modalType === 'deleteMyCarPopup') {
        yield put(modalClose());
      }
    }

    yield put(deleteYourCarError(err));
  }
}

/**
 * User Sagas
 */
export default [
  takeLatest(MyCarTypes.ADD_YOUR_CAR.REQUEST, addYourCar),
  takeLeading(MyCarTypes.DELETE_YOUR_CAR.REQUEST, deleteYourCar),
  takeLatest(MyCarTypes.GET_YOUR_CARS.REQUEST, getYourCars),
  takeLatest(MyCarTypes.UPDATE_YOUR_CAR.REQUEST, updateYourCar),
];
