import keyMirror from 'keymirror';
import { createAsyncActionSet } from '../lib/action-helper';

const syncActionSet = keyMirror({
  SET_SELECTED_CAR: undefined,
  SET_DATE_TARGET: undefined,
});

const asyncActionSet = {
  ADD_YOUR_CAR: createAsyncActionSet('ADD_YOUR_CAR'),
  DELETE_YOUR_CAR: createAsyncActionSet('DELETE_YOUR_CAR'),
  GET_YOUR_CARS: createAsyncActionSet('GET_YOUR_CARS'),
  UPDATE_YOUR_CAR: createAsyncActionSet('UPDATE_YOUR_CAR'),
};

export default {
  ...syncActionSet,
  ...asyncActionSet,
};
