import { Router } from 'server/pages';
import { CHECK_OUT_URL, CHECK_OUT_STATUS } from './constant';

const PlateHelper = {
  navigateRoute: (page, status) => {
    switch (status) {
      case CHECK_OUT_STATUS.PERSONAL_DETAIL:
        if (page != CHECK_OUT_URL.PERSONAL_DETAIL) Router.pushRoute(CHECK_OUT_URL.PERSONAL_DETAIL);
        break;
      case CHECK_OUT_STATUS.CUSTOMIZE_PLATE:
        if (page != CHECK_OUT_URL.CUSTOMIZE_PLATE) Router.pushRoute(CHECK_OUT_URL.CUSTOMIZE_PLATE);
        break;
      case CHECK_OUT_STATUS.TRANSFER_DELIVERY:
        if (page != CHECK_OUT_URL.TRANSFER_DELIVERY) Router.pushRoute(CHECK_OUT_URL.TRANSFER_DELIVERY);
        break;
      case CHECK_OUT_STATUS.ORDER_SUMMARY:
        if (page != CHECK_OUT_URL.ORDER_SUMMARY) Router.pushRoute(CHECK_OUT_URL.ORDER_SUMMARY);
        break;
      case CHECK_OUT_STATUS.PAYMENT:
        if (page != CHECK_OUT_URL.PAYMENT) Router.pushRoute(CHECK_OUT_URL.PAYMENT);
        break;
      case CHECK_OUT_STATUS.CONFIRMATION:
        if (page != CHECK_OUT_URL.CONFIRMATION) Router.pushRoute(CHECK_OUT_URL.CONFIRMATION);
        break;
      default:
        Router.pushRoute(CHECK_OUT_URL.HOME);
    }
  },

  getNavStatus: currentStatus => {
    switch (currentStatus) {
      case CHECK_OUT_STATUS.PERSONAL_DETAIL:
        return CHECK_OUT_STATUS.CUSTOMIZE_PLATE;

      case CHECK_OUT_STATUS.CUSTOMIZE_PLATE:
        return CHECK_OUT_STATUS.TRANSFER_DELIVERY;

      case CHECK_OUT_STATUS.TRANSFER_DELIVERY:
        return CHECK_OUT_STATUS.ORDER_SUMMARY;

      case CHECK_OUT_STATUS.ORDER_SUMMARY:
        return CHECK_OUT_STATUS.PAYMENT;

      default:
        return CHECK_OUT_STATUS.PERSONAL_DETAIL;
    }
  },
};

export default PlateHelper;
