/* eslint-disable no-lone-blocks */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { valuationQuote } from 'api-client/apiClientInstance/valuationQuote';
import { redirect } from 'lib/routes';
import { userLogoutRequest } from 'store/actions/user';
import { md5 } from 'lib/encrypt';
import moment from 'moment';
import { segmentTrack, segmentIdentify } from 'lib/segment';
import { getValuationData, getRegistrationNumber, getMileage } from '../selectors/valuationQuoteProcess';
import ValuationQuoteTypes from '../action-types/valuationQuoteProcess';
import { createValuationQuoteSuccess } from '../actions/valuationQuoteProcess';
import { showToast } from '../actions/toasts';
import mobileNumberFormat from '../lib/mobilePhoneHandler';

import {
  getUserEmailSelector,
  getUserFirstNameSelector,
  getUserLastNameSelector,
  getUserPhoneNumberSelector,
  getGdprConsent,
} from '../selectors/user';
import { getErrorMessage } from 'lib/message';

export function* createValuationQuote({
  payload: {
    actions: { setSubmitting },
  },
}) {
  try {
    setSubmitting(true);
    const registrationNumber = yield select(getRegistrationNumber);
    const mileage = yield select(getMileage);
    let valuationOption = yield select(getValuationData);

    if (valuationOption.valuationOption) {
      valuationOption = valuationOption.valuationOption;
    }

    const firstName = yield select(getUserFirstNameSelector);
    const lastName = yield select(getUserLastNameSelector);
    const email = yield select(getUserEmailSelector);
    const phoneNumber = yield select(getUserPhoneNumberSelector);
    const gdprConsent = yield select(getGdprConsent);

    if (email) {
      segmentIdentify(md5(email), {
        email,
        firstname: firstName,
        lastname: lastName,
        GDPRConsent: gdprConsent,
        mobile: mobileNumberFormat(phoneNumber),
        first_name: firstName,
        last_name: lastName,
        phone: mobileNumberFormat(phoneNumber),
        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
      });
    }

    segmentTrack('Valuation_Set_Option', {
      valuationOption,
    });

    const { BillingAccount, Request, Response, carValuationDataID, vehicleImage, car_make, car_model, car_year
    } = yield call(
      valuationQuote.getValuation,
      {
        carRegistration: registrationNumber,
        carMileage: mileage,
      },
    );

    if (email) {
      segmentIdentify(md5(email), {
        email,
        firstname: firstName,
        lastname: lastName,
        GDPRConsent: gdprConsent,
        mobile: mobileNumberFormat(phoneNumber),
        first_name: firstName,
        last_name: lastName,
        phone: mobileNumberFormat(phoneNumber),
        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
      });
    }

    if (Response && Response.StatusMessage && Response.StatusMessage === 'Success') {
      segmentTrack('Valuation_Complete_Quote', {
        ...Response.DataItems,
        email,
        customer_name: `${firstName} ${lastName}`,
        car_make,
        car_model,
        car_year
      });
    }

    yield put(createValuationQuoteSuccess({ BillingAccount, Request, Response, carValuationDataID, vehicleImage }));
    setSubmitting(false);
  } catch (err) {
    if (err?.exception === 'ErrorException') {
      yield put(showToast({ message: 'Invalid Registration Number', kind: 'error' }));
    } else if (err && err.message && err.message.search('Token Expired') != -1) {
      yield put(showToast({ message: 'Login Expired', kind: 'error' }));
      yield put(userLogoutRequest({}, {}, '/car-valuation/sign-in'));
    } else if (err && err.error && err.error.messages?.common[0]?.search('daily limit') != -1) {
      yield put(showToast({ message: err.error.messages.common[0], kind: 'error' }));
      yield redirect({}, '/car-valuation');
    } else {
      const message = getErrorMessage(err) || 'Something Went Wrong - Please try again';
      yield put(showToast({ message, kind: 'error' }));
      yield redirect({}, '/car-valuation');
    }
    setSubmitting(false);
  }
}

export function* handleOtherDataSetting(payload) {
  const firstName = yield select(getUserFirstNameSelector);
  const lastName = yield select(getUserLastNameSelector);
  const email = yield select(getUserEmailSelector);
  const phoneNumber = yield select(getUserPhoneNumberSelector);
  const gdprConsent = yield select(getGdprConsent);

  if (email) {
    segmentIdentify(md5(email), {
      email,
      firstname: firstName,
      lastname: lastName,
      GDPRConsent: gdprConsent,
      mobile: mobileNumberFormat(phoneNumber),
      first_name: firstName,
      last_name: lastName,
      phone: mobileNumberFormat(phoneNumber),
      createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
    });
  }

  const sendPack = payload && payload.payload;
  if (sendPack.actions) {
    delete sendPack.actions;
  }

  switch (payload.type) {
    case 'SET_VALUATION_VALUES_REQUEST':
      {
        segmentTrack('Valuation_Begin_Quote', {
          ...sendPack,
        });
      }
      break;
    default:
      {
        segmentTrack('Valuation_Set_Other_Details', {
          ...sendPack,
        });
      }
      break;
  }
  return payload;
}

export default [
  takeLatest([ValuationQuoteTypes.SET_VALUATION_OPTION.REQUEST], createValuationQuote),
  takeLatest([ValuationQuoteTypes.SET_VALUATION_VALUES.REQUEST], handleOtherDataSetting),
];
