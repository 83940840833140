export default class CommonClient {
  constructor(client) {
    this.client = client;
  }

  deleteFilesByBeacon = data => this.client.sendBeacon('/delete-files', data, false);

  deleteFiles = paths => this.client.post('/delete-files', { paths });

  trackingUTM = data => this.client.post('/tracking-utm', data);
}
