/* eslint-disable default-case */
/* eslint-disable no-lone-blocks */
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Router } from 'server/pages';
import { zuto } from 'api-client/apiClientInstance/zuto';
import moment from 'moment';
import { reportToBugsnag } from 'lib/bugsnag';
import { md5 } from 'lib/encrypt';
import { segmentTrack, segmentIdentify } from 'lib/segment';
import financeQuoteProcessTypes from '../action-types/financeQuoteProcess';
import mobileNumberFormat from '../lib/mobilePhoneHandler';

import {
  getFinanceQuoteProcess,
  // completeApplicationSuccess,
  // completeApplicationError,
} from '../selectors/financeQuoteProcess';
import { getFinanceSettings } from '../selectors/finance';

export function* completeApplicationRequest({ payload: { resolve, reject } }) {
  const financeQuoteProcess = yield select(getFinanceQuoteProcess);
  const { ThirdPartyReferral } = financeQuoteProcess.applicantDetails.MarketingOptIn;
  const refId = sessionStorage && sessionStorage.getItem('finance-quote-refId');
  const {
    otherInfo: { shortLink = '' },
  } = financeQuoteProcess;
  try {
    const response = yield call(zuto.submit, financeQuoteProcess);
    if (sessionStorage?.removeItem) {
      sessionStorage.removeItem('finance-quote-refId');
      sessionStorage.removeItem('finance-quote-authorization');
    }

    const {
      DateOfBirth,
      Email,
      Forename,
      Gender,
      MiddleNames,
      PhoneNumbers,
      Surname,
      Title,
    } = financeQuoteProcess.applicantDetails.BasicDetails;

    const { MaritalStatus, OtherMonthlyIncome } = financeQuoteProcess.applicantDetails.AdditionalDetails;

    const { PostCode, ResidentialStatus } =
      financeQuoteProcess.applicantDetails.ApplicantAddress && financeQuoteProcess.applicantDetails.ApplicantAddress[0];

    const { EmploymentStatus, Occupation } =
      financeQuoteProcess.applicantDetails.ApplicantEmployment &&
      financeQuoteProcess.applicantDetails.ApplicantEmployment[0];

    segmentIdentify(md5(Email), {
      email: Email,
      firstname: Forename,
      lastname: Surname,
      first_name: Forename,
      last_name: Surname,
      middlename: MiddleNames,
      title: Title,
      mobile: mobileNumberFormat(PhoneNumbers && PhoneNumbers[0].Value),
      phone: mobileNumberFormat(PhoneNumbers && PhoneNumbers[0].Value),
      dateOfBirth: DateOfBirth,
      gender: Gender,
      income: OtherMonthlyIncome,
      maritalStatus: MaritalStatus,
      employmentStatus: EmploymentStatus,
      postcode: PostCode,
      livingArrangements: ResidentialStatus,
      occupation: Occupation,
      marketingThirdParty: ThirdPartyReferral,
      createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
    });

    segmentTrack('Finance_Quote_Complete', {
      reference: response.Reference,
      token: refId,
      passOnConsent: ThirdPartyReferral,
      shortLink,
      email:Email
    });

    yield put({
      type: financeQuoteProcessTypes.COMPLETE_APPLICATION.SUCCESS,
      payload: response,
      stepsFetched: true,
    });
    resolve(response);
  } catch (e) {
    reportToBugsnag(e, 'completeApplicationRequest');
    segmentTrack('Finance_Quote_Complete_Failed', {
      reference: refId,
      passOnConsent: ThirdPartyReferral,
      token: refId,
      shortLink,
      ...e,
    });
    reject(e);
    yield put({
      type: financeQuoteProcessTypes.COMPLETE_APPLICATION.ERROR,
      payload: e,
    });
    // Temporarily stop it from jumping to the page and allow the user to try again
    // const financeSettings = yield select(getFinanceSettings);
    // yield call(Router.pushRoute, financeSettings.calculator.path);
  }
}

export function* initApplicationRequest({ payload: { resolve, reject } }) {
  try {
    // remove old token first.
    sessionStorage && sessionStorage.removeItem('finance-quote-authorization');
    const financeQuoteProcess = yield select(getFinanceQuoteProcess);
    const response = yield call(zuto.init, financeQuoteProcess);
    const { financeQuoteToken, refId } = response;

    sessionStorage && sessionStorage.setItem('finance-quote-authorization', financeQuoteToken);
    sessionStorage && sessionStorage.setItem('finance-quote-refId', refId);

    yield put({
      type: financeQuoteProcessTypes.INIT_APPLICATION.SUCCESS,
      payload: response,
      stepsFetched: true,
    });
    resolve(response);
  } catch (e) {
    reportToBugsnag(e, 'initApplicationRequest');

    reject(e);
    yield put({
      type: financeQuoteProcessTypes.INIT_APPLICATION.ERROR,
      payload: e,
    });
  }
}

export function* fetchApplicationRequest() {
  try {
    const response = yield call(zuto.fetch);
    yield put({
      type: financeQuoteProcessTypes.FETCH_APPLICATION.SUCCESS,
      payload: response,
    });
  } catch (e) {
    reportToBugsnag(e, 'fetchApplicationRequest');

    yield put({
      type: financeQuoteProcessTypes.FETCH_APPLICATION.ERROR,
      payload: e,
    });
    const financeSettings = yield select(getFinanceSettings);
    yield call(Router.pushRoute, financeSettings.calculator.path);
  }
}

export function* appendApplicationRequest({ payload: { resolve, reject, checkHasAccount } }) {
  try {
    const financeQuoteProcess = yield select(getFinanceQuoteProcess);
    let response;

    if (checkHasAccount) {
      response = yield call(zuto.appendCheckAccount, financeQuoteProcess);
      if (response && response.financeQuoteToken) {
        sessionStorage && sessionStorage.setItem('finance-quote-authorization', response.financeQuoteToken);
      }
      if (response && response.refId) {
        sessionStorage && sessionStorage.setItem('finance-quote-refId', refId);
      }
    } else {
      response = yield call(zuto.append, financeQuoteProcess);
    }

    yield put({
      type: financeQuoteProcessTypes.APPEND_APPLICATION.SUCCESS,
      payload: response,
      stepsFetched: true,
    });
    resolve(response);
  } catch (e) {
    reportToBugsnag(e, 'appendApplicationRequest');

    reject(e);
    yield put({
      type: financeQuoteProcessTypes.APPEND_APPLICATION.ERROR,
      payload: e,
    });
    const financeSettings = yield select(getFinanceSettings);
    yield call(Router.pushRoute, financeSettings.calculator.path);
  }
}

export function* handleOtherDataSetting(payload) {
  const financeQuoteProcess = yield select(getFinanceQuoteProcess);
  // let payloadOrder = {
  //   a: ['SET_APPLICATION_DETAILS'],
  //   b: ['SET_BASIC_DETAILS', 'SET_ADDITIONAL_DETAILS', 'SET_APPLICATION_DETAILS'],
  //   c: ['SET_APPLICANT_ADDRESS', 'APPEND_APPLICATION'],
  //   d: ['SET_APPLICANT_EMPLOYMENT', 'APPEND_APPLICATION'],
  //   e: ['COMPLETE_APPLICATION'],
  // };

  const reference = sessionStorage && sessionStorage.getItem('finance-quote-authorization');
  const refId = sessionStorage && sessionStorage.getItem('finance-quote-refId');

  const {
    otherInfo: { shortLink = '' },
  } = financeQuoteProcess;

  const {
    DateOfBirth,
    Email,
    Forename,
    Gender,
    MiddleNames,
    PhoneNumbers,
    Surname,
    Title,
  } = financeQuoteProcess.applicantDetails.BasicDetails;

  const {
    LicenceType,
    MaritalStatus,
    OtherMonthlyIncome,
    ValidUkPassport,
  } = financeQuoteProcess.applicantDetails.AdditionalDetails;

  const { VehicleType } = financeQuoteProcess.applicationDetails;

  const { PostCode, ResidentialStatus, Years, Months } =
    financeQuoteProcess.applicantDetails.ApplicantAddress && financeQuoteProcess.applicantDetails.ApplicantAddress[0];

  const { EmploymentStatus, Occupation } =
    financeQuoteProcess.applicantDetails.ApplicantEmployment &&
    financeQuoteProcess.applicantDetails.ApplicantEmployment[0];

  switch (payload.type) {
    case 'SET_APPLICATION_DETAILS':
      {
        // first and second steps in finance quote SEGMENT
        // check to see if first or second by basic details presence
        const { TermLength, CreditLimit } = payload.payload;
        if (TermLength) {
          if (shortLink !== '') {
            segmentTrack('Finance_1_QuoteAttempted', {
              LikeToBorrow: CreditLimit,
              PayBackOver: TermLength,
              shortLink,
            });
          }
        } else {
          segmentIdentify(md5(Email), {
            email: Email,
            firstname: Forename,
            lastname: Surname,
            first_name: Forename,
            last_name: Surname,
            middlename: MiddleNames,
            title: Title,
            mobile: mobileNumberFormat(PhoneNumbers && PhoneNumbers[0].Value),
            phone: mobileNumberFormat(PhoneNumbers && PhoneNumbers[0].Value),
            dateOfBirth: DateOfBirth,
            gender: Gender,
            income: OtherMonthlyIncome,
            maritalStatus: MaritalStatus,
            createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
          });
          segmentTrack('Finance_2_QuoteBasicDetails', {
            FinancedVehicleType: VehicleType,
            Income: OtherMonthlyIncome,
            reference,
            token: refId,
            shortLink,
          });
        }
      }
      break;
    case 'SET_APPLICANT_ADDRESS':
      {
        // Third step identifier
        segmentIdentify(md5(Email), {
          email: Email,
          firstname: Forename,
          lastname: Surname,
          first_name: Forename,
          last_name: Surname,
          middlename: MiddleNames,
          title: Title,
          phone: mobileNumberFormat(PhoneNumbers && PhoneNumbers[0].Value),
          mobile: mobileNumberFormat(PhoneNumbers && PhoneNumbers[0].Value),
          dateOfBirth: DateOfBirth,
          gender: Gender,
          income: OtherMonthlyIncome,
          maritalStatus: MaritalStatus,
          postcode: PostCode,
          livingArrangements: ResidentialStatus,
          createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
        });
        segmentTrack('Finance_3_AddressDetails', {
          LivedAtDuration: `${Years} years, ${Months} months`,
          LivingArrangements: ResidentialStatus,
          reference,
          token: refId,
          shortLink,
        });
      }
      break;
    case 'SET_APPLICANT_EMPLOYMENT':
      {
        // Fourth step identifier
        segmentIdentify(md5(Email), {
          email: Email,
          firstname: Forename,
          lastname: Surname,
          middlename: MiddleNames,
          title: Title,
          first_name: Forename,
          last_name: Surname,
          phone: mobileNumberFormat(PhoneNumbers && PhoneNumbers[0].Value),
          mobile: mobileNumberFormat(PhoneNumbers && PhoneNumbers[0].Value),
          dateOfBirth: DateOfBirth,
          gender: Gender,
          income: OtherMonthlyIncome,
          maritalStatus: MaritalStatus,
          postcode: PostCode,
          livingArrangements: ResidentialStatus,
          occupation: Occupation,
          employmentStatus: EmploymentStatus,
          createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
        });
        segmentTrack('Finance_4_EmploymentDetails', {
          occupation: Occupation,
          employmentType: EmploymentStatus,
          reference,
          token: refId,
          shortLink,
        });
      }
      break;
  }
  return payload;
}

export default [
  takeEvery(financeQuoteProcessTypes.FETCH_APPLICATION.REQUEST, fetchApplicationRequest),
  takeEvery(financeQuoteProcessTypes.INIT_APPLICATION.REQUEST, initApplicationRequest),
  takeEvery(financeQuoteProcessTypes.APPEND_APPLICATION.REQUEST, appendApplicationRequest),
  takeEvery(financeQuoteProcessTypes.COMPLETE_APPLICATION.REQUEST, completeApplicationRequest),
  takeEvery(
    [
      financeQuoteProcessTypes.SET_BASIC_DETAILS,
      financeQuoteProcessTypes.SET_ADDITIONAL_DETAILS,
      financeQuoteProcessTypes.SET_APPLICANT_ADDRESS,
      financeQuoteProcessTypes.SET_APPLICANT_EMPLOYMENT,
      financeQuoteProcessTypes.SET_APPLICATION_DETAILS,
    ],
    handleOtherDataSetting,
  ),
];
