import fetch from 'cross-fetch';
import RestClient from './restClient';

export default class CustomRestClient extends RestClient {
  constructor(baseUrl, config) {
    super(baseUrl);
    this.defaultConfig = config;
  }

  getConfig(method, data) {
    return { ...super.getConfig(method, data), ...this.defaultConfig };
  }

  request(endpoint, config) {
    return fetch(endpoint, config).then(response => {
      const { status } = response;

      if (status === 204) {
        return { status: response.status };
      }

      if (status >= 200 && status < 300) {
        return response.json().catch(error => {
          throw error;
        });
      }

      return response.json().then(err => {
        const error = err;
        error.status = response.status;
        throw error;
      });
    });
  }
}
