import { createSelector } from 'reselect';
import { byId, makeGetByPath } from './cms/genericSelectors';
import { makeGetCmsByType } from './cms';

const getFAQ = faqType => state => state.faq[faqType];
const getFAQCategoriesByType = faqType => state => state.faq.categories[faqType];
const getFAQSearchByType = faqType => state => state.faq.search[faqType];
const getLandingByType = faqType => state => state.faq?.landing[faqType];
const getByPath = makeGetByPath('faq');

const random = Math.random();

export const makeGetFAQData = faqType =>
  createSelector([getFAQ(faqType), makeGetCmsByType('nodeFaqItem')], (faq, cms) => byId(faq, cms));

export const makeGetFAQSuggestions = faqType =>
  createSelector([makeGetFAQData(faqType), getFAQ(faqType)], (faq = []) =>
    faq
      .filter(i => i?.label)
      .sort(() => 0.5 - random)
      .slice(0, 3),
  );

export const makeGetFAQCats = faqType =>
  createSelector([getFAQCategoriesByType(faqType), makeGetCmsByType('taxonomyTermFaqCategories')], (faq, cms) =>
    byId(faq, cms),
  );

export const makeGetSearch = faqType =>
  createSelector([getFAQSearchByType(faqType), makeGetCmsByType('nodeFaqItem')], (faq, cms) => byId(faq, cms));

export const makeGetLanding = faqType =>
  createSelector(
    [getLandingByType(faqType), makeGetCmsByType('nodeFaqItem'), makeGetFAQCats(faqType)],
    (landing = {}, cms = {}, cats = []) => {
      const byCatId = Object.keys(landing).reduce(
        (accumulator, catId) => ({ ...accumulator, [catId]: byId(landing[catId], cms) }),
        {},
      );
      return cats
        .map(cat => ({ ...cat, content: byCatId[cat?.id] }))
        .filter(cat => cat.content instanceof Array && cat.content.length > 0);
    },
  );

export const makeGetIndividualByPath = () =>
  createSelector([getByPath, makeGetCmsByType('nodeFaqItem')], (id, cms) => cms[id] || {});
