import { addCacheResistanceToParams } from '../lib/cache';

export default class {
  constructor(client) {
    this.resourceUrl = '/warranties-flow';
    this.endpoints = {
      createVehicle: `${this.resourceUrl}/car`,
      createCustomer: `${this.resourceUrl}/customer`,
      createQuote: `${this.resourceUrl}/quote`,
      getMakes: `${this.resourceUrl}/car-makes`,
      getModels: `${this.resourceUrl}/car-models?car_make=`,
      fetch: `${this.resourceUrl}/getByRefId`,
      append: `${this.resourceUrl}/append`,
      submit: `${this.resourceUrl}/submit`,
      appendCheckAccount: `${this.resourceUrl}/appendCheckAccount`,
      payment: `${this.resourceUrl}/payment`,
      verifyPayment: `${this.resourceUrl}/payment/verify`,
    };
    this.client = client;
  }

  addAuth = params => {
    const token = sessionStorage.getItem('quote-authorization');
    if (token) {
      return {
        ...params,
        quoteToken: token,
      };
    }

    return params;
  };

  fetch = () => this.client.post(this.endpoints.fetch, this.addAuth());

  createCar = params => this.client.post(this.endpoints.createVehicle, this.addAuth(params));

  createCustomer = params => this.client.post(this.endpoints.createCustomer, this.addAuth(params));

  // createQuote = params => this.client.post(this.endpoints.createQuote, this.addAuth(params));

  getVehicleMakeData = params => this.client.get(this.endpoints.getMakes, params);

  getVehicleModelsData = params => this.client.get(`${this.endpoints.getModels}${params}`);

  createPayment = params => this.client.post(this.endpoints.payment, this.addAuth(params));

  verifyPament = params => this.client.post(this.endpoints.verifyPayment, this.addAuth(params));

  continueWithQuote = (token, req) =>
    this.client.get(`${this.resourceUrl}/continue/${token}`, addCacheResistanceToParams(), req);

  createQuote = data => this.client.post(`${this.resourceUrl}/create-quote`, data);

  updateQuoteInfo = (data, quoteId) => this.client.put(`${this.resourceUrl}/enter-details/${quoteId}`, data);

  getAvailableProducts = quoteId => this.client.get(`${this.resourceUrl}/product-variants/${quoteId}`);

  selectProduct = (data, quoteId) => this.client.put(`${this.resourceUrl}/select-product/${quoteId}`, data);

  getQuoteInfoByToken = (token, req) =>
    this.client.get(`${this.resourceUrl}/getByToken`, { warrantiesQuoteToken: token }, req);

  getClientSecret = quoteId => this.client.get(`${this.resourceUrl}/get-client-secret/${quoteId}`);

  submitPayment = (params, quoteId) => this.client.post(`${this.resourceUrl}/payment/${quoteId}`, this.addAuth(params));

  checkPayment = quoteId => this.client.post(`${this.resourceUrl}/check-payment/${quoteId}`);

  requireCustomQuote = data => this.client.post(`${this.resourceUrl}/custom-quote`, data);

  getQuotesHistory = (data, req) => this.client.get(`${this.resourceUrl}/history`, data, req);

  deleteQuote = quoteId => this.client.delete(`${this.resourceUrl}/quote/${quoteId}`);
}
