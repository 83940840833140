/* eslint-disable no-console */
import { call, put, take, takeLatest, select } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import CmsTypes from 'store/action-types/cms';
import { wrapWith, extractStoreIds } from 'store/lib/mergeToStateHelper';
import { numberPlateSearchClient, numberPlateClient } from 'api-client/apiClientInstance/numberPlate';
import { reportToBugsnag } from 'lib/bugsnag';
import { redirect } from 'lib/routes';
import { segmentTrack } from 'lib/segment';
import NumberPlateActionTypes from '../action-types/numberPlates';
import { getNumberPlateKeyword, getFilter, getNumberPlatePage } from '../selector/numberPlates';

export function* getSettings() {
  const fingerprint = 'numberPlateSettings';
  try {
    const type = 'settings';
    const chan = yield call(channel);
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: {
        section: 'number-plates',
        type,
        includes: ['field_top_banner', 'field_content_item', 'field_top_banner.image.file', 'field_insurance_pages'],
      },
      fingerprint,
      chan,
    });
    const fullData = yield take(chan);
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: NumberPlateActionTypes.GET_SETTING.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    yield put({
      type: NumberPlateActionTypes.GET_SETTING.ERROR,
      payload: err,
    });
    reportToBugsnag(err, 'getSettings');
  }
}

export function* getIdeasPages() {
  const fingerprint = 'Ideas';
  try {
    const type = 'ideas';
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: {
        section: 'number-plates',
        type,
        includes: [
          'field_paragraphs',
          'field_paragraphs.items',
          'field_paragraphs.image',
          'field_paragraphs.image.file',
          'field_paragraphs.items.image',
          'field_paragraphs.items.image.file',
          'field_paragraphs.items.media.file',
          'field_paragraphs.items.paragraphs',
          'field_paragraphs.items.paragraphs.items',
          'field_paragraphs.items.paragraphs.image',
          'field_paragraphs.items.paragraphs.image.file',
          'field_paragraphs.items.paragraphs.items.image',
          'field_paragraphs.items.paragraphs.items.image.file',
          'field_paragraphs.items.paragraphs.items.paragraphs',
          'field_paragraphs.items.paragraphs.items.media.file',
          'field_paragraphs.items.paragraphs.items.category',
          'field_paragraphs.left_col',
          'field_paragraphs.left_col.image.file',
          'field_paragraphs.right_col',
          'field_paragraphs.right_col.image.file',
        ],
      },
      fingerprint,
    });
    const fullData = yield take(
      ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp,
    );
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: NumberPlateActionTypes.GET_IDEAS_PAGES.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    yield put({
      type: NumberPlateActionTypes.GET_IDEAS_PAGES.ERROR,
      payload: err,
    });
    reportToBugsnag(err, 'getIdeasPages');
  }
}

export function* getIndividualByPath({ payload: { specificIncludes, ...payload } }) {
  const chan = yield call(channel);
  const { includes } = payload;
  try {
    yield put({
      type: CmsTypes.RESOLVE_CMS_ENTITY.REQUEST,
      payload,
      chan,
    });
    const {
      payload: {
        data,
        path: resourcePath,
        jsonapi: { resourceName },
      },
      path,
    } = yield take(chan);
    let outData = data;
    if (resourceName !== 'node--number_plate') {
      yield put({
        type: CmsTypes.FETCH_CMS_INDIVIDUAL.REQUEST,
        payload: { path: resourcePath, includes },
        path,
      });
      const {
        payload: { data: includedData },
      } = yield take(CmsTypes.FETCH_CMS_INDIVIDUAL.SUCCESS);
      outData = includedData;
    }
    yield put({
      type: NumberPlateActionTypes.GET_PRIVATE_NUMBER_PLATES_BY_PATH.SUCCESS,
      payload: extractStoreIds(outData, path),
    });
  } catch (err) {
    yield put({
      type: NumberPlateActionTypes.GET_PRIVATE_NUMBER_PLATES_BY_PATH.ERROR,
      payload: err,
    });

    reportToBugsnag(err, 'getIndividualByPath');
  }
}

export function* setPlateKeywordForSearch({ payload }) {
  yield put({
    type: NumberPlateActionTypes.SET_PLATE_KEYWORD_FOR_SEARCH.SUCCESS,
    payload,
  });

  redirect(null, '/number-plates/search-results');
}

export function* numberPlateSearch({ payload }) {
  const { start, keyword, filter, order, pagesize = 25 } = payload;
  if (!keyword) {
    yield put({
      type: NumberPlateActionTypes.CHANGE_LOADING,
      payload: { isLoading: false },
    });
    return;
  }
  try {
    const data = yield call(numberPlateClient.numberPlateFirstSearch, {
      start,
      keyword,
      filter,
      pagesize,
      order,
    });
    const { numFound, list } = data;
    // segmeng track for number plate search
    segmentTrack('Number Plate Searched', {
      query: { keyword },
      numFound,
      vertical: 'Number plates',
    });
    yield put({
      type: NumberPlateActionTypes.NUMBER_PLATE_SEARCH.SUCCESS,
      payload: { list, numFound, start, keyword, pagesize },
    });
  } catch (err) {
    reportToBugsnag(err, 'numberPlateSearch');
    yield put({
      type: NumberPlateActionTypes.NUMBER_PLATE_SEARCH.ERROR,
    });
  }
}

export function* numberPlateLoadMore({ payload }) {
  const { keyword, filter, start, order, pagesize = 25 } = payload;
  let page = yield select(getNumberPlatePage);
  page += 1;
  try {
    const data = yield call(numberPlateClient.numberPlateFilter, {
      filter,
      keyword,
      start,
      page,
      order,
    });
    const { numFound, list } = data;
    yield put({
      type: NumberPlateActionTypes.NUMBER_PLATE_LOAD_MORE.SUCCESS,
      payload: { list, numFound, start, page, keyword, pagesize },
    });
  } catch (err) {
    reportToBugsnag(err, 'numberPlateLoadMore');
    yield put({
      type: NumberPlateActionTypes.NUMBER_PLATE_LOAD_MORE.ERROR,
    });
  }
}

export function* randomFetchPlates({ payload }) {
  const { category } = payload;
  try {
    const sort = `random_score_${Math.random()} desc`;
    const fq = category === 'all' ? null : `fq=category_s:${category}`;
    const { docs } = yield call(numberPlateSearchClient.numberPlateSearch, {
      start: 0,
      keyword: `*:*`,
      sort,
      fq,
      rows: 40,
    });
    yield put({
      type: NumberPlateActionTypes.RANDOM_FETCH_PLATES.SUCCESS,
      payload: { docs },
    });
  } catch (err) {
    reportToBugsnag(err, 'randomFetchPlates');
  }
}

export function* addFilter({ payload }) {
  try {
    const filters = yield select(getFilter);
    const sort = payload.sort || payload.sort === 0 ? payload.sort : filters.sort;
    const startWith = (payload.startWith || filters.startWith).toUpperCase();
    const endWith = (payload.endWith || filters.endWith).toUpperCase();
    const contains = (payload.contains || filters.contains).toUpperCase();
    const priceRange = payload.priceRange || filters.priceRange;
    let fq = contains === '*' ? '*' : `*${contains}*`;
    const length = payload.length || filters.length;
    const carYear = payload.carYear || filters.carYear;
    let plateType = payload.plateType || filters.plateType;
    plateType = plateType === 'irish' ? 'ni' : plateType;
    const filter = [];
    yield put({
      type: NumberPlateActionTypes.ADD_FILTER.SUCCESS,
      payload: { sort, startWith, endWith, plateType, carYear, contains, priceRange, length },
    });
    const keyword = yield select(getNumberPlateKeyword);
    const order = getSortMethod(sort);
    if (startWith !== '*') {
      fq = `${startWith}${fq}`;
    }
    if (endWith !== '*') {
      fq = `${fq}${endWith}`;
    }

    if (length) {
      filter.push(`length_i:${length}`);
    }

    filter.push(`minimum_year_s:[ * TO ${carYear} ]`);
    filter.push(`category_s:${plateType}`);

    if (priceRange !== '*') {
      if (priceRange !== '1000+') {
        const section = priceRange.split('-');
        filter.push(`car_price_f:[ ${section[0]} TO ${section[1]} ]`);
      } else {
        filter.push(`car_price_f:[ 1000 TO * ]`);
      }
    }

    const { docs, numFound, start } = yield call(numberPlateClient.numberPlateFilter, {
      filter,
      fq: `plate_s:${fq}`,
      keyword,
      sort: order,
      start: 0,
    });

    // segmeng track for number plate search
    segmentTrack('Number Plate List Filtered', {
      filters: [
        {
          type: 'startWith',
          value: startWith,
        },
        {
          type: 'endWith',
          value: endWith,
        },
        {
          type: 'priceRange',
          value: priceRange,
        },
        {
          type: 'plateType',
          value: plateType,
        },
        {
          type: 'length',
          value: length,
        },
        {
          type: 'contains',
          value: contains,
        },
        {
          type: 'carYear',
          value: carYear,
        },
      ],
      sorts: [
        {
          type: 'price',
          value: order,
        },
      ],
      products: [
        {
          quantity: numFound,
        },
      ],
    });

    yield put({
      type: NumberPlateActionTypes.NUMBER_PLATE_SEARCH.SUCCESS,
      payload: { docs, numFound, start, endWith, keyword, length },
    });
  } catch (err) {
    reportToBugsnag(err, 'addFilter');
  }
}

export function* clearFilter() {
  yield put({
    type: NumberPlateActionTypes.CLEAR_FILTER.SUCCESS,
    payload: {},
  });
  const keyword = yield select(getNumberPlateKeyword);
  yield put({
    type: NumberPlateActionTypes.NUMBER_PLATE_SEARCH.REQUEST,
    payload: { keyword, start: 0 },
  });
}

export function* sendContactUsEnquiry({ payload }) {
  try {
    yield call(numberPlateClient.sendEnquireRequest, payload);
    yield put({
      type: NumberPlateActionTypes.SUBMIT_ENQUIRE.SUCCESS,
    });
  } catch (err) {
    reportToBugsnag(err, 'sendNumberPlateEnquiry');
  }
}

const getSortMethod = sort => {
  let str = '';
  switch (sort) {
    case -1:
      str = 'score_i desc';
      break;
    case 0:
      str = 'car_price_f asc';
      break;
    case 1:
      str = 'car_price_f desc';
      break;
    case 2:
      str = 'plate_s asc';
      break;
    case 3:
      str = 'minimum_year_s asc';
      break;
    case 4:
      str = 'length_i asc';
      break;
    default:
      str = 'score_i desc';
      break;
  }
  return str;
};

export default [
  takeLatest(NumberPlateActionTypes.GET_SETTING.REQUEST, getSettings),
  takeLatest(NumberPlateActionTypes.GET_IDEAS_PAGES.REQUEST, getIdeasPages),
  takeLatest(NumberPlateActionTypes.GET_PRIVATE_NUMBER_PLATES_BY_PATH.REQUEST, getIndividualByPath),
  takeLatest(NumberPlateActionTypes.GET_TYPES_OF_REGISTRATIONS_BY_PATH.REQUEST, getIndividualByPath),
  takeLatest(NumberPlateActionTypes.GET_FAQS_BY_PATH.REQUEST, getIndividualByPath),
  takeLatest(NumberPlateActionTypes.NUMBER_PLATE_SEARCH.REQUEST, numberPlateSearch),
  takeLatest(NumberPlateActionTypes.NUMBER_PLATE_LOAD_MORE.REQUEST, numberPlateLoadMore),
  takeLatest(NumberPlateActionTypes.ADD_FILTER.REQUEST, addFilter),
  takeLatest(NumberPlateActionTypes.SET_PLATE_KEYWORD_FOR_SEARCH.REQUEST, setPlateKeywordForSearch),
  takeLatest(NumberPlateActionTypes.RANDOM_FETCH_PLATES.REQUEST, randomFetchPlates),
  takeLatest(NumberPlateActionTypes.CLEAR_FILTER.REQUEST, clearFilter),
  takeLatest(NumberPlateActionTypes.SUBMIT_ENQUIRE.REQUEST, sendContactUsEnquiry),
];
