import { createAsyncActionSet } from '../lib/action-helper';

export default {
  CREATE_AG_WARRANTIES_QUOTE: createAsyncActionSet('CREATE_AG_WARRANTIES_QUOTE'),
  UPDATE_QUOTE_INFO: createAsyncActionSet('UPDATE_QUOTE_INFO'),
  CLEAR_QUOTE_INFO: 'CLEAR_QUOTE_INFO',
  STORE_QUOTE_INFO: 'STORE_QUOTE_INFO',
  GET_AVAILABLE_PRODUCTS: createAsyncActionSet('GET_AVAILABLE_PRODUCTS'),
  GET_CLIENT_SECRET: createAsyncActionSet('GET_CLIENT_SECRET'),
  CLEAR_CLIENT_SECRET: 'CLEAR_CLIENT_SECRET',
  SUBMIT_PAYMENT: createAsyncActionSet('SUBMIT_PAYMENT'),
  CHECK_PAYMENT: createAsyncActionSet('CHECK_PAYMENT'),
  REQUIRE_CUSTOM_QUOTE: createAsyncActionSet('REQUIRE_CUSTOM_QUOTE'),

  SELECT_PRODUCT: createAsyncActionSet('SELECT_PRODUCT'),
  GET_QUOTE_INFO_BY_TOKEN: createAsyncActionSet('GET_QUOTE_INFO_BY_TOKEN'),
};
