const QUOTE_STATUS = {
  DRAFT: 'draft',
  CREATED: 'created',
  PENDING_PAYMENT: 'pending_payment',
  CONFIRMED: 'confirmed',
  EXPIRED: 'expired',
  DELETED: 'deleted',
  COMPLETED: 'completed',
  UNAUTHORIZED: '401',
  FORBIDDEN: '403',
  BAD: '400',
  CONTINUE_FORBIDDEN: 'CONTINUE_FORBIDDEN',
};

const QUOTE_ROUTES = {
  VEHICLE_DETAILS: '/car-warranties/vehicle-details',
  YOUR_DETAILS: '/car-warranties/your-details',
  YOUR_QUOTE: '/car-warranties/your-quote',
  WARRANTIES_DETAILS: '/car-warranties/warranty-details',
  PAYMENT: '/car-warranties/payment',
  HOMEPAGE: '/car-warranties',
  LOGIN: '/sign-in',
};

// Use construction of module.exports is required
// because of the absence of server support for export keyword
module.exports = {
  QUOTE_STATUS,
  QUOTE_ROUTES,
};
