import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteYourCarRequest } from 'store/actions/myCar';
import BaseConfirmationPopup from '../../../components/BaseConfirmationPopup';

const DeleteMyCarPopup = () => {
  const dispatch = useDispatch();
  const onDelete = () => dispatch(deleteYourCarRequest());

  return (
    <BaseConfirmationPopup
      title={`Are you sure you <strong>want to delete</strong> this car?`}
      description="By hitting yes, your car will be permanently deleted and can not be recovered"
      confirmAction={onDelete}
    />
  );
};

export default DeleteMyCarPopup;
