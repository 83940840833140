import { createActions } from 'redux-actions';
import MyCarTypes from '../action-types/myCar';

export const {
  addYourCarRequest,
  addYourCarSuccess,
  addYourCarError,

  deleteYourCarRequest,
  deleteYourCarSuccess,
  deleteYourCarError,

  updateYourCarRequest,
  updateYourCarSuccess,
  updateYourCarError,

  getYourCarsRequest,
  getYourCarsSuccess,
  getYourCarsError,

  setSelectedCar,

  setDateTarget,
} = createActions({
  [MyCarTypes.ADD_YOUR_CAR.REQUEST]: data => data,
  [MyCarTypes.ADD_YOUR_CAR.SUCCESS]: data => data,
  [MyCarTypes.ADD_YOUR_CAR.ERROR]: data => data,

  [MyCarTypes.DELETE_YOUR_CAR.REQUEST]: data => data,
  [MyCarTypes.DELETE_YOUR_CAR.SUCCESS]: data => data,
  [MyCarTypes.DELETE_YOUR_CAR.ERROR]: data => data,

  [MyCarTypes.UPDATE_YOUR_CAR.REQUEST]: data => data,
  [MyCarTypes.UPDATE_YOUR_CAR.SUCCESS]: data => data,
  [MyCarTypes.UPDATE_YOUR_CAR.ERROR]: data => data,

  [MyCarTypes.GET_YOUR_CARS.REQUEST]: data => data,
  [MyCarTypes.GET_YOUR_CARS.SUCCESS]: data => data,
  [MyCarTypes.GET_YOUR_CARS.ERROR]: data => data,

  [MyCarTypes.SET_SELECTED_CAR]: data => data,

  [MyCarTypes.SET_DATE_TARGET]: data => data,
});
