export default class {
  constructor(client) {
    this.resourceUrl = '/finance-flow';
    this.endpoints = {
      init: `${this.resourceUrl}/init`,
      fetch: `${this.resourceUrl}/getByRefId`,
      append: `${this.resourceUrl}/append`,
      submit: `${this.resourceUrl}/submit`,
      appendCheckAccount: `${this.resourceUrl}/appendCheckAccount`,
    };
    this.client = client;
  }

  addAuth = params => {
    const token = sessionStorage.getItem('finance-quote-authorization');
    if (token) {
      return {
        ...params,
        financeQuoteToken: token,
      };
    }

    return params;
  };

  generateFullApplicationData({
    applicantDetails,
    applicationDetails,
    internals: { hasAccount, password, gdpr_consent, step },
  }) {
    const { AdditionalDetails } = applicantDetails;
    const applicantCopy = {
      ...applicantDetails,
      AdditionalDetails: { ...AdditionalDetails },
    };
    if (
      applicantCopy &&
      applicantCopy.ApplicantEmployment &&
      applicantCopy.ApplicantEmployment.length > 0 &&
      applicantCopy.AdditionalDetails &&
      applicantCopy.AdditionalDetails.OtherMonthlyIncome
    ) {
      applicantCopy.ApplicantEmployment[0].NetMonthlyIncome = applicantCopy.AdditionalDetails.OtherMonthlyIncome;
      delete applicantCopy.AdditionalDetails.OtherMonthlyIncome;
    }
    let data = {
      Applicants: [
        {
          ...applicantCopy,
        },
      ],
      ApplicationDetails: applicationDetails,
      gdpr_consent,
      hasAccount,
      password,
      step: window.location.pathname,
    };
    return this.addAuth(data);
  }

  fetch = () => this.client.post(this.endpoints.fetch, this.addAuth());

  init = applicationData => {
    const data = this.generateFullApplicationData(applicationData);
    return this.client.post(this.endpoints.init, data);
  };

  append = applicationData =>
    this.client.post(this.endpoints.append, this.generateFullApplicationData(applicationData));

  appendCheckAccount = applicationData =>
    this.client.post(this.endpoints.appendCheckAccount, this.generateFullApplicationData(applicationData));

  submit = applicationData =>
    this.client.post(this.endpoints.submit, this.generateFullApplicationData(applicationData));
}
