import { createSelector } from 'reselect';

export const getWarrantiesQuoteProcess = state => state.warrantiesAgQuoteProcess;
export const getWarrantiesQuoteId = createSelector(getWarrantiesQuoteProcess, warrantyData => warrantyData.id);
export const getWarrantiesQuoteToken = createSelector(getWarrantiesQuoteProcess, warrantyData => warrantyData.token);

export const getVehicleData = createSelector(getWarrantiesQuoteProcess, warrantyData => warrantyData.vehicle);
export const getCustomerData = createSelector(getWarrantiesQuoteProcess, warrantyData => warrantyData.customer);
export const getProductData = createSelector(getWarrantiesQuoteProcess, warrantyData => warrantyData.product);
export const getAvailableProducts = createSelector(getProductData, warrantyData => warrantyData.availableProducts);

export const getClientSecret = createSelector(getWarrantiesQuoteProcess, warrantyData => warrantyData.clientSecret);
