import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getRootModalState } from 'store/selectors/modal';
import { modalClose } from 'store/actions/modal';
import CarAuctionPlaceBidForm from 'containers/auction/carAuctionPlaceBidFormContainer';
import { DeleteAccountPopup, RequestPersonalDataPopup } from 'modules/User/MyAccount/Settings/EditProfile';
import CancelQuotePopup from 'modules/MyAccount/ScrapMyCar/cancelQuotePopup';
import AmendCollectionPopup from 'modules/MyAccount/ScrapMyCar/amendCollectionPopup';
import AddYourCarPopup from 'modules/MyAccount/AddYourCar/AddYourCarPopup';
import DeleteMyCarPopup from 'modules/MyAccount/MyCars/DeleteMyCarPopup';
import IframePopup from 'components/IframePopup/IframePopup';
import ConfirmPopup from 'components/ConfirmPopup/ConfirmPopup';
import RestartPopup from 'containers/plateCheckout/orderProcess/restartPopup';
import DeleteQuotePopup from '../modules/MyAccount/ScrapMyCar/deleteQuotePopup';
import ForgotPasswordPopup from '../modules/ForgotPassword/ForgotPasswordPopup';
import ApprovedAccountWarning from '../components/approvedAccountWarning';
import WarrantiesCustomQuote from '../components/carWarranties/QuoteProcess/vehicleDetails/CustomQuotePopup/CustomQuotePopup';

export const MODAL_TYPE = {
  placeBids: 'placeBids',
  login: 'login',
  forgotPassword: 'forgotPassword',
  deleteAccountPopup: 'deleteAccountPopup',
  requestPersonalDataPopup: 'requestPersonalDataPopup',
  deleteQuotePopup: 'deleteQuotePopup',
  cancelQuotePopup: 'cancelQuotePopup',
  amendCollectionPopup: 'amendCollectionPopup',
  addYourCarPopup: 'addYourCarPopup',
  deleteMyCarPopup: 'deleteMyCarPopup',
  iframePopup: 'iframePopup',
  restartPopup: 'restartPopup',
  warrantiesCustomQuote: 'warrantiesCustomQuote',
  confirmPopup: 'confirmPopup',
};

const MODAL_COMPONENTS = {
  placeBids: CarAuctionPlaceBidForm,
  login: ApprovedAccountWarning,
  forgotPassword: ForgotPasswordPopup,
  deleteAccountPopup: DeleteAccountPopup,
  requestPersonalDataPopup: RequestPersonalDataPopup,
  deleteQuotePopup: DeleteQuotePopup,
  cancelQuotePopup: CancelQuotePopup,
  amendCollectionPopup: AmendCollectionPopup,
  addYourCarPopup: AddYourCarPopup,
  deleteMyCarPopup: DeleteMyCarPopup,
  iframePopup: IframePopup,
  restartPopup: RestartPopup,
  warrantiesCustomQuote: WarrantiesCustomQuote,
  confirmPopup: ConfirmPopup,
};

const exceptions = [MODAL_TYPE.deleteAccountPopup, MODAL_TYPE.requestPersonalDataPopup];

const RootModal = ({ rootModalProps: { modalType, isModalOpen, modalInfo, backdrop }, closeModal }) => {
  const ModalComponent = MODAL_COMPONENTS[modalType];

  return ModalComponent ? (
    <div className="root-modal">
      <Modal
        className={`modal-${exceptions.includes(modalType) ? 'edit-' : ''}component`}
        show={isModalOpen}
        backdrop={backdrop || 'static'}
        keyboard={false}
        onHide={closeModal}
      >
        <button type="button" className="fancybox-close-small" title="Close" onClick={closeModal} />

        <Modal.Body>
          <ModalComponent {...modalInfo} />
        </Modal.Body>
      </Modal>
    </div>
  ) : null;
};

RootModal.propTypes = {
  rootModalProps: PropTypes.shape({
    modalType: PropTypes.string,
    isModalOpen: PropTypes.bool,
  }),
  closeModal: PropTypes.func,
  modalInfo: PropTypes.any,
  backdrop: PropTypes.string,
};

RootModal.defaultProps = {
  rootModalProps: {},
  closeModal: () => {},
};

const mapStateToProps = createStructuredSelector({
  rootModalProps: getRootModalState(),
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(modalClose()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RootModal);
