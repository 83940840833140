import { createActions } from 'redux-actions';
import FaqTypes from '../action-types/faq';

export const {
  getFaqRequest,
  getFaqSuccess,
  getFaqError,

  searchFaqRequest,
  searchFaqSuccess,
  searchFaqError,

  faqLandingRequest,
  faqLandingSuccess,
  faqLandingError,

  getFaqByPathRequest,
  getFaqByPathSuccess,
  getFaqByPathError,

  getFaqCategoriesRequest,
  getFaqCategoriesSuccess,
  getFaqCategoriesError,

  setSearchPhrase,
} = createActions({
  [FaqTypes.GET_FAQ.REQUEST]: payload => payload,
  [FaqTypes.GET_FAQ.SUCCESS]: payload => payload,
  [FaqTypes.GET_FAQ.ERROR]: payload => payload,

  [FaqTypes.SEARCH_FAQ.REQUEST]: payload => payload,
  [FaqTypes.SEARCH_FAQ.SUCCESS]: payload => payload,
  [FaqTypes.SEARCH_FAQ.ERROR]: payload => payload,

  [FaqTypes.FAQ_LANDING.REQUEST]: payload => payload,
  [FaqTypes.FAQ_LANDING.SUCCESS]: payload => payload,
  [FaqTypes.FAQ_LANDING.ERROR]: payload => payload,

  [FaqTypes.GET_FAQ_BY_PATH.REQUEST]: payload => payload,
  [FaqTypes.GET_FAQ_BY_PATH.SUCCESS]: payload => payload,
  [FaqTypes.GET_FAQ_BY_PATH.ERROR]: payload => payload,

  [FaqTypes.GET_FAQ_CATEGORIES.REQUEST]: payload => payload,
  [FaqTypes.GET_FAQ_CATEGORIES.SUCCESS]: payload => payload,
  [FaqTypes.GET_FAQ_CATEGORIES.ERROR]: payload => payload,

  [FaqTypes.SET_SEARCH_PHRASE]: payload => payload,
});
