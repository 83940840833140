import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SelectFormGroup from 'components/form/SelectFormGroup';

const BookingJobSlotItem = ({ name, value, values, setFieldValue, originCollectionDate }) => {
  const [isShowAll, setShowMore] = useState(typeof window !== 'undefined' && window.innerWidth > 768);
  const onSlotSelected = item => {
    setFieldValue(name, {
      date: item.date,
      startTime: item.startTime,
      endTime: item.endTime,
      slotDefaultType: item.slotDefaultType,
    });
  };
  const [bookingSlots, setBookingSlots] = useState({});

  const slotIsLimited = useMemo(() => {
    const availableSlots = values?.slice(0, 10).filter(item => item.availableSlots > 0);
    return values?.length > 0 && availableSlots.length < 2;
  }, [values]);

  const selectOptions = useMemo(() => {
    return values
      ?.filter(item => !!item.availableSlots)
      ?.map(item => {
        return {
          label: `${item.date} ${item.startTime}-${item.endTime}`,
          value: item.key,
          ...item,
        };
      });
  }, [values]);

  const valueMiddleTime = useMemo(() => {
    if (value.date) {
      const startTime = moment(`${value.date} ${value.startTime}`);
      const endTime = moment(`${value.date} ${value.endTime}`);

      return startTime.add(endTime.diff(startTime) / 2);
    } else {
      return undefined;
    }
  }, [value]);

  const currentOption = useMemo(() => {
    return selectOptions?.find(
      item =>
        valueMiddleTime &&
        valueMiddleTime.isBetween(moment(`${item.date} ${item.startTime}`), moment(`${item.date} ${item.endTime}`)),
    );
  }, [valueMiddleTime, selectOptions]);

  useEffect(() => {
    if (slotIsLimited) {
      setShowMore(true);
    }
  }, [slotIsLimited]);

  const blockClassName = useMemo(() => {
    return isShowAll ? 'mini-block' : '';
  }, [isShowAll]);

  const hiddenClassName = useMemo(() => {
    return isShowAll ? 'd-none d-md-block' : '';
  }, [isShowAll]);

  useEffect(() => {
    const matchValue =
      value &&
      value.date &&
      values.find(
        item =>
          value.date === item.date &&
          valueMiddleTime &&
          valueMiddleTime.isBetween(moment(`${item.date} ${item.startTime}`), moment(`${item.date} ${item.endTime}`)),
      );
    if (!matchValue?.availableSlots && values && values.length > 0) {
      const firstCanSlotItem = values.find(item => {
        return item.availableSlots > 0;
      });
      if (firstCanSlotItem) {
        setFieldValue(name, {
          date: firstCanSlotItem.date,
          startTime: firstCanSlotItem.startTime,
          endTime: firstCanSlotItem.endTime,
          slotDefaultType: firstCanSlotItem.slotDefaultType,
        });
      }
    }
  }, [value, valueMiddleTime, values]);

  useEffect(() => {
    const result = [];
    values.forEach(item => {
      if (!result[item.slotDefaultType]) result[item.slotDefaultType] = [];
      result[item.slotDefaultType].push(item);
    });
    if (result.length > 0) {
      setBookingSlots(result);
    }
  }, [values]);

  const showNumber = isShowAll ? Math.min(values.length, 10) : 5;

  return (
    <div>
      {Array.isArray(selectOptions) && selectOptions?.length > 0 && (
        <SelectFormGroup
          options={selectOptions}
          name="slot"
          placeholder="Select your slot"
          onChange={(name, value) => {
            onSlotSelected(value);
          }}
          stylesTheme="longAuction"
          currentOption={currentOption}
          setFieldTouched={() => {}}
          setFieldValue={() => {}}
          isOptionDisabled={item => item.availableSlots <= 0}
        />
      )}
      <div className="form-row double-input-row arrange-collection align-items-stretch">
        <div className="header-container">
          {values instanceof Array &&
            values
              .filter(item => item.slotDefaultType === values[0].slotDefaultType)
              .slice(0, showNumber)
              .map(item => {
                return (
                  <div
                    key={`header_${item.key}`}
                    className="text-center border border-bottom-2 pb-md-2 pt-md-2 pr-1 pl-1 col header-block d-flex flex-column justify-content-center"
                  >
                    <strong style={{ lineHeight: '1.3125rem' }}>{item.day}</strong>
                    <div className={`font-weight-light small date-text`}>{moment(item.date).format('Do MMM')}</div>
                  </div>
                );
              })}
        </div>

        {Object.keys(bookingSlots).map(index => {
          return (
            <div className="slot-container">
              {bookingSlots[index].slice(0, showNumber).map(item => {
                const isDisabled = item.availableSlots <= 0;
                const isSelect =
                  value &&
                  value.date &&
                  valueMiddleTime &&
                  valueMiddleTime.isBetween(
                    moment(`${item.date} ${item.startTime}`),
                    moment(`${item.date} ${item.endTime}`),
                  );
                const isOriginal =
                  originCollectionDate &&
                  originCollectionDate.startTime === item.startTime &&
                  originCollectionDate.date === item.date;
                return (
                  <React.Fragment key={item.key}>
                    <div className="text-center border col p-0">
                      <button
                        type="button"
                        className={`${isSelect ? 'slot-select' : ''} ${
                          isOriginal ? 'slot-origin-select' : ''
                        } btn btn-success slot-block ${blockClassName}`}
                        disabled={isDisabled}
                        onClick={() => onSlotSelected(item)}
                      >
                        <div className={`${hiddenClassName}`}>{item.slotName}</div>
                        <div className="small pl-1 pr-1">{item.timeDisplay}</div>
                        <div className="small">{isDisabled ? '' : isSelect ? 'SELECTED' : 'AVAILABLE'}</div>
                      </button>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
      </div>

      <div className="showMoreLink">
        <span role="button" tabIndex="-1" className="register__link" onClick={() => setShowMore(!isShowAll)}>
          {isShowAll ? 'Hide' : 'Show more'}
        </span>
      </div>

      {value && value.date && (
        <p
          className="label d-block mt-1"
          style={{
            marginBottom: '-12px !important',
          }}
        >
          <span className="mr-1">Collection time:</span>
          {`${moment(value.date).format('dddd Do MMMM')} between ${value.startTime} & ${value.endTime}`}
        </p>
      )}
    </div>
  );
};

BookingJobSlotItem.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      day: PropTypes.string,
      slotType: PropTypes.string,
      availableSlots: PropTypes.number,
      timeDisplay: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    }),
  ),
};

export default BookingJobSlotItem;
