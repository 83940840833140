import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getScrappedCarsStatus } from 'store/selectors/scrappedCars';
import { cancelQuoteMyAccountRequest } from 'store/actions/scrappedCars';
import { STATUS } from 'store/constants';
import ConfirmationWithReasonPopup from '../../../components/ConfirmationWithReasonPopup';

const CancelQuotePopup = () => {
  const dispatch = useDispatch();
  const cancelQuote = ({ reason }) => {
    dispatch(cancelQuoteMyAccountRequest(reason));
  };
  const status = useSelector(getScrappedCarsStatus);
  return (
    <ConfirmationWithReasonPopup
      title={`Are you sure you <strong>want to cancel</strong> this scrap car quote?`}
      description="By hitting yes, your scrap car quote will be permanently cancelled and can not be recovered"
      confirmAction={cancelQuote}
      isConfirmButtonDisabled={status === STATUS.RUNNING}
    />
  );
};

export default CancelQuotePopup;
