import { handleActions } from 'redux-actions';
import immutable, { extend } from 'immutability-helper';
import ValuationQuoteTypes from '../action-types/valuationQuoteProcess';
import { STATUS } from '../constants';

extend('$setIfUpdated', (value, original) => (value === undefined ? original : value));

export const ValuationQuoteState = {
  registrationNumber: '',
  mileage: '',
  status: '',
  valuationOption: '',
  BillingAccount: '',
  valuationRequest: '',
  valuationResponse: '',
  carValuationDataID: '',
  vehicleImage: '',
};

export default {
  valuationQuoteProcess: handleActions(
    {
      [ValuationQuoteTypes.SET_VALUATION_VALUES.REQUEST]: (state, { payload: { registrationNumber, mileage } }) =>
        immutable(state, {
          registrationNumber: { $setIfUpdated: registrationNumber },
          mileage: { $setIfUpdated: mileage },
        }),
      [ValuationQuoteTypes.CREATE_VALUATION_QUOTE.REQUEST]: state =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
        }),
      [ValuationQuoteTypes.CREATE_VALUATION_QUOTE.SUCCESS]: (
        state,
        { payload: { BillingAccount, Request, Response, carValuationDataID, vehicleImage } },
      ) =>
        immutable(state, {
          BillingAccount: { $set: BillingAccount },
          valuationRequest: { $set: Request },
          valuationResponse: { $set: Response },
          carValuationDataID: { $set: carValuationDataID },
          vehicleImage: { $set: vehicleImage },
          status: { $set: STATUS.SUCCESS },
        }),
      [ValuationQuoteTypes.SET_VALUATION_OPTION.REQUEST]: (state, { payload: { values } }) =>
        immutable(state, {
          valuationOption: { $setIfUpdated: values.valuationOption },
        }),
      [ValuationQuoteTypes.CLEAR_VALUATION_QUOTE_PROCESS_DATA.actionName]: () => ValuationQuoteState,
    },
    ValuationQuoteState,
  ),
};
