import { createSelector } from 'reselect';
import { byId, makeGetByPath } from 'store/selectors/cms/genericSelectors';
import { makeGetCmsByType } from 'store/selectors/cms';

const getSettings = state => state.numberPlate.settings;
const getIdeas = state => state.numberPlate.ideas;
const getNumberPlate = state => state.numberPlate;

const getByPath = makeGetByPath('numberPlate');
const getCmsPrivateNumberPlatesPage = makeGetCmsByType('nodeNumberPlate');
export const getNumberPlateSettings = createSelector(
  [getSettings, makeGetCmsByType('configPagesNumberPlateSettings')],
  (iSettings, cms) => {
    const [settings] = byId(iSettings, cms);
    return settings;
  },
);

export const getNumberPlateIdeasPages = createSelector(
  [getIdeas, makeGetCmsByType('configPagesNumberPlateIdeas')],
  (iSettings, cms) => {
    const [settings] = byId(iSettings, cms);
    return settings;
  },
);

export const getIndividualByPath = createSelector(
  [getByPath, getCmsPrivateNumberPlatesPage],
  (id, privateNumberPlates) => {
    return privateNumberPlates[id] || {};
  },
);

export const getRandomPlates = createSelector(getNumberPlate, state => state.random_plates);
export const getNumberPlateSearchResult = createSelector(getNumberPlate, state => state.searchResultWithGroup);
export const getNumberPlateResultStart = createSelector(getNumberPlate, state => state.start);
export const getNumberPlateKeyword = createSelector(getNumberPlate, state => state.keyword);
export const getNumberPlateNumFound = createSelector(getNumberPlate, state => state.numFound);
export const getNumberPlatePriceRange = createSelector(getNumberPlate, state => state.priceRange);
export const getNumberPlateCarYear = createSelector(getNumberPlate, state => state.carYear);
export const getNumberPlatePlateType = createSelector(getNumberPlate, state => state.plateType);
export const getHideLoadMoreButton = createSelector(getNumberPlate, state => state.hideLoadMore);
export const getSearchLoading = createSelector(getNumberPlate, state => state.searchLoading);
export const getPlateLength = createSelector(getNumberPlate, state => state.plateLength);
export const getNumberPlatePage = createSelector(getNumberPlate, state => state.page);

export const getFilter = createSelector(getNumberPlate, state => state.filters);
