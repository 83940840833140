import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCheckoutBusySelector, getCartId, getFormattedRegSelector } from 'store/selectors/numberPlate';
import { restartRequest } from 'store/actions/numberPlate';
import { modalClose } from 'store/actions/modal';
import BaseConfirmationPopup from 'components/BaseConfirmationPopup';
const RestartPopup = () => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(getCheckoutBusySelector);
  const cartId = useSelector(getCartId);
  const registration = useSelector(getFormattedRegSelector);
  const confirmAction = () => {
    dispatch(restartRequest({ cartId }));
    dispatch(modalClose());
  };

  return (
    <BaseConfirmationPopup
      title={`Entered information will not be saved and ${registration} will not be secured.`}
      description=""
      confirmAction={confirmAction}
      isConfirmButtonDisabled={isSubmitting}
    />
  );
};

export default RestartPopup;
