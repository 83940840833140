import { createActions } from 'redux-actions';
import NumberPlate from '../action-types/numberPlate';

export const {
  setCartId,
  resetCheckStatus,
  setNavRoute,
  manuallyEnterAddress,
  startAction,
  stopAction,
  setCustom,
  dropCart,

  setTransferCustom,

  goHome,

  restartRequest,
  restartSuccess,
  restartError,

  checkRegRequest,
  checkRegSuccess,
  checkRegError,

  submitPersonalDetailRequest,
  submitPersonalDetailSuccess,
  submitPersonalDetailError,

  submitCustomTemplateRequest,
  submitCustomTemplateSuccess,
  submitCustomTemplateError,

  submitTransferDeliveryRequest,
  submitTransferDeliverySuccess,
  submitTransferDeliveryError,

  confirmCartRequest,
  confirmCartSuccess,
  confirmCartError,

  placeOrderRequest,
  placeOrderSuccess,
  placeOrderError,

  fetchCartRequest,
  fetchCartSuccess,
  fetchCartError,

  getCustomizeOptionRequest,
  getCustomizeOptionSuccess,
  getCustomizeOptionError,

  createCartRequest,
  createCartSuccess,
  createCartError,

  getTransferDeliveryRequest,
  getTransferDeliverySuccess,
  getTransferDeliveryError,

  updateTransferAndGetDeliveryRequest,
  updateTransferAndGetDeliverySuccess,
  updateTransferAndGetDeliveryError,

  getDeliveryMethodRequest,
  getDeliveryMethodSuccess,
  getDeliveryMethodError,

  getCustomerCardsRequest,
  getCustomerCardsSuccess,
  getCustomerCardsError,

  getStripeSetupIntentRequest,
  getStripeSetupIntentSuccess,
  getStripeSetupIntentError,

  checkUserLoginRequest,
  checkUserLoginSuccess,
  checkUserLoginError,

  checkUserAccountRequest,
  checkUserAccountSuccess,
  checkUserAccountError,
} = createActions({
  [NumberPlate.SET_CART_ID]: payload => payload,
  [NumberPlate.RESET_CHECK_STATUS]: payload => payload,
  [NumberPlate.SET_NAV_ROUTE]: payload => payload,
  [NumberPlate.MANUALLY_ENTER_ADDRESS]: payload => payload,
  [NumberPlate.START_ACTION]: payload => payload,
  [NumberPlate.STOP_ACTION]: payload => payload,
  [NumberPlate.SET_CUSTOM]: payload => payload,
  [NumberPlate.DROP_CART]: payload => payload,

  [NumberPlate.SET_TRANSFER_CUSTOM]: payload => payload,
  [NumberPlate.GO_HOME]: payload => payload,

  [NumberPlate.RESTART.REQUEST]: payload => payload,
  [NumberPlate.RESTART.SUCCESS]: payload => payload,
  [NumberPlate.RESTART.ERROR]: payload => payload,

  [NumberPlate.CHECK_REG.REQUEST]: payload => payload,
  [NumberPlate.CHECK_REG.SUCCESS]: payload => payload,
  [NumberPlate.CHECK_REG.ERROR]: payload => payload,

  [NumberPlate.SUBMIT_PERSONAL_DETAIL.REQUEST]: payload => payload,
  [NumberPlate.SUBMIT_PERSONAL_DETAIL.SUCCESS]: payload => payload,
  [NumberPlate.SUBMIT_PERSONAL_DETAIL.ERROR]: payload => payload,

  [NumberPlate.SUBMIT_CUSTOM_TEMPLATE.REQUEST]: payload => payload,
  [NumberPlate.SUBMIT_CUSTOM_TEMPLATE.SUCCESS]: payload => payload,
  [NumberPlate.SUBMIT_CUSTOM_TEMPLATE.ERROR]: payload => payload,

  [NumberPlate.SUBMIT_TRANSFER_DELIVERY.REQUEST]: payload => payload,
  [NumberPlate.SUBMIT_TRANSFER_DELIVERY.SUCCESS]: payload => payload,
  [NumberPlate.SUBMIT_TRANSFER_DELIVERY.ERROR]: payload => payload,

  [NumberPlate.CONFIRM_CART.REQUEST]: payload => payload,
  [NumberPlate.CONFIRM_CART.SUCCESS]: payload => payload,
  [NumberPlate.CONFIRM_CART.ERROR]: payload => payload,

  [NumberPlate.PLACE_ORDER.REQUEST]: payload => payload,
  [NumberPlate.PLACE_ORDER.SUCCESS]: payload => payload,
  [NumberPlate.PLACE_ORDER.ERROR]: payload => payload,

  [NumberPlate.FETCH_CART.REQUEST]: payload => payload,
  [NumberPlate.FETCH_CART.SUCCESS]: payload => payload,
  [NumberPlate.FETCH_CART.ERROR]: payload => payload,

  [NumberPlate.GET_CUSTOMIZE_OPTION.REQUEST]: payload => payload,
  [NumberPlate.GET_CUSTOMIZE_OPTION.SUCCESS]: payload => payload,
  [NumberPlate.GET_CUSTOMIZE_OPTION.ERROR]: payload => payload,

  [NumberPlate.CREATE_CART.REQUEST]: payload => payload,
  [NumberPlate.CREATE_CART.SUCCESS]: payload => payload,
  [NumberPlate.CREATE_CART.ERROR]: payload => payload,

  [NumberPlate.GET_TRANSFER_DELIVERY.REQUEST]: payload => payload,
  [NumberPlate.GET_TRANSFER_DELIVERY.SUCCESS]: payload => payload,
  [NumberPlate.GET_TRANSFER_DELIVERY.ERROR]: payload => payload,

  [NumberPlate.UPDATE_TRANSFER_AND_GET_DELIVERY.REQUEST]: payload => payload,
  [NumberPlate.UPDATE_TRANSFER_AND_GET_DELIVERY.SUCCESS]: payload => payload,
  [NumberPlate.UPDATE_TRANSFER_AND_GET_DELIVERY.ERROR]: payload => payload,

  [NumberPlate.GET_DELIVERY_METHOD.REQUEST]: payload => payload,
  [NumberPlate.GET_DELIVERY_METHOD.SUCCESS]: payload => payload,
  [NumberPlate.GET_DELIVERY_METHOD.ERROR]: payload => payload,

  [NumberPlate.GET_CUSTOMER_CARDS.REQUEST]: payload => payload,
  [NumberPlate.GET_CUSTOMER_CARDS.SUCCESS]: payload => payload,
  [NumberPlate.GET_CUSTOMER_CARDS.ERROR]: payload => payload,

  [NumberPlate.GET_STRIPE_SETUP_INTENT.REQUEST]: payload => payload,
  [NumberPlate.GET_STRIPE_SETUP_INTENT.SUCCESS]: payload => payload,
  [NumberPlate.GET_STRIPE_SETUP_INTENT.ERROR]: payload => payload,

  // check user exists for customize template: to show login or ont
  [NumberPlate.CHECK_USER_LOGIN.REQUEST]: payload => payload,
  [NumberPlate.CHECK_USER_LOGIN.SUCCESS]: payload => payload,
  [NumberPlate.CHECK_USER_LOGIN.ERROR]: payload => payload,

  // check user has account or not for summary page: to show password setting
  [NumberPlate.CHECK_USER_ACCOUNT.REQUEST]: payload => payload,
  [NumberPlate.CHECK_USER_ACCOUNT.SUCCESS]: payload => payload,
  [NumberPlate.CHECK_USER_ACCOUNT.ERROR]: payload => payload,
});
