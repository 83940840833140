import { put, take, takeLatest, call } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { reportToBugsnag} from 'lib/bugsnag';
import VehicleInspectionsTypes from '../action-types/vehicleInspections';
import CmsTypes from '../action-types/cms';
import { wrapWith, extractStoreIds } from '../lib/mergeToStateHelper';
import { snakeToCamelCase } from '../lib/snakeToCamelCase';
import { SOME_ERROR_OCCURRED } from '../constants';

export function* getCMSData({ payload }) {
  const STORE_AS = 'vehicleInspectionsPage';
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: { section: 'vehicle-inspections', ...payload },
      chan,
    });
    const {
      payload: { data, links },
      path,
    } = yield take(chan);
    yield put({
      type: VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_BY_TYPE.SUCCESS,
      payload: wrapWith(STORE_AS, extractStoreIds(data, path, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getCMSData');


    yield put({
      type: VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_BY_TYPE.ERROR,
      payload: err,
    });
  }
}

export function* getSettings() {
  const fingerprint = 'vehicleInspectionsSettings';
  const chan = yield call(channel);
  try {
    const type = 'settings';
    yield put({
      type: CmsTypes.FETCH_CMS.REQUEST,
      payload: {
        section: 'vehicle-inspections',
        type,
        includes: ['homepage', 'type', 'top_banner', 'top_banner.image.file'],
      },
      fingerprint,
      chan,
    });
    const fullData = yield take(chan);
    //   ({ type: t, fingerprint: fp }) => t === CmsTypes.FETCH_CMS.SUCCESS && fingerprint === fp,
    // );
    const {
      payload: { data, links },
    } = fullData;
    yield put({
      type: VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_SETTINGS.SUCCESS,
      payload: wrapWith(type, extractStoreIds(data, undefined, links)),
    });
  } catch (err) {
    reportToBugsnag(err, 'getSettings');


    yield put({
      type: VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_SETTINGS.ERROR,
      payload: err,
    });
  }
}

export function* getIndividualByPath({ payload: { specificIncludes, ...payload } }) {
  const chan = yield call(channel);
  try {
    yield put({
      type: CmsTypes.RESOLVE_CMS_ENTITY.REQUEST,
      payload,
      chan,
    });
    const {
      payload: {
        data,
        path: resourcePath,
        jsonapi: { resourceName },
      },
      path,
    } = yield take(chan);
    let outData = data;
    const camelResourceName = snakeToCamelCase(resourceName);
    if (camelResourceName !== 'vehicleInspectionsPage') {
      yield put({
        type: CmsTypes.FETCH_CMS_INDIVIDUAL.REQUEST,
        payload: { path: resourcePath, includes: specificIncludes[camelResourceName] },
        path,
      });
      const {
        payload: { data: includedData },
      } = yield take(CmsTypes.FETCH_CMS_INDIVIDUAL.SUCCESS);
      outData = includedData;
    }

    yield put({
      type: VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_BY_PATH.SUCCESS,
      payload: extractStoreIds(outData, path),
    });
  } catch (err) {
    reportToBugsnag(err, 'getIndividualByPath');


    yield put({
      type: VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_BY_PATH.ERROR,
      payload: err,
    });
  }
}

export default [
  takeLatest(VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_BY_TYPE.REQUEST, getCMSData),
  takeLatest(VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_BY_PATH.REQUEST, getIndividualByPath),

  takeLatest(VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_SETTINGS.REQUEST, getSettings),
];
