import { createActions } from 'redux-actions';
import MotQuoteTypes from '../action-types/motQuoteProcess';

export const {
  createMotQuoteRequest,
  createMotQuoteSuccess,
  createMotQuoteError,
  clearMotQuoteProcessData,
} = createActions({
  [MotQuoteTypes.CREATE_MOT_QUOTE.REQUEST]: payload => payload,
  [MotQuoteTypes.CREATE_MOT_QUOTE.SUCCESS]: payload => payload,
  [MotQuoteTypes.CREATE_MOT_QUOTE.ERROR]: payload => payload,
  [MotQuoteTypes.CLEAR_MOT_QUOTE_PROCESS_DATA.actionName]: () => {},
});
