import { handleActions, combineActions } from 'redux-actions';
import immutable from 'immutability-helper';

import { formatPhoneNumber } from 'lib/formatters';
import UserTypes from '../action-types/user';
import QuoteTypes from '../action-types/quote';

import { STATUS } from '../constants';

export const userState = {
  status: STATUS.IDLE,
};

export default {
  user: handleActions(
    {
      // GET_USER_DATA
      [UserTypes.GET_USER_DATA.REQUEST]: state =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
        }),
      [UserTypes.GET_USER_DATA.SUCCESS]: (state, { payload }) =>
        immutable(state, {
          firstName: { $set: payload?.firstName },
          lastName: { $set: payload?.lastName },
          email: { $set: payload?.email },
          phoneNumber: { $set: formatPhoneNumber(payload?.phoneNumber) },
          dateOfBirth: { $set: payload?.dateOfBirth },
          subscribedToEmail: { $set: payload?.subscribedToEmail || '' },
          subscribedToSms: { $set: payload?.subscribedToSms || '' },
          status: { $set: STATUS.READY },
        }),
      [UserTypes.GET_USER_DATA.ERROR]: state =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),

      // SET USER EMAIL
      [combineActions(
        UserTypes.SET_EMAIL,
        QuoteTypes.FETCH_SCRAP_APPLICATION.SUCCESS,
        QuoteTypes.CONTINUE_WITH_QUOTE.SUCCESS,
        QuoteTypes.CONTINUE_WITH_QUOTE_SHORT_LINK.SUCCESS
      )]: (state, { payload }) =>
        immutable(state, {
          email: { $set: payload.email || '' },
        }),
      // SET USER GDPR_CONSENT
      [UserTypes.SET_GDPR_CONSENT]: (state, { payload }) =>
        immutable(state, {
          terms: { $set: payload.terms || '' },
        }),

      // SET USER FIRST NAME
      [UserTypes.SET_FIRST_NAME]: (state, { payload }) =>
        immutable(state, {
          firstName: { $set: payload?.firstName || '' },
        }),

      // SET USER LAST NAME
      [UserTypes.SET_LAST_NAME]: (state, { payload }) =>
        immutable(state, {
          lastName: { $set: payload?.lastName || '' },
        }),

      // SET USER PHONE NUMBER
      [UserTypes.SET_PHONE_NUMBER]: (state, { payload }) =>
        immutable(state, {
          phoneNumber: { $set: payload.phoneNumber || '' },
        }),

      // SET USER CONFIRMATION FLAG
      [UserTypes.SET_CONFIRMATION_VALUE]: (state, { payload }) =>
        immutable(state, {
          confirmationValue: { $set: payload.confirmationValue || false },
        }),

      [UserTypes.PERSONAL_DATA.REQUEST]: state =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
        }),

      [UserTypes.PERSONAL_DATA.SUCCESS]: state =>
        immutable(state, {
          status: { $set: STATUS.READY },
        }),

      [UserTypes.PERSONAL_DATA.ERROR]: state =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),

      [UserTypes.FORGOT_PASSWORD.REQUEST]: state =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
        }),

      [UserTypes.FORGOT_PASSWORD.SUCCESS]: state =>
        immutable(state, {
          status: { $set: STATUS.READY },
        }),
      [UserTypes.GET_PAYMENT_METHODS.REQUEST]: state =>
        immutable(state, {
          paymentMethods: { $set: [] },
        }),
      [UserTypes.GET_PAYMENT_METHODS.SUCCESS]: (state, { payload }) =>
        immutable(state, {
          paymentMethods: { $set: payload },
        }),
      [UserTypes.FORGOT_PASSWORD.ERROR]: state =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),
      [UserTypes.DROP_USER_DATA]: () => userState,
    },
    userState
  ),
};
