import { handleActions } from 'redux-actions';
import immutable, { extend } from 'immutability-helper';
import CarTaxTypes from '../action-types/carTaxProcess';
import { STATUS } from '../constants';

extend('$setIfUpdated', (value, original) => (value === undefined ? original : value));

export const CarTaxState = {
  registrationNumber: '',
  status: '',
  vedCurrentlyValid: '',
  vedExpiryDate: '',
  vedDaysRemaining: '',
  motSornInPlace: '',
  vedCo2band: '',
  vedCo2Emissions: '',
  vedBand: '',
  twelveMonth: '',
  vehicleRegistration: '',
  vehicleImage: '',
  response: '',
};

export default {
  carTaxProcess: handleActions(
    {
      [CarTaxTypes.CREATE_CAR_TAX.REQUEST]: (state, { payload: { registrationNumber } }) =>
        immutable(state, {
          registrationNumber: { $setIfUpdated: registrationNumber },
          status: { $set: STATUS.RUNNING },
        }),
      [CarTaxTypes.CREATE_CAR_TAX.SUCCESS]: (state, { payload: { 
        vedCurrentlyValid, vedExpiryDate, vedDaysRemaining, motSornInPlace, vedCo2band,
        vedCo2Emissions, vedBand, twelveMonth, vehicleRegistration, vehicleImage, response
      } }) =>
        immutable(state, {
          vedCurrentlyValid: { $set: vedCurrentlyValid },
          vedExpiryDate: { $set: vedExpiryDate },
          vedDaysRemaining: { $set: vedDaysRemaining },
          motSornInPlace: { $set: motSornInPlace },
          vedCo2band: { $set: vedCo2band },
          vedCo2Emissions: { $set: vedCo2Emissions },
          vedBand: { $set: vedBand },
          twelveMonth: { $set: twelveMonth },
          vehicleRegistration: { $set: vehicleRegistration },
          vehicleImage: { $set: vehicleImage },
          response: { $set: response },
          status: { $set: STATUS.SUCCESS },
        }),
      [CarTaxTypes.CLEAR_CAR_TAX_PROCESS_DATA.actionName]: () => CarTaxState,
    },
    CarTaxState,
  ),
};
