import { createAsyncActionSet } from '../lib/action-helper';

export default {
  DELETE_QUOTE: createAsyncActionSet('DELETE_QUOTE'),
  GET_QUOTE_HISTORY: createAsyncActionSet('GET_QUOTE_HISTORY'),
  CANCEL_QUOTE_MY_ACCOUNT: createAsyncActionSet('CANCEL_QUOTE_MY_ACCOUNT'),
  AMEND_QUOTE_COLLECTION: createAsyncActionSet('AMEND_QUOTE_COLLECTION'),
  GET_ACTIVE_QUOTES: createAsyncActionSet('GET_ACTIVE_QUOTES'),
  SET_ACTION_QUOTE_ID: 'SET_ACTION_QUOTE_ID',
  INITIALIZE_QUOTE_HISTORY: 'INITIALIZE_QUOTE_HISTORY',
  SET_RE_QUOTE_VRM: 'SET_RE_QUOTE_VRM',
};
