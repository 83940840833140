export default class {
  constructor(client) {
    this.client = client;
    this.resourceUrl = '/customer';
  }

  editProfile = data => this.client.post(`${this.resourceUrl}/profile`, data);

  createSupportTicket = data => this.client.postFormData(`${this.resourceUrl}/support`, data);

  getSupportTicketStatuses = () => this.client.get(`${this.resourceUrl}/support/statuses`);
}
